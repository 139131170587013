import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../lib/api/user';
import { ShortProfile } from '../../lib/api/users';
import { Track } from '../../lib/api/tracks';
import tracksReducer from './tracks';
import toArray from '../../utils/toArray';
import { UserStats } from '../../lib/api/statistics';

const { actions: trackActions } = tracksReducer;

export interface State {
  entries: Record<number, User>;
  fetching: Record<number, boolean>;
  isRequestingUser: boolean;
  isFetching: boolean;
  errorUser: string;
  shortProfile: ShortProfile | Record<string, never>;
  isRequestingShortProfile: boolean;
  errorShortProfile: string;
  notificationIds: number[];
  users: User[];
  isBlockingUser: boolean;
  isUnblockingUser: boolean;
  onlineUsersIds: number[];
  onlineSubscriptionsUsersIds: number[];
  isFetchingStats: Record<number, boolean>;
  stats: Record<number, UserStats | undefined>;
  isSpotlightFetching: boolean;
  successSpotlight?: boolean;
  spotlight?: User;
}

export const defaultState: State = {
  entries: {},
  fetching: {},
  isFetching: false,
  isRequestingUser: false,
  errorUser: '',
  shortProfile: {},
  isRequestingShortProfile: false,
  errorShortProfile: '',
  notificationIds: [],
  users: [],
  isBlockingUser: false,
  isUnblockingUser: false,
  onlineUsersIds: [],
  onlineSubscriptionsUsersIds: [],
  isFetchingStats: {},
  stats: {},
  isSpotlightFetching: false,
  successSpotlight: false,
  spotlight: undefined,
};

const slice = createSlice({
  name: 'USERS',
  initialState: defaultState,
  reducers: {
    addUsers: (state, { payload }: PayloadAction<User | User[]>) => {
      const usersAsArray = toArray(payload);
      usersAsArray.forEach((user) => {
        const currentUser = {
          ...(state.entries[user.id] || {}),
        };
        state.entries[user.id] = {
          ...currentUser,
          ...user,
        };
      });
    },

    requestFetch: (state, { payload: ids }: PayloadAction<number[]>) => {
      ids.forEach((id) => {
        state.fetching[id] = true;
      });
    },
    receiveFetch: (state, { payload: ids }: PayloadAction<number[]>) => {
      ids.forEach((id) => {
        delete state.fetching[id];
      });
    },
    requestShortProfile: (state: State) => {
      state.isRequestingShortProfile = true;
    },
    receiveShortProfile: (
      state: State,
      action: PayloadAction<ShortProfile>,
    ) => {
      state.isRequestingShortProfile = false;
      state.shortProfile = action.payload;
    },
    errorShortProfile: (state: State, action: PayloadAction<string>) => {
      state.isRequestingShortProfile = false;
      state.errorShortProfile = action.payload;
    },
    requestUser: (state: State) => {
      state.isRequestingUser = true;
    },
    receiveUser: (state: State) => {
      state.isRequestingUser = false;
    },
    errorUsers: (state: State, action: PayloadAction<string>) => {
      state.isRequestingUser = false;
      state.errorUser = action.payload;
    },
    changeFollowingByUser: (
      state,
      { payload: { id, flag } }: PayloadAction<{ id: number; flag: boolean }>,
    ) => {
      if (state.entries[id]) {
        state.entries[id].logged_in_user_follows_user = flag;
      }
    },

    blockUserRequest: (state) => {
      state.isBlockingUser = true;
    },
    blockUserSuccess: (state, { payload: userId }: PayloadAction<number>) => {
      state.isBlockingUser = false;
      state.entries[userId].blocked = true;
    },
    unblockUserError: (state) => {
      state.isBlockingUser = false;
      state.isUnblockingUser = false;
    },

    unblockUserRequest: (state) => {
      state.isUnblockingUser = true;
    },
    unblockUserSuccess: (state, { payload: userId }: PayloadAction<number>) => {
      state.isUnblockingUser = false;
      state.entries[userId].blocked = false;
    },

    addOnlineSubscriptions: (
      state,
      action: PayloadAction<number | number[]>,
    ) => {
      const userIds = action.payload;

      const ids = Array.isArray(userIds) ? userIds : [userIds];

      // add each userId to onlineSubscriptionsUsersIds if it is not there
      ids.forEach((userId) => {
        if (!state.onlineSubscriptionsUsersIds.includes(userId)) {
          state.onlineSubscriptionsUsersIds.push(userId);
        }
      });
    },
    removeOnlineSubscriptions: (
      state,
      action: PayloadAction<number | number[]>,
    ) => {
      const userIds = action.payload;
      const ids = Array.isArray(userIds) ? userIds : [userIds];

      // remove each userId from onlineSubscriptionsUsersIds if it is in the array
      ids.forEach((userId) => {
        if (state.onlineSubscriptionsUsersIds.includes(userId)) {
          const index = state.onlineSubscriptionsUsersIds.indexOf(userId);
          state.onlineSubscriptionsUsersIds.splice(index, 1);
        }
      });
    },
    userAppearedOnline: (state, action) => {
      const userId = action.payload;

      if (!state.onlineUsersIds.includes(userId)) {
        state.onlineUsersIds.push(userId);
      }
    },
    userWentOffline: (state, action) => {
      const userId = action.payload;

      if (state.onlineUsersIds.includes(userId)) {
        const index = state.onlineUsersIds.indexOf(userId);
        state.onlineUsersIds.splice(index, 1);
      }
    },

    requestStats: (state, { payload: userId }: PayloadAction<number>) => {
      state.isFetchingStats[userId] = true;
    },
    receiveStats: (
      state,
      {
        payload: { userId, stats },
      }: PayloadAction<{ userId: number; stats: UserStats }>,
    ) => {
      state.isFetchingStats[userId] = false;
      state.stats[userId] = stats;
    },
    errorStats: (state, { payload: userId }: PayloadAction<number>) => {
      state.isFetchingStats[userId] = false;
    },
    requestSpotlightArtist: (state) => {
      state.isSpotlightFetching = true;
    },
    receiveSpotlightArtist: (state, action: PayloadAction<User>) => {
      state.isSpotlightFetching = false;
      state.successSpotlight = true;
      state.spotlight = action.payload;
    },

    createConversation: (
      state,
      { payload }: PayloadAction<{ userId: number; conversationId: number }>,
    ) => {
      state.entries[payload.userId].conversation_id = payload.conversationId;
    },
  },
  extraReducers: {
    [trackActions.addTrackEntries.type]: (
      state,
      { payload: tracks }: PayloadAction<Track | Track[]>,
    ) => {
      const tracksToAdd = Array.isArray(tracks) ? tracks : [tracks];
      tracksToAdd.forEach(({ user }) => {
        if (user) {
          const currentUser = {
            ...(state.entries[user.id] || {}),
          };
          state.entries[user.id] = {
            ...currentUser,
            ...user,
          };
        }
      });
    },
  },
});

export default slice;

export const usersActions = slice.actions;
