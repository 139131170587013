import React from 'react';
import { CardBody } from 'reactstrap';

export const VuexyCardBody = ({
  text,
  children,
  ...props
}: VuexyCardBodyProps) => (
  <CardBody {...props}>
    {text} {children}
  </CardBody>
);

interface VuexyCardBodyProps {
  tag?: () => void | string;
  className?: string;
  text?: string;
  children?: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

export default VuexyCardBody;
