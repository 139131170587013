import round from 'lodash/round';

const useWaveformPath = (props: {
  values: number[];
  bar: {
    width: number;
    offset: number;
  };
  container: {
    width: number;
    height: number;
  };
}) => {
  const { values, container, bar } = props;

  const result = [`M 0`];

  values.forEach((value, index, list) => {
    const next = list[index + 1];

    const h = round((container.height * value) / 100, 0);
    const top = round((container.height - h) / 2, 0);
    const offset = round(next ? bar.width * (index + 1) : 0, 0);

    result.push(
      `${top} h${bar.width - bar.offset} V${top + h} h${-(
        bar.width - bar.offset
      )} Z M ${offset}`,
    );
  });

  return [...result, '0'].join(' ');
};

export default useWaveformPath;
