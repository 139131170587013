// deletes 1 item from paginated entities
export default (obj: Record<number, number[]>, itmToDelete: number) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  Object.keys(obj).reduce((acc: object, key: any) => {
    const prop = obj[key];
    const indexOfItemToDelete = prop.indexOf(itmToDelete);

    if (indexOfItemToDelete !== -1) prop.splice(indexOfItemToDelete, 1);

    return { ...acc, [key]: prop };
  }, {});
