import * as aiMusicChatAPI from '../../lib/api/ai-music-chat';
import { ThunkResult } from './ThunkDispatch';
import aiMusicChatReducer from '../reducers/ai-music-chat';
import { handleError } from './alerts';

const { actions: aiMusicChatActions } = aiMusicChatReducer;

export const fetchAnswerFromAiExpert = ({
  question,
  prompt,
  chat_id,
}: {
  question: string;
  prompt: string;
  chat_id?: number | undefined;
}): ThunkResult => async (dispatch, getState) => {
  dispatch(aiMusicChatActions.setChatIsLoading(true));
  const { vCoinCountData } = getState().aiMusicChat;
  try {
    const aiAnswerData = await aiMusicChatAPI.askQuestionToMusicAiExpert(
      question,
      prompt,
      chat_id,
    );

    if (!chat_id) {
      dispatch(aiMusicChatActions.setCurrentChatId(aiAnswerData.id));
      dispatch(fetchAiExpertChatHistoryList(prompt));
      dispatch(aiMusicChatActions.setChatMessagesData(aiAnswerData.message));
    }
    if (chat_id === getState().aiMusicChat.currentChatId) {
      dispatch(aiMusicChatActions.setChatMessagesData(aiAnswerData.message));
    }

    dispatch(aiMusicChatActions.setChatIsLoading(false));
    dispatch(aiMusicChatActions.setVCoinDataOnAiMusiChat(vCoinCountData - 1));
  } catch (e: any) {
    dispatch(handleError(e));
    dispatch(aiMusicChatActions.setChatIsLoading(false));
  }
};

export const setQuestionForMusicAiExpert = (chatDataWithQuestion: {
  content: string;
  role: 'user';
}): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setChatQuestionsData(chatDataWithQuestion));
};

export const setErrorHandlerForChatHistory = (
  isError: boolean,
): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setIsChatHistoryGotError(isError));
};
export const setVcoinTotalCountData = (
  vCoinCount: number,
): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setVCoinDataOnAiMusiChat(vCoinCount));
};

export const fetchAiExpertChatHistoryList = (
  prompt: string,
): ThunkResult => async (dispatch) => {
  try {
    const aiExpertChatHistoryList = await aiMusicChatAPI.getAiExpertChatHistoryListData(
      prompt,
    );
    dispatch(
      aiMusicChatActions.setChatHistoryListData(aiExpertChatHistoryList),
    );
  } catch (e: any) {
    dispatch(handleError(e));
  }
};

export const setCurrentChatIdData = (chatId: number): ThunkResult => async (
  dispatch,
) => {
  dispatch(aiMusicChatActions.setCurrentChatId(chatId));
};
export const setQuestionInputValue = (
  inputValue: string,
): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setAskQuestionInputValue(inputValue));
};

export const fetchAiExpertChatMessagesList = (
  chatId: number,
): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setChatHistoryIsLoading(true));

  try {
    const aiExpertChatHistoryList = await aiMusicChatAPI.getAiExpertChatHistoryById(
      chatId,
    );

    dispatch(
      aiMusicChatActions.setChatMessagesDataById(
        aiExpertChatHistoryList.messages,
      ),
    );
    dispatch(aiMusicChatActions.setChatHistoryIsLoading(false));
  } catch (e: any) {
    dispatch(handleError(e));
    dispatch(aiMusicChatActions.setChatHistoryIsLoading(false));
    dispatch(aiMusicChatActions.setChatIdBasedMessagesError(true));
  }
};

export const setEditNameForChatHistory = (
  chatId: number,
  name: string,
  updatedChatHistoryList: aiMusicChatAPI.AiExpertChatDataType[],
): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setChatHistoryListData(updatedChatHistoryList));
  try {
    await aiMusicChatAPI.editAiExpertChatHistoryName(chatId, name);
  } catch (e: any) {
    dispatch(handleError(e));
  }
};

export const setChatHistoryDeleteById = (
  chatId: number,
  updatedChatHistoryList: aiMusicChatAPI.AiExpertChatDataType[],
): ThunkResult => async (dispatch) => {
  dispatch(aiMusicChatActions.setChatHistoryListData(updatedChatHistoryList));
  try {
    await aiMusicChatAPI.deleteAiExpertChatHistory(chatId);
  } catch (e: any) {
    dispatch(handleError(e));
  }
};
