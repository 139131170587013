import React from 'react';

import styled from 'styled-components';
import round from 'lodash/round';
import useToggle from 'react-use/lib/useToggle';
import { style } from '../../api';

import { PlayerInstance } from './PlayerContext';

import ClientOnlyPortal from '../ClientOnlyPortal';

const Demo = ({ player }: { player: PlayerInstance }) => {
  const [visible, toggle] = useToggle(false);

  return (
    <ClientOnlyPortal selector="#__player-manager">
      <Container>
        <button type="button" className="trigger" onClick={toggle}>
          <svg
            style={{ width: 40, height: 40 }}
            fill="none"
            stroke={
              !player.state.paused
                ? style.primary_pink_new
                : style.grey_dark_new
            }
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        {visible && (
          <div className="controls">
            <div className="details">
              <span>
                {`Time: ${round(player.state.time, 1).toFixed(1)}/${round(
                  player.state.duration,
                  1,
                ).toFixed(1)}`}
              </span>
            </div>
            <button
              type="button"
              onClick={() =>
                player.state.paused
                  ? player.controls.play()
                  : player.controls.pause()
              }
            >
              {player.state.paused ? 'Play' : 'Pause'}
            </button>
            <button
              type="button"
              onClick={() =>
                player.state.muted
                  ? player.controls.unmute()
                  : player.controls.mute()
              }
            >
              {player.state.muted ? 'Un-mute' : 'Mute'}
            </button>
            <button type="button" onClick={() => player.controls.volume(0.1)}>
              Volume: 10%
            </button>
            <button type="button" onClick={() => player.controls.volume(0.5)}>
              Volume: 50%
            </button>
            <button type="button" onClick={() => player.controls.volume(1)}>
              Volume: 100%
            </button>
            <button
              type="button"
              onClick={() => player.controls.seek(player.state.time - 5)}
            >
              -5 sec
            </button>
            <button
              type="button"
              onClick={() => player.controls.seek(player.state.time + 5)}
            >
              +5 sec
            </button>
          </div>
        )}
      </Container>
    </ClientOnlyPortal>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;

  .trigger {
    background: none;
    margin: 0;
    cursor: pointer;
    width: fit-content;
    padding: 0;
    color: #000;
    margin-right: 0.5rem;
  }

  .controls {
    display: flex;
    align-items: center;
    flex-direction: row;

    .details {
      width: 100px;
      span {
        text-align: center;
        font-size: 12px;
      }
    }

    button {
      margin: 0;
      cursor: pointer;
      width: fit-content;
      font-size: 12px;
      margin-right: 0.5rem;
      height: auto;
      min-height: auto;
    }
  }
`;

export default Demo;
