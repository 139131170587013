import axios from './axios';
import ResponseWrapper from './ResponseWrapper';

export interface Country {
  id: number;
  name: string;
  city?: string;
  has_active_tracks?: boolean;
  code: string;
}

export const fetch = async () =>
  axios
    .get('/countries')
    .then(({ data }: { data: ResponseWrapper<Country[]> }) => data.data);
