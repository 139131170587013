import React, { useMemo } from 'react';
import styled from 'styled-components';
import max from 'lodash/max';
import chunk from 'lodash/chunk';
import mean from 'lodash/mean';
import useMeasure from 'react-use/lib/useMeasure';
import useTranslation from 'next-translate/useTranslation';

import { style, usePlayer, usePlayerPosition, useWaveform } from '../api';

import useWaveformPath from '../hooks/useWaveformPath';

import { WaveformLoader } from './common/Loader';
import { usePlayerPosition as useAudioPosition } from './Player/PlayerContext';

interface WaveformProps {
  trackId?: number;
  className?: string;
  colorActive?: string;
  colorInactive?: string;
  rectanglePadding?: number;
  full?: boolean;
  forceLoading?: boolean;
  disableSearch?: boolean;
}

export const useWaveformState = ({
  trackId,
  full,
  container,
  bar,
}: {
  trackId?: number;
  remixId?: number;
  full?: boolean;
  bar: {
    offset: number;
  };
  container: {
    width: number;
    height: number;
  };
}) => {
  const data = useWaveform(trackId, full);

  const values = useMemo(() => {
    if (!data) {
      return [];
    }

    const bars = Math.min(data.length, container.width);
    const chunkSize = Math.floor(data.length / bars);
    const format = chunk(data, chunkSize).map(mean);

    const largestVal = max(data) as number;

    return format.map((val) => val / largestVal).map((val) => val * 100);
  }, [data, container.width]);

  const path = useWaveformPath({
    values,
    container,
    bar: {
      ...bar,
      width: container.width / values.length,
    },
  });

  return { values, path };
};

const Waveform = ({
  className,
  rectanglePadding = 2,
  colorActive = style.pink,
  colorInactive = style.grey_light_2_new,
  trackId,
  full,
  forceLoading = false,
  disableSearch = false,
}: WaveformProps) => {
  const { t } = useTranslation('upload');
  const player = usePlayer();
  const playerPosition = usePlayerPosition();
  const { position, setPosition } = useAudioPosition();

  const [waveformRef, container] = useMeasure<HTMLDivElement>();

  const { values, path: pathD } = useWaveformState({
    trackId,
    full,
    container,
    bar: {
      offset: rectanglePadding,
    },
  });

  const onClick = (e: React.MouseEvent) => {
    if (!disableSearch && !!waveformRef) {
      const { width } = container;

      const {
        nativeEvent: { offsetX },
      } = e;

      const nextPosition = offsetX / width;

      playerPosition.allowSetPosition(true);

      if (setPosition) {
        setPosition(nextPosition);
      }
    }
  };

  if (!player.isFetchingWaveform && values && values.length === 0) {
    return (
      <div className={className} ref={waveformRef}>
        <h2>{t('upload:noWaveformAvailable') as string}</h2>
      </div>
    );
  }

  if (forceLoading || player.isFetchingWaveform || !values) {
    return (
      <div className={className} ref={waveformRef}>
        <WaveformLoader />
      </div>
    );
  }

  const clientWidth = container.width || 0;

  return (
    <Container className={className} onClick={onClick} ref={waveformRef}>
      <WaveformProgressContainer
        width={`${Math.floor(clientWidth * position)}px`}
      >
        <svg width={`${clientWidth}px`}>
          <path d={pathD} fill={colorActive} />
        </svg>
      </WaveformProgressContainer>
      <svg width="100%" height="100%">
        <path d={pathD} fill={colorInactive} />
      </svg>
    </Container>
  );
};

Waveform.defaultProps = {
  rectanglePadding: 0,
  colorActive: '#fe497c',
  colorInactive: style.grey_dark_new,
};

const Container = styled.div`
  position: relative;
`;

const WaveformProgressContainer = styled.div<{ width: number | string }>`
  width: ${(props) => props.width};
  position: absolute;
  transition: width 0.1s linear;
  will-change: width;
  left: 0;
  z-index: 3;
  top: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  pointer-events: none;

  svg {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;

export default styled(Waveform)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
