import React from 'react';
import { CustomInput } from 'reactstrap';

export const VuexySwitch = ({
  onClick,
  onChange,
  ...props
}: VuexySwitchProps) => (
  <div onClick={onClick}>
    <CustomInput type="switch" {...props} onChange={onChange} />
  </div>
);

interface VuexySwitchProps {
  id: string;
  defaultChecked?: boolean;
  inline?: boolean;
  disabled?: boolean;
  className?: string;
  style?: string | Record<string, unknown>;
  name?: string;
  checked?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default VuexySwitch;
