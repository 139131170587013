import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AiExpertChatDataType,
  AiExpertChatMessagesType,
} from '../../lib/api/ai-music-chat';

export interface State {
  chatLoading: boolean;
  chatMessagesData: AiExpertChatMessagesType[] | null;
  vCoinCountData: number;
  chatHistoryList: AiExpertChatDataType[] | null;
  isChatHistoryGotError: boolean;
  chatHistoryLoader: boolean;
  currentChatId: null | number;
  questionAskInputValue: string;
  chatIdBasedMessagesError: boolean;
}

export const defaultState: State = {
  chatMessagesData: null,
  chatLoading: false,
  vCoinCountData: 0,
  chatHistoryList: null,
  isChatHistoryGotError: false,
  chatHistoryLoader: false,
  currentChatId: null,
  questionAskInputValue: '',
  chatIdBasedMessagesError: false,
};

const slice = createSlice({
  name: 'AIMUSICCHAT',
  initialState: defaultState,
  reducers: {
    setChatMessagesData: (
      state,
      { payload }: PayloadAction<AiExpertChatMessagesType>,
    ) => {
      if (state.chatMessagesData) {
        state.chatMessagesData = [...state.chatMessagesData, payload];
      }
    },
    clearChatMessagesData: (state) => {
      state.chatMessagesData = [];
      state.currentChatId = null;
      state.questionAskInputValue = '';
      state.chatLoading = false;
    },
    clearChatHisotryListData: (state) => {
      state.chatHistoryList = null;
    },
    setChatMessagesDataById: (
      state,
      { payload }: PayloadAction<AiExpertChatMessagesType[]>,
    ) => {
      state.chatMessagesData = [...payload];
    },
    setChatQuestionsData: (
      state,
      { payload }: PayloadAction<AiExpertChatMessagesType>,
    ) => {
      if (state.chatMessagesData) {
        state.chatMessagesData = [...state.chatMessagesData, payload];
      } else {
        state.chatMessagesData = [payload];
      }
    },
    setChatIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.chatLoading = payload;
    },
    setVCoinDataOnAiMusiChat: (state, { payload }: PayloadAction<number>) => {
      state.vCoinCountData = payload;
    },
    setChatHistoryListData: (
      state,
      { payload }: PayloadAction<AiExpertChatDataType[]>,
    ) => {
      state.chatHistoryList = payload;
    },
    setIsChatHistoryGotError: (state, { payload }: PayloadAction<boolean>) => {
      state.isChatHistoryGotError = payload;
    },
    setChatHistoryIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.chatHistoryLoader = payload;
    },
    setCurrentChatId: (state, { payload }: PayloadAction<number>) => {
      state.currentChatId = payload;
    },
    setAskQuestionInputValue: (state, { payload }: PayloadAction<string>) => {
      state.questionAskInputValue = payload;
    },
    setChatIdBasedMessagesError: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.chatIdBasedMessagesError = payload;
    },
  },
});

export default slice;
export const { actions: aiMusicChatActions } = slice;
