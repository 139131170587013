import { createAction } from '@reduxjs/toolkit';
import { Subscription } from '../../lib/api/subscriptions';

export const receiveVerifyPayment = createAction<number>(
  'RECEIVE_VERIFY_PAYMENT',
);
export const errorVerifyPayment = createAction<string>('ERROR_VERIFY_PAYMENT');

export const updateSubscription = createAction<Subscription>(
  'UPDATE_SUBSCRIPTION',
);
