import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSelect } from './common/useSelect';
import { TrackPlayedByLabel } from '../../lib/api/notifications';
import {
  notificationActions,
  selectCurrentUser,
  selectLabelNotifications,
  selectNotifications,
  selectNotificationsPagination,
  selectUnreadNotifications,
  useAuthentication,
  useDispatch,
  usePaginatedFetch,
} from '../../index';
import { isLastPageReached, selectActivityNotifications } from '../selectors';

export const useAllNotifications = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications);
  const lastPageReached = useSelect(
    ({ notifications: { paginatedNotifications } }) =>
      isLastPageReached(paginatedNotifications),
  );

  const { authenticated } = useAuthentication();

  const [nextPage, loading] = usePaginatedFetch(async (page) => {
    await dispatch(notificationActions.fetch({ page }));
  }, selectNotificationsPagination);

  useEffect(() => {
    if (!authenticated) return;

    nextPage(0);
  }, [authenticated]);

  return [notifications, loading, nextPage, lastPageReached] as const;
};

export const useUnreadNotifications = () =>
  useSelect(selectUnreadNotifications);

export const useUserNotifications = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const notifications = useSelector(selectActivityNotifications);
  const lastPageReached = useSelect(
    ({ notifications: { paginatedActivityNotifications } }) =>
      isLastPageReached(paginatedActivityNotifications),
  );

  const { authenticated } = useAuthentication();

  const [nextPage] = usePaginatedFetch(
    async (page) => {
      if (loading) return;

      setLoading(true);
      await dispatch(notificationActions.fetch({ page, filter: 'activity' }));
      setLoading(false);
    },
    ({ notifications: { paginatedActivityNotifications } }) =>
      paginatedActivityNotifications.pagination,
  );

  useEffect(() => {
    nextPage(0);
  }, [authenticated]);

  return [notifications, loading, nextPage, lastPageReached] as const;
};

export const useLabelNotifications = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const notifications = useSelector(selectLabelNotifications);
  const lastPageReached = useSelect(
    ({ notifications: { paginatedNotificationsByLabel } }) =>
      isLastPageReached(paginatedNotificationsByLabel),
  );

  const { authenticated } = useAuthentication();

  const [nextPage] = usePaginatedFetch(
    async (page) => {
      if (loading) return;

      setLoading(true);
      await dispatch(notificationActions.fetch({ page, filter: 'labels' }));
      setLoading(false);
    },
    ({ notifications: { paginatedNotificationsByLabel } }) =>
      paginatedNotificationsByLabel.pagination,
  );

  useEffect(() => {
    nextPage(0);
  }, [authenticated]);

  return [notifications, loading, nextPage, lastPageReached] as const;
};

export const useFetchNotifications = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUser).id;

  useEffect(() => {
    dispatch(notificationActions.fetchAllTypes());
  }, [userId]);
};

export const useLabelLikesAndPlays = () => {
  useFetchNotifications();
  return useSelect((state) =>
    selectLabelNotifications(state)
      .filter((notification) => notification.data.type === 'TrackPlayedByLabel')
      .map((x) => x.data),
  ) as TrackPlayedByLabel[];
};
