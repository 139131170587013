import jwtDecode from 'jwt-decode';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface State {
  token?: string;
  role?: string;
  isLoggedIn: boolean;
  hasCheckedInitialLogin: boolean;
}

export const defaultState: State = {
  token: undefined,
  role: undefined,
  isLoggedIn: false,
  hasCheckedInitialLogin: false,
};

export default createSlice({
  name: 'AUTHENTICATION',
  initialState: defaultState,
  reducers: {
    refreshToken: (state, { payload: token }: PayloadAction<string>) => {
      try {
        const { role } = jwtDecode(token);
        state.token = token;
        state.role = role;
        state.isLoggedIn = true;
      } catch (decodeError) {
        state.isLoggedIn = false;
        state.role = undefined;
        state.token = undefined;
      }
    },
    checkedInitialLogin: (state) => {
      state.hasCheckedInitialLogin = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.role = undefined;
      state.token = undefined;
      state.hasCheckedInitialLogin = true;
    },
  },
});
