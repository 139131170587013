import { Dispatch } from 'redux';
import * as musicKnowledgeHubAPI from '../../lib/api/musicKnowledgeHub';
import { ThunkResult } from './ThunkDispatch';
import musicKnowledgeHubReducer from '../reducers/musicKnowledgeHub';
import { handleError } from './alerts';

const { actions: musicKnowledgeHubActions } = musicKnowledgeHubReducer;

export const fetchCommentsData = (
  postId: number,
  page = 1,
  perPage = 20,
): ThunkResult => async (dispatch) => {
  dispatch(musicKnowledgeHubActions.requestPostComments());

  try {
    const {
      comments,
      pagination,
    } = await musicKnowledgeHubAPI.getCommentsListData(postId, page, perPage);
    dispatch(musicKnowledgeHubActions.addCommentData(comments));
    dispatch(
      musicKnowledgeHubActions.receivePostComments({
        comments,
        postId,
        pagination,
      }),
    );
  } catch (e: any) {
    dispatch(musicKnowledgeHubActions.errorPostComments(e.message));
  }
};

export const fetchCommentsById = (id: number): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    comments: { comments },
  } = getState();

  if (comments[id] !== undefined) {
    console.debug(
      `[Shared/comments] Comment with id ${id} already in store, not fetching`,
    );
    return;
  }

  dispatch(musicKnowledgeHubActions.fetchCommentById());

  try {
    const comment = await musicKnowledgeHubAPI.fetchCommentById(id);
    // @ts-ignore
    dispatch(musicKnowledgeHubActions.addCommentData([comment]));
  } catch (e: any) {
    dispatch(musicKnowledgeHubActions.errorFetchCommentById(e.message));
  }
};

export const addCommentData = (
  postId: number,
  text: string,
): ThunkResult => async (dispatch, getState) => {
  dispatch(musicKnowledgeHubActions.addPostCommentRequest());
  const commentsCount = getState().musicKnowledgeHub.totalCommentsCountData;
  try {
    const { data: comment, status } = await musicKnowledgeHubAPI.addCommentData(
      postId,
      text,
    );

    if (status === 'User must be logged in.') {
      throw new Error(status);
    }

    dispatch(musicKnowledgeHubActions.addCommentData([comment]));
    dispatch(
      musicKnowledgeHubActions.addPostCommentSuccess({ postId, comment }),
    );
    dispatch(
      musicKnowledgeHubActions.setTotalCommentsCountData(commentsCount + 1),
    );
  } catch (e: any) {
    dispatch(handleError(e));
  }
};

export const addReplyOnCommentData = (
  postId: number,
  commentId: number,
  text: string,
): ThunkResult => async (dispatch) => {
  dispatch(musicKnowledgeHubActions.addCommentReplyRequest());

  try {
    const reply = await musicKnowledgeHubAPI.replyToCommentData(
      postId,
      commentId,
      text,
    );
    dispatch(musicKnowledgeHubActions.addCommentData([reply]));
  } catch (e: any) {
    dispatch(musicKnowledgeHubActions.errorAddCommentReply(e.message));
  }
};

export const likeOnCommentData = (commentId: number): ThunkResult => async (
  dispatch,
) => {
  dispatch(musicKnowledgeHubActions.requestCommentLike(commentId));

  try {
    await musicKnowledgeHubAPI.likeOnCommentData(commentId);
    dispatch(musicKnowledgeHubActions.successCommentLike(commentId));
  } catch (e: any) {
    dispatch(musicKnowledgeHubActions.errorCommentLike(e.message));
  }
};

export const removeLikeOnCommentData = (
  commentId: number,
): ThunkResult => async (dispatch) => {
  dispatch(musicKnowledgeHubActions.requestCommentRemoveLike(commentId));

  try {
    await musicKnowledgeHubAPI.removeLikeOnCommentData(commentId);
    dispatch(musicKnowledgeHubActions.successCommentRemoveLike(commentId));
  } catch (e: any) {
    dispatch(musicKnowledgeHubActions.errorCommentRemoveLike(e.message));
  }
};

export const deleteCommentData = (
  commentId: number,
  parent: { parentCommentId?: number; postId?: number },
): ThunkResult => async (dispatch, getState) => {
  const commentsCount = getState().musicKnowledgeHub.totalCommentsCountData;
  await musicKnowledgeHubAPI.deleteCommentData(commentId);

  dispatch(
    musicKnowledgeHubActions.successCommentDelete({ commentId, ...parent }),
  );
  dispatch(
    musicKnowledgeHubActions.setTotalCommentsCountData(commentsCount - 1),
  );
};

export const setBlogPostInfoData = (
  infoData: musicKnowledgeHubAPI.CommunityPostInfo,
) => (dispatch: Dispatch) => {
  dispatch(musicKnowledgeHubActions.setBlogPostInformationData(infoData));
};
export const setTotalCommentsCountForPost = (commentCounts: number) => (
  dispatch: Dispatch,
) => {
  dispatch(musicKnowledgeHubActions.setTotalCommentsCountData(commentCounts));
};
