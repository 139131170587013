import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PlaylistType } from '../dispatch/player';

interface Playlist {
  trackIds: number[];
  currentTrack: number;
  playlistType: PlaylistType;
}

export interface State {
  showPlayer: boolean;
  isPlaying: boolean;
  isLoading?: boolean;
  currentTrack?: number;
  currentPlaylist: number[];
  playlistType: PlaylistType;
  position: number;
  fetchingWaveforms: Record<number, boolean>;
  waveforms: Record<number, number[]>;
  fullWaveforms: Record<number, number[]>;
  playlists: Record<string, Playlist>;
  isPosSetAllowed: boolean;
  editMode: boolean;
  userPlaylistId?: number;
}

export const defaultState: State = {
  showPlayer: false,
  isPlaying: false,
  isLoading: undefined,
  currentPlaylist: [],
  currentTrack: undefined,
  position: 0,
  fetchingWaveforms: {},
  waveforms: {},
  fullWaveforms: {},
  playlists: {},
  isPosSetAllowed: false,
  editMode: false,
  playlistType: 'swiper',
  userPlaylistId: undefined,
};

export default createSlice({
  name: 'PLAYER',
  initialState: defaultState,
  reducers: {
    playPauseTrack: (player) => {
      if (player.currentTrack === undefined) {
        player.currentTrack = 0;
      } else {
        player.isPlaying = !player.isPlaying;
      }
      if (player.isPlaying && !player.showPlayer) {
        player.showPlayer = true;
      }
    },
    previousTrack: (player) => {
      if (player.currentTrack === undefined) return;
      player.currentTrack = Math.max(0, player.currentTrack - 1);
    },
    play: (player) => {
      player.isLoading = false;
      if (player.currentTrack !== undefined) {
        player.isPlaying = true;
      }
    },
    pause: (player) => {
      player.isLoading = false;
      player.isPlaying = false;
    },
    loading: (player) => {
      player.isPlaying = false;
      player.isLoading = true;
    },
    ready: (player) => {
      player.isLoading = false;
    },
    skipCurrentTrack: (player) => {
      if (player.currentTrack === undefined) {
        player.currentTrack = 0;
        player.isPlaying = true;
        player.showPlayer = true;
      } else if (player.currentTrack === player.currentPlaylist.length - 1) {
        player.currentTrack = 0;
        player.isPlaying = false;
      } else {
        player.currentTrack += 1;
        player.isPlaying = true;
        player.showPlayer = true;
      }
      player.position = 0;
    },
    addTracksToPlaylistById: (
      player,
      {
        payload: { ids, resetList, playlistType },
      }: PayloadAction<{
        ids: number[];
        resetList?: boolean;
        playlistType?: PlaylistType;
      }>,
    ) => {
      // If there are no tracks, it is a unecessary  dispatch. Ignore.
      if (!resetList && ids.length === 0) return;

      // if we change playlistType, create a new playlist and remember the old one.
      if (playlistType && playlistType !== player.playlistType) {
        // remember the old one
        player.playlists[player.playlistType] = {
          trackIds: player.currentPlaylist,
          currentTrack: player.currentTrack || 0,
          playlistType: player.playlistType,
        };

        // try to get one of old playlists if it exists
        const oldPlaylist = player.playlists[playlistType];
        if (oldPlaylist) {
          player.currentPlaylist = oldPlaylist.trackIds;
          player.currentTrack = oldPlaylist.currentTrack;
        } else {
          player.currentPlaylist = ids;
          player.currentTrack = 0;
        }
      } else if (resetList) {
        player.currentPlaylist = ids;
        player.currentTrack = 0;
      } else {
        player.currentPlaylist.push(...ids);
      }

      player.playlistType = playlistType || player.playlistType || 'swiper';

      // Reset the playlistId if tracks got added that are not from a userPlaylist
      if (playlistType !== 'userPlaylist') {
        player.userPlaylistId = undefined;
      }
    },
    addNext: (player, { payload }: PayloadAction<number>) => {
      const { currentTrack = -1 } = player;

      player.currentPlaylist.splice(currentTrack + 1, 0, payload);
      player.showPlayer = true;
      player.isPlaying = true;
    },
    replayTrack: (player) => {
      player.position = 0;
    },
    startPlayer: (player) => {
      if (player.currentTrack === undefined) {
        player.currentTrack = 0;
      }
      player.showPlayer = true;
    },
    requestWaveform: (player, { payload: trackId }: PayloadAction<number>) => {
      player.fetchingWaveforms[trackId] = true;
    },
    receiveWaveform: (
      player,
      {
        payload: { trackId, waveform },
      }: PayloadAction<{
        waveform: number[];
        trackId: number;
      }>,
    ) => {
      player.fetchingWaveforms[trackId] = false;
      player.waveforms[trackId] = waveform;
    },
    stopFetchingWaveform: (
      player,
      { payload: trackId }: PayloadAction<number>,
    ) => {
      player.fetchingWaveforms[trackId] = false;
    },
    receiveFullWaveform: (
      player,
      {
        payload: { waveform, trackId },
      }: PayloadAction<{
        waveform: number[];
        trackId: number;
      }>,
    ) => {
      player.fetchingWaveforms[trackId] = false;
      player.fullWaveforms[trackId] = waveform;
    },
    setPosition: (player, { payload }: PayloadAction<number>) => {
      player.position = payload;
    },
    allowSetPosition: (player, { payload }: PayloadAction<boolean>) => {
      player.isPosSetAllowed = payload;
    },
    showPlayer: (player) => {
      player.showPlayer = true;
    },
    toggleEditMode: (player, { payload }: PayloadAction<boolean>) => {
      player.editMode = payload;
      if (!payload) {
        player.currentPlaylist = [];
        player.currentTrack = undefined;
      }
    },
    playTrackById: (player, { payload }: PayloadAction<number | string>) => {
      player.currentTrack = player.currentPlaylist.findIndex(
        (id) => String(id) === String(payload),
      );
      player.position = 0;
      player.isPlaying = true;
    },
    // added this action after after update to react-native-track-player v2
    setCurrentTrackIndex: (
      player,
      { payload }: PayloadAction<string | number>,
    ) => {
      player.currentTrack = Number(payload);
      player.position = 0;
    },
    setTrackId: (player, { payload }: PayloadAction<string | number>) => {
      player.currentTrack = player.currentPlaylist.findIndex(
        (id) => String(id) === String(payload),
      );
      player.position = 0;
    },
    setCurrentTrack: (player, { payload }: PayloadAction<number>) => {
      player.currentTrack = payload;
    },
    playPrev: (player) => {
      if (player.currentTrack) {
        player.currentTrack -= 1;
      }
    },
    setUserplaylistId: (player, { payload }) => {
      player.userPlaylistId = payload;
    },
  },
});
