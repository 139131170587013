import {
  configureStore,
  compose,
  EnhancedStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { enableBatching } from 'redux-batched-actions';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { Persistor, Storage } from 'redux-persist/es/types';

import reducer, { RootState } from './reducers';
import axios from '../lib/api/axios';
import authenticationReducer from './reducers/authentication';

import * as chatActions from './dispatch/chat';
import * as trackActions from './dispatch/tracks';
import * as playerActions from './dispatch/player';
import * as swiperActions from './dispatch/swiper';
import * as userActions from './dispatch/user';
import * as subscriptionsActions from './dispatch/subscriptions';
import * as authenticationActions from './dispatch/authentication';
import * as playlistActions from './dispatch/playlists';
import * as usersActions from './dispatch/users';
import * as followersActions from './dispatch/followers';
import * as commentsActions from './dispatch/comments';
import * as musicKnowledgeHubActions from './dispatch/musicKnowledgeHub';
import * as searchActions from './dispatch/search';
import * as notificationActions from './dispatch/notifications';
import * as vcoinActions from './dispatch/vcoins';
import * as labelWidgetActions from './dispatch/label-widget';
import * as aiMusicChatActions from './dispatch/ai-music-chat';
import { SwiperTypes } from './reducers/swiper';

export {
  chatActions,
  trackActions,
  playerActions,
  swiperActions,
  authenticationActions,
  userActions,
  subscriptionsActions,
  playlistActions,
  usersActions,
  followersActions as followActions,
  searchActions,
  commentsActions,
  notificationActions,
  vcoinActions,
  labelWidgetActions,
  aiMusicChatActions,
  musicKnowledgeHubActions,
};

export type { SwiperTypes };

export * from './selectors';
export * from './hooks';

// export { getNextPage } from './PaginatedEntries';
export { compose };

export type StoreType = EnhancedStore<RootState>;

interface Options {
  initialState: RootState;
}

interface PersistedOptions {
  storage: Storage;
}

const { logout, refreshToken } = authenticationReducer.actions;

/**
 * Create the redux store using an optional initial state
 *
 * */
export const createStore = ({ initialState }: Options) =>
  configureStore({
    reducer: enableBatching(reducer),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
  });

// used in mobile. uses redux-persist to cache some parts of the store.
// not used on web because of SSR
export const createPersistedStore = ({ storage }: PersistedOptions) => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['statistics'],
    // whitelist: ['user', 'statistics'],
  };

  const persistedReducer = persistReducer(
    persistConfig,
    enableBatching(reducer),
  );

  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    /* a fix for redux-persist and redux-toolkit compatibility */
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
  });

  const persistor = persistStore(store);

  return { store, persistor } as { store: StoreType; persistor: Persistor };
};

/**
 * Subscribe to changes and deletion of Bearer authentication token. Add the current bearer token
 * to every request's authorization header. !IMPORTANT: This function should only be called client-side.
 * @param getCurrentToken method to get the current token, stored in cookie or localstorage
 * @param changeAuthenticationTokenCb callback to set new value of token in cookie or localstorage
 * @param removeAuthenticationTokenCb callback to remove token from cookie or localstorage
 */
export const subscribeToTokenEvents = (
  store: StoreType,
  // store: Store<StoreType, AnyAction>,
  getCurrentToken: () => Promise<string | undefined | null>,
  changeAuthenticationTokenCb: (token: string) => void,
  removeAuthenticationTokenCb: () => void,
  onLogout?: () => void,
) => {
  // const { authentication } = store.getState().getState()
  // Perform the following actions when an action is being dispatched to the store
  store.subscribe(async () => {
    const {
      token,
      isLoggedIn,
      hasCheckedInitialLogin,
    } = store.getState().authentication;
    const currentStoredToken = await getCurrentToken();

    // Update locally stored token if it was updated in the redux store
    // This makes sure the user stays logged in while refreshing the page
    if (
      token &&
      token !== currentStoredToken &&
      hasCheckedInitialLogin &&
      changeAuthenticationTokenCb
    ) {
      await changeAuthenticationTokenCb(token);
    }

    // Clear the remaining token from localStorage if the user is not logged in
    if (
      !isLoggedIn &&
      hasCheckedInitialLogin &&
      currentStoredToken &&
      removeAuthenticationTokenCb
    ) {
      await removeAuthenticationTokenCb();
    }
  });

  // Perform the following actions to outgoing requests
  axios.interceptors.request.use(async (request) => {
    const { token } = store.getState().authentication;

    // Add the token from the redux store as authorization header on every request
    if (token) {
      if (request.headers === undefined) {
        request.headers = {};
      }
      request.headers.Authorization = token;
    }
    return request;
  });

  // Perform the following actions for all incoming requests
  axios.interceptors.response.use(async (response) => {
    const { token: currentToken, isLoggedIn } = store.getState().authentication;
    const newToken = response.headers.authorization;

    // Update the token in the store when a new token is supplied in a response
    if (
      newToken &&
      currentToken !== undefined &&
      newToken !== 'Bearer' &&
      currentToken !== newToken
    ) {
      store.dispatch(refreshToken(newToken));
    }

    // Logout if no token was supplied at all OR if response had "Token is Expired" message
    if (
      isLoggedIn &&
      (response.data.status === 'Token is Invalid' ||
        response.data.status === 'Token is Expired' ||
        response.data.status === 'User must be logged in.')
    ) {
      console.debug(
        '[Authentication] BE auth error, logging  out.',
        response.data,
      );
      store.dispatch(logout());
      if (onLogout) {
        onLogout();
      }
    }
    return response;
  });

  // intercept 401 error and logout
  axios.interceptors.response.use(undefined, (error) => {
    // change comment: do not perform logout action on login route
    // this is to fix a refresh bug on the widget which prevents error handling
    const isLoginRoute = String(error?.config?.url).endsWith('/login');
    if (error?.response?.status === 401 && !isLoginRoute) {
      const { isLoggedIn } = store.getState().authentication;
      if (isLoggedIn) {
        console.debug(
          '[Authentication] status code 401 while currently logged in, logging out.',
          error.response.data,
        );
        store.dispatch(logout());
        if (onLogout) {
          onLogout();
        }
      }
    }

    // change comment: we should not replace the previous error by a custom string
    // for showing the correct message we can use handleError function
    return Promise.reject(error);
  });
};
