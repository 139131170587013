import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import {
  LabelNotification,
  UserNotification,
} from '../../lib/api/notifications';
import { getAllPaginatedItems } from './index';

export const selectUnreadNotifications = (state: RootState) =>
  state.notifications.unreadNotificationsNumber;

const getUnreadMessages = (state: RootState) => state.chat.unreadMessagesNumber;

// const getNotifications = (state: RootState) => state.chat.conversations;

export const selectNumberOfUnreadNotifications = createSelector(
  selectUnreadNotifications,
  getUnreadMessages,
  (x, y) => x + y,
);

export const selectNotifications = ({ notifications }: RootState) =>
  getAllPaginatedItems(
    notifications.paginatedNotifications,
    notifications.notifications,
  ) as UserNotification[];

export const selectLabelNotifications = ({ notifications }: RootState) =>
  getAllPaginatedItems(
    notifications.paginatedNotificationsByLabel,
    notifications.notifications,
  ) as LabelNotification[];

export const selectActivityNotifications = ({ notifications }: RootState) =>
  getAllPaginatedItems(
    notifications.paginatedActivityNotifications,
    notifications.notifications,
  ) as UserNotification[];

export const selectNotificationsPagination = ({ notifications }: RootState) =>
  notifications.paginatedNotifications.pagination;

export const selectLabelNotificationsPagination = ({
  notifications,
}: RootState) => notifications.paginatedNotificationsByLabel.pagination;

export const selectActivityNotificationsPagination = ({
  notifications,
}: RootState) => notifications.paginatedActivityNotifications.pagination;
