import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { useCurrentUser, style, userActions, useDispatch } from '../../../api';

import { FlatButtonLink } from '../../common/Link';
import { TextRegular14, TextBold18 } from '../../common/Text';
import Dialog from '../../common/Dialog';

const Button = styled(FlatButtonLink)`
  color: #fff;
  background-color: ${style.primary_pink_new};
`;

const ManageTracks = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('myTracks');

  const { tracks_archived } = useCurrentUser();

  const [visible, setVisible] = useState(() => !!tracks_archived);

  const onDismiss = () => {
    setVisible(false);
    dispatch(userActions.tracksArchivedNotified());
  };

  return (
    <Dialog open={visible} hideCloseButton disableBackdropClick>
      <Container>
        <Content>
          <TextBold18>{t('myTracks:archivedMessage') as string}</TextBold18>
          &nbsp;
          <TextRegular14>
            {t('myTracks:freeUserNotification') as string}
            <strong>{t('myTracks:oneTrack') as string}</strong>
            {t('myTracks:manageTracks') as string}
          </TextRegular14>
        </Content>
        <ButtonsContainer>
          <Button href="/my-tracks" onClick={onDismiss}>
            {t('myTracks:manageMyTracks') as string}
          </Button>
        </ButtonsContainer>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 700px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 28px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default ManageTracks;
