import dayjs from 'dayjs';
import { RootState } from '../reducers';
import { Conversation, selectors } from '../../index';

const sortConversations = (a: Conversation, b: Conversation) =>
  dayjs(b.last_reply).valueOf() - dayjs(a.last_reply).valueOf();

export const selectChats = ({ chat }: RootState) =>
  selectors
    .getAllPaginatedItems(chat.paginatedConversations, chat.conversations)
    .sort(sortConversations);

export const selectChatsPagination = (state: RootState) =>
  state.chat.paginatedConversations.pagination;

export const selectFeedbackReqsCount = ({ chat }: RootState) =>
  chat.feedbackRequestsCount;

export const selectFeedbackRequests = ({ chat }: RootState) =>
  selectors
    .getAllPaginatedItems(chat.paginatedFeedbackRequests, chat.conversations)
    .sort(sortConversations);

export const selectFeedbackRequestsPagination = ({ chat }: RootState) =>
  chat.paginatedFeedbackRequests.pagination;

export const selectChat = (chatId: number) => ({ chat }: RootState) =>
  chat.conversations[chatId];

export const selectChatPagination = (chatId: number) => ({ chat }: RootState) =>
  chat.repliesPerConversation[chatId]?.pagination;

export const selectChatMessages = (chatId: number) => ({ chat }: RootState) => {
  const messages = selectors
    .getAllPaginatedItems(
      chat.repliesPerConversation[chatId],
      chat.replyEntries,
    )
    .sort((a, b) => (b?.timestamp || 0) - (a.timestamp || 0));

  return messages.filter((m, index) => messages.indexOf(m) === index);
};

export const selectUnreadMessagesCount = ({ chat }: RootState) =>
  chat.unreadMessagesNumber;

export const selectIsChatDeleted = (chatId: number) => ({ chat }: RootState) =>
  chat.conversationsDeleted[chatId];
