import { Dispatch } from 'redux';
import authenticationReducer from '../reducers/authentication';
import { ThunkResult } from './ThunkDispatch';
import { API, userActions } from '../../index';
import { handleError } from './alerts';
import { successAction } from '../reducers/alerts';

const {
  actions: { refreshToken, checkedInitialLogin },
} = authenticationReducer;

export const { logout } = authenticationReducer.actions;

export const verifyLoginOnStartup = (
  passedToken?: string,
  getToken?: () => Promise<string>,
): ThunkResult => async (dispatch) => {
  const token = passedToken || (getToken && (await getToken()));

  if (token) {
    dispatch(refreshToken(token));
  }

  dispatch(checkedInitialLogin());
};

export const register = (token: string) => async (dispatch: Dispatch) => {
  if (token) {
    dispatch(refreshToken(`Bearer ${token}`));
  }
};

export const login = (
  email: string,
  password: string,
  rememberMe: boolean,
): ThunkResult<Promise<string | undefined>> => async (dispatch) => {
  const { headers } = await API.authentication.login(
    email,
    password,
    rememberMe,
  );

  await dispatch(userActions.getCurrent(headers.authorization));

  dispatch(verifyLoginOnStartup(headers.authorization));

  return headers.authorization as string | undefined;
};

export const loginWithFacebook = (fbToken: string): ThunkResult => async (
  dispatch,
) => {
  const { token } = await API.authentication.facebookSignIn(fbToken);

  await dispatch(userActions.getCurrent(token));
  await dispatch(verifyLoginOnStartup(token));
};

export const signInWithApple = (idToken: string): ThunkResult => async (
  dispatch,
) => {
  const { token } = await API.authentication.appleSignIn(idToken);

  await dispatch(userActions.getCurrent(token));
  await dispatch(verifyLoginOnStartup(token));
};

export const signInWithSpotify = (
  code: string,
  connecting?: boolean,
): ThunkResult => async (dispatch) => {
  try {
    const { token } = await API.authentication.spotifySignInWeb(
      code,
      'virpp://spotify',
    );

    if (connecting) dispatch(successAction('Connected successfully'));

    await dispatch(userActions.getCurrent(token));
    await dispatch(verifyLoginOnStartup(token));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};
