import { createAction } from '@reduxjs/toolkit';
import { Follower } from '../../lib/api/followers';
import { Pagination } from '../../lib/api/Pagination';

export const addFollowEntries = createAction<Follower[]>('ADD_FOLLOW_ENTRIES');

export const requestFollowers = createAction('REQUEST_FOLLOWERS');
export const receiveFollowers = createAction<{
  pagination: Pagination;
  followers: Follower[];
}>('RECEIVE_FOLLOWERS');
export const receiveMutualFollowers = createAction<{
  pagination: Pagination;
  followers: Follower[];
}>('RECEIVE_MUTUAL_FOLLOWERS');
export const errorFollowers = createAction<string>('ERROR_FOLLOWERS');

export const requestFollowing = createAction('REQUEST_FOLLOWING');
export const receiveFollowing = createAction<{
  pagination: Pagination;
  following: Follower[];
}>('RECEIVE_FOLLOWING');
export const errorFollowing = createAction<string>('ERROR_FOLLOWING');

export const requestFollowUser = createAction('REQUEST_FOLLOW_USER');
export const confirmFollowUser = createAction<number>('CONFIRM_FOLLOW_USER');
export const errorFollowUser = createAction<string>('ERROR_FOLLOW_USER');

export const requestUnfollowUser = createAction('REQUEST_UNFOLLOW_USER');
export const confirmUnfollowUser = createAction<number>(
  'CONFIRM_UNFOLLOW_USER',
);
export const errorUnfollowUser = createAction<string>('ERROR_UNFOLLOW_USER');

export const changeFollowingByUserInFollowers = createAction<{
  id: number;
  flag: boolean;
}>('CHANGE_FOLLOWING_BY_USER_IN_FOLLOWERS');

export const removeFromFollowing = createAction<number>(
  'REMOVE_FROM_FOLLOWING',
);

export const removeFromFollowers = createAction<number>(
  'REMOVE_FROM_FOLLOWERS',
);

export const addToFollowers = createAction<Follower>('ADD_TO_FOLLOWERS');

export const resetPagination = createAction<string>(
  'RESET_PAGINATION_FOR_FOLLOWERS_OR_FOLLOWING',
);

export const addRequestingId = createAction<number>(
  'FOLLOWERS_ADD_REQUESTING_ID',
);

export const removeRequestingId = createAction<number>(
  'FOLLOWERS_REMOVE_REQUESTING_ID',
);

export const requestFollowingIds = createAction('REQUEST_FOLLOWING_IDS');
export const confirmFollowingIds = createAction<number[]>(
  'CONFIRM_FOLLOWING_IDS',
);
export const errorFollowingIds = createAction<string>('ERROR_FOLLOWING_IDS');

export const followersIdsReceived = createAction<number[]>(
  'RECEIVE_FOLLOWERS_IDS',
);
