import { Selector, useSelector } from 'react-redux';
import { useState } from 'react';

import { RootState } from '../../reducers';
import { Pagination } from '../../../lib/api/Pagination';
import { ThunkDispatch } from '../../dispatch/ThunkDispatch';
import { handleError } from '../../dispatch/alerts';
import useDispatch from './useDispatch';

// wrap call to fetch function with a loading boolean.
// return loading value and augmented fetch function
export const useFetchWithLoading = (fetch: Fetch) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  async function fetchWithLoading(...args: void[]) {
    if (loading) return;

    setLoading(true);

    try {
      await fetch(...args);
    } catch (error: any) {
      dispatch(handleError(error));
    } finally {
      setLoading(false);
    }
  }

  return [loading, fetchWithLoading] as const;
};

export function usePaginatedFetch(
  fetch: (page: number) => Promise<unknown> | void,
  paginationSelector: Selector<RootState, Pagination | undefined>,
): [(forcePage?: number) => Promise<unknown> | void, boolean, boolean] {
  const pagination = useSelector(paginationSelector);
  const page = pagination?.currentPage || 0;
  const lastPage = pagination?.lastPage || 1;
  const lastPageReached = page >= lastPage;

  const [loading, setLoading] = useState(false);

  async function nextPage(forcePage?: number) {
    if (lastPageReached && !forcePage) return undefined;

    try {
      setLoading(true);
      return await fetch(forcePage || page + 1);
    } finally {
      setLoading(false);
    }
  }

  return [nextPage, loading, lastPageReached];
}

type Fetch = (
  ...args: void[]
) => void | Promise<void | unknown[] | unknown> | ThunkDispatch;
