import React from 'react';
import { Input } from 'reactstrap';

const VuexyInput = ({ ...props }: VuexyInputProps) => <Input {...props} />;

interface VuexyInputProps {
  type?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  autoComplete?: string;
  color?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
}

export default VuexyInput;
