import { useQuery } from 'react-query';
import { StatsPeriods } from '../../index';
import * as statisticsAPI from '../../lib/api/statistics';
import { WorldMapDataEntry } from '../../lib/api/statistics';
import { useMergeWithPreviousData } from './common/queryUtils';
import { useTracks } from './tracks';
import { useCurrentUser } from './user';

const identifier = 'statistics';

export const useStatisticsForTrack = (
  trackId: number | undefined,
  period: StatsPeriods,
) => {
  const key = [identifier, trackId, period];
  const merge = useMergeWithPreviousData(key);
  return useQuery(
    key,
    () =>
      trackId
        ? statisticsAPI.getTrackStatistics(trackId, { period })
        : undefined,
    { select: merge },
  );
};

export const useMyStats = () => {
  const { id } = useCurrentUser();
  const key = [identifier, 'my-stats'];
  const merge = useMergeWithPreviousData(key);
  return useQuery(key, () => (id ? statisticsAPI.fetchStats(id) : undefined), {
    select: merge,
  });
};

export const useStatisticsForAllTracks = (period: StatsPeriods) => {
  const { id } = useCurrentUser();
  const key = [identifier, 'stats-for-all-tracks', period];
  const merge = useMergeWithPreviousData(key);

  const result = useQuery(
    key,
    () => (id ? statisticsAPI.getAllStats(id, period) : undefined),
    { select: merge, enabled: id !== undefined },
  );

  const { data: { tracks } = {} } = result;

  useTracks(tracks?.map((t) => t.id) || []);

  return result;
};

const sortLocations = (a: WorldMapDataEntry, b: WorldMapDataEntry) =>
  b.percentage || 0 - a.percentage || 0;

export const useLocations = (period: StatsPeriods) => {
  const { data: stats } = useStatisticsForAllTracks(period);
  const locations = stats?.worldmap_data || [];
  return locations.sort(sortLocations);
};

export const useInteractedLabels = () =>
  useQuery(['statistics/labels'], statisticsAPI.getStatsForLabels, {
    refetchOnWindowFocus: false,
  });

export const useLabelInteractionStats = (labelId: number | undefined) => {
  const key = [identifier, 'label-interactions-stats', labelId];
  return useQuery(key, () =>
    labelId ? statisticsAPI.getStatsForLabel(labelId) : undefined,
  );
};
