import React from 'react';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import Button from '../../VuexyDesign/atoms/Button';

const AcceptDeclineButtons = ({
  accept,
  decline,
}: {
  accept: () => void;
  decline: () => void;
}) => {
  const { t } = useTranslation('notifications');
  return (
    <AcceptDeclineContainer>
      <Button className="mx-1 btn btn-sm" color="success" onClick={accept}>
        {
          t(
            'notifications:entries.TrackCollaborationRequest.buttons.Accept',
          ) as string
        }
      </Button>
      <Button className="mx-1 btn btn-sm" onClick={decline}>
        {
          t(
            'notifications:entries.TrackCollaborationRequest.buttons.Decline',
          ) as string
        }
      </Button>
    </AcceptDeclineContainer>
  );
};

export default AcceptDeclineButtons;

const AcceptDeclineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 250px;

  button {
    box-shadow: none;
  }
`;
