import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { style } from '../api';

import { Warning, Error, CheckCircle, Info } from './common/Icons';
import { mobile } from '../style/constants';

import 'react-toastify/dist/ReactToastify.min.css';

export const ToastSuccess = (message: string) => {
  const toastId = toast.success(message);

  toast.update(toastId, {
    render: () => (
      <div className="Toastify__toast--custom-content">
        <span>{message}</span>
        <CheckCircle size={16} color={style.success_new} />
      </div>
    ),
  });
};

export const ToastWarning = (message: string) => {
  const toastId = toast.warn(message);

  toast.update(toastId, {
    render: () => (
      <div className="Toastify__toast--custom-content">
        <span>{message}</span>
        <Warning size={16} color={style.warning_new} />
      </div>
    ),
  });
};

export const ToastError = (message: string) => {
  const toastId = toast.error(message);

  toast.update(toastId, {
    render: () => (
      <div className="Toastify__toast--custom-content">
        <span>{message}</span>
        <Error size={16} color={style.error_new} />
      </div>
    ),
  });
};

export const ToastInfo = (message: string) => {
  const toastId = toast.info(message);

  toast.update(toastId, {
    render: () => (
      <div className="Toastify__toast--custom-content">
        <span>{message}</span>
        <Info size={16} color={style.info_new} />
      </div>
    ),
  });
};

const Toast = () => (
  <ToastContainerWrapper>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      closeButton={false}
    />
  </ToastContainerWrapper>
);

export default Toast;

const ToastContainerWrapper = styled.div`
  .Toastify__toast {
    min-height: 54px;

    @media ${mobile} {
      margin: 0 10px;
    }

    &-container {
      max-width: 600px;
      width: 100%;
      text-align: center;

      &--bottom-center {
        bottom: 80px;
      }
    }

    &--default,
    &--info,
    &--warning,
    &--error,
    &--success {
      background: ${style.grey_light_1_new};
      color: ${style.black_new};
    }

    &--custom-content {
      span {
        margin-right: 10px;
      }
    }
  }
`;
