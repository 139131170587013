import qs from 'qs';
import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { Genre, GenreIdentifier } from './genres';
import { Raffle } from './raffles';
import { User, UserSimple } from './user';

export interface NewTrack {
  id: number;
  title?: string;
  track_desc?: string;
  genre?: Genre;
  track_img: string;
  track_url: string;
  waveform: string;
  release_status: string;
  pulled_status: boolean;
  upload: string;
  original_name: string;
  created_at: string;
  updated_at: string;
  user: User;
  collaborators: UserSimple[];
  logged_in_user_liked_track: boolean;
  total_likes: number;
  total_plays: number;
  total_comments: number;
  bpm?: number;
  comments: string;
  tags: {
    name: string;
  }[];
  pulled_at?: string;
  visibility?: number;
  link: string;
  share?: {
    ig_share_1080x1920: string;
    fb_track_pulled_1200x630?: string;
    ig_track_pulled_1080x1920?: string;
  };
  images: {
    '210x210': string;
    '300x300': string;
    '800x800': string;
    '1100x1100': string;
  };
  primary_user?: number;
  duration?: number;
  preferred_labels: User[];
  api_key?: string;
  raffle_id?: number; // deprecated
  contest?: Raffle; // deprecated
  contests: number[];
  status?: TrackStatus;
}

export interface TrackPlay {
  id: number;
  ip: string;
  time_played: number;
  track_id: number;
  vcoin_balance?: number;
}

export interface Track extends NewTrack {
  track_desc: string;
  bpm: number;
  display_name: string;
  track_img: string;
  visibility: 0 | 1;
  start: number;
  waveform_upload: string;
  requested_my_feedback: boolean;
  pending_collaborators?: User[];
  position_last_week?: number | 'new';
  isRemixUpload?: boolean;
  extra_field_1?: string;
  charts?: {
    current_week?: {
      general?: number;
      genre: {
        genre_id: number;
        position: number;
      };
    };
    previous_week?: {
      general?: number;
      genre: {
        genre_id: number;
        position: number;
      };
    };
  };
  release_date?: string;
  feedback_request_count?: string;
}

type TrackWithGenre = Omit<Track, 'genre'> & { genre: Genre };

export type TrackStatus =
  | 'active'
  | 'inactive'
  | 'archived'
  | 'uploaded'
  | 'declined'
  | 'pending'
  | 'accepted'
  | 'archived';

export const PeriodFilterOptions = [
  'all',
  'last_year',
  'last_month',
  'last_week',
  '24hrs',
] as const;

export type PeriodFilterType = typeof PeriodFilterOptions[number];

export type SortType =
  | 'most_liked'
  | 'least_liked'
  | 'random'
  | 'new'
  | 'popular'
  | 'top_artist';

export interface CompleteFilter {
  page: number;
  keyword: string;
  bpm: number;
  genre: GenreIdentifier | GenreIdentifier[];
  release_status: string;
  country: number | number[];
  sort: SortType;
  min_bpm: number;
  max_bpm: number;
  period: PeriodFilterType;
  id: number;
  selection: Selection;
  contestId: number;
}

export const SelectionOptions = [
  'new_selection',
  'weekly_selections',
  'editors_pick',
] as const;

export type Selection = typeof SelectionOptions[number];

export interface SwiperFilter extends Partial<CompleteFilter> {
  genre?: number[];
  country?: number[];
  user?: number;
}

export interface Filter extends Partial<CompleteFilter> {
  genre?: GenreIdentifier;
  country?: number;
  page?: number;
  release_status?: string;
  user?: number;
}

export interface TrackPulledRecieved {
  id?: number;
  user_id?: number;
  label_id?: number;
  track_id?: number;
  status?: string;
  created_at?: string;
  label?: { id: number; display_name: string; profile_photo: string };
}

export const getAll = async (
  {
    page = 1,
    keyword,
    genre,
    bpm,
    country,
    sort,
    min_bpm,
    max_bpm,
    period,
    selection,
    contestId,
    user,
  }: Filter | SwiperFilter = { page: 1 },
  token?: string,
  url?: string,
) =>
  axios
    .get<ResponseWrapperPaginated<Track[]>>(url || '/tracks', {
      params: {
        page,
        keyword,
        genre,
        bpm,
        country,
        sort,
        min_bpm,
        max_bpm,
        period,
        token,
        selection,
        contestId,
        user,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => ({
      tracks: data.data || [],
      pagination: data.pagination,
    }));

export const getTopTen = async (token?: string) =>
  getAll({ sort: 'most_liked' }, token).then(({ tracks }) =>
    tracks.slice(0, 10),
  );

export const getNewest = async (page = 1, token?: string) =>
  getAll({ sort: 'new', page }, token);

export const getFilteredRandom = async (
  filter: Filter | SwiperFilter,
  token?: string,
) => getAll({ sort: 'random', ...filter }, token);

export const like = async (id: number) =>
  axios.post(`/tracks/${id}/like`).then(() => true);

export const removeLike = async (id: number) =>
  axios.delete(`/tracks/${id}/like`).then(() => true);

export const getWaveformData = async (waveformDataUrl: string) =>
  axios.get(waveformDataUrl).then(({ data }) => data.data as number[]);

export const makeActive = async (trackId: number) =>
  axios.post(`api/tracks/${trackId}/activate`).then(() => true);

export const getPullRequestsForLogginInUser = async () =>
  axios.get(`pull`).then(({ data }) => data.data as TrackPulledRecieved[]);

export const getPullByTrackId = (trackId: number) =>
  axios.get(`pull/tracks/${trackId}`).then(({ data }) => data);

export const getTrackStatus = async (trackId: number) =>
  axios.get(`tracks/${trackId}/status`).then(({ data }) => data.data);

export const getPullCount = async () =>
  axios
    .get(`user/my-tracks?pull_status=open`)
    .then(({ data }) => data.data.length);

export const getCharts = async (
  { page, keyword, genre }: Filter,
  token?: string,
) =>
  getAll(
    { page, keyword, genre: genre === 'all' || !genre ? undefined : genre },
    token,
    '/charts',
  );

export const acceptRelease = async (trackId: number) =>
  axios.patch(`track-releases/${trackId}/accept`).then((data) => data.data);

export const declineRelease = async (trackId: number) =>
  axios.patch(`track-releases/${trackId}/decline`).then((data) => data.data);

export const getVirppTopPicks = async () =>
  axios
    .get<ResponseWrapperPaginated<Track[]>>('/tracks/picks')
    .then(({ data }) => ({
      tracks: data.data,
    }));

export interface UserTracksFilter {
  keyword: string;
  page: number;
  sort: 'likes' | 'plays' | 'date';
  sort_dir: 'asc' | 'desc';
}

export const getUserTracks = async (
  id: number,
  { page = 1, ...rest }: Partial<UserTracksFilter> = {},
  token?: string,
) =>
  axios
    .get<ResponseWrapperPaginated<Track[]>>(`user/${id}/tracks`, {
      params: { page, ...rest },
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => ({
      tracks: data.data || [],
      pagination: data.pagination,
    }));

export const getTrackById = async (id: number, token?: string) =>
  axios
    .get<ResponseWrapper<Track>>(`tracks/${id}`, {
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => data.data);

export const getRelatedTracks = async (id: number, token?: string) =>
  axios
    .get<ResponseWrapper<Track[]>>(`/tracks/${id}/related`, {
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => data.data);

export const deleteTrack = async (id: number) =>
  axios
    .delete<ResponseWrapper<Track>>(`tracks/${id}`)
    .then(({ data }) => data.data);

export const activateTrack = async (id: number) =>
  axios
    .put<ResponseWrapper<Track>>(`tracks/${id}/activate`)
    .then(({ data }) => data.data);

export const createTrack = async (
  track: FormData,
  options?: {
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
  },
) =>
  axios
    .post<ResponseWrapper<NewTrack>>('tracks', track, options)
    .then(({ data }) => data.data);

export interface Report {
  body: string;
  email: string;
  phone_number: string;
  copyright_check: boolean | number;
  information_check: boolean | number;
  terms_check: boolean | number;
}

export const reportTrack = async (
  id: number,
  {
    body,
    email,
    phone_number,
    copyright_check,
    information_check,
    terms_check,
  }: Report,
) =>
  axios
    .post(`track/${id}/report`, {
      body,
      email,
      phone_number,
      copyright_check,
      information_check,
      terms_check,
    })
    .then(({ data }) => data);

export const mobileReportTrack = async (
  trackId: number,
  reportMessage: string,
) =>
  axios
    .post(`track/${trackId}/report-mobile`, { body: reportMessage })
    .then(({ data }) => data);

export const getMyTracks = async (
  { page, keyword, release_status }: Filter = { page: 1 },
) =>
  axios
    .get<ResponseWrapperPaginated<NewTrack[]>>('/user/my-tracks', {
      params: {
        page,
        keyword,
        release_status,
      },
    })
    .then(({ data }) => data);

export interface TrackUpdate {
  id: number | string;
  title: string;
  track_desc: string;
  tags: string;
  genre_id: number;
  start: number;
  bpm: number;
  collaborators: number[];
  primary_user: number;
  preferred_labels: number[];
  api_key?: string;
  contests: number[];
  visibility?: 1 | 0;
  request_release: boolean;
  extra_field_1?: string;
}

export const updateTrack = async ({
  id,
  title,
  track_desc,
  tags,
  genre_id,
  start,
  bpm,
  collaborators,
  primary_user,
  preferred_labels,
  api_key,
  visibility,
  contests,
  request_release,
  extra_field_1,
}: Partial<TrackUpdate>) =>
  axios
    .put<ResponseWrapper<NewTrack>>(`tracks/${id}`, {
      title,
      track_desc,
      tags,
      genre_id,
      start,
      bpm,
      collaborators,
      primary_user,
      preferred_labels,
      api_key,
      visibility,
      contests,
      request_release,
      extra_field_1,
    })
    .then(({ data }) => data.data);

interface Permission {
  id: number;
  visibility: 1 | 0;
  start: number;
}

export const updatePermissions = async ({
  id,
  start,
  visibility,
}: Permission) =>
  axios
    .put<ResponseWrapper<NewTrack>>(`tracks/${id}/permissions`, {
      start,
      visibility,
    })
    .then(({ data }) => data.data);

export const updateCover = async (id: number, cover: FormData) =>
  axios
    .post<ResponseWrapper<NewTrack>>(`tracks/${id}/cover`, cover)
    .then(({ data }) => data.data);

export const reportTrackListen = async (id: number, time_played: number) =>
  axios
    .post<ResponseWrapper<TrackPlay>>(`tracks/${id}/play`, { time_played })
    .then(({ data }) => data.data);

export const updateTrackListen = async (playId: number, timePlayed: number) =>
  axios
    .post<ResponseWrapper<TrackPlay>>(`plays/${playId}`, {
      time_played: timePlayed,
    })
    .then(({ data }) => data.data);

export const requestFeedback = async (
  user_id: number,
  track_id: number,
  message?: string,
) =>
  axios
    .post('tracks/feedback', { user_id, track_id, message })
    .then(({ data }) => data.data);

export const acceptCollaboration = async (trackId: number) =>
  axios.post(`tracks/${trackId}/accept-collaboration`).then(() => true);

export const fetch = (ids: number[], token?: string) =>
  ids.length > 0
    ? axios
        .get<ResponseWrapper<Track[]>>(`/tracks/ids/${ids.join(',')}`, {
          ...(token ? { headers: { authorization: token } } : {}),
        })
        .then(({ data }) => data.data)
    : Promise.resolve([]);

export const declineCollaboration = async (trackId: number) =>
  axios.post(`tracks/${trackId}/decline-collaboration`).then(() => true);

export const reportTrackSkip = async (id: number) =>
  axios
    .post<ResponseWrapper<NewTrack>>(`tracks/${id}/skip`)
    .then(({ data }) => data.data);

type ParamsPeriod =
  | '24hrs'
  | 'last_7_days'
  | 'last_week'
  | 'last_month'
  | 'all';

/**
 * @api - https://api-dev.virpp.com/docs/#popular-genres
 */

export const getPopularGenres = ({
  params,
}: {
  params?: { period?: ParamsPeriod };
}) =>
  axios
    .get('/tracks/popular-genres', { params })
    .then(({ data }: { data: ResponseWrapper<TrackWithGenre[]> }) => data.data);

export interface TrackStatement {
  statement: string;
}

export const getTrackStatement = (trackId: number) =>
  axios
    .get(`tracks/${trackId}/statement`)
    .then(({ data }: { data: ResponseWrapper<TrackStatement> }) => data.data);
