import { createWrapper } from 'next-redux-wrapper';
import { createStore } from './api';

const makeStore = () => createStore({});

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;

export const wrapper = createWrapper<AppStore>(makeStore, {
  debug: false,
});
