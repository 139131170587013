import axios from './axios';

// eslint-disable-next-line import/prefer-default-export
export const request = (trackId: number, userId: number, message?: string) =>
  axios
    .post('/tracks/feedback', {
      user_id: userId,
      track_id: trackId,
      message,
    })
    .then(() => true);
