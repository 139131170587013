import * as tracksAPI from '../../lib/api/tracks';
import * as likesAPI from '../../lib/api/likes';
import { clearSearchResult } from '../actions/search';
import tracksReducer, { TrackTypes } from '../reducers/tracks';

import { addTracksToPlaylistById } from './player';
import { ThunkResult } from './ThunkDispatch';
import { RootState } from '../reducers';
import { API } from '../../index';
import { Pagination } from '../../lib/api/Pagination';
import { User } from '../../lib/api/user';
import { successAction } from '../reducers/alerts';
import { selectTracksRandom } from '../selectors';
import determineIdsToFetch from '../../utils/determineIdsToFetch';
import { handleError } from './alerts';
import { NewTrack, Track, UserTracksFilter } from '../../lib/api/tracks';
import { usersActions } from '../reducers/users';

const { actions: trackActions } = tracksReducer;

export const {
  addTrackEntries,
  feedbackSent,
  feedbackRequested,
  addTrackLinkId,
} = trackActions;

export const fetchTracks = ({
  page = 1,
}: tracksAPI.Filter): ThunkResult => async (dispatch) => {
  dispatch(trackActions.requestTracks());
  try {
    const { tracks, pagination } = await tracksAPI.getAll();
    dispatch(trackActions.addTrackEntries(tracks));
    if (page === 1) {
      dispatch(trackActions.resetPaginatedTracks(TrackTypes.defaultPaginated));
    }
    dispatch(
      trackActions.receiveTracks({
        pagination,
        tracks,
      }),
    );
  } catch (error: any) {
    dispatch(trackActions.errorTracks(error.message));
  }
};

export const fetch = (
  ids: number | number[],
  token?: string,
  forceUpdate = false,
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  // Collect all ids that are not available in redux yet
  const { entries, fetching } = getState().tracks;
  const idsToFetch = determineIdsToFetch(ids, entries, fetching, forceUpdate);

  // If we don't have some tracks yet, request them from the backend
  if (idsToFetch.length > 0) {
    try {
      dispatch(trackActions.requestFetch(idsToFetch));

      const tracks = await tracksAPI.fetch(idsToFetch, token);

      if (tracks.length > 0) {
        dispatch(trackActions.addTrackEntries(tracks));
      }

      dispatch(trackActions.receiveFetch(idsToFetch));
    } catch (error: any) {
      dispatch(handleError(error));
    }
  }
};

export const fetchTracksTopTen = (token?: string): ThunkResult => async (
  dispatch,
) => {
  dispatch(trackActions.requestTopTen());
  try {
    const topTenTracks = await tracksAPI.getTopTen(token);
    dispatch(trackActions.addTrackEntries(topTenTracks));
    dispatch(trackActions.receiveTopTen(topTenTracks));
  } catch (error: any) {
    dispatch(trackActions.errorTracksTopTen(error.message));
  }
};

export const fetchTracksNewest = (
  page = 1,
  token?: string,
): ThunkResult => async (dispatch) => {
  dispatch(trackActions.requestNewest());
  try {
    const { tracks: newestTracks, pagination } = await tracksAPI.getNewest(
      page,
      token,
    );
    dispatch(trackActions.addTrackEntries(newestTracks));
    if (page === 1) {
      await dispatch(trackActions.resetPaginatedTracks(TrackTypes.newest));
    }
    dispatch(
      trackActions.receiveNewest({
        tracks: newestTracks,
        pagination,
      }),
    );
  } catch (error: any) {
    dispatch(trackActions.errorTracksNewest(error.message));
  }
};

export const fetchTracksFilteredRandom = (
  filters: tracksAPI.Filter | tracksAPI.SwiperFilter,
  mobile = false,
  token?: string,
): ThunkResult<Promise<tracksAPI.Track[] | undefined>> => async (
  dispatch,
  getState,
) => {
  const currentPage = filters.page || 1;
  const isFirstPage = currentPage === 1;

  const stateTracksRandom = selectTracksRandom(getState());

  dispatch(trackActions.requestFilteredRandom());

  try {
    const {
      tracks: randomTracks,
      pagination,
    } = await tracksAPI.getFilteredRandom(filters, token);

    let tracks = [];
    if (isFirstPage) {
      tracks = randomTracks;

      await dispatch(
        trackActions.resetPaginatedTracks(TrackTypes.filteredRandom),
      );
    } else {
      // remove duplicates
      tracks = randomTracks.filter((track) => {
        const exists = stateTracksRandom.find(
          (stateTrack) => stateTrack.id === track.id,
        );
        return !exists;
      });
    }

    dispatch(trackActions.addTrackEntries(tracks));

    if (!mobile) {
      dispatch(
        addTracksToPlaylistById({
          ids: tracks.map((track) => track.id),
          resetList: isFirstPage,
        }),
      );
    }

    dispatch(
      trackActions.receiveFilteredRandom({
        tracks: isFirstPage ? randomTracks : tracks,
        pagination,
      }),
    );

    return tracks;
  } catch (error: any) {
    dispatch(trackActions.errorTracksFilteredRandom(error.message));
  }

  return undefined;
};

export const reportTrackSkip = (id: number): ThunkResult => async () => {
  try {
    await tracksAPI.reportTrackSkip(id);
  } catch (error: any) {
    handleError(error);
  }
};

export const likeTrack = (id: number): ThunkResult => async (dispatch) => {
  dispatch(trackActions.requestLikeTrack());
  dispatch(trackActions.confirmLikeTrack(id));
  try {
    await tracksAPI.like(id);
    dispatch(fetch(id, undefined, true));
  } catch (error: any) {
    dispatch(trackActions.errorLikeTrack('Error'));
    dispatch(trackActions.confirmRemoveLike(id));
  }
};

export const removeLike = (id: number): ThunkResult => async (dispatch) => {
  dispatch(trackActions.requestRemoveLike());
  dispatch(trackActions.confirmRemoveLike(id));
  try {
    await tracksAPI.removeLike(id);
    dispatch(fetch(id, undefined, true));
  } catch (error: any) {
    dispatch(trackActions.errorRemoveLike('Error'));
    dispatch(trackActions.confirmLikeTrack(id));
  }
};

export const addFilter = trackActions.addTracksFilter;

export const fetchChartsTracks = (
  filters: tracksAPI.Filter,
  virppPicks = false,
  token?: string,
): ThunkResult => async (dispatch, getState) => {
  const {
    tracks: { isFetchingChartsTracks },
  } = getState();

  if (filters.genre && isFetchingChartsTracks[filters.genre]) {
    return;
  }

  const genre = virppPicks ? 'top10VirppPicks' : filters.genre || 'all';

  dispatch(trackActions.requestChartsTracks(genre));
  try {
    const result: {
      tracks: tracksAPI.Track[];
      pagination?: Pagination;
    } = virppPicks
      ? await tracksAPI.getVirppTopPicks()
      : await tracksAPI.getCharts(filters, token);
    let { pagination } = result;
    const { tracks: chartTracks } = result;

    if (pagination === undefined) {
      pagination = {
        currentPage: 1,
        lastPage: 1,
        total: chartTracks.length,
        perPage: 10,
      };
    }
    const currentPage = filters && filters.page ? filters.page : 1;
    if (currentPage === 1) {
      await dispatch(trackActions.resetPaginatedTracks(TrackTypes.chartTracks));
    }

    dispatch(trackActions.addTrackEntries(chartTracks));
    dispatch(
      trackActions.receiveChartsTracks({
        tracks: chartTracks,
        pagination,
        genre,
      }),
    );
  } catch (error: any) {
    dispatch(trackActions.errorChartsTracks(error.message));
  }
};

export const fetchGenreSet = (
  genreId: number,
  page = 1,
  sort: tracksAPI.SortType = 'popular',
): ThunkResult => async (dispatch, getState) => {
  const {
    tracks: { isFetchingGenreSets },
  } = getState();

  if (isFetchingGenreSets[genreId]) {
    return;
  }

  dispatch(trackActions.requestGenreSet(genreId));
  const { tracks, pagination } = await tracksAPI.getAll({
    genre: genreId,
    page,
    sort,
  });

  dispatch(trackActions.addTrackEntries(tracks));
  dispatch(
    trackActions.receiveGenreSet({
      tracks,
      pagination,
      genreId,
    }),
  );
};

export const fetchTopArtistsGenreSet = (
  genreId: number,
  page = 1,
): ThunkResult => async (dispatch, getState) => {
  const {
    tracks: { isFetchingTopArtistsGenreSets },
  } = getState();

  if (isFetchingTopArtistsGenreSets[genreId]) {
    return;
  }

  dispatch(trackActions.requestTopArtistsGenreSet(genreId));
  const { tracks, pagination } = await tracksAPI.getAll({
    genre: genreId,
    page,
    sort: 'top_artist',
  });

  dispatch(trackActions.addTrackEntries(tracks));
  dispatch(
    trackActions.receiveTopArtistsGenreSet({
      tracks,
      pagination,
      genreId,
    }),
  );
};

export const fetchUserTracks = (
  id: number,
  filter?: Partial<UserTracksFilter>,
  token?: string,
): ThunkResult<Promise<tracksAPI.Track[] | undefined>> => async (dispatch) => {
  dispatch(trackActions.requestUserTracks());
  try {
    const { tracks, pagination } = await tracksAPI.getUserTracks(
      id,
      filter,
      token,
    );
    dispatch(trackActions.addTrackEntries(tracks));
    if (filter?.page === 1) {
      await dispatch(trackActions.resetPaginatedTracks(TrackTypes.userTracks));
    }

    dispatch(
      trackActions.receiveUserTracks({ userId: id, tracks, pagination }),
    );

    return tracks;
  } catch (error: any) {
    dispatch(trackActions.errorUserTracks(error.message));
  }

  return undefined;
};

export const searchUserTracks = (
  keyword: string,
  page = 1,
  mobile = false,
): ThunkResult => async (dispatch, getState: () => RootState) => {
  const {
    user: { id },
  } = getState();

  dispatch(trackActions.requestSearchUserTracks());
  try {
    const { tracks, pagination } = await tracksAPI.getUserTracks(id as number, {
      page,
      keyword,
    });
    dispatch(trackActions.addTrackEntries(tracks));
    if (page === 1) {
      dispatch(clearSearchResult());
    }

    if (!mobile) {
      dispatch(
        addTracksToPlaylistById({
          ids: tracks.map((track) => track.id),
          resetList: page === 1,
        }),
      );
    }

    if (!id) return;
    dispatch(
      trackActions.receiveUserTracks({ userId: id, tracks, pagination }),
    );
  } catch (error: any) {
    dispatch(trackActions.errorSearchUserTracks(error.message));
  }
};

export const fetchLikedTracks = (
  id: number,
  page = 1,
  mobile = false,
): ThunkResult => async (dispatch) => {
  dispatch(trackActions.requestLikedTracks());
  try {
    const { likes, pagination } = await likesAPI.getLikes(id, page);
    const likedTracks = likes.map((like) => like.track);
    dispatch(trackActions.addTrackEntries(likedTracks));
    if (page === 1) {
      await dispatch(trackActions.resetPaginatedTracks(TrackTypes.likedTracks));
    }

    if (!mobile) {
      dispatch(
        addTracksToPlaylistById({
          ids: likedTracks.map((track) => track.id),
          resetList: page === 1,
        }),
      );
    }

    dispatch(
      trackActions.receiveLikedTracks({ tracks: likedTracks, pagination }),
    );
  } catch (error: any) {
    dispatch(trackActions.errorLikedTracks(error.message));
  }
};

export const fetchTrackById = (
  id: number,
  mobile = false,
  token?: string,
): ThunkResult => async (dispatch) => {
  dispatch(trackActions.requestTrackById());

  try {
    const track = await tracksAPI.getTrackById(id, token);
    dispatch(trackActions.receiveTrackById(track));

    if (!mobile) {
      dispatch(addTracksToPlaylistById({ ids: [track.id], resetList: true }));
    }
  } catch (error: any) {
    dispatch(trackActions.errorTrackById(error.message));
  }
};

export const fetchRelatedTracks = (
  id: number,
  token?: string,
): ThunkResult<Promise<tracksAPI.Track[] | undefined>> => async (dispatch) => {
  dispatch(trackActions.requestRelatedTracks());
  try {
    const relatedTracks = await tracksAPI.getRelatedTracks(id, token);

    dispatch(trackActions.addTrackEntries(relatedTracks));
    dispatch(
      trackActions.receiveRelatedTracks({ tracks: relatedTracks, trackId: id }),
    );

    return relatedTracks;
  } catch (error: any) {
    dispatch(trackActions.errorRelatedTracks(error.message));
  }

  return undefined;
};

export const activateTrack = (trackId: number): ThunkResult => async (
  dispatch,
  getState,
) => {
  const myId = getState().user.id;

  if (!myId) return;

  try {
    await tracksAPI.activateTrack(trackId);
    dispatch(trackActions.archiveTracks(myId));
    dispatch(trackActions.activateTrack(trackId));
    dispatch(successAction('Track was set to active'));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const deleteTrack = (id: number): ThunkResult<Promise<void>> => async (
  dispatch,
  getState: () => RootState,
) => {
  const {
    user: { id: userId },
  } = getState();

  if (!userId) {
    throw new Error('Trying to delete track without valid userId');
  }

  dispatch(trackActions.requestDeleteTrack());
  try {
    await tracksAPI.deleteTrack(id);
    dispatch(trackActions.receiveDeleteTrack({ userId, trackId: id }));
    dispatch(successAction('Track was deleted'));
  } catch (error: any) {
    dispatch(trackActions.errorDeleteTrack(error.message));
  }
};

export const createTrack = (
  trackFormdata: FormData,
  onUploadProgress: (e: ProgressEvent) => void,
): ThunkResult<Promise<NewTrack>> => async (dispatch) => {
  const track = await API.track.createTrack(trackFormdata, {
    onUploadProgress,
  });

  dispatch(trackActions.addTrackEntries([track as Track]));

  // fetch list of my tracks to reflect the addition of new track
  dispatch(fetchMyTracks());

  return track;
};

export const fetchMyTracks = (page = 1): ThunkResult => async (
  dispatch,
  getState: () => RootState,
) => {
  const {
    user: { id: myId },
  } = getState();

  if (!myId) return;

  dispatch(trackActions.requestMyTracks());
  try {
    const { data: myTracks, pagination } = await tracksAPI.getMyTracks({
      page,
    });
    dispatch(trackActions.addTrackEntries(myTracks as tracksAPI.Track[]));
    if (page === 1)
      await dispatch(trackActions.resetPaginatedTracks(TrackTypes.myTracks));
    dispatch(
      trackActions.receiveMyTracks({
        tracks: myTracks,
        pagination,
        myUserId: myId,
      }),
    );
  } catch (error: any) {
    dispatch(trackActions.errorMyTracks(error.message));
  }
};

export const updateCover = (id: number, cover: FormData): ThunkResult => async (
  dispatch,
) => {
  dispatch(trackActions.requestUpdateCover());
  try {
    const updatedTrack = await tracksAPI.updateCover(id, cover);
    dispatch(trackActions.addTrackEntries(updatedTrack as tracksAPI.Track));
    dispatch(trackActions.receiveUpdateCover());
  } catch (error: any) {
    if (error.response)
      dispatch(trackActions.errorUpdateCover(error.response?.data.message));
    else dispatch(trackActions.errorUpdateCover('Failed, unknown error'));
  }
};

export const reportTrack = (
  trackId: number,
  reportMessage: string,
): ThunkResult => async (dispatch) => {
  try {
    await tracksAPI.mobileReportTrack(trackId, reportMessage);
    dispatch(successAction('Track was reported'));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const requestFeedback = (
  userId: number,
  trackId: number,
  message?: string,
): ThunkResult => async (dispatch) => {
  try {
    const { conversation_id } = await API.track.requestFeedback(
      userId,
      trackId,
      message,
    );

    dispatch(
      usersActions.createConversation({
        userId,
        conversationId: conversation_id,
      }),
    );
    dispatch(successAction('Feedback request sent'));
    return conversation_id;
  } catch (error: any) {
    dispatch(handleError(error));
  }

  return undefined;
};

export const acceptCollaboration = (
  trackId: number,
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const { user } = getState();

  if (!user) {
    throw new Error('Cannot accept collaboration without being logged in');
  }

  const success = await API.track.acceptCollaboration(trackId);
  if (success) {
    dispatch(
      trackActions.acceptCollaborationSuccess({ trackId, user: user as User }),
    );
  }
};

export const declineCollaboration = (
  trackId: number,
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const {
    user: { id },
  } = getState();

  if (!id) {
    throw new Error('Cannot accept collaboration without being logged in');
  }

  const success = await API.track.declineCollaboration(trackId);
  if (success) {
    dispatch(trackActions.declineCollaborationSuccess({ trackId, userId: id }));
  }
};

export const fetchSelection = (
  selection: tracksAPI.Selection,
  page = 1,
): ThunkResult => async (dispatch) => {
  dispatch(trackActions.fetchSelection(selection));

  const { tracks, pagination } = await API.track.getAll({ selection, page });
  dispatch(trackActions.addTrackEntries(tracks));
  dispatch(
    trackActions.receiveSelection({
      tracks: tracks.map((track) => track.id),
      pagination,
      selection,
    }),
  );
};

export const fetchPopularGenres = ({
  params,
}: Parameters<typeof API.track.getPopularGenres>[0]): ThunkResult => async (
  dispatch,
) => {
  try {
    const data = await API.track.getPopularGenres({ params });

    /**
     * We update the tracks cache
     */
    dispatch(trackActions.addTrackEntries(data));

    /**
     * Store the track ids only
     */
    dispatch(trackActions.receivePopularGenres({ tracks: data }));
  } catch (error: any) {
    dispatch(trackActions.errorPopularGenres(error));
  }
};
