import React from 'react';
import { Modal } from 'reactstrap';
import Image from 'next/image';
import Link from 'next/link';
import { X } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { TextRegular14 } from '../../VuexyDesign/atoms/Text';
import { Button } from '../../VuexyDesign/atoms/_index';
import { TextBold28 } from '../common/Text';

interface NoReleasesModalProps {
  isOpen: boolean;
  toggle?: () => void;
  close: () => void;
}

export const NoReleasesModal = ({
  isOpen,
  toggle,
  close,
}: NoReleasesModalProps) => {
  const { t } = useTranslation('stats');
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered p-0"
    >
      <div className="w-100 d-flex flex-column align-items-center p-2">
        <div className="w-100 d-flex justify-content-end">
          <X className="cursor-pointer" onClick={() => close()} />
        </div>
        <Image
          alt="no-releases"
          src="/static/images/noReleases.svg"
          height={150}
          width={150}
          style={{
            maxWidth: '100%',
          }}
        />
        <TextBold28>{t('stats:requestAReleaseFirst') as string}</TextBold28>
        <TextRegular14 className="mb-2 text-center">
          {t('stats:dontHaveReleasesYet') as string}
        </TextRegular14>
        <Link href="/music-distribution">
          <Button color="success" onClick={() => close()}>
            {t('stats:requestARelease') as string}
          </Button>
        </Link>
      </div>
    </Modal>
  );
};
