import React from 'react';
import styled from 'styled-components';

const CongratulationsIcon = () => (
  <PartyIconWrapper>&#127881;</PartyIconWrapper>
);

export default CongratulationsIcon;

const PartyIconWrapper = styled.span`
  width: 35px;
  text-align: center;
  font-size: 25px;
`;
