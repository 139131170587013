import { useQuery } from 'react-query';
import notUndefinedOrNull from '../../utils/notUndefinedOrNull';
import * as userAPI from '../../lib/api/user';
import { useCurrentUser } from './user';

const identifier = 'awards';

function normalizeAwards(awards: Partial<userAPI.CloseToAwards>) {
  return Object.keys(awards)
    .map((category) => {
      const award = awards[category as keyof userAPI.CloseToAwards];

      if (!award) return null;

      return {
        ...award,
        category,
      };
    })
    .filter(notUndefinedOrNull);
}

export const useMyAwards = () => {
  const { id: currentUserId } = useCurrentUser();

  return useQuery([identifier, 'current-user'], () =>
    currentUserId ? userAPI.loadAwards(currentUserId) : undefined,
  );
};

export const useCloseToAwards = () => {
  const { id: currentUserId } = useCurrentUser();

  return useQuery([identifier, 'current-user', 'close-to-awards'], () =>
    currentUserId ? userAPI.loadCloseToAwards(currentUserId) : undefined,
  );
};

export const useCloseToAwardsNormalized = () => {
  const { id: currentUserId } = useCurrentUser();

  const { data: awards } = useQuery(
    [identifier, 'current-user', 'close-to-awards'],
    () =>
      currentUserId ? userAPI.loadCloseToAwards(currentUserId) : undefined,
  );

  if (!awards) return [];

  return normalizeAwards(awards);
};

export const useQueryAwardsForUser = (userId?: number) =>
  useQuery([identifier, userId], () =>
    userId ? userAPI.loadAwards(userId) : undefined,
  );
