import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { User } from './user';

export interface Comment {
  id: number;
  comment: string;
  user: User;
  user_liked_comment: boolean;
  like_count: number;
  timestamp: number;
  feedback: boolean;
  created_at: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  replies: Comment[];
  track_id: number;
}

interface CommentReportFields {
  body: string;
  email: string;
  phone_number?: string;
  information_check: boolean;
  terms_check: boolean;
}

export const getTrackComments = async (id: number, page = 1, perPage = 20) =>
  axios
    .get<ResponseWrapperPaginated<Comment[]>>(`track/${id}/comments`, {
      params: { page, perPage },
    })
    .then(({ data }) => ({
      comments: data.data || [],
      pagination: data.pagination,
    }));

export const getTrackFeedback = async (id: number, page?: number) =>
  axios
    .get<ResponseWrapperPaginated<Comment[]>>(`tracks/${id}/feedback`, {
      params: { page },
    })
    .then(({ data }) => data.data);

export const commentTrack = async (id: number, comment: string) =>
  axios
    .post<ResponseWrapper<Comment>>(`tracks/${id}/comment`, { comment })
    .then(({ data }) => data);

export const replyToComment = async (
  trackId: number,
  commentId: number,
  comment: string,
) =>
  axios
    .post<ResponseWrapper<Comment>>(`tracks/${trackId}/comment/${commentId}`, {
      comment,
    })
    .then(({ data }) => data.data);

export const likeComment = async (id: number) =>
  axios
    .post<ResponseWrapper<Comment>>(`comments/${id}/like`)
    .then(({ data }) => data.data);

export const removeCommentLike = async (id: number) =>
  axios
    .post<ResponseWrapper<Comment>>(`comments/${id}/like`)
    .then(({ data }) => data.data);

export const deleteComment = async (id: number) =>
  axios
    .delete<ResponseWrapper<Comment>>(`comment/${id}`)
    .then(({ data }) => data.data);

export const reportComment = async (id: number, data: CommentReportFields) =>
  axios.post(`comment/${id}/report`, data).then(() => true);

export const mobileReportComment = async (id: number, reason: string) =>
  axios
    .post(`comment/${id}/report-mobile`, { body: reason })
    .then(({ data }) => data.data);

export const fetchById = async (id: number) =>
  axios
    .get<ResponseWrapper<Comment>>(`comment/${id}`)
    .then(({ data }) => data.data);
