import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { UserSimple } from './user';
import { Track, Filter } from './tracks';
import { Genre } from './genres';
import { Country } from './countries';

export interface Raffle {
  id: number;
  link: string;
  title: string;
  slug: string;
  description: string;
  video_url: string;
  plain_text_description: string;
  costs: number;
  image: string;
  opened_at: number;
  closed_at: number;
  user: UserSimple;
  user_joined: boolean;
  popular: boolean;
  media: RaffleImage[];
  total_contestants: number;
  share: {
    ig_share_1080x1920?: string;
  };
  remix_contest: boolean;
  open_demo_submission: boolean;
  contest: boolean;
  track?: Track;
  private: boolean;
  status?: RaffleStatus;
  winner?: {
    winner_id: number;
    winning_track_id?: number;
  };
  genres: Genre[];
  countries: Country[];
  stems?: string;
  type: 'raffle' | 'contest' | 'remix_contest' | 'open_demo_submission';
  my_entries: number[];
  members_only: boolean;
  primary_color: string;
  extra_field_1?: string;
}

export type RaffleStatus = 'active' | 'closed';

interface RaffleImage {
  url: string;
}

export const raffleSortOptions = [
  'latest',
  'popular',
  'vcoins_high',
  'vcoins_low',
  'ending',
] as const;
export type RaffleSort = typeof raffleSortOptions[number];

export const raffleFilterOptions = [
  'contest',
  'remix_contest',
  'open_demo_submission',
  'talent_contests',
  'remix_contests',
  'raffles',
  'all',
] as const;
export type RaffleFilter = typeof raffleFilterOptions[number];

const defaultRaffleSort: RaffleSort = 'latest';

export interface RaffleGetArgs {
  page?: number;
  sort?: RaffleSort;
  filter?: RaffleFilter;
  keyword?: string;
  user?: number;
  open?: boolean;
  contest_id?: number;
  countries?: string;
  genres?: string;
  highlight?: boolean;
}

export interface IContestFilter {
  genre?: number[];
  country?: number[];
}

export const getAll = (
  { sort = defaultRaffleSort, ...restOfParams }: RaffleGetArgs,
  token?: string,
) =>
  axios
    .get<ResponseWrapperPaginated<Raffle[]>>('/raffles', {
      params: {
        ...restOfParams,
        sort,
      },
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => data);

export const getAllRaffelContestLists = () =>
  axios
    .get<ResponseWrapper<{ id: number; type: string }[]>>('/sitemap/contests')
    .then(({ data }) => data);

export const getById = (raffleId: number, token?: string) =>
  axios
    .get<ResponseWrapper<Raffle>>(
      `/raffles/${raffleId}`,
      token
        ? {
            headers: { authorization: token },
          }
        : {},
    )
    .then(({ data }) => data.data);

export const getPreview = (previewToken: string) =>
  axios
    .get<ResponseWrapper<Raffle>>(`/raffles/preview/${previewToken}`)
    .then(({ data }) => data.data);

export const getContestants = (raffleId: number, page?: number) =>
  axios
    .get<ResponseWrapperPaginated<UserSimple[]>>(
      `/raffles/${raffleId}/contestants`,
      { params: { page } },
    )
    .then(({ data }) => data);

export const subscribe = (raffleId: number) =>
  axios
    .post<ResponseWrapper<Record<string, never>>>(`/raffle/${raffleId}`)
    .then(({ data }) => data);

export const sendStems = (raffleId: number, email: string) =>
  axios
    .post<ResponseWrapper<Record<string, never>>>(
      `/raffles/${raffleId}/send-stems`,
      {
        email,
      },
    )
    .then(({ data }) => data);

export const getForUser = (userId: number, params: RaffleGetArgs = {}) =>
  axios
    .get<ResponseWrapperPaginated<Raffle[]>>(`user/${userId}/raffles`, {
      params: {
        page: 1,
        ...params,
      },
    })
    .then(({ data }) => data);

export const fetch = (ids: number[], token?: string) =>
  ids.length > 0
    ? axios
        .get<ResponseWrapper<Raffle[]>>(`raffles/ids/${ids.join(',')}`, {
          ...(token ? { headers: { authorization: token } } : {}),
        })
        .then(({ data }) => data.data)
    : Promise.resolve([]);

export const fetchSingle = (id: number, token?: string) =>
  axios
    .get<ResponseWrapper<Raffle[]>>(`raffles/ids/${id}`, {
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => data.data[0]);

export const fetchRaffleTracks = (raffleId: number, filters: Filter = {}) =>
  axios
    .get<ResponseWrapperPaginated<Track[]>>(`/raffles/${raffleId}/tracks`, {
      params: { ...filters },
    })
    .then(({ data }) => data);

export const fetchJoinedByUser = (userId: number, params: RaffleGetArgs = {}) =>
  axios
    .get<ResponseWrapperPaginated<Raffle[]>>(`/user/${userId}/joined-raffles`, {
      params: {
        page: 1,
        ...params,
      },
    })
    .then(({ data }) => data);

export const acceptDenyTeamInvite = (inviteId: number, isAccept?: boolean) =>
  axios
    .get(`/invitations/${inviteId}/${isAccept ? 'accept' : 'deny'}`)
    .then(({ data: { data } }) => data.data);
