/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FocusEventHandler } from 'react';
import Select, { StylesConfig } from 'react-select';
import { style } from '../../api';

export interface DropdownOption {
  value: number | string;
  label: string;
  icon?: string;
}

type Props = {
  onChange: (selected: DropdownOption | DropdownOption[] | null) => void;
  options: readonly DropdownOption[];
  placeholder?: string;
  value?: DropdownOption | DropdownOption[] | null;
  closeMenuOnSelect?: boolean;
  isClearable?: boolean;
  components?: any;
  isMulti?: boolean;
  hideSelectedOptions?: boolean;
  styles?: StylesConfig;
  menuPortalTarget?: HTMLElement | null;
  menuPosition?: 'absolute' | 'fixed';
  isDisabled?: boolean;
  isSearchable?: boolean;
  id?: string;
  instanceId?: string;
  defaultValue?: DropdownOption | DropdownOption[] | null;
  style?: React.CSSProperties;
  className?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  menuIsOpen?: boolean;
  name?: string;
  isOptionDisabled?: (option: DropdownOption) => boolean;
};

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
  input: (provided: any) => ({
    ...provided,
    paddingTop: '0',
    paddingBottom: '0',
    // height: '20px',
    width: '1px',
    margin: '0',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    marginBottom: '5px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    paddingTop: '0',
    paddingBottom: '0',
    boxShadow: state.isFocused ? '0 3px 10px 0 rgb(34 41 47 / 10%)' : 'none',
    margin: '0px 0px 20px 0px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
  }),
  multiValue: (provided: any) => ({
    ...provided,
  }),
  option: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any, { isFocused }: any) => ({
    ...provided,
    display: isFocused ? 'none' : 'flex',
    alignItems: 'center',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const customTheme = (theme: any) => ({
  ...theme,
  borderColor: style.primary_pink_new,
  colors: {
    ...theme.colors,
    primary: '#F71454',
    primary25: '#F7145440',
    primary50: '#F7145480',
    primary75: '#F71454C0',
    danger: '#ea5455',
    dangerLight: '#f7a9a9',
    neutral0: '#ffffff',
    neutral5: '#efefef',
    neutral10: '#d8d6de',
    neutral20: '#d8d6de',
    neutral30: '#d8d6de',
    neutral40: '#4b4b4b',
    neutral50: '#b9b9c3',
    neutral60: '#b9b9c3',
    neutral70: '#b9b9c3',
    neutral80: '#000000',
    neutral90: '#b9b9c3',
  },
});

// ! @TS-IGNORE IS REQUIRED BECAUSE ONCHANGE REQUIRES A readyonly Options[], and that causes problems
const VuexyDropdown = ({ className, ...props }: Props) => (
  <>
    {/* @ts-ignore */}
    <Select
      {...props}
      styles={customStyles}
      theme={customTheme}
      className={`react-select ${
        className || ''
      } reactstrap-select-custom-style`}
      classNamePrefix="select"
    />
  </>
);

export default VuexyDropdown;
