/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SwiperTypes = 'userTracks' | 'related';

export interface State {
  // type is used in cases when user wants to see related tracks or user's tracks
  // in such cases, swiper state should be independent of player's state
  // until user will start swiping - after that, player state and playlist
  // should be changed to correspond to swiper
  type?: SwiperTypes;
  userId?: number;
  trackId?: number;
  lastTrackId?: string;
}

const initialState: State = {
  type: undefined,
  userId: undefined,
  trackId: undefined,
  lastTrackId: undefined,
};

const swiper = createSlice({
  name: 'swiper',
  initialState,
  reducers: {
    setPlaylistTypeRelated: (state, { payload }: PayloadAction<number>) => {
      state.type = 'related';
      state.trackId = payload;
    },

    setPlaylistTypeUserTracks: (state, { payload }: PayloadAction<number>) => {
      state.type = 'userTracks';
      state.userId = payload;
    },

    clearSwiperType: (state) => {
      state.type = undefined;
      state.userId = undefined;
      state.trackId = undefined;
    },

    rememberTrackId: (state, { payload }: PayloadAction<string>) => {
      state.lastTrackId = payload;
    },
  },
});

export default swiper;
