import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  User,
  Settings,
  SimpleTrack,
  Invoice,
  ProfileCompletion,
} from '../../lib/api/user';
import {
  errorVerifyPayment,
  receiveVerifyPayment,
  updateSubscription,
} from '../actions/subscriptions';

interface InnerState {
  requesting: boolean;
  error?: string;
  requestingSettings: boolean;
  errorSettings?: string;
  isUpdatingUser: boolean;
  userUpdateError?: string;
  isUpdatingProfilePhoto: boolean;
  errorProfilePhoto?: string;
  isUpdatingProfileCover: boolean;
  errorProfileCover?: string;
  isFetchingAllTracks: boolean;
  allTracks?: SimpleTrack[];
  errorAllTracks?: string;
  errorVerifyPayment?: string;
  errorFinishPayment?: string;
  requestingInvoices: boolean;
  invoices: Invoice[];
  sendingOtp: boolean;
  otpSent: boolean;
  otpError?: string;
  accountDeactivating: boolean;
  accountDeactivated: boolean;
  accountDeactivatingError: boolean;
  accountDeleting: boolean;
  accountDeleted: boolean;
  accountDeletingError: boolean;
  status?: string;
  deactivated_at?: number;
  accountReactivated: boolean;
  accountReactivationError: boolean;
}

export type State = InnerState & Partial<User>;

export const defaultState: State = {
  requesting: false,
  error: undefined,
  requestingSettings: false,
  errorSettings: undefined,
  isUpdatingUser: false,
  userUpdateError: undefined,
  isUpdatingProfilePhoto: false,
  errorProfilePhoto: undefined,
  isUpdatingProfileCover: false,
  errorProfileCover: undefined,
  isFetchingAllTracks: false,
  allTracks: undefined,
  errorAllTracks: undefined,
  errorVerifyPayment: undefined,
  errorFinishPayment: undefined,
  requestingInvoices: false,
  invoices: [],
  sendingOtp: false,
  otpSent: false,
  otpError: undefined,
  accountDeactivating: false,
  accountDeactivated: false,
  accountDeactivatingError: false,
  accountDeleting: false,
  accountDeleted: false,
  accountDeletingError: false,
  deactivated_at: undefined,
  accountReactivated: false,
  accountReactivationError: false,
};

const slice = createSlice({
  name: 'USER',
  initialState: defaultState,
  reducers: {
    requestCurrent: (state) => {
      state.requesting = true;
    },
    errorCurrent: (state, { payload }: PayloadAction<string>) => {
      state.requesting = false;
      state.error = payload;
    },
    receiveCurrent: (state, { payload }: PayloadAction<User>) => ({
      ...state,
      requesting: false,
      error: undefined,
      ...payload,
    }),
    requestSettings: (state) => {
      state.requestingSettings = true;
    },
    updateSettings: (state, { payload }: PayloadAction<Settings>) => ({
      ...state,
      requestingSettings: false,
      errorSettings: undefined,
      ...{ settings: payload },
    }),
    errorSettings: (state, { payload }: PayloadAction<string>) => {
      state.requestingSettings = false;
      state.errorSettings = payload;
    },
    requestUserUpdate: (state) => {
      state.isUpdatingUser = true;
    },
    receiveUserUpdate: (state, { payload }: PayloadAction<User>) => ({
      ...state,
      isUpdatingUser: false,
      userUpdateError: undefined,
      ...payload,
    }),
    errorUserUpdate: (state, { payload }: PayloadAction<string>) => {
      state.isUpdatingUser = false;
      state.userUpdateError = payload;
    },
    requestPhotoUpdate: (state) => {
      state.isUpdatingProfilePhoto = true;
    },
    errorPhotoUpdate: (state, { payload }: PayloadAction<string>) => {
      state.isUpdatingProfilePhoto = false;
      state.errorProfilePhoto = payload;
    },

    requestCoverUpdate: (state) => {
      state.isUpdatingProfileCover = true;
    },
    errorCoverUpdate: (state, { payload }: PayloadAction<string>) => {
      state.isUpdatingProfileCover = false;
      state.errorProfileCover = payload;
    },
    requestTracksAll: (state) => {
      state.isFetchingAllTracks = true;
      state.errorAllTracks = undefined;
    },
    receiveTracksAll: (state, { payload }: PayloadAction<SimpleTrack[]>) => {
      state.isFetchingAllTracks = false;
      state.allTracks = payload;
    },
    errorTracksAll: (state, { payload }: PayloadAction<string>) => {
      state.isFetchingAllTracks = false;
      state.errorAllTracks = payload;
    },

    setInstructedStatus: (state) => {
      state.instructed = true;
    },
    facebookLinked: (state, { payload }) => {
      state.facebook = payload;
    },
    spotifyLinked: (state, { payload }) => ({
      ...state,
      ...payload,
    }),

    requestInvoices: (state) => {
      state.requestingInvoices = true;
    },
    receiveInvoices: (state, { payload }: PayloadAction<Invoice[]>) => {
      state.requestingInvoices = false;
      state.invoices = payload;
    },

    tracksArchivedNotified: (state) => {
      state.tracks_archived = false;
    },

    sendingOtp: (state) => {
      state.sendingOtp = true;
    },

    otpSent: (state) => {
      state.sendingOtp = false;
      state.otpSent = true;
    },

    otpError: (state, { payload }: PayloadAction<string>) => {
      state.sendingOtp = false;
      state.otpError = payload;
    },

    resetOtp: (state) => {
      state.sendingOtp = false;
      state.otpSent = false;
      state.otpError = undefined;
    },

    requestDeactivateAccount: (state) => {
      state.accountDeactivating = true;
    },

    accountDeactivated: (state) => {
      state.accountDeactivating = false;
      state.accountDeactivated = true;
      state.accountDeactivatingError = false;
    },

    accountDeactivatingError: (state) => {
      state.accountDeactivating = false;
      state.accountDeactivated = false;
      state.accountDeactivatingError = true;
    },

    requestDeleteAccount: (state) => {
      state.accountDeleting = true;
    },

    accountDeleted: (state) => {
      state.accountDeleting = false;
      state.accountDeleted = true;
      state.accountDeletingError = false;
    },

    accountDeletingError: (state) => {
      state.accountDeleting = false;
      state.accountDeleted = false;
      state.accountDeletingError = true;
    },

    accountDeactivateReset: (state) => {
      state.accountDeactivating = false;
      state.accountDeactivated = false;
      state.accountDeactivatingError = false;
    },

    accountDeleteReset: (state) => {
      state.accountDeleting = false;
      state.accountDeleted = false;
      state.accountDeletingError = false;
    },

    accountReactivated: (state) => {
      state.accountReactivated = true;
      state.accountReactivationError = false;
    },

    accountReactivationError: (state) => {
      state.accountReactivated = false;
      state.accountReactivationError = true;
    },

    accountReactivationReset: (state) => {
      state.accountReactivated = false;
      state.accountReactivationError = false;
    },

    profileShared: (state, { payload }: PayloadAction<ProfileCompletion>) => {
      state.profile_completion = payload;
    },
  },
  extraReducers: {
    // TODO remove
    [errorVerifyPayment.type]: (state, { payload }: PayloadAction<string>) => {
      state.errorVerifyPayment = payload;
    },
    [receiveVerifyPayment.type]: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.errorVerifyPayment = undefined;

      if (payload && state.subscription) {
        state.subscription.expires_at = payload;
      }

      state.member = true;
    },
    [updateSubscription.type]: (state, { payload }) => {
      state.subscription = payload;
      state.member = true;
    },
  },
});

export default slice;
export const userActions = slice.actions;
