import React, { useState, useEffect } from 'react';
import Image, { ImageProps } from 'next/image';
import styled from 'styled-components';
import { Track, style, useCurrentUser, usePlayer } from '../api';
import Flex, { FlexProps } from './common/Flex';
import { Back, HeartBorder, Heart } from './common/Icons';
import PlayButton from './common/Buttons/V2/PlayButton';
import TransparentButton from './common/Buttons/TransparentButton';
import { usePlayerState } from './Player/PlayerContext';

interface CardProps {
  track: Track;
  overlay?: 'none' | 'like' | 'skip';
  onSkipButton?: () => void;
  onLikeButton?: () => void;
  hideContent?: boolean;
  disableHover?: boolean;
  circle?: boolean;
  local?: boolean;
  playTrack?: (trackId: number) => void;
  showPlayButtonOnLoad?: boolean;
  imageProps?: Partial<Pick<ImageProps, 'loading' | 'src'>>;
  alwaysShowPlayButton?: boolean;
  playButtonSize?: string;
  isPlaying?: boolean;
  autoHideButtons?: boolean;
  hideButtonsOnPlay?: boolean;
  rounded?: boolean;
}

const Buttons = styled(Flex)`
  ${({ disableHover }: { disableHover?: CardProps['disableHover'] }) =>
    !disableHover &&
    `
    transition: opacity 1s ease-out;
    opacity: 0;
    height: auto;
    overflow: hidden;
`}
`;

const StyledCard = styled(Flex)<{
  overlay: CardProps['overlay'];
  disableHover: CardProps['disableHover'];
  forceShowButtons: boolean;
}>`
  padding: 0px;
  transition: background 300ms linear;
  text-transform: capitalize;
  :hover {
    ${({ overlay, disableHover }) =>
      (overlay === 'none' || disableHover) &&
      `
      background-color: #00000033;
    `}
  }

  :hover ${Buttons} {
    opacity: 1;
    height: 100%;
    pointer-events: all;
  }

  ${({ forceShowButtons }) =>
    forceShowButtons &&
    `${Buttons} {
      opacity: 1;
      height: 100%;
      pointer-events: all;
    }`}
`;

const PlayerCover = ({
  track,
  overlay = 'none',
  local,
  onSkipButton,
  onLikeButton,
  hideContent,
  disableHover,
  playTrack,
  showPlayButtonOnLoad,
  imageProps,
  alwaysShowPlayButton,
  playButtonSize = '35%',
  isPlaying,
  autoHideButtons,
  hideButtonsOnPlay,
  rounded,
  ...props
}: CardProps & FlexProps) => {
  const [forceShowOverlay, setForceShowOverlay] = useState(
    Boolean(showPlayButtonOnLoad) || autoHideButtons,
  );
  const [hideButtons, setHideButtons] = useState(false);
  const playerState = usePlayerState();
  const player = usePlayer();
  const isCurrentTrackPlaying =
    track.id === player.track?.id && !playerState?.paused;

  const user = useCurrentUser();
  let disableHoverShow = disableHover;

  if (user.created_at !== undefined) {
    const registerDate = new Date(+user.created_at * 1000);
    const createdDate = new Date(
      registerDate.setDate(registerDate.getDate() + 10),
    );

    disableHoverShow = new Date() < createdDate;
  }

  useEffect(() => {
    if (hideButtonsOnPlay && isCurrentTrackPlaying) {
      const timeoutId = setTimeout(() => {
        setHideButtons(true);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
    setHideButtons(false);
    return () => {};
  }, [hideButtonsOnPlay, isCurrentTrackPlaying]);

  useEffect(() => {
    setTimeout(() => {
      if (forceShowOverlay) {
        setForceShowOverlay(false);
      }
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundColor = () => {
    if (overlay === 'skip') {
      return style.grey_dark_new;
    }
    if (overlay === 'like') {
      return style.primary_pink_new;
    }
    return 'transparent';
  };

  return (
    <Flex
      alignItems="center"
      flex={0}
      justifyContent="center"
      position="relative"
      {...props}
    >
      <Image
        alt={track?.title || 'No artwork'}
        src={
          track?.track_img === 'https://dev.virpp.com/'
            ? '/static/images/artworks/no_artwork_img.webp'
            : track?.images?.['210x210'] || track?.track_img
        }
        className={`pointer-events-none${rounded ? ' br-5' : ''}`}
        loading="lazy"
        {...imageProps}
        fill
        sizes="100vw"
        style={{
          objectFit: 'cover',
        }}
      />
      {!hideContent && (
        <StyledCard
          p={2}
          disableHover={disableHoverShow}
          overlay={overlay}
          backgroundColor={getBackgroundColor()}
          color="white"
          alignItems="center"
          justifyContent="center"
          width="inherit"
          height="inherit"
          forceShowButtons={
            !hideButtons &&
            (alwaysShowPlayButton ||
              isCurrentTrackPlaying ||
              (!isPlaying && !forceShowOverlay))
          }
        >
          {overlay === 'none' ? (
            <Buttons
              width="100%"
              height="100%"
              position="relative"
              alignItems="center"
              justifyContent={
                onSkipButton && onLikeButton ? 'space-between' : 'center'
              }
            >
              {onSkipButton && (
                <TransparentButton
                  color={style.white}
                  className="ml-1"
                  size={24}
                  onClick={onSkipButton}
                >
                  <Back size="100%" />
                </TransparentButton>
              )}
              {track.release_status !== 'live' &&
                track.release_status !== 'approved' && (
                  <PlayButton
                    trackId={track.id}
                    size={playButtonSize}
                    local={local}
                    maxWidth="100%"
                    maxHeight="100%"
                    playTrack={playTrack}
                  />
                )}

              {onLikeButton && (
                <TransparentButton
                  onClick={onLikeButton}
                  color={style.white}
                  size={24}
                  className="mr-1"
                >
                  {track.logged_in_user_liked_track ? (
                    <Heart size="100%" />
                  ) : (
                    <HeartBorder size="100%" />
                  )}
                </TransparentButton>
              )}
            </Buttons>
          ) : (
            <h1>{overlay}</h1>
          )}
        </StyledCard>
      )}
    </Flex>
  );
};

export default PlayerCover;
