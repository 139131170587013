import { RootState } from '../reducers';
import { getAllPaginatedItems, getTotalResults } from './index';

export const selectFollowersIds = ({ followers }: RootState) =>
  followers.followersIds;

export const selectFollowingIds = ({ followers }: RootState) =>
  followers.followingIds;

export const selectFollowers = (mutual?: boolean) => ({
  followers: { followers, followersMutual, entries },
}: RootState) =>
  getAllPaginatedItems(mutual ? followersMutual : followers, entries);

export const selectTotalFollowers = (mutual?: boolean) => ({
  followers: { followers, followersMutual },
}: RootState) => getTotalResults(mutual ? followersMutual : followers);

export const selectFollowersPagination = (mutual?: boolean) => ({
  followers,
}: RootState) =>
  mutual
    ? followers.followersMutual.pagination
    : followers.followers.pagination;
