import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useRouter } from 'next/router';
import * as usersAPI from '../../lib/api/users';
import * as statisticsAPI from '../../lib/api/statistics';
import * as followersAPI from '../../lib/api/followers';
import { getEntries, getNextPageParam, getTotal } from '../../utils/pagination';
import { defaultResponsePaginated } from '../../lib/api/ResponseWrapper';
import { useSelect } from './common/useSelect';
import { API, useCheckUserProPlanMember } from '../../index';
import { useMergeWithPreviousData } from './common/queryUtils';
import { addToDataLayerGA } from '../../../utils/googleAnalytics';
import isClientside from '../../../utils/isClientside';
import { ProducerRole } from '../../lib/api/user';

const identifier = 'users';

export { identifier as usersIdentifier };

export const useUser = (userId?: number) => {
  const key = [identifier, userId];
  const merge = useMergeWithPreviousData(key);
  return useQuery(key, () => (userId ? usersAPI.getUser(userId) : undefined), {
    select: merge,
  });
};

export const useUsers = (ids: number[]) => {
  const queryClient = useQueryClient();

  const { data = [] } = useQuery(
    [identifier, ids.join(',')],
    () => usersAPI.fetch(ids),
    {
      onSuccess: (result) => {
        result.forEach((user) => {
          queryClient.setQueryData([identifier, user.id], user);
        });
      },
    },
  );

  return data;
};

export const useUserStats = (userId: number | undefined) => {
  const key = [identifier, userId, 'stats'];
  const merge = useMergeWithPreviousData(key);
  const { data } = useQuery(
    key,
    () => (userId ? statisticsAPI.fetchStats(userId) : undefined),
    { select: merge },
  );

  return data;
};

export const useSocialInfo = (userId: number | undefined) => {
  const key = [identifier, userId, 'social-info'];
  const merge = useMergeWithPreviousData(key);

  return useQuery(
    key,
    () => (userId ? usersAPI.fetchSocial(userId) : undefined),
    { select: merge },
  );
};

export const useFollowers = (userId?: number, mutual?: boolean) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery(
    [identifier, 'followers', userId],
    ({ pageParam }) =>
      userId
        ? followersAPI.getFollowers(userId, pageParam, mutual)
        : defaultResponsePaginated([]),
    { getNextPageParam },
  );

  const followers = getEntries(data);
  const total = getTotal(data);

  return [followers, total, isLoading, fetchNextPage] as const;
};

export const useFollow = (userId: number) => {
  const { data: follower } = useUser(userId);
  const queryClient = useQueryClient();

  const { mutateAsync: follow, isLoading: isLoadingFollow } = useMutation(
    () => followersAPI.followUser(userId),
    {
      onSettled: () => {
        queryClient.invalidateQueries([identifier, userId]);
      },
    },
  );

  const { mutateAsync: unfollow, isLoading: isLoadingUnfollow } = useMutation(
    () => followersAPI.unfollowUser(userId),
    {
      onSettled: () => {
        queryClient.invalidateQueries([identifier, userId]);
      },
    },
  );

  const following = Boolean(follower?.logged_in_user_follows_user);
  const followsMe = useSelect(({ followers: { followersIds } }) =>
    follower?.id ? followersIds.includes(follower?.id) : false,
  );

  const toggle = async () => {
    if (following) {
      await unfollow();
    } else {
      await follow();
    }
  };

  return {
    follower,
    toggle,
    following,
    followsMe,
    loading: isLoadingFollow || isLoadingUnfollow,
  };
};

export const useBlockUnblockUser = (userId: number) => {
  const queryClient = useQueryClient();
  const block = useMutation(() => usersAPI.blockUser(userId), {
    onSuccess: () => queryClient.invalidateQueries([identifier, userId]),
  });
  const unblock = useMutation(() => usersAPI.unblockUser(userId), {
    onSuccess: () => queryClient.invalidateQueries([identifier, userId]),
  });

  return {
    block,
    unblock,
  };
};

export const useSpotlightArtist = () =>
  useQuery([identifier, 'spotlight-artist'], usersAPI.getSpotlight);

export const useSocials = (userId: number | undefined) => {
  const { data: socials = [], refetch: refetchSocial } = useSocialInfo(userId);

  const { mutate: addSocial } = useMutation(API.user.addSocialInfo, {
    onSuccess: () => {
      if (!userId) return;
      refetchSocial();
    },
  });

  return { socials, addSocial };
};

export const useIsPro = (userId: number) => {
  const { data: user } = useUser(userId);
  return !!user?.member;
};

export const useQuerySuccessStories = () =>
  useQuery([identifier, 'success-stories'], usersAPI.getSuccessStories);

export const useQuerySpotlights = () =>
  useQuery([identifier, 'spotlights'], usersAPI.getSpotlights);

export const useSendGoogleAnalysticDataLayer = () => {
  const router = useRouter();
  const isProMember = useCheckUserProPlanMember();

  const sendGoogleDatalayer = (
    payload: {
      event: string;
      userId?: number;
      userType?: string;
      artistType?: ProducerRole[];
      currency?: string;
      value?: number;
    },
    {
      validateForFreeUser = false,
      paramsList,
    }: { validateForFreeUser: boolean; paramsList: string[] },
  ) => {
    if (isClientside && router.query.fire_ga?.toString() === 'true') {
      // @ts-ignore
      const params = new URLSearchParams(router.query);
      paramsList.forEach((param) => {
        params.delete(param);
      });
      router.replace(
        { pathname: router.pathname, query: params.toString() },
        undefined,
        {
          shallow: true,
        },
      );
      if (!validateForFreeUser) {
        addToDataLayerGA({
          ...payload,
        });
      }

      if (validateForFreeUser && !isProMember) {
        addToDataLayerGA({
          ...payload,
        });
      }
    }
  };

  return {
    sendGoogleDatalayer,
  };
};

export const useSetDataLayerAutoLoginFlagSessionStorage = () => {
  const setIsDataLayerSent = () => {
    sessionStorage.setItem('isLoginDataLayerSent', 'true');
  };
  const getIsDataLayerSentSessionStorage = () =>
    !!sessionStorage.getItem('isLoginDataLayerSent');
  return { setIsDataLayerSent, getIsDataLayerSentSessionStorage };
};

export const useGetUserOnDemand = (userId?: number) => {
  const getUserDataOnCall = async () => {
    if (userId) {
      const data = await usersAPI.getUser(userId);
      return data;
    }
    return undefined;
  };

  return {
    getUserDataOnCall,
  };
};
