import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

const LinkComponent = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => <FooterLink href={href}>{children}</FooterLink>;

export default LinkComponent;

const FooterLink = styled(Link)`
  color: #565656 !important;
`;
