import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { RootState } from '../reducers';

const getTracksMemoized = memoizeOne(
  (ids, entries) => ids.map((id: number) => entries[id]),
  // compare only tracks ids
  ([ids1], [ids2]) => isEqual(ids1, ids2),
);

export const selectPlayerTracks = ({
  tracks: { entries },
  player,
}: RootState) => getTracksMemoized(player.currentPlaylist, entries);

export const selectCurrentPlayerTrack = ({ player, tracks }: RootState) => {
  const { currentTrack, currentPlaylist } = player;
  if (currentTrack === undefined) return undefined;

  return tracks.entries[currentPlaylist[currentTrack]];
};

export const selectIsPlaying = ({ player }: RootState) => player.isPlaying;

export const selectIsLoading = ({ player }: RootState) => player.isLoading;

export const selectPlayer = ({ player }: RootState) => player;
