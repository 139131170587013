import { useEffect, useCallback, useState, useRef } from 'react';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';

import { PlaylistType } from '../dispatch/player';
import { playerActions, useDispatch } from '../index';
import { useSelect } from './common/useSelect';

export const useLoadTracksToPlayer = () => {
  const dispatch = useDispatch();

  return (args: {
    ids: number[];
    resetList: boolean;
    playlistType?: PlaylistType;
    startPlayerOnLoad?: boolean | undefined;
    shuffle?: boolean | undefined;
  }) => {
    dispatch(playerActions.addTracksToPlaylistById(args));
  };
};

export const useWaveform = (
  trackId: number | undefined,
  fullData?: boolean,
) => {
  const dispatch = useDispatch();

  const waveform = useSelect((state) => {
    if (!trackId) return undefined;

    return fullData
      ? state.player.fullWaveforms[trackId]
      : state.player.waveforms[trackId];
  });

  useEffect(() => {
    if (trackId === undefined) return;
    if (waveform?.length && waveform.length >= 0) return;

    dispatch(playerActions.fetchTrackWaveform(trackId, fullData));
  }, [trackId, fullData]);

  return waveform;
};

export const usePlayerPosition = () => {
  const dispatch = useDispatch();
  const state = useSelect(({ player: { isPosSetAllowed, position } }) => ({
    isPosSetAllowed,
    position,
  }));

  const setTrackPosition = useCallback(
    (position: number) => {
      dispatch(playerActions.setPosition(position));
    },
    [dispatch],
  );

  const allowSetPosition = useCallback(
    (val: boolean) => {
      dispatch(playerActions.allowSetPosition(val));
    },
    [dispatch],
  );

  return {
    ...state,
    setTrackPosition,
    allowSetPosition,
  };
};

export const usePlayer = () => {
  const dispatch = useDispatch();

  const isPlaying = useSelect((state) => state.player.isPlaying);
  const trackIndex = useSelect((state) => state.player.currentTrack);
  const playlist = useSelect((state) => state.player.currentPlaylist);
  const currentTrackid = trackIndex ? playlist[trackIndex] : undefined;
  const isFetchingWaveform = useSelect((state) => {
    if (!currentTrackid) return undefined;
    return state.player.fetchingWaveforms[currentTrackid];
  });
  const tracks = useSelect((state) => state.tracks.entries);
  const isEditMode = useSelect((state) => state.player.editMode);

  const track =
    trackIndex !== undefined ? tracks[playlist[trackIndex]] : undefined;

  const playPauseTrack = useCallback(() => {
    dispatch(playerActions.playPauseTrack());
  }, [dispatch]);

  const skipCurrentTrack = useCallback(() => {
    dispatch(playerActions.skipCurrentTrack());
  }, [dispatch]);

  const fetchTrackWaveform = useCallback(
    (id: number, full = false) => {
      dispatch(playerActions.fetchTrackWaveform(id, full));
    },
    [dispatch],
  );

  const toggleEditMode = useCallback(
    (val: boolean) => {
      dispatch(playerActions.toggleEditMode(val));
    },
    [dispatch],
  );

  const setTrackPosition = useCallback(
    (position: number) => {
      dispatch(playerActions.setPosition(position));
    },
    [dispatch],
  );

  const setCurrentTrack = useCallback(
    (id: number) => {
      dispatch(playerActions.setCurrentTrack(id));
    },
    [dispatch],
  );

  const playTrackById = useCallback(
    (id: number) => {
      if (!playlist.includes(id)) {
        dispatch(
          playerActions.addTracksToPlaylistById({
            ids: [id],
            playlistType: 'userTracks',
            resetList: false,
          }),
        );
      }
      dispatch(playerActions.playTrackById(id));
    },
    [dispatch],
  );

  const playTrackByIdOrEnqueuePlaylist = useCallback(
    (ids: number[], playlistType: PlaylistType, resetList = true) => (
      id: number,
    ) => {
      if (!playlist.includes(id)) {
        dispatch(
          playerActions.addTracksToPlaylistById({
            ids,
            playlistType,
            resetList,
            startPlayerOnLoad: true,
          }),
        );
      }

      dispatch(playerActions.playTrackById(id));
    },
    [dispatch],
  );

  const replayTrack = useCallback(() => {
    dispatch(playerActions.replayTrack());
  }, [dispatch]);

  const pause = useCallback(() => {
    dispatch(playerActions.pause());
  }, [dispatch]);

  const play = useCallback(() => {
    dispatch(playerActions.play());
  }, [dispatch]);

  return {
    playPauseTrack,
    skipCurrentTrack,
    fetchTrackWaveform,
    toggleEditMode,
    setTrackPosition,
    setCurrentTrack,
    playTrackById,
    playTrackByIdOrEnqueuePlaylist,
    replayTrack,
    isFetchingWaveform,
    isPlaying,
    track,
    isPlaylist: playlist.length > 1,
    isEditMode,
    pause,
    play,
    currentIndex: trackIndex,
    playlist,
  };
};

export const usePlayPlaylist = (
  trackIds: number[],
  playlistType: PlaylistType,
) => {
  const [hasReset, setHasReset] = useState(false);
  const dispatch = useDispatch();

  // Use a reference to prevent a stale closure
  const ids = useRef(trackIds);

  useDeepCompareEffect(() => {
    ids.current = trackIds;
  }, [trackIds]);

  const playTrack = (trackIdToPlay: number) => {
    if (!hasReset) {
      dispatch(
        playerActions.addTracksToPlaylistById({
          ids: ids.current,
          playlistType,
          resetList: true,
          startPlayerOnLoad: true,
        }),
      );
      setHasReset(true);
    }
    dispatch(playerActions.playTrackById(trackIdToPlay));
  };

  return playTrack;
};
