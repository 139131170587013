export const getPlanNamesFromPlanTypes = (planType?: string) => {
  if (planType === 'growth') {
    return 'Growing';
  }
  if (planType === 'ultra') {
    return 'Performing';
  }
  if (planType === 'premium') {
    return 'Ultra';
  }
  return 'pricing:free' as string;
};
