import React, { useState } from 'react';
import styled from 'styled-components';
import { Check } from '@styled-icons/boxicons-regular/Check';
import useTranslation from 'next-translate/useTranslation';
import { API } from '../api';
import { ErrorLabel } from './common/Miscellanea';

import { Button } from '../VuexyDesign/atoms/_index';

const ResendVerificationEmail = ({
  email,
  close,
}: {
  email: string;
  close: () => void;
}) => {
  const { t } = useTranslation('profile');
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [resending, setResending] = useState(false);

  const sendLink = async () => {
    setResending(true);
    try {
      const resolvedResp = await API.authentication.resendVerificationEmail(
        email,
      );
      if (resolvedResp) {
        setSuccess(true);
        setTimeout(() => close(), 2000);
      } else {
        setSuccess(false);
      }
    } catch (error: any) {
      setSuccess(false);
    } finally {
      setResending(false);
    }
  };

  return (
    <ResendContainer>
      <TextDiv>
        {t('profile:resentVerification') as string} {email}?
      </TextDiv>
      {success ? (
        <div>
          <BoldGreenCheck size={40} /> {t('profile:newLinkSent') as string}
        </div>
      ) : (
        <Button
          className="br-5"
          style={{ width: '12.5rem' }}
          onClick={sendLink}
          disabled={resending}
        >
          {t('profile:resend') as string}
        </Button>
      )}
      {success === false && (
        <ErrorLabel> {t('profile:somethingWentWrong') as string} </ErrorLabel>
      )}
    </ResendContainer>
  );
};

export default ResendVerificationEmail;

const TextDiv = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const ResendContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 33px;
`;

const BoldGreenCheck = styled(Check)`
  color: green;
  font-weight: bold;
`;
