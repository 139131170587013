import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { DeleteOutline } from '@styled-icons/typicons/DeleteOutline';
import { useRouter } from 'next/router';
import _debounce from 'lodash/debounce';
import useTranslation from 'next-translate/useTranslation';
import { X } from 'react-feather';
import { style, useFetchSearch } from '../api';
import { fireEventGA } from '../utils/googleAnalytics';
import SearchBoxResultsPopup from './SearchBoxResultsPopup';
import { mobile, desktop, useIsDesktop, noDesktop } from '../style/constants';
import useDisableBackgroundScrolling from '../hooks/useDisableBackgroundScrolling';
import { useHeaderHeight } from '../hooks/style';

const minimumLengthValidationMsg = 'search:searchMustContain';

type Props = {
  toggleLinks: (showLinks: boolean) => void;
  showLinks?: boolean;
};

const SearchBox = ({ toggleLinks, showLinks = true }: Props) => {
  const { t } = useTranslation('search');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchedQuery, setSearchedQuery] = useState('');
  const [focussed, setFocussed] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const router = useRouter();
  const isDesktop = useIsDesktop();
  const inputRef = React.createRef<HTMLInputElement>();
  const { headerHeight } = useHeaderHeight();

  useEffect(() => {
    if (searchQuery.length === 0 && !showSearchPopup) {
      toggleLinks(true);
    } else if (showSearchPopup) {
      toggleLinks(false);
    } else if (!showSearchPopup) {
      toggleLinks(true);
    }
  }, [searchQuery, showSearchPopup, toggleLinks]);

  const onClick = () => {
    toggleLinks(!showLinks);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(_debounce(setSearchedQuery, 500), []);

  useEffect(() => {
    // Focus search input on mobile when search button is pressed
    if (!isDesktop && showSearchPopup && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearchPopup, isDesktop, inputRef]);

  useEffect(() => {
    if (router.query && router.query.keyword) {
      setSearchQuery(router.query.keyword as string);
    }
  }, [router.query]);

  useDisableBackgroundScrolling(!isDesktop && showSearchPopup);
  useFetchSearch(searchedQuery);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    debounceSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const onSubmit = () => {
    if (searchQuery.length > 1) {
      if (router) {
        router.push({
          pathname: '/search',
          query: { keyword: searchQuery.trimRight() },
        });
      }

      fireEventGA({
        category: 'User',
        action: 'Search Submit',
        label: searchQuery,
      });
    }
  };

  if (isDesktop) {
    return (
      <SearchBoxWrapperMobile>
        {showSearchPopup && (
          <SearchBoxResultsWrapperMobile offset={headerHeight}>
            <InputWrapper className="pl-1">
              <SearchInput
                offset={headerHeight}
                ref={inputRef}
                type="text"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={t('search:searchPlaceholder') as string}
                value={searchQuery}
                onFocus={() => setFocussed(true)}
                onBlur={() => setFocussed(false)}
              />
            </InputWrapper>
            {searchQuery.length === 1 && (
              <SearchErrorMessage>
                {t(minimumLengthValidationMsg)}
              </SearchErrorMessage>
            )}
          </SearchBoxResultsWrapperMobile>
        )}
        {showSearchPopup ? (
          <>
            <X
              onClick={() => {
                setSearchQuery('');
                setShowSearchPopup(false);
                toggleLinks(true);
                onClick();
              }}
              size={22}
              color={style.grey_dark_new}
              className="mx-1"
              style={{ cursor: 'pointer' }}
            />
            {searchQuery.length >= 2 && (
              <SearchBoxResultsPopup
                searchQuery={searchQuery}
                onSubmit={onSubmit}
                focussed={focussed}
              />
            )}
          </>
        ) : (
          <Search
            onClick={() => {
              toggleLinks(false);
              setSearchQuery('');
              setShowSearchPopup(!showSearchPopup);
              onClick();
            }}
            size={22}
            color={style.grey_dark_new}
            className="mx-1"
            style={{ cursor: 'pointer' }}
          />
        )}
      </SearchBoxWrapperMobile>
    );
  }

  return (
    <SearchBoxWrapper>
      <InputWrapper>
        <ButtonContainer>
          <Search size="20" onClick={onSubmit} />
        </ButtonContainer>
        <SearchInput
          className="pl-0 w-100"
          offset={headerHeight}
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('search:searchPlaceholder') as string}
          value={searchQuery}
          onFocus={() => setFocussed(true)}
          onBlur={() => setFocussed(false)}
        />
        {searchQuery.length > 0 && (
          <ButtonContainer
            onClick={() => setSearchQuery('')}
            style={{ width: 40 }}
          >
            <DeleteOutline size={17} color={style.grey_main_new} />
          </ButtonContainer>
        )}
      </InputWrapper>
      {searchQuery.length === 1 && (
        <SearchErrorMessage>{t(minimumLengthValidationMsg)}</SearchErrorMessage>
      )}
      {searchQuery.length >= 2 && (
        <SearchBoxResultsPopup
          searchQuery={searchQuery}
          onSubmit={onSubmit}
          focussed={focussed}
        />
      )}
    </SearchBoxWrapper>
  );
};

export default SearchBox;

const SearchBoxWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SearchBoxResultsWrapperMobile = styled.div<{ offset: number }>`
  width: 100%;
  border-radius: 5px;
  background-color: ${style.white};
  left: 0px;
  top: ${(p) => p.offset}px;
  border: solid 2px ${style.primary_pink_new};
`;

const SearchBoxWrapper = styled.div`
  width: 100%;
  border: 1.8px solid ${style.grey_main_new};
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-right: 32px;
`;

const InputWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  align-items: center;
  width: 100%;
  border: none !important;

  @media ${mobile} {
    position: relative;
  }
`;

const SearchInput = styled.input<{ offset: number }>`
  background: #ffffff;
  border: none !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;

  ::placeholder {
    font-weight: 400;
    border: none !important;
  }

  @media ${desktop} {
    padding-left: 0px;
    height: 40px;
    border-radius: 5px;
    border: none !important;
    width: 100%;
  }

  @media ${noDesktop} {
    top: ${(p) => p.offset}px;
  }
`;

const SearchErrorMessage = styled.div`
  font-size: 8.5px;
  text-align: center;
  color: ${style.dark};
  position: absolute;
  bottom: -8px;

  @media ${noDesktop} {
    position: absolute;
    margin-top: 0px;
    bottom: -15px;
  }
`;

const ButtonContainer = styled.button`
  background: #ffffff;
  border: none !important;
  border-radius: 5px;
  color: ${style.grey_dark_new};

  width: 45px;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  padding: 0px 10px;
`;

// const ClearSearchButtonMobile = styled(ButtonContainer)`
//   top: 0px;
//   right: 5vw;
//   position: absolute;
// `;
