import { useRouter } from 'next/router';
import { useIsSmallMobile } from '../style/constants';
import {
  pagesWithHiddenHeader,
  pagesWithHiddenHeaderOnMobile,
} from '../constants/pages-with-hidden-elements';

const useHeaderVisibility = () => {
  const isMobile = useIsSmallMobile();
  const { route } = useRouter();

  const visibleDesktop = !pagesWithHiddenHeader.includes(route);
  const visibleMobile = !pagesWithHiddenHeaderOnMobile.includes(route);

  if (visibleMobile && visibleDesktop) {
    return true;
  }

  if (!isMobile) {
    return visibleDesktop;
  }

  return visibleMobile;
};

export default useHeaderVisibility;
