import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import enhanceWithClickOutside from 'react-onclickoutside';
import useTranslation from 'next-translate/useTranslation';
import {
  Eye,
  BarChart2,
  Bell,
  DollarSign,
  HelpCircle,
  LogOut,
  MessageSquare,
  Music,
  Settings,
  TrendingUp,
  AlertTriangle,
  ExternalLink,
  Globe,
} from 'react-feather';
import { Warning } from '@styled-icons/material';
import { getWalletEarningsData } from '../../../api/lib/api/statistics';

import {
  style,
  useUnreadNotifications,
  useCurrentUser,
  useSubscriptionInfo,
  useUserProfileCompleted,
} from '../../../api';
import { Role } from '../../../api/lib/api/user';

import { Link } from '../../common/Miscellanea';
import { noDesktop, desktop, useIsDesktop } from '../../../style/constants';
import Notification from './NotificationIndicator';
import UserImage from '../../common/UserImage';
import Flex from '../../common/Flex';
import useLogout from '../../../hooks/useLogout';
import {
  TextNoMargin,
  TextRegular16,
  TextRegular14,
} from '../../../VuexyDesign/atoms/Text';
import { Button } from '../../../VuexyDesign/atoms/_index';
import {
  languageNames,
  LanguageCode,
} from '../../../VuexyDesign/organisms/LanguageSpikeModal';

interface Props {
  close: () => void;
  profilePhotoUrl?: string;
  newMessages?: number;
  role?: Role | null;
  setLanguage: (value: boolean) => void;
}

const ProfileMenu = ({
  close,
  profilePhotoUrl,
  newMessages,
  role,
  setLanguage,
}: Props) => {
  const newNotifications = useUnreadNotifications();
  const user = useCurrentUser();
  const isDesktop = useIsDesktop();
  const {
    expiresInDays,
    hasExpired,
    isCanceledSubscription,
  } = useSubscriptionInfo();

  const { display_name, first_name, last_name } = user;
  const handleClick = () => {
    setLanguage(true);
  };

  const { lang, t } = useTranslation('header');

  const { complete: profileCompleted } = useUserProfileCompleted();
  const logout = useLogout();
  const [balance, setBalance] = useState(0);

  let proTexts;

  if (user.abilities?.plan === 'premium') {
    proTexts = t('header:profileMenu.My VIRPP');
  } else if (user.abilities?.plan === 'amateur') {
    proTexts = t('header:becomePro');
  } else {
    proTexts = t('header:profileMenu.Upgrade') as string;
  }

  useEffect(() => {
    if (user && user.id) {
      getWalletEarningsData(user.id)
        .then((res) => {
          setBalance(Number(parseFloat(res.balance_amount).toFixed(2)));
        })
        .catch(() => {
          setBalance(0);
        });
    }
  }, [user.id]);

  return (
    <Wrapper id="user-menu" className={`${isDesktop ? 'br-10' : ''}`}>
      <InfoWrapper>
        <UserImage src={profilePhotoUrl} size={56} />
        <InfoInnerWrapper>
          {user.team === null && <UserName>{display_name}</UserName>}
          {user.team !== null && (
            <TextRegular16 className="font-weight-bold">
              {display_name}
            </TextRegular16>
          )}
          {user.team !== null && role !== 'producer' && (
            <TextRegular14>
              {first_name} {last_name}
            </TextRegular14>
          )}
          {user.team === null && role !== 'producer' && (
            <TextRegular14>
              {first_name} {last_name}
            </TextRegular14>
          )}
          {role === 'producer' && (
            <div className="d-flex">
              <TextRegular16 className="mr-50">
                {t('header:balance') as string}
              </TextRegular16>
              <TextRegular16>€{balance}</TextRegular16>
            </div>
          )}
        </InfoInnerWrapper>
      </InfoWrapper>

      {isDesktop && (
        <Link
          href={profileCompleted ? '/profile' : '/profile/edit'}
          onClick={close}
        >
          <ViewProfileButton className="rounded" completed={profileCompleted}>
            {profileCompleted ? (
              <Eye size={14} color={style.black} />
            ) : (
              <AlertTriangle size={14} color={style.white} />
            )}

            {profileCompleted
              ? t('header:profileMenu.View Profile')
              : t('header:profileMenu.Profile Incomplete')}
          </ViewProfileButton>
        </Link>
      )}
      {isDesktop && (
        <>
          {user.team !== null && (
            <div className="mt-1">
              <Link href="https://business.virpp.com/" onClick={close}>
                <BusinessButton className="rounded" outline color="info">
                  <div className="d-flex">
                    <ExternalLink size={14} className="mr-1" />
                    {t('header:businessDashboard') as string}
                  </div>
                </BusinessButton>
              </Link>
            </div>
          )}
        </>
      )}

      {!isDesktop && (
        <>
          <NotificationsWrapper>
            <Link href="/chat">
              <NotificationsInnerWrapper>
                <NotificationsIconWrapper>
                  <MessageSquare size={24} onClick={close} />
                  {newMessages !== 0 && (
                    <Notification>
                      <MessageCount>{newMessages}</MessageCount>
                    </Notification>
                  )}
                  <TextRegular14 className="ml-1">
                    {t('header:messages') as string}
                  </TextRegular14>
                </NotificationsIconWrapper>
              </NotificationsInnerWrapper>
            </Link>
            <Link href="/notifications">
              <NotificationsInnerWrapper>
                <NotificationsIconWrapper>
                  <Bell size={24} onClick={close} />
                  {newNotifications !== 0 && <Notification />}
                  <TextRegular14 className="ml-1" onClick={close}>
                    {t('header:notifications') as string}
                  </TextRegular14>
                </NotificationsIconWrapper>
                {newNotifications !== 0 && (
                  <NotificationsCount>{newNotifications}</NotificationsCount>
                )}
              </NotificationsInnerWrapper>
            </Link>
          </NotificationsWrapper>
          <Link
            href={profileCompleted ? '/profile' : '/profile/edit'}
            onClick={close}
            className="mb-0"
          >
            <ViewProfileButton className="rounded" completed={profileCompleted}>
              {profileCompleted ? (
                <Eye size={20} color="black" />
              ) : (
                <AlertTriangle size={14} color={style.white} />
              )}

              {profileCompleted
                ? t('header:profileMenu.View Profile')
                : t('header:profileMenu.Profile Incomplete')}
            </ViewProfileButton>
          </Link>
          <div style={{ marginTop: '-15px' }}>
            {user.team !== null && (
              <Link href="https://business.virpp.com/" onClick={close}>
                <BusinessButton className="rounded" outline color="info">
                  <div className="d-flex">
                    <ExternalLink size={14} className="mr-1" />
                    {t('header:businessDashboard') as string}
                  </div>
                </BusinessButton>
              </Link>
            )}
          </div>
        </>
      )}

      <ProfileNav>
        {role === 'producer' && (
          <Link
            href={`${
              user.abilities?.plan === 'premium' ? '/my-pro' : '/become-pro '
            }`}
            className="border-bottom"
            onClick={close}
          >
            <TrendingUp size={24} color="grey" className="mr-1" />
            <div>
              <span
                className="font-weight-bold"
                style={{
                  background:
                    'radial-gradient(rgb(17, 89, 241) 1.64%, rgb(244, 22, 87) 100%)',
                  backgroundSize: '120% 120%',
                  animation:
                    '10s ease 0s infinite normal none running gradient',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {proTexts}
              </span>
              {!!(
                !hasExpired &&
                isCanceledSubscription &&
                expiresInDays !== undefined &&
                expiresInDays < 14
              ) && (
                <Flex flexBasis="100%" mt={1}>
                  <Warning
                    color={style.error_new}
                    size={14}
                    style={{ marginRight: 4 }}
                  />
                  <TextNoMargin fontSize="12px" color={style.error_new}>
                    {t('header:profileMenu.expiration_text_interval', {
                      count: expiresInDays,
                      postProcess: 'interval',
                    })}
                  </TextNoMargin>
                </Flex>
              )}
              {hasExpired && (
                <>
                  <Warning
                    color={style.error_new}
                    size={14}
                    style={{ marginLeft: 8, marginRight: 4 }}
                  />
                  <TextNoMargin fontSize="12px" color={style.error_new}>
                    {t('header:profileMenu.Expired')}
                  </TextNoMargin>
                </>
              )}
            </div>
          </Link>
        )}
        {role === 'producer' && (
          <Link href="/my-tracks" onClick={close}>
            <Music size={24} color="grey" className="mr-1" />
            {t('header:profileMenu.My Tracks') as string}
          </Link>
        )}
        {role === 'producer' && (
          <Link passHref href="/my-wallet" onClick={close}>
            <DollarSign size={24} color="grey" className="mr-1" />
            {t('header:profileMenu.wallet')}
          </Link>
        )}
        {role === 'producer' && (
          <Link href="/statistics" onClick={close}>
            <BarChart2 size={24} color="grey" className="mr-1" />
            {t('header:profileMenu.View Stats') as string}
          </Link>
        )}
        <Link href="/settings" onClick={close}>
          <Settings size={24} color="grey" className="mr-1" />
          {t('header:profileMenu.Settings') as string}
        </Link>
        <a
          onClick={close}
          target="_blank"
          rel="noreferrer"
          href="https://virpp.zendesk.com/hc/en-us"
        >
          <HelpCircle size={24} color="grey" className="mr-1" />
          {t('header:profileMenu.help') as string}
        </a>
        <div className="profile-menu-link" onClick={handleClick}>
          <Globe color="grey" size={24} className="mr-1" />
          {
            t('header:profileMenu.language', {
              language: languageNames[lang as LanguageCode],
            }) as string
          }
        </div>

        <Flex
          className={`profile-menu-link  ${!isDesktop ? 'mb-5' : null}`}
          onClick={() => {
            logout();
            close();
          }}
        >
          <LogOut size={24} color="grey" className="mr-1" />
          {t('header:profileMenu.Logout') as string}
        </Flex>
      </ProfileNav>
    </Wrapper>
  );
};

export default enhanceWithClickOutside(ProfileMenu);

const ProfileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;

  a,
  .profile-menu-link {
    cursor: pointer;
    :hover {
      background-color: ${style.grey_light_1_new};
    }
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    height: 50px;
    color: ${style.dark};
    padding-left: 20px;
  }
`;

const BusinessButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90% !important;
  border-radius: 5px;

  margin-left: auto;
  margin-right: auto;

  @media ${noDesktop} {
    margin: 1rem auto;
    width: 90%;
  }
  padding: 0px 16px;

  box-shadow: none;
  width: auto;
  font-weight: bold;
  min-width: 140px;
`;

const MessageCount = styled.span`
  font-size: 11px !important;
  position: relative;
  bottom: 2px;
  color: #ffffff;
  z-index: 999;
`;

const NotificationsCount = styled.span`
  position: relative;
  bottom: 38px;
  font-size: 11px !important;
  color: #ffffff;
  right: 43px;
  z-index: 999;
`;

const NotificationsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 35px;
  margin-right: 40px;
`;

const NotificationsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-bottom: 10px;
  }
  font-size: 12px;
  color: ${style.black_new};
  width: 100px;
`;

const NotificationsIconWrapper = styled.div`
  position: relative;
  display: flex;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 20px;
  margin-bottom: 25px;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 25px;
`;

const UserName = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ViewProfileButton = styled.button<{ completed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90% !important;
  border-radius: 5px;

  margin-left: auto;
  margin-right: auto;

  @media ${noDesktop} {
    margin: 1rem auto;
    width: 90%;
  }
  padding: 0px 16px;

  box-shadow: none;
  width: auto;
  font-weight: bold;
  min-width: 140px;

  border: ${(props) =>
    props.completed ? `1px solid ${style.grey_light_2_new}` : 'none'};

  color: ${(props) => (props.completed ? style.black_new : style.white)};

  background-color: ${(props) =>
    props.completed ? style.secondary_white_new : style.error_new};

  text-decoration: none !important;

  font-size: 14px;
  cursor: pointer;

  transition: 0.2s background-color;

  :hover {
    background-color: ${(props) => (props.completed ? `whitesmoke` : 'none')};
  }

  svg {
    margin-right: 10px;
  }
`;

const Wrapper = styled.div`
  position: fixed;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  top: 65px;
  right: 0;
  z-index: 24;

  @media ${noDesktop} {
    width: 100vw;
    height: calc(100vh - 65px);
  }

  @media ${desktop} {
    box-shadow: ${style.lightBoxShadow};
  }

  background-color: ${style.secondary_white_new};
`;
