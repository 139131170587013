import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ExtraData = Record<string, unknown>;

export interface VcoinMessage {
  title: string;
  message: string;
}

export interface AlertsState {
  errors: string[];
  successMessages: string[];
  vcoinMessages: VcoinMessage[];
  extraData?: ExtraData;
  currentLastError: string;
}

const initialState: AlertsState = {
  errors: [],
  successMessages: [],
  vcoinMessages: [],
  extraData: undefined,
  currentLastError: '',
};

// interface AlertPayload extends Partial<AxiosError> {
//   message: string;
//   extraData?: ExtraData;
// }

const { reducer, actions } = createSlice({
  name: 'ALERTS',
  initialState,
  reducers: {
    errorAction: (state, { payload }: PayloadAction<string>) => {
      state.errors = [...state.errors, payload];
    },
    setCurrentLastError: (state, { payload }: PayloadAction<string>) => {
      state.currentLastError = payload;
    },
    successAction: (state, { payload }: PayloadAction<string>) => {
      state.successMessages = [...state.successMessages, payload];
    },

    showVcoinMessage: (
      state,
      { payload }: PayloadAction<{ message: string; title: string }>,
    ) => {
      state.vcoinMessages = [...state.vcoinMessages, payload];
    },
  },
});

export default reducer;
export const { errorAction, successAction, setCurrentLastError } = actions;
export const alertsActions = actions;
