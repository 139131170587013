import axios from './axios';
import ResponseWrapper from './ResponseWrapper';
import { UserSimple } from './user';

export interface Artist extends UserSimple {
  logged_in_user_follows_user: boolean;
  plays: number;
}

export const getTop10Artists = async () =>
  axios
    .get('/charts/top-10-artists')
    .then(({ data }: { data: ResponseWrapper<Artist[]> }) => data.data);
