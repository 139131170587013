import React from 'react';

import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import { Close } from '@styled-icons/material/Close';

import MuiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import MuiDialogContent from '@material-ui/core/DialogContent';

import MuiDialogActions from '@material-ui/core/DialogActions';

import MuiDialog, {
  DialogProps as MUIDialogProps,
} from '@material-ui/core/Dialog';
import styled from 'styled-components';
import { mobile } from '../../style/constants';

export interface DialogProps extends Omit<MUIDialogProps, 'onClose'> {
  onClose: () => void;
}

const Dialog = withStyles((theme: Theme) => ({
  paper: {
    borderRadius: '10px',
    // minHeight: 400,
    maxWidth: 720,
    backgroundColor: '#F5F5F5',
    padding: '0px 45px 15px 45px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px 15px 8px',
      width: `calc(100% - 12px)`,
      margin: 0,
    },
  },
}))(MuiDialog);

export interface DialogTitleProps extends WithStyles<typeof dialogStyles> {
  children: React.ReactNode;
  onClose?: () => void;
}

const dialogStyles = () =>
  createStyles({
    root: {
      margin: 0,
      padding: '28px 8px 20px 8px',
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    closeButton: {
      width: 54,
      height: 54,
      position: 'absolute',
      right: 0,
      top: 0,
      boxShadow: 'none',
      color: '#1F1F1F',
      padding: 0,
    },
  });

export const DialogTitle = withStyles(dialogStyles)(
  (props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <h6 className={classes.title}>{children}</h6>

        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            disableRipple
          >
            <Close size={20} />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  },
);

export const DialogContent = withStyles(() => ({
  root: {
    margin: 7,
    padding: 0,
  },
}))(MuiDialogContent);

const DialogActionsUnstyled = withStyles(() => ({
  root: {
    margin: 0,
    justifyContent: 'center',
  },
}))(MuiDialogActions);

export const DialogActions = styled(DialogActionsUnstyled)`
  @media ${mobile} {
    flex-direction: column-reverse;

    button:last-child {
      margin-bottom: 16px;
    }
  }
`;

export const DialogButton = withStyles(() => ({
  root: {
    fontSize: 14,
    borderRadius: 0,
    fontWeight: 'bold',
    boxShadow: '1px 2px 3px #00000029',
    textTransform: 'none',
  },
  disabled: {
    opacity: `1 !important`,
    backgroundColor: '#B7B7B7 !important',
    color: '#fff !important',
  },
}))(MuiButton);

export default Dialog;
