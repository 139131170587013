import { InfiniteData } from 'react-query';
import flatMap from 'lodash/flatMap';
import { ResponseWrapperPaginated } from '../lib/api/ResponseWrapper';

// eslint-disable-next-line import/prefer-default-export
export const getNextPageParam = (
  result: ResponseWrapperPaginated<unknown> | undefined,
) => {
  if (!result?.pagination) return undefined;
  const {
    pagination: { lastPage, currentPage },
  } = result;
  return lastPage > currentPage ? currentPage + 1 : undefined;
};

export const getTotal = (
  data?: InfiniteData<Partial<ResponseWrapperPaginated<unknown>>>,
) => data?.pages[0].pagination?.total || 0;

export const getEntries = <T>(
  data?: InfiniteData<ResponseWrapperPaginated<T[]>>,
) => flatMap(data?.pages, (x) => x.data);
