import axios from './axios';
import ResponseWrapper from './ResponseWrapper';

export interface Genre {
  id: number;
  name: string;
  has_active_tracks?: boolean;
  background_color?: string;
  text_color?: string;
}

export interface GenreGetArgs {
  keyword?: string;
}

export type GenreIdentifier = number | 'all' | 'top10VirppPicks';

export const fetch = async () =>
  axios
    .get('/genres')
    .then(({ data }: { data: ResponseWrapper<Genre[]> }) => data.data);

export const getAll = async ({ keyword }: GenreGetArgs) =>
  axios
    .get('/genres', { params: { keyword } })
    .then(({ data }: { data: ResponseWrapper<Genre[]> }) => data.data);
