import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface State {
  vcoinPaymentLoading: boolean;
  subscriptionPaymentLoading: boolean;
}

export const defaultState: State = {
  vcoinPaymentLoading: false,
  subscriptionPaymentLoading: false,
};

const slice = createSlice({
  name: 'VCOINS',
  initialState: defaultState,
  reducers: {
    setVcoinPaymentLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.vcoinPaymentLoading = payload;
    },

    setSubscriptionPaymentLoading: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.subscriptionPaymentLoading = payload;
    },
  },
});

export default slice;
export const { actions: vcoinActions } = slice;
