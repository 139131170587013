import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { User } from './user';
import { Track } from './tracks';

export interface PlaylistBase {
  id: number;
  name: string;
  type: number;
  total_likes: number;
  user_liked_playlist: boolean;
  track_added_to_playlist?: boolean;
  share?: {
    ig_share_1080x1920: string;
  };
}

export interface PlaylistResponse extends PlaylistBase {
  user: User;
  tracks: Track[];
  user_id: number;
  playlist_id: number;
}

export const get = async (id: number, token?: string) =>
  axios
    .get(`/playlist/${id}`, token ? { headers: { authorization: token } } : {})
    .then(({ data }: { data: ResponseWrapper<PlaylistResponse> }) => data.data);

export const create = async (name: string) =>
  axios
    .post('/playlist', { name })
    .then(({ data }: { data: ResponseWrapper<PlaylistBase> }) => data.data);

export const remove = async (id: number) =>
  axios.delete(`/playlist/${id}`).then(() => true);

export const addTrack = async (playlistId: number, trackId: number) =>
  axios.post(`/playlist/${playlistId}/track/${trackId}`).then(() => true);

export const removeTrack = async (playlistId: number, trackId: number) =>
  axios.delete(`/playlist/${playlistId}/track/${trackId}`).then(() => true);

export const like = async (id: number) =>
  axios.post(`/playlists/${id}/like`).then(() => true);

export const removeLike = async (id: number) =>
  axios.delete(`/playlists/${id}/like`).then(() => true);

export const update = async (id: number, name: string) =>
  axios
    .put(`/playlist/${id}`, { name })
    .then(({ data }: { data: ResponseWrapper<PlaylistResponse> }) => data.data);

export interface PlaylistsRequest {
  id?: number;
  page?: number;
  trackId?: number;
  keyword?: string;
}

interface UserPlaylistsRequest extends PlaylistsRequest {
  id: number;
}

export const getPlaylists = async ({
  id,
  page = 1,
  trackId,
  keyword,
}: UserPlaylistsRequest) =>
  axios
    .get<ResponseWrapperPaginated<PlaylistResponse[]>>(
      `/user/${id}/playlists`,
      { params: { page, trackId, keyword } },
    )
    .then(({ data }) => ({
      playlists: data.data || [],
      pagination: data.pagination,
    }));

export const getAllPlaylists = async (userId: number) =>
  axios
    .get<ResponseWrapperPaginated<PlaylistResponse[]>>(
      `/user/${userId}/playlists?page=all`,
    )
    .then(({ data }) => data.data);
