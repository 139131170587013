import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { GoogleLogin } from '@react-oauth/google';
import { API } from '../../../api';

import { ErrorLabel } from '../Miscellanea';
import { fireEventGA } from '../../../utils/googleAnalytics';
import { useAffiliate } from '../../../utils/affiliate';

declare global {
  interface Window {
    gtag: (
      event: string,
      conversion: string,
      args: { send_to: string },
    ) => void;
    fbq: (type: string, someOther: string) => void;
  }
}

const GoogleLoginHandler = ({
  width,
  finalize,
  authType,
  type,
}: {
  width?: string;
  authType?: string;
  type?: string;
  finalize: ({ token, new_user }: { token: string; new_user: boolean }) => void;
}) => {
  const [error, setError] = useState<string>();
  const affiliate = useAffiliate();

  const responseGoogle = async ({ credential }: { credential?: string }) => {
    setError('');

    if (!credential) {
      setError('Failed to log in.');
    } else {
      try {
        const data = await API.authentication.googleSignInWeb(
          credential,
          affiliate.getAffiliateId(),
        );

        if (data.new_user) {
          fireEventGA({
            category: 'User',
            action: 'Registered',
            label: 'through Google',
          });
        }
        finalize(data);
      } catch (err) {
        const message = err?.response?.data?.message;
        setError(message);
      }
    }
  };

  // Do not render the GoogleLogin component when disabled, because it will always hijack
  // the url and open a google popup window.

  return (
    <>
      <GoogleLogin
        type={type ? 'icon' : 'standard'}
        logo_alignment="center"
        // @ts-ignore
        onSuccess={responseGoogle}
        width={width || '400px'}
        size="medium"
        text={authType === 'SIGN_IN' ? 'signin_with' : 'signup_with'}
      />
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
};

export default GoogleLoginHandler;
