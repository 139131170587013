import React from 'react';

import Flex, { FlexProps } from '../Flex';

export type Props = FlexProps & Omit<React.ComponentProps<'button'>, 'ref'>;

const TransparentButton: React.FC<Props> = ({ children, ...props }) => (
  <Flex
    as="button"
    boxShadow="initial"
    padding="initial"
    color="initial"
    fontWeight="initial"
    backgroundColor="transparent"
    display="block"
    {...props}
  >
    {children}
  </Flex>
);

export default TransparentButton;
