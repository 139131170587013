import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import flatten from 'lodash/flatten';
import * as chatActions from '../actions/chat';
import * as pusherActions from '../actions/pusher';
import { Conversation, Reply, SimpleChatUser } from '../../lib/api/chat';
import { Pagination } from '../../lib/api/Pagination';
import PaginatedEntries from '../PaginatedEntries';

export interface State {
  fetching: Record<number, boolean>;
  fetchingConversation: boolean;
  fetchingCreateConversation: boolean;
  fetchingShowConversation: boolean;
  fetchingCreateConversationReply: boolean;
  fetchingDeleteConversationReply: boolean;
  fetchingSearchConversation: boolean;
  fetchingDeleteConversation: boolean;
  fetchingUserSearchResult: boolean;
  fetchingUnreadMessagesNumber: boolean;
  conversations: Record<number, Conversation>;
  paginatedConversations: PaginatedEntries<number>;
  paginatedFeedbackRequests: PaginatedEntries<number>;
  feedbackRequestsCount: number;
  conversationsDeleted: Record<number, boolean>;
  errorCreateConversation?: string;
  replyEntries: Record<number, Reply>;
  repliesPerConversation: Record<number, PaginatedEntries>;
  searchResult: number[];
  searchConducted: boolean;
  userSearchResult: Record<number, SimpleChatUser>;
  userSearchPaginated: PaginatedEntries;
  unreadMessagesNumber: number;
  autoSelectedUserId: number;
  modalState: boolean;
}

export const defaultState: State = {
  fetching: {},
  fetchingConversation: false,
  fetchingCreateConversation: false,
  fetchingShowConversation: false,
  fetchingCreateConversationReply: false,
  fetchingDeleteConversationReply: false,
  fetchingSearchConversation: false,
  fetchingDeleteConversation: false,
  fetchingUserSearchResult: false,
  fetchingUnreadMessagesNumber: false,
  conversations: {},
  paginatedConversations: {
    data: {},
    pagination: undefined,
  },
  paginatedFeedbackRequests: {
    data: {},
    pagination: undefined,
  },
  feedbackRequestsCount: 0,
  conversationsDeleted: {},
  errorCreateConversation: '',
  replyEntries: {},
  repliesPerConversation: {},
  searchResult: [],
  searchConducted: false,
  userSearchResult: {},
  userSearchPaginated: {
    data: {},
  },
  unreadMessagesNumber: 0,
  autoSelectedUserId: 0,
  modalState: false,
};

export default createReducer<State>(defaultState, {
  [chatActions.add.type]: (
    state,
    { payload: conversations }: PayloadAction<Conversation | Conversation[]>,
  ) => {
    const conversationsToAdd = Array.isArray(conversations)
      ? conversations
      : [conversations];

    conversationsToAdd.forEach((conversation) => {
      state.conversations[conversation.id] = conversation;
    });
  },
  [chatActions.receiveFetch.type]: (
    state,
    { payload: ids }: PayloadAction<number[]>,
  ) => {
    ids.forEach((id) => {
      state.fetching[id] = true;
    });
  },
  [chatActions.receiveFetch.type]: (
    state,
    { payload: ids }: PayloadAction<number[]>,
  ) => {
    ids.forEach((id) => {
      delete state.fetching[id];
    });
  },
  [chatActions.requestConversations.type]: (state: State) => {
    state.fetchingConversation = true;
  },
  [chatActions.receiveConversations.type]: (
    state: State,
    {
      payload,
    }: PayloadAction<{
      conversations: Conversation[];
      pagination: Pagination;
      filter: string;
    }>,
  ) => {
    const { filter, pagination, conversations } = payload;

    const conversationIds = conversations.map(
      (conversation) => conversation.id,
    );

    conversations.forEach((conv) => {
      state.conversations[conv.id] = conv;
    });

    state.fetchingConversation = false;

    if (filter === 'feedback') {
      state.feedbackRequestsCount = pagination.total;

      state.paginatedFeedbackRequests.data[
        pagination.currentPage
      ] = conversationIds;
      state.paginatedFeedbackRequests.pagination = payload.pagination;
    } else {
      state.paginatedConversations.data[
        pagination.currentPage
      ] = conversationIds;
      state.paginatedConversations.pagination = payload.pagination;
    }
  },
  [chatActions.errorConversations.type]: (state: State) => {
    state.fetchingConversation = false;
  },
  [chatActions.requestCreateConversations.type]: (state: State) => {
    state.fetchingCreateConversation = true;
  },
  [chatActions.recieveCreateConversations.type]: (
    state: State,
    { payload }: PayloadAction<Conversation>,
  ) => {
    state.fetchingCreateConversation = false;
    state.conversations[payload.id] = payload;
  },
  [chatActions.errorCreateConversations.type]: (
    state: State,
    { payload }: PayloadAction<string>,
  ) => {
    state.fetchingCreateConversation = false;
    state.errorCreateConversation = payload;
  },

  [chatActions.requestCreateConversationsReply.type]: (state: State) => {
    state.fetchingCreateConversationReply = true;
  },
  [chatActions.recieveCreateConversationsReply.type]: (
    state: State,
    { payload }: PayloadAction<{ reply: Reply; mid?: number }>,
  ) => {
    const conversationId = payload.reply.conversation_id;
    const replyId = payload.reply.id;
    const mockId = payload.mid;

    state.fetchingCreateConversationReply = false;

    state.replyEntries[mockId || replyId] = payload.reply;
    if (payload.reply.created_at) {
      state.conversations[conversationId].last_reply_message =
        payload.reply.body;
      state.conversations[conversationId].last_reply = payload.reply.created_at;
      state.conversations[conversationId].last_reply_read_at = undefined;
    }

    if (
      state.repliesPerConversation[conversationId] &&
      !state.repliesPerConversation[conversationId].data[1].includes(
        mockId || replyId,
      )
    ) {
      state.repliesPerConversation[conversationId].data[1].push(
        mockId || replyId,
      );
    }
  },
  [chatActions.errorCreateConversationsReply.type]: (state: State) => {
    state.fetchingCreateConversationReply = false;
  },

  [chatActions.requestDeleteConversationsReply.type]: (state: State) => {
    state.fetchingDeleteConversationReply = true;
  },
  [chatActions.recieveDeleteConversationsReply.type]: (
    state: State,
    { payload }: PayloadAction<{ id: number; reply: number }>,
  ) => {
    state.fetchingDeleteConversationReply = false;
    if (state.replyEntries[payload.reply]) {
      state.replyEntries[payload.reply].deleted_at = 'deleted';
    }
  },
  [chatActions.errorDeleteConversationsReply.type]: (state: State) => {
    state.fetchingDeleteConversationReply = false;
  },
  [chatActions.requestSearchConversations.type]: (state: State) => {
    state.fetchingSearchConversation = true;
  },
  [chatActions.recieveSearchConversations.type]: (
    state: State,
    { payload }: PayloadAction<Conversation[]>,
  ) => {
    state.fetchingSearchConversation = false;
    payload.forEach((item) => {
      state.conversations[item.id] = item;
      if (!state.searchResult.includes(item.id)) {
        state.searchResult.push(item.id);
      }
    });
    state.searchConducted = true;
  },
  [chatActions.clearSearchResult.type]: (state: State) => {
    state.searchResult = [];
    state.fetchingSearchConversation = false;
    state.searchConducted = false;
  },
  [chatActions.errorSearchConversations.type]: (state: State) => {
    state.fetchingSearchConversation = false;
  },
  [chatActions.requestDeleteConversations.type]: (state: State) => {
    state.fetchingDeleteConversation = true;
  },
  [chatActions.recieveDeleteConversations.type]: (
    state: State,
    { payload }: PayloadAction<number>,
  ) => {
    state.fetchingDeleteConversation = false;
    delete state.conversations[payload];
  },
  [chatActions.errorDeleteConversations.type]: (state: State) => {
    state.fetchingDeleteConversation = false;
  },
  [chatActions.recieveShowConversation.type]: (
    state: State,
    { payload }: PayloadAction<Conversation>,
  ) => {
    state.conversations[payload.id] = payload;
  },
  [chatActions.requestShowConversation.type]: (state: State) => {
    state.fetchingConversation = true;
  },
  [chatActions.errorShowConversation.type]: (state: State) => {
    state.fetchingConversation = false;
  },
  [chatActions.errorConversationDeleted.type]: (
    state: State,
    { payload: conversationId },
  ) => {
    state.conversationsDeleted[conversationId] = true;
  },
  [chatActions.recieveShowConversationReplies.type]: (
    state: State,
    {
      payload,
    }: PayloadAction<{
      id: number;
      replies: Reply[];
      pagination: Pagination;
    }>,
  ) => {
    const { id, replies, pagination } = payload;
    const conversationReplies = replies
      .map((reply: Reply) => reply.id)
      .reverse();

    if (pagination.currentPage > 1) {
      state.repliesPerConversation[id].data[
        pagination.currentPage
      ] = conversationReplies;
    } else {
      state.repliesPerConversation[id] = {
        data: { 1: conversationReplies },
      };
    }
    state.repliesPerConversation[id].pagination = pagination;

    state.fetchingConversation = false;
  },
  [chatActions.requestShowConversationReplies.type]: (state: State) => {
    state.fetchingConversation = true;
  },
  [chatActions.errorShowConversationReplies.type]: (state: State) => {
    state.fetchingConversation = false;
  },
  [chatActions.addReplyEntries.type]: (
    state,
    action: PayloadAction<Reply | Reply[]>,
  ) => {
    if (Array.isArray(action.payload)) {
      action.payload.forEach((reply) => {
        state.replyEntries[reply.id] = reply;
      });
    } else {
      state.replyEntries[action.payload.id] = action.payload;
    }
  },
  [chatActions.requestFindUserByName.type]: (state: State) => {
    state.fetchingUserSearchResult = true;
  },
  [chatActions.errorFindUserByName.type]: (state: State) => {
    state.fetchingUserSearchResult = false;
  },
  [chatActions.receiveUserSearchResult.type]: (
    state: State,
    {
      payload,
    }: PayloadAction<{
      users: SimpleChatUser[];
      pagination: Pagination;
    }>,
  ) => {
    const { users, pagination } = payload;
    users.forEach((u) => {
      state.userSearchResult[u.id] = u;
    });
    state.userSearchPaginated.data[pagination.currentPage] = users.map(
      (u) => u.id,
    );
    state.userSearchPaginated.pagination = pagination;
    state.fetchingUserSearchResult = false;
  },
  [chatActions.clearUserSearch.type]: (state: State) => {
    state.userSearchPaginated.data = {};
    state.userSearchResult = [];
  },

  [chatActions.requestUnreadMessagesNumber.type]: (state: State) => {
    state.fetchingUnreadMessagesNumber = true;
  },
  [chatActions.recieveUnreadMessagesNumber.type]: (
    state: State,
    { payload }: PayloadAction<number>,
  ) => {
    state.fetchingUnreadMessagesNumber = false;
    state.unreadMessagesNumber = payload;
  },
  [chatActions.errorUnreadMessagesNumber.type]: (state: State) => {
    state.fetchingUnreadMessagesNumber = false;
  },

  [chatActions.incrementMessagesCounter.type]: (
    state: State,
    { payload: conversationId },
  ) => {
    const conversation = state.conversations[conversationId];

    if (conversation) {
      if (conversation.total_unread_messages) {
        conversation.total_unread_messages += 1;
      } else {
        conversation.total_unread_messages = 1;
      }
    }

    state.unreadMessagesNumber += 1;
  },

  [chatActions.conversationRead.type]: (
    state: State,
    { payload: conversationId },
  ) => {
    const conversation = state.conversations[conversationId];

    if (conversation) {
      state.unreadMessagesNumber -= conversation.total_unread_messages || 0;
      conversation.total_unread_messages = 0;
    }
  },

  [chatActions.setAutoSelectedUserId.type]: (
    state: State,
    { payload }: PayloadAction<number>,
  ) => {
    state.autoSelectedUserId = payload;
  },

  // TODO: CHECK IF THIS IS GONNA WORK
  // Pusher chat notifications
  [pusherActions.conversationCreated.type]: (state, { payload }) => {
    const conversation = payload as Conversation;
    state.conversations[conversation.id] = conversation;
  },
  [pusherActions.pusherConversationReplyCreated.type]: (state, { payload }) => {
    const reply = payload as Reply;

    const conversation = state.conversations[reply.conversation_id];

    if (conversation) {
      state.replyEntries[reply.id] = reply;
      if (reply.created_at) {
        state.conversations[reply.conversation_id].last_reply =
          reply.created_at;
      }
      state.conversations[reply.conversation_id].last_reply_message =
        reply.body;
      state.conversations[reply.conversation_id].last_reply_read_at = undefined;
      if (state.repliesPerConversation[reply.conversation_id]) {
        state.repliesPerConversation[reply.conversation_id].data[1].push(
          reply.id,
        );
      }
    }
    // will leave here for now, but shouldn't be possible
    // as I was told that only ConversationCreated will be sent
    // when a new conversation is initiated
    else {
      state.conversations[reply.conversation_id] = ({
        last_reply: reply.created_at,
      } as unknown) as Conversation;
    }
  },
  [chatActions.feedbackSent.type]: (
    { replyEntries, repliesPerConversation }: State,
    {
      payload: { trackId, reply: sentReply, conversationId },
    }: PayloadAction<{
      trackId: number;
      reply: Reply;
      conversationId: number;
    }>,
  ) => {
    const conversationReplies = flatten(
      Object.values(
        repliesPerConversation[conversationId] !== undefined
          ? repliesPerConversation[conversationId].data
          : {},
      ),
    ).map((replyId) => replyEntries[replyId]);

    conversationReplies.forEach((reply) => {
      if (reply.track?.id === trackId) {
        reply.feedback_request_status = sentReply.feedback_request_status;
      }
    });
  },

  [chatActions.modalStateType.type]: (
    state,
    { payload }: PayloadAction<boolean>,
  ) => {
    state.modalState = payload;
  },
});
