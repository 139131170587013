import React from 'react';
import Head from 'next/head';

const BrowserPageTitle = ({
  children,
  description,
  noGoogleIndex,
}: {
  children: string;
  description?: string;
  noGoogleIndex?: boolean;
}) => (
  <Head>
    <title>
      {`
      ${process.env.NODE_ENV !== 'production' ? 'DEV | ' : ''}
      ${children} | VIRPP
    `}
    </title>
    {description && <meta name="description" content={description} />}
    {noGoogleIndex && <meta name="googlebot" content="noindex" />}
  </Head>
);

export default BrowserPageTitle;
