import { Dispatch } from 'redux';
import { PresenceChannel } from 'pusher-js';

import * as usersAPI from '../../lib/api/users';
import { ThunkResult } from './ThunkDispatch';
import { getSocket, UserInfo } from '../pusher';
import { RootState } from '../reducers';
import { handleError } from './alerts';
import usersReducer from '../reducers/users';
import determineIdsToFetch from '../../utils/determineIdsToFetch';

const { actions: usersActions } = usersReducer;

export const fetch = (
  ids: number | number[],
  token?: string,
): ThunkResult => async (dispatch, getState) => {
  // Collect all ids that are not available in redux yet

  const { entries, fetching } = getState().users;
  const idsToFetch = determineIdsToFetch(ids, entries, fetching);

  // If we don't have some tracks yet, request them from the backend
  if (idsToFetch.length > 0) {
    try {
      dispatch(usersActions.requestFetch(idsToFetch));

      const users = await usersAPI.fetch(idsToFetch, token);
      dispatch(usersActions.addUsers(users));
      dispatch(usersActions.receiveFetch(idsToFetch));
    } catch (error: any) {
      dispatch(handleError(error));
    }
  }
};

// take a batch of userIds and subscribe to all of them in a loop.
// dispatch just one action for all of them so that redux store does not get
// overloaded with actions
export const subscribeToOnlineStatus = (
  userIds: number | number[],
): ThunkResult => async (dispatch, getState) => {
  const state = getState();
  const socket = getSocket();

  if (!socket) {
    console.warn('Socket is unavailable');
    return;
  }

  const ids = Array.isArray(userIds) ? userIds : [userIds];

  console.info('subscribing to:', ids);

  try {
    ids.forEach((userId) => {
      if (state.users.onlineSubscriptionsUsersIds.includes(userId)) {
        console.info('Already subscribed to ', userId, ', ignoring');
        return;
      }

      const onlineChannel = socket?.subscribe(
        `presence-user-online.${userId}`,
      ) as PresenceChannel<UserInfo>;

      onlineChannel.bind('pusher:subscription_succeeded', (data) => {
        if (data.members[userId]) {
          dispatch(usersActions.userAppearedOnline(userId));
        }
      });

      onlineChannel.bind('pusher:member_added', (data) => {
        if (data.info.id === userId) {
          dispatch(usersActions.userAppearedOnline(userId));
        }
      });

      onlineChannel.bind('pusher:member_removed', (data) => {
        if (data.info.id === userId) {
          dispatch(usersActions.userWentOffline(userId));
        }
      });
    });

    dispatch(usersActions.addOnlineSubscriptions(userIds));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const unsubscribeFromOnlineStatus = (
  userIds: number | number[],
) => async (dispatch: Dispatch) => {
  const socket = getSocket();

  const ids = Array.isArray(userIds) ? userIds : [userIds];

  ids.forEach((userId) =>
    socket?.unsubscribe(`presence-user-online.${userId}`),
  );

  dispatch(usersActions.removeOnlineSubscriptions(userIds));
};

export const unsubscribeFromAllOnlineStatuses = () => (
  _: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();

  unsubscribeFromOnlineStatus(state.users.onlineSubscriptionsUsersIds);
};
