import Pusher from 'pusher-js';
import { StoreType, setupPusher, stopPusher } from '../api';

export const registerPusher = (store: StoreType) => {
  const { token } = store.getState().authentication;

  if (!token) {
    console.debug('No token found in store');
    return;
  }

  const socket = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: token,
      },
    },
  });

  console.debug('The socket is', socket);

  setupPusher(store, socket);
};

export const unregisterPusher = (store: StoreType) => {
  stopPusher(store);
};
