import { RootState } from '../reducers';
import { LabelWidgetTrack } from '../../lib/api/widget';

export const selectTrackByWidgetHash = (widget_hash?: string) => ({
  tracks,
}: RootState) => {
  const track = !widget_hash
    ? undefined
    : Object.values(tracks?.entries || {}).find(
        (item) => (item as LabelWidgetTrack)?.widget_hash === widget_hash,
      );

  return track as LabelWidgetTrack;
};
