import { InfiniteData, QueryKey, useQueryClient } from 'react-query';
import { ResponseWrapperPaginated } from '../../../lib/api/ResponseWrapper';
import { getEntries } from '../../../utils/pagination';

export const useMergeWithPreviousData = (queryKey: unknown[]) => {
  const queryCache = useQueryClient();
  const previousData = queryCache.getQueryData(queryKey);

  return <T>(data?: T) => (data || previousData) as T | undefined;
};

export const useUpdateEntriesInfiniteQuery = () => {
  const queryClient = useQueryClient();

  return <T>(
    resultEntries: InfiniteData<ResponseWrapperPaginated<T[]>>,
    getKey: (entry: T) => QueryKey,
  ) => {
    const data = getEntries(resultEntries);
    data.forEach((entry) => {
      queryClient.setQueryData(getKey(entry), entry);
    });
  };
};
