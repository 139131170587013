import flatten from 'lodash/flatten';

import PaginatedEntries from '../PaginatedEntries';

// extract all loaded items from pagination and return it as array.
// return empty array otherwise
export const getAllPaginatedItems = <ID extends string | number, T>(
  paginatedEntriesIds: PaginatedEntries<ID> | undefined,
  entriesById: Record<ID, T>,
  reversePages?: boolean,
): T[] => {
  if (!paginatedEntriesIds || !paginatedEntriesIds.pagination) return [];

  const { pagination, data } = paginatedEntriesIds;

  const currentPage = pagination.currentPage || 1;
  let pagesSoFar = Array.from({ length: currentPage }).map(
    (_, index) => index + 1,
  );

  // this option is used in chat
  if (reversePages) {
    pagesSoFar = pagesSoFar.reverse();
  }

  const flatIds = flatten(pagesSoFar.map((pageId) => data[pageId]));

  return flatIds.map((id) => entriesById[id]).filter(Boolean);
};

// try to get and return current page from pagination. return 1 otherwise
export const getCurrentPage = (entries?: PaginatedEntries) =>
  entries?.pagination?.currentPage || 0;

export const isLastPageReached = (
  entries?: PaginatedEntries<number | string>,
) => {
  if (!entries || !entries.pagination) return false;

  const { currentPage, lastPage } = entries.pagination;

  return currentPage >= lastPage;
};

export const getTotalResults = (entries?: PaginatedEntries) =>
  entries?.pagination?.total || 0;

export * from './player';
export * from './user';
export * from './search';
export * from './tracks';
export * from './swiper';
export * from './users';
export * from './auth';
export * from './notifications';
export * from './followers';
export * from './chats';
export * from './label-widget';
