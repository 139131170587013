import { useRouter } from 'next/router';
import { useIsDesktop } from '../style/constants';
import {
  pagesWithHiddenFooter,
  pagesWithHiddenFooterOnMobile,
} from '../constants/pages-with-hidden-elements';

const useFooterVisibility = () => {
  const isDesktop = useIsDesktop();

  const { route } = useRouter();

  const visibleDesktop = !pagesWithHiddenFooter.includes(route);
  const visibleMobile = !pagesWithHiddenFooterOnMobile.includes(route);

  if (visibleMobile && visibleDesktop) {
    return true;
  }

  if (isDesktop) {
    return visibleDesktop;
  }

  return visibleMobile;
};

export default useFooterVisibility;
