import uniq from 'lodash/uniq';
import difference from 'lodash/difference';

/** Collect all ids that are not available in redux yet */
export default (
  ids: number | number[],
  entries: Record<number, unknown>,
  fetching: Record<number, boolean>,
  forceUpdate = false,
) => {
  const idsWithoutDuplicates = uniq(Array.isArray(ids) ? ids : [ids]);

  const idsAlreadyAvailable = forceUpdate
    ? []
    : Object.keys(entries).map(Number);
  const idsFetching = Object.keys(fetching).map(Number);
  const idsToFetch = difference(idsWithoutDuplicates, [
    ...idsAlreadyAvailable,
    ...idsFetching,
  ]).filter(Boolean);

  return idsToFetch;
};
