import axios from './axios';
import { Pagination } from './Pagination';
import ResponseWrapper from './ResponseWrapper';

export interface TrackRelease {
  active_step: string;
  terms_and_agreement: boolean;
  artist_details: ArtistDetails;
  track_details: TrackDetails;
  incorrect_details?: IncorrectDetails;
  album_art: {
    completed: boolean;
    artwork: string;
  };
  music_stores: MusicStores;
  payment_setup: PaymentDetails;
  finish: {
    completed: boolean;
    original_release_date: string;
  };
}

export interface ReleaseTracksResponse {
  data: TrackReleaseDetails[];
  pagination: Pagination;
}

export interface TrackReleaseDetails {
  id: number;
  track_id: number;
  original_release_date: string;
  release_title: string;
  main_genre: string;
  artwork: string;
  links?: {
    live_link: {
      dsp: {
        name?: string;
      };
    };
    url: string;
  };
  artists: TrackArtist[];
  artist_name: string;
}

export interface IncorrectDetails {
  album_art?: {
    artwork?: boolean;
  };
  track_details?: {
    explicit?: boolean;
    main_genre_id?: boolean;
    release_title?: boolean;
    lyrics_language?: boolean;
    main_sub_genre_id?: boolean;
    meta_data_language?: boolean;
    master_file?: boolean;
  };
  artist_details?: {
    label?: boolean;
    artists?: IncorrectArtist[];
  };
  contributors?: IncorrectArtist[];
  finish?: {
    original_release_date?: boolean;
  };
  admin_message: string;
}

export interface IncorrectArtist {
  id: number;
  email?: boolean;
  real_name?: boolean;
  artist_name?: boolean;
}

export interface ArtistDetails {
  completed?: boolean;
  artists: TrackArtist[];
  label: string;
}

export interface TrackArtist {
  id?: number;
  apple_artist_id?: string;
  artist_name: string;
  artist_role: string[];
  artist_type: string;
  email: string;
  percentage?: number;
  real_name: string;
  spotify_artist_id?: string;
  user_id?: number;
  youtube_artist_id?: string;
  first_artist?: boolean;
}

export interface TrackDetails {
  completed?: boolean;
  release_title: string;
  main_genre_id: number;
  main_sub_genre_id: number;
  secondary_genre_id?: number;
  secondary_sub_genre_id?: number;
  production_year: number;
  meta_data_language: string;
  lyrics_language: string;
  explicit: string;
  price: string;
  master_file?: string;
}

export interface MusicStores {
  completed?: boolean;
  stores: string[];
}
export interface PaymentInfo {
  completed?: boolean;
  payment_type: 'bank' | 'paypal';
  bank_name?: string;
  account_number?: string;
  iban_code?: string;
  country_id?: number;
  paypal_email?: string;
}

export interface CountryOption {
  value: number;
  label: string;
}
export interface StoreSelection {
  id: string;
  name: string;
}

export interface PaymentDetails {
  completed?: boolean;
  payment_info: PaymentInfo;
}

export interface ImageInfo {
  height: number;
  url: string;
  width: number;
}

export interface ArtistRole {
  id: number;
  name: string;
}

export interface SpotifyArtist {
  external_urls: {
    spotify: string;
  };
  followers: {
    href: string;
    total: number;
  };
  genres: [];
  href: string;
  id: string;
  images: ImageInfo[];
  name: string;
  popularity: number;
  type: string; // artist
  uri: string;
}

export interface ArtistPercentage {
  artist_id: number;
  percentage: number;
}

export interface DocusignContract {
  redirect_url: number;
}

export interface DocusignSignature {
  email: string;
  name: string;
  artist_name: string;
  user_id: number;
  artist_type: string;
  signed: boolean;
}

export interface Signatures {
  artists: DocusignSignature[];
}

export interface StoreLink {
  dsp: DSP;
  url: string;
}

export interface DSP {
  id: number;
  name: string;
}

export interface FugaLanguage {
  id: number;
  name: string;
}

export interface ReleaseStatistics {
  release_title: string;
  artwork: string;
  artists: TrackArtist[];
  total_streams: number;
  original_release_date: string;
  upload_date: string;
  isrc_code: string;
  upc_number: string;
  track_id: number;
  earnings?: number;
}

export const postMusicStores = async (
  stores: { stores: string[] },
  trackId: number,
) =>
  axios
    .patch<ResponseWrapper<TrackRelease>>(
      `track-releases/${trackId}/stores`,
      stores,
    )
    .then((res) => res.data);

export const patchTrackDetails = async (body: TrackDetails, trackId: number) =>
  axios
    .patch<ResponseWrapper<TrackRelease>>(
      `track-releases/${trackId}/details`,
      body,
    )
    .then((res) => res.data)
    .catch((error) => error.response.data);

export const getTrackRelease = async (trackId: number) =>
  axios
    .get<ResponseWrapper<TrackRelease>>(`track-releases/${trackId}`)
    .then((res) => res.data)
    .catch((error) => error.response.data);

export const getTrackReleaseId = async (trackReleaseId: number) =>
  axios
    .get<ResponseWrapper<TrackRelease>>(`track-releases/show/${trackReleaseId}`)
    .then((res) => res.data)
    .catch((error) => error.response.data);

export const getReleaseDetails = async (filter: string, page: number) =>
  axios
    .get<ResponseWrapper<TrackReleaseDetails>>(
      `track-releases/?filter=${filter}&page=${page}`,
    )
    .then((res) => res.data)
    .catch((error) => error.response.data);

export const postArtwork = async (trackId: number, body: FormData) =>
  axios
    .post<ResponseWrapper<TrackRelease>>(
      `track-releases/${trackId}/artwork`,
      body,
    )
    .then(({ data }) => data)
    .catch((error) => error.response.data);

export const postPaymentInfo = async (data: PaymentInfo, trackId: number) =>
  axios
    .patch<ResponseWrapper<PaymentInfo>>(
      `track-releases/${trackId}/payment`,
      data,
    )
    .then((res) => res.data)
    .catch((error) => error.response.data);

export const getTrackGenres = async () =>
  axios.get('track-releases/genres').then((res) => res.data);

export const getMusicStores = async () =>
  axios
    .get<ResponseWrapper<StoreSelection[]>>(`track-releases/stores`)
    .then((res) => res.data);

export const postWelcomePage = async (
  body: { agree: boolean },
  trackId: number,
) =>
  axios
    .post<ResponseWrapper<TrackRelease>>(
      `track-releases/${trackId}/welcome`,
      body,
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const postFinishPage = async (
  body: { original_release_date: string },
  trackId: number,
) =>
  axios
    .patch<ResponseWrapper<TrackRelease>>(
      `track-releases/${trackId}/finish`,
      body,
    )
    .then((res) => res)
    .catch((err) => err?.response);

export const getSpotifyArtists = async ({
  keyword,
  artistId,
}: {
  keyword?: string;
  artistId?: string;
}) =>
  axios
    .get<ResponseWrapper<SpotifyArtist[]>>(
      `spotify?keyword=${keyword || ''}&artist_id=${artistId || ''}`,
    )
    .then((res) => res.data)
    .catch((e) => e.response.data);

// update artist `with spotify` account endpoint: Add new artist
export const updateArtist = async (body: TrackArtist, trackId: number) =>
  axios
    .put<ResponseWrapper<TrackArtist>>(
      `track-releases/${trackId}/artists/${body.id}`,
      body,
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const addArtist = async (body: TrackArtist, trackId: number) =>
  axios
    .post<ResponseWrapper<TrackArtist>>(
      `track-releases/${trackId}/artists`,
      body,
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const saveLabel = async (body: { label: string }, trackId: number) =>
  axios
    .patch<ResponseWrapper<string>>(`track-releases/${trackId}/label`, body)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const deleteArtist = async (artistId: number, trackId: number) =>
  axios
    .delete<ResponseWrapper<TrackArtist>>(
      `track-releases/${trackId}/artists/${artistId}`,
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

// export const getDocusignContract = async (trackId: number) =>
//   axios
//     .get<ResponseWrapper<DocusignContract>>(
//       `track-releases/${trackId}/signature`,
//     )
//     .then((res) => res.data)
//     .catch((err) => err?.response.data);

export const updateArtistsPercentages = async (
  body: { percentages: ArtistPercentage[] },
  trackId: number,
) =>
  axios
    .put<ResponseWrapper<TrackArtist>>(
      `track-releases/${trackId}/artists/percentages`,
      body,
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

// export const getDocusignSignatures = async (trackId: number) =>
//   axios
//     .get<ResponseWrapper<Signatures>>(`track-releases/${trackId}/signatures`)
//     .then((res) => res.data)
//     .catch((err) => err?.response?.data);

export const emailInstructions = async (
  body: { email: string },
  trackId: number,
) =>
  axios
    .post(`track-releases/${trackId}/instructions`, body)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const uploadChunk = async (body: FormData, trackId: number) =>
  axios
    .post(`track-releases/${trackId}/upload`, body)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const updateReleaseStatus = async (trackId: number, status: string) =>
  axios
    .patch(`track-releases/${trackId}/status`, { status })
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const getLinksForRelease = async (trackId: number) =>
  axios
    .get<ResponseWrapper<StoreLink[]>>(`track-releases/${trackId}/links`)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const getLanguages = async (lyrics?: boolean) =>
  axios
    .get<ResponseWrapper<FugaLanguage[]>>(`track-releases/languages`, {
      params: { lyrics },
    })
    .then((res) => res.data)
    .catch((err) => err?.response?.data);

export const getReleaseStatisticsForTrack = async (
  trackId: number,
  period: string,
): Promise<ReleaseStatistics> =>
  axios
    .get<ResponseWrapper<ReleaseStatistics>>(
      `track-releases/statistics/${trackId}`,
      { params: { period } },
    )
    .then((res) => res.data.data)
    .catch((err) => err?.response?.data);

export const getReleaseStatisticsTracks = async (
  period: string,
): Promise<ReleaseStatistics[]> =>
  axios
    .get<ResponseWrapper<ReleaseStatistics[]>>(
      `track-releases/statistics?period=${period}`,
    )
    .then((res) => res.data.data)
    .catch((err) => err?.response?.data);

export const getTrackReleaseLiveStatus = async (trackId: number) =>
  axios
    .get(`track-releases/${trackId}/dsp-status`)
    .then(({ data }) => data.data);

export const trackReleaseStartDistribution = async (body: {
  agree: boolean;
  trackId?: number;
}) =>
  axios
    .post<ResponseWrapper<TrackRelease>>(`track-releases/start`, body)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);
