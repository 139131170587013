import React from 'react';
import styled from 'styled-components';
import { style } from '../../api';

const VuexyProOutlineButton = ({
  text,
  children,
  ...props
}: VuexyButtonProps) => (
  <ProOutlineButton style={{ borderRadius: '5px' }} {...props}>
    {text} {children}
  </ProOutlineButton>
);

interface VuexyButtonProps {
  href?: string;
  text?: string;
  size?: string;
  active?: boolean;
  'aria-label'?: string;
  block?: boolean;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  target?: string;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default VuexyProOutlineButton;

const ProOutlineButton = styled.button`
  background: ${style.distributionGradient};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  white-space: nowrap;
  box-shadow: none;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  text-decoration: none;
  border-radius: 5px;

  :before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border: 2px solid transparent;
    border-radius: 5px;
    background: inherit;
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    -webkit-mask-repeat: no-repeat;
  }

  :hover {
    color: #fff;
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    box-shadow: 0 8px 25px -8px #8338a4;
    transition: box-shadow 0.2s;
  }

  :focus {
    outline: none;
  }

  :hover::before {
    -webkit-mask: none;
  }
`;
