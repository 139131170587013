import React, { useState } from 'react';
import styled from 'styled-components';
import { Heart } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { style, useAuthentication, useLikeTrack, useTrack } from '../../api';
import AuthenticationPopupDialog from '../../components/Modals/AuthenticationPopupDialog';

import { TextRegular14 } from './Text';
import { Button } from './_index';

export interface LikeProps {
  trackId: number;
  className?: string;
  iconOnly?: boolean;
  size?: number;
}

export default function VuexyLike({ trackId }: LikeProps) {
  const [track] = useTrack(trackId);
  const { t } = useTranslation('tracks');
  const { like, unlike } = useLikeTrack(trackId);
  const { isLoggedIn } = useAuthentication();
  const [isAuthenticationDialogOpen, setIsAuthenticationDialogOpen] = useState(
    false,
  );

  const tl = (
    pathToTranslation: string, // translate with 'as string' to avoid type error
  ) => t(`${pathToTranslation}` as string);

  const handleAuthenticationDialogClose = () => {
    setIsAuthenticationDialogOpen(false);
  };
  const handleAuthenticationDialogOpen = () => {
    setIsAuthenticationDialogOpen(true);
  };

  if (track === undefined) {
    return null;
  }
  if (track.logged_in_user_liked_track && isLoggedIn) {
    return (
      <LikeButton
        onClick={() => unlike()}
        color="secondary"
        className="text-primary d-flex br-5"
        outline
      >
        <Heart size="14" fill={style.primary_pink_new} />
        <TextRegular14>{tl('tracks:like')}</TextRegular14>
      </LikeButton>
    );
  }

  return (
    <>
      <AuthenticationPopupDialog
        isOpen={isAuthenticationDialogOpen}
        onClose={handleAuthenticationDialogClose}
      />
      <LikeButton
        onClick={() => {
          if (isLoggedIn) {
            like();
          } else {
            handleAuthenticationDialogOpen();
          }
        }}
        outline
        color="secondary"
        className="text-dark d-flex br-5"
      >
        <Heart size={14} />
        <TextRegular14>{tl('tracks:like')}</TextRegular14>
      </LikeButton>
    </>
  );
}
const LikeButton = styled(Button)`
  padding: 8px 12px;
  gap: 10px;
  white-space: nowrap;
`;
