import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  incrementLabelNotificationsCounter,
  incrementNotificationsCounter,
  pusherNotificationReceived,
  zeroifyLabelNotificationsCounter,
  zeroifyNotificationsCounter,
} from '../actions/pusher';
import {
  Notification,
  NotificationData,
  LabelNotificationData,
} from '../../lib/api/notifications';
import * as notificationActions from '../actions/notifications';
import { Pagination } from '../../lib/api/Pagination';
import PaginatedEntries from '../PaginatedEntries';

export interface State {
  requestingNotifications: boolean;
  requestingTrackPullStatus: boolean;
  requestingChangeTrackPullStatus: boolean;
  requestingUnreadNotificationsNumber: boolean;
  notifications: Record<string, Notification>;
  paginatedNotifications: PaginatedEntries<string>;
  paginatedNotificationsByLabel: PaginatedEntries<string>;
  paginatedActivityNotifications: PaginatedEntries<string>;
  unreadNotificationsNumber: number;
  unreadLabelNotificationsNumber: number;
  pushNotificationsDeviceToken?: string;
  pushNotificationsPlatformIsIOS?: boolean;
  trackIdForRelease?: number;
}

export type PusherNotification =
  | (NotificationData & { id: string })
  | (LabelNotificationData & { id: string });

export const defaultState: State = {
  requestingNotifications: false,
  requestingTrackPullStatus: false,
  requestingChangeTrackPullStatus: false,
  requestingUnreadNotificationsNumber: false,
  notifications: {},
  paginatedNotifications: {
    data: { 1: [] },
    pagination: undefined,
  },
  paginatedNotificationsByLabel: {
    data: { 1: [] },
    pagination: undefined,
  },
  paginatedActivityNotifications: {
    data: { 1: [] },
    pagination: undefined,
  },
  unreadNotificationsNumber: 0,
  unreadLabelNotificationsNumber: 0,
  pushNotificationsDeviceToken: undefined,
  pushNotificationsPlatformIsIOS: undefined,
  trackIdForRelease: -1,
};

export const isNotificationByLabel = (
  // @ts-ignore
  notificationData,
) => {
  const { type } = notificationData;

  return (
    type === 'ListenerInvited' ||
    type === 'TrackPlayedByLabel' ||
    type === 'TrackPullRequestMade'
  );
};

export default createReducer<State>(defaultState, {
  [notificationActions.addNotificationsEntries.type]: (
    state,
    { payload }: PayloadAction<Notification[]>,
  ) => {
    payload.forEach((notification) => {
      state.notifications[notification.id] = notification;
    });
  },
  [pusherNotificationReceived.type]: (
    state,
    { payload: notification }: PayloadAction<Notification>,
  ) => {
    state.notifications[notification.id] = notification;

    if (
      notification.data.type === 'TrackSelectedForRelease' ||
      notification.data.type === 'TrackReleaseReleased'
    ) {
      state.trackIdForRelease = notification.data.track_id;
    }

    const isLabelNotification = isNotificationByLabel(notification.data);
    const paginatedNotifications = isLabelNotification
      ? state.paginatedNotificationsByLabel
      : state.paginatedNotifications;

    // add notification id to first page of corresponding notifications array
    paginatedNotifications.data[1] = [
      notification.id,
      ...paginatedNotifications.data[1],
    ];
  },

  [incrementNotificationsCounter.type]: (state) => {
    state.unreadNotificationsNumber += 1;
  },
  [zeroifyNotificationsCounter.type]: (state) => {
    state.unreadNotificationsNumber = 0;
  },

  [incrementLabelNotificationsCounter.type]: (state) => {
    state.unreadLabelNotificationsNumber += 1;
  },
  [zeroifyLabelNotificationsCounter.type]: (state) => {
    state.unreadLabelNotificationsNumber = 0;
  },

  [notificationActions.requestNotifications.type]: (state) => {
    state.requestingNotifications = true;
  },
  [notificationActions.receiveNotifications.type]: (
    state,
    {
      payload,
    }: PayloadAction<{
      notifications: Notification[];
      pagination: Pagination;
    }>,
  ) => {
    const { notifications, pagination } = payload;

    state.requestingNotifications = false;

    state.paginatedNotifications.data[
      pagination.currentPage
    ] = notifications.map((notification) => notification.id);

    state.paginatedNotifications.pagination = pagination;
  },
  [notificationActions.receiveLabelNotifications.type]: (
    state,
    {
      payload,
    }: PayloadAction<{
      notifications: Notification[];
      pagination: Pagination;
    }>,
  ) => {
    const { notifications, pagination } = payload;
    state.paginatedNotificationsByLabel.data[
      pagination.currentPage
    ] = notifications.map((notification) => notification.id);
    state.paginatedNotificationsByLabel.pagination = pagination;
    state.requestingNotifications = false;
  },
  [notificationActions.receiveActivityNotifications.type]: (
    state,
    {
      payload,
    }: PayloadAction<{
      notifications: Notification[];
      pagination: Pagination;
    }>,
  ) => {
    const { notifications, pagination } = payload;
    state.paginatedActivityNotifications.data[
      pagination.currentPage
    ] = notifications.map((notification) => notification.id);
    state.paginatedActivityNotifications.pagination = pagination;
    state.requestingNotifications = false;
  },
  [notificationActions.requestUnreadNotificationsNumber.type]: (
    state: State,
  ) => {
    state.requestingUnreadNotificationsNumber = true;
  },
  [notificationActions.recieveUnreadNotificationsNumber.type]: (
    state: State,
    { payload }: PayloadAction<number>,
  ) => {
    state.requestingUnreadNotificationsNumber = false;
    state.unreadNotificationsNumber = payload;
  },
  [notificationActions.setPushDeviceToken.type]: (
    state,
    { payload }: PayloadAction<{ token: string; isIOS: boolean }>,
  ) => {
    state.pushNotificationsDeviceToken = payload.token;
    state.pushNotificationsPlatformIsIOS = payload.isIOS;
  },
  [notificationActions.setTrackForRelease.type]: (
    state,
    { payload: { id } },
  ) => {
    state.trackIdForRelease = id;
  },
});
