import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import PaginatedEntries from '../PaginatedEntries';
import { User } from '../../lib/api/user';
import * as searchActions from '../actions/search';
import { Track } from '../../lib/api/tracks';
import { Pagination } from '../../lib/api/Pagination';
import { SearchKeyword, SearchResult } from '../../lib/api/search';
import { Genre } from '../../lib/api/genres';
import { Raffle } from '../../lib/api/raffles';
import { SimpleChatUser } from '../../lib/api/chat';

export interface State {
  searchResults: SearchResult[];
  recentSearches: SearchKeyword[];
  popularSearches: SearchKeyword[];
  pagination?: Pagination;

  searchResultUserEntries: Record<number, SimpleChatUser>;

  searchResultTracks: PaginatedEntries;
  searchResultUsers: PaginatedEntries;
  searchResultRaffles: PaginatedEntries;
  searchResultContests: PaginatedEntries;
  searchResultGenres: Genre[];
  lastKeyword?: string;
  errorSearchResult: string;
  isFetchingTracksSearch: boolean;
  isFetchingUsersSearch: boolean;
  isFetchingRafflesSearch: boolean;
  isFetchingContestsSearch: boolean;
  isFetchingGenresSearch: boolean;
}

const clearResults = (state: State) => {
  state.searchResults = [];

  state.searchResultTracks.data = {};
  state.searchResultTracks.pagination = undefined;
  state.searchResultUsers.data = {};
  state.searchResultUsers.pagination = undefined;
  state.searchResultRaffles.data = {};
  state.searchResultRaffles.pagination = undefined;
  state.searchResultContests.data = {};
  state.searchResultContests.pagination = undefined;
  state.searchResultGenres = [];
};

export const defaultState: State = {
  searchResults: [],
  recentSearches: [],
  popularSearches: [],
  pagination: undefined,

  errorSearchResult: '',
  searchResultTracks: {
    data: {},
  },
  searchResultUsers: {
    data: {},
  },
  searchResultRaffles: {
    data: {},
  },
  searchResultContests: {
    data: {},
  },
  searchResultUserEntries: {},
  searchResultGenres: [],
  isFetchingTracksSearch: false,
  isFetchingUsersSearch: false,
  isFetchingRafflesSearch: false,
  isFetchingContestsSearch: false,
  isFetchingGenresSearch: false,
};

export default createReducer<State>(defaultState, {
  [searchActions.receiveSearchResults.type]: (
    state,
    { payload: { pagination, data } },
  ) => {
    if (pagination.currentPage === 1) {
      state.searchResults = data;
    } else {
      state.searchResults = [...state.searchResults, ...data];
    }

    state.pagination = pagination;
  },

  [searchActions.receiveSearchesPopular.type]: (state, { payload }) => {
    state.popularSearches = payload;
  },

  [searchActions.receiveSearchesRecent.type]: (state, { payload }) => {
    state.recentSearches = payload;
  },

  [searchActions.clearSearchResult.type]: (state) => {
    clearResults(state);

    state.lastKeyword = undefined;
  },

  [searchActions.updateRecentSearches.type]: (state, { payload: keyword }) => {
    const searches = state.recentSearches;

    // prevent same keywords following each other in the list
    if (searches[0]?.keyword === keyword) return;

    searches.pop();
    state.recentSearches = [{ keyword }, ...searches];
  },

  [searchActions.requestTracksSearch.type]: (
    state,
    { payload: keyword }: PayloadAction<string>,
  ) => {
    state.isFetchingTracksSearch = true;

    // Reset the previous results if a new keyword is being used
    if (state.lastKeyword !== keyword) {
      clearResults(state);
    }

    state.lastKeyword = keyword;
  },
  [searchActions.requestUsersSearch.type]: (
    state,
    { payload: keyword }: PayloadAction<string>,
  ) => {
    state.isFetchingUsersSearch = true;

    // Reset the previous results if a new keyword is being used
    if (state.lastKeyword !== keyword) {
      clearResults(state);
    }

    state.lastKeyword = keyword;
  },
  [searchActions.requestRafflesSearch.type]: (
    state,
    { payload: keyword }: PayloadAction<string>,
  ) => {
    state.isFetchingRafflesSearch = true;

    // Reset the previous results if a new keyword is being used
    if (state.lastKeyword !== keyword) {
      clearResults(state);
    }

    state.lastKeyword = keyword;
  },
  [searchActions.requestContestsSearch.type]: (
    state,
    { payload: keyword }: PayloadAction<string>,
  ) => {
    state.isFetchingContestsSearch = true;

    // Reset the previous results if a new keyword is being used
    if (state.lastKeyword !== keyword) {
      clearResults(state);
    }

    state.lastKeyword = keyword;
  },
  [searchActions.requestGenresSearch.type]: (
    state,
    { payload: keyword }: PayloadAction<string>,
  ) => {
    state.isFetchingGenresSearch = true;

    // Reset the previous results if a new keyword is being used
    if (state.lastKeyword !== keyword) {
      clearResults(state);
    }

    state.lastKeyword = keyword;
  },
  [searchActions.receiveSearchResult_DEPRECATED.type]: (
    state,
    {
      payload: { tracks, users, raffles, contests, genres, keyword },
    }: PayloadAction<{
      tracks?: { data: Track[]; pagination: Pagination };
      users?: { data: User[]; pagination: Pagination };
      raffles?: { data: Raffle[]; pagination: Pagination };
      contests?: { data: Raffle[]; pagination: Pagination };
      genres?: Genre[];
      keyword: string;
    }>,
  ) => {
    if (keyword !== state.lastKeyword) {
      return;
    }

    if (tracks) {
      state.isFetchingTracksSearch = false;
      state.searchResultTracks.data[
        tracks.pagination.currentPage
      ] = tracks.data.map((track) => track.id);
      state.searchResultTracks.pagination = tracks.pagination;
    }

    if (users) {
      state.isFetchingUsersSearch = false;
      state.searchResultUsers.data[
        users.pagination.currentPage
      ] = users.data.map((user) => user.id);
      state.searchResultUsers.pagination = users.pagination;
    }

    if (raffles) {
      state.isFetchingRafflesSearch = false;
      state.searchResultRaffles.data[
        raffles.pagination.currentPage
      ] = raffles.data.map((user) => user.id);
      state.searchResultRaffles.pagination = raffles.pagination;
    }

    if (contests) {
      state.isFetchingContestsSearch = false;
      state.searchResultContests.data[
        contests.pagination.currentPage
      ] = contests.data.map((user) => user.id);
      state.searchResultContests.pagination = contests.pagination;
    }

    if (genres) {
      state.isFetchingGenresSearch = false;
      state.searchResultGenres = genres;
    }
  },

  [searchActions.errorSearchResult.type]: (
    state,
    action: PayloadAction<string>,
  ) => {
    state.errorSearchResult = action.payload;
    state.isFetchingTracksSearch = false;
    state.isFetchingUsersSearch = false;
  },
  [searchActions.receiveSearchResultUserEntries.type]: (
    state,
    { payload: users }: PayloadAction<SimpleChatUser[]>,
  ) => {
    users.forEach((u) => {
      state.searchResultUserEntries[u.id] = u;
    });
  },
});
