/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Error } from '@styled-icons/material/Error';
import useTranslation from 'next-translate/useTranslation';
import { style, useHasPaymentError, useQueryCards } from '../../api';

import Dialog from '../common/Dialog';
import { TextNoMargin } from '../common/Text';
import { FlatButtonLink } from '../common/Link';
import Flex from '../common/Flex';
import { useLocalStorageFlagForCurrentUser } from '../../hooks/useLocalstorageFlag';

const dontShowOnPages = ['/my-pro', '/pro-payment'];
const flag = 'HAS_SHOWN_PAYMENT_METHOD_FAILED_DIALOG';

const PaymentMethodFailedDialog = () => {
  const { t } = useTranslation('modals');
  const [show, setShow] = useState(false);
  const router = useRouter();
  const showOnPage = !dontShowOnPages.includes(router.pathname);
  const [hasShown, setHasShown] = useLocalStorageFlagForCurrentUser(flag);

  const { data: cards = [] } = useQueryCards();

  const activeCard = cards.find((c) => c.default);
  const activeCardFailed = activeCard?.failed;
  const paymentError = useHasPaymentError();

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if ((paymentError || activeCardFailed) && showOnPage && !hasShown) {
      setShow(true);
      setHasShown(true);
    }
  }, [paymentError, activeCardFailed, showOnPage, hasShown]);

  return (
    <Dialog open={show} onClose={onClose}>
      <Flex
        flexDirection="column"
        maxWidth="590px"
        alignItems="center"
        paddingX="60px"
        pb="60px"
      >
        <Error color={style.error_new} size={35} />
        <TextNoMargin fontWeight="bold" fontSize="18px" marginY="12px">
          {t('modals:errors.paymentError')}
        </TextNoMargin>
        <TextNoMargin textAlign="center" mb="16px">
          {activeCard && (
            <span>
              {t('modals:paymentMethod')} <b>{activeCard.name}</b>{' '}
              {t('modals:wasDeclined')}{' '}
            </span>
          )}
          {t('modals:correctPayment')}
        </TextNoMargin>
        <FlatButtonLink href="/settings" onClick={onClose}>
          {t('modals:fixPayment')}
        </FlatButtonLink>
      </Flex>
    </Dialog>
  );
};

export default PaymentMethodFailedDialog;
