/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO add types
import React, { useEffect, useState } from 'react';
import type { ChartData, ChartOptions } from 'chart.js';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import Loader from '../../components/common/Loader';

function DonutChart({ options, data, labelValues }: DonutChartProps) {
  const [chartOptions] = useState<ChartOptions<'doughnut'>>({
    ...defaultOptionsValues,
    ...options,
  });
  const [chartData, setChartData] = useState<ChartData<'doughnut'>>({
    datasets: data.datasets,
  });

  useEffect(() => {
    setChartData({
      datasets: data.datasets,
    });
  }, [data]);
  if (!chartData || !chartOptions) {
    return <Loader />;
  }

  return (
    <>
      {labelValues?.labels?.length !== 0 ? (
        <>
          <Doughnut data={chartData} options={chartOptions} height={275} />
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          No data to display
        </div>
      )}
    </>
  );
}

export default DonutChart;

interface DonutChartProps {
  data: ChartData<'doughnut'>;
  options?: ChartOptions<'doughnut'>;
  labelValues?: {
    labels: string[];
    data: number[];
    backgroundColor: string[];
  };
}

const defaultOptionsValues = {
  responsive: true,
  maintainAspectRatio: false,
  responsiveAnimationDuration: 500,
  cutoutPercentage: 60,
  legend: { display: false },
  tooltips: {
    callbacks: {
      label(tooltipItem: any, data: any) {
        const label = data.datasets[0].labels[tooltipItem.index] || '';
        const value = data.datasets[0].data[tooltipItem.index];
        const output = ` ${label} : ${value} %`;
        return output;
      },
    },
    // Updated default tooltip UI
    shadowOffsetX: 1,
    shadowOffsetY: 1,
    shadowBlur: 8,
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#fff',
    titleFontColor: '#000',
    bodyFontColor: '#000',
  },
};
