import { RootState } from '../reducers';

export const selectSwiperType = ({ swiper }: RootState) => swiper.type;

export const selectSwiperTrack = ({ swiper, tracks }: RootState) => {
  if (!swiper.trackId) return undefined;

  return tracks.entries[swiper.trackId];
};

export const selectSwiperUser = ({ swiper, users }: RootState) => {
  if (!swiper.userId) return undefined;

  return users.entries[swiper.userId];
};

export const selectSwiperRememberedTrackId = ({ swiper }: RootState) =>
  swiper.lastTrackId;
