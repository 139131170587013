import React from 'react';
import Dialog from '../common/Dialog';
import { DialogContent } from '../common/MaterialDialog';
import { useCurrentUser } from '../../api';
import { useIsDesktop } from '../../style/constants';
import AuthenticationPopupDialogBody from '../AuthenticationPopupDialogBody';

interface AuthenticationPopupDialogProps {
  onClose: () => void;
  isOpen: boolean;
  setIsNewUser?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AuthenticationPopupDialog({
  onClose,
  isOpen,
  setIsNewUser,
}: AuthenticationPopupDialogProps) {
  const currentUser = useCurrentUser();
  const isDesktop = useIsDesktop();

  const isLoggedIn = currentUser.id !== undefined;

  const paymentWidth = isDesktop ? '60rem' : '100%';
  const paymentMinWidth = isDesktop ? '40rem' : '100%';

  const registerWidth = isDesktop ? '36.5rem' : '100%';
  const registerMinWidth = isDesktop ? '20rem' : '100%';

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        style={{
          width: isLoggedIn ? paymentWidth : registerWidth,
          minWidth: isLoggedIn ? paymentMinWidth : registerMinWidth,
          overflowY: 'auto',
        }}
        className="p-0 m-0 user-select-none overflow-hidden"
      >
        <AuthenticationPopupDialogBody
          closeDialog={onClose}
          setIsNewUser={setIsNewUser}
        />
      </DialogContent>
    </Dialog>
  );
}

export default AuthenticationPopupDialog;
