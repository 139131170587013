import { useQuery, useQueryClient, useMutation } from 'react-query';
import * as pullAPI from '../../lib/api/pullRequests';

export const pullRequestIdentifier = 'pull-requests';

export const useQueryPullRequest = (pullId?: number) => {
  const key = [pullRequestIdentifier, pullId];
  const query = useQuery(
    key,
    () => (pullId ? pullAPI.get(pullId) : undefined),
    { enabled: !!pullId },
  );

  const client = useQueryClient();

  const accept = useMutation(
    () => {
      if (!pullId) throw new Error('Accepting pull request without valid id');
      return pullAPI.accept(pullId);
    },
    {
      onSuccess: (data) => {
        client.setQueryData(key, data);
      },
    },
  );

  const decline = useMutation(
    () => {
      if (!pullId) throw new Error('Declining pull request without valid id');
      return pullAPI.decline(pullId);
    },
    {
      onSuccess: (data) => {
        client.setQueryData(key, data);
      },
    },
  );

  return {
    ...query,
    accept,
    decline,
  };
};

export const useQueryPullRequestsUnshown = () => {
  const query = useQuery(
    [pullRequestIdentifier, 'unshown'],
    pullAPI.getUnshown,
  );

  const { mutateAsync: markAsShown } = useMutation((pullId: number) =>
    pullAPI.markAsShown(pullId),
  );

  return {
    ...query,
    markAsShown,
  };
};
