export const pagesGoogleSignupPopupHidden = [
  '/login',
  '/register',
  '/register/email/initial',
  '/reset-password',
  '/music-distribution',
  '/distribution-landing/ad-landing',
];

export const pagesGoogleSignupPopupNotRedirectAfterLogin = [
  '/become-pro',
  '/music-distribution',
  '/contests/[raffleId]',
  '/raffles/[raffleId]',
  '/ai-music-chat',
];
