import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';

import useFooterVisibility from './hooks/useFooterVisibility';
import useHeaderVisibility from './hooks/useHeaderVisibility';
import { useHeaderHeight } from './hooks/style';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isHeaderVisible = useHeaderVisibility();
  const isFooterVisible = useFooterVisibility();
  const { headerHeight } = useHeaderHeight();

  return (
    <>
      {isHeaderVisible && <Header />}
      <BodyWrapper
        isFooterVisible={isFooterVisible}
        headerHeight={isHeaderVisible ? headerHeight : 0}
      >
        {children}
      </BodyWrapper>
      {isFooterVisible && <Footer />}
    </>
  );
};

const BodyWrapper = styled.div<{
  headerHeight: number;
  isFooterVisible: boolean;
}>`
  min-height: ${(p) => (p.isFooterVisible ? 'calc(100vh - 506px)' : 'unset')};
  margin-top: ${(p) => p.headerHeight}px;
  margin-bottom: ${(p) => p.headerHeight}px;
`;

export default Layout;
