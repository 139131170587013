import React from 'react';
import { Col } from 'reactstrap';

export const VuexyColumn = ({ children, ...props }: VuexyColProps) => (
  <Col {...props}>{children}</Col>
);

interface VuexyColProps {
  noGutters?: boolean;
  // see https://reactstrap.github.io/components/form Form Grid with Form Row
  className?: string;
  form?: boolean;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
  widths?: Array<string>;
  children?: JSX.Element | JSX.Element[] | undefined;
  style?: string | Record<string, unknown>;
  onClick?: () => void;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
}

export default VuexyColumn;
