import React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { GradientButton } from './common/Buttons';

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ReportErrorMessage = ({ eventId }: { eventId?: string }) => {
  const { t } = useTranslation('common');
  return (
    <ErrorContainer>
      <h1>{t('common:somethingWentWrong') as string}</h1>
      <h3>{t('common:appreciateYourFeedback') as string}</h3>
      <GradientButton onClick={() => Sentry.showReportDialog({ eventId })}>
        {t('common:reportFeedback') as string}
      </GradientButton>
    </ErrorContainer>
  );
};

export default ReportErrorMessage;
