import React from 'react';
import styled from 'styled-components';
import { Link } from './Miscellanea';

const HeaderLogo = styled.img<{ width: number }>`
  width: ${(p) => p.width}px;
  height: auto;
  :hover {
    cursor: pointer;
  }
`;

export default styled(
  ({ className, width = 120 }: { className?: string; width?: number }) => (
    <Link legacyBehavior href="/" className={className}>
      <HeaderLogo
        width={width}
        src="/static/images/logo@2x.png"
        alt="VIRPP"
        srcSet="/static/images/logo@2x.png 2x"
      />
    </Link>
  ),
)``;
