import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { User, Role } from './user';
import { Country } from './countries';
import { roleToRoleID } from '../../utils/role';

export interface ProfileViews {
  id: number;
  user_id: number;
  views: number;
  label_views: number;
  created_at: string;
  updated_at: string;
}

export interface ShortProfile {
  id: number;
  username: string;
  display_name: string;
  location: number;
  birthday: string;
  followers_count: number;
  age: number;
  profile_views: ProfileViews;
  country: Country;
}

export const getUser = async (userId: number, token?: string) =>
  axios
    .get<ResponseWrapper<User>>(
      `/users/${userId}`,
      token
        ? {
            headers: { authorization: token },
          }
        : undefined,
    )
    .then(({ data }) => data.data);

export const getShortProfile = async (userId: number) =>
  axios
    .get<ResponseWrapper<ShortProfile>>(`/users/${userId}/short-profile`)
    .then(({ data }) => data.data);

export const blockUser = async (userId: number) =>
  axios
    .post<ResponseWrapper<ShortProfile>>(`/user/${userId}/block`)
    .then(({ data }) => data.data);

export const unblockUser = async (userId: number) =>
  axios
    .delete<ResponseWrapper<ShortProfile>>(`/user/${userId}/unblock`)
    .then(({ data }) => data.data);

export const reportUser = async (userId: number, reportMessage: string) =>
  axios
    .post<ResponseWrapper<ShortProfile>>(`/user/${userId}/report-mobile`, {
      body: reportMessage,
    })
    .then(({ data }) => data.data);

export const fetch = (ids: number[], token?: string) =>
  ids.length > 0
    ? axios
        .get<ResponseWrapper<User[]>>(`users/ids/${ids.join(',')}`, {
          ...(token ? { headers: { authorization: token } } : {}),
        })
        .then(({ data }) => data.data)
    : Promise.resolve([]);

export const search = ({
  username,
  role,
  page,
}: {
  username?: string;
  role?: Role;
  page: number;
}) =>
  axios
    .get<ResponseWrapperPaginated<User[]>>('users', {
      params: {
        username,
        role: roleToRoleID(role),
        page,
      },
    })
    .then(({ data }) => data);

export type SocialPlatform =
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'spotify'
  | 'soundcloud'
  | 'youtube'
  | 'custom'
  | 'applemusic'
  | 'bandcamp'
  | 'snapchat';

export interface SocialItem {
  type: SocialPlatform;
  url: string;
  username: string;
  id: number;
}

export const fetchSocial = (userId: number) =>
  axios
    .get<ResponseWrapper<SocialItem[]>>(`socials/${userId}`)
    .then(({ data }) => data.data);

export const addSocial = ({
  platform,
  url,
  username,
}: {
  platform: SocialPlatform;
  url: string;
  username: string;
}) => axios.post(`socials`, { platform, url, username });

export const updateSocial = ({
  socialId: userId,
  platform,
  url,
  username,
}: {
  socialId: number;
  platform: SocialPlatform;
  url: string;
  username: string;
}) =>
  axios
    .patch(`socials/${userId}`, {
      userId,
      platform,
      url,
      username,
    })
    .then(() => true);

export const removeSocial = (socialId: number) =>
  axios.delete(`socials/${socialId}`).then(() => true);

export const getSpotlight = async () =>
  axios
    .get('/user/spotlight')
    .then(({ data }: { data: ResponseWrapper<User> }) => data.data);

export type SuccessStoryUser = Pick<
  User,
  | 'id'
  | 'username'
  | 'profile_photo'
  | 'role'
  | 'logged_in_user_follows_user'
  | 'display_name'
  | 'description'
> & { plays: number };

export const getSuccessStories = () =>
  axios
    .get<
      ResponseWrapper<{ label: SuccessStoryUser; user: SuccessStoryUser }[]>
    >('/users/success-stories')
    .then(({ data }) => data.data);

export const getSpotlights = () =>
  axios
    .get<
      ResponseWrapper<
        { user: SuccessStoryUser; image: string; user_image: string }[]
      >
    >('/spotlights')
    .then(({ data }) => data.data);
