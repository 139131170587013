import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { useRouter } from 'next/router';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useIsDesktop, noDesktop } from '../../style/constants';
import { style } from '../../api';
import useFooterPlayerVisibility from '../../hooks/useFooterPlayerVisibility';

import NavLink from './Link';
import { TextBold18, TextRegular14 } from '../common/Text';

const Footer = () => {
  const { t } = useTranslation('footer');
  const { pathname } = useRouter();
  const hideTopMargin = ['/'].includes(pathname);
  const isDesktop = useIsDesktop();
  const isFooterPlayerVisible = useFooterPlayerVisibility();

  return (
    <FooterContainer
      hideTopMargin={hideTopMargin}
      className={`mt-0 bg-light ${isFooterPlayerVisible ? 'mb-5' : 'mb-0'}`}
    >
      <StyledContainer>
        <Row
          className={`justify-content-between mx-0 ${
            isDesktop ? '' : 'flex-column'
          }`}
        >
          <Col md={12} lg={4}>
            <FooterGroup>
              <LogoContainer className={`${isDesktop ? 'mb-1' : ''}`}>
                <Image
                  src="/static/images/logo@2x.png"
                  alt="VIRPP"
                  priority
                  height={39}
                  width={160}
                  className="pointer-events-none img-fluid"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </LogoContainer>
              <GroupTitle>
                <FooterGroup style={{ lineHeight: '1' }}>
                  <TextRegular14 className="my-2">
                    {t('footer:virppDesc')}
                  </TextRegular14>
                  <div className="virppSocial__icons mt-2">
                    <a
                      href="https://www.instagram.com/virpp/"
                      className="virppSocial__icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/static/images/footer/logo-instagram.svg"
                        alt="Instagram Logo"
                        width={30}
                        height={30}
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCRtGAKg8yG44-QXvRsuDQMA"
                      className="virppSocial__icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/static/images/footer/logo-youtube.svg"
                        alt="Youtube Logo"
                        width={30}
                        height={30}
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/ViralPromoPlatform/"
                      className="virppSocial__icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/static/images/footer/logo-facebook.svg"
                        alt="Facebook Logo"
                        width={30}
                        height={30}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/virpp/"
                      className="virppSocial__icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/static/images/footer/logo-linkedin.svg"
                        alt="LinkedIn Logo"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                </FooterGroup>
              </GroupTitle>
            </FooterGroup>
          </Col>
          <div
            className={`d-flex ${
              isDesktop
                ? 'justify-content-between w-50'
                : 'flex-column justify-content-between'
            }`}
          >
            <Col md={2} className="pr-0">
              <FooterGroup className="h-100">
                <TextBold18 className="font-weight-bolder">
                  {t('footer:discover')}
                </TextBold18>
                <GroupTitle className="h-75 mt-2" style={{ width: '9rem' }}>
                  <FooterGroup className="h-100 d-flex flex-column justify-content-around">
                    <GroupLink>
                      <NavLink href="/explore">{t('footer:tracks')}</NavLink>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="/charts">{t('footer:charts')}</NavLink>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="/connect">{t('footer:contests')}</NavLink>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="/become-pro#success-stories">
                        {t('footer:successStories')}
                      </NavLink>
                    </GroupLink>
                  </FooterGroup>
                </GroupTitle>
              </FooterGroup>
            </Col>

            <Col md={2} className="pr-0">
              <FooterGroup className="h-100">
                <TextBold18 className="font-weight-bolder">
                  {t('footer:explore')}
                </TextBold18>
                <GroupTitle className="h-75 mt-2" style={{ width: '9rem' }}>
                  <FooterGroup className="h-100 d-flex flex-column justify-content-around">
                    <GroupLink>
                      <NavLink href="/music-distribution">
                        {t('footer:distribution')}
                      </NavLink>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="https://business.virpp.com/login">
                        {t('footer:forRecordLabels')}
                      </NavLink>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="/become-pro">
                        {t('footer:pricing')}
                      </NavLink>
                    </GroupLink>
                    <GroupLink>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/hello/blog/"
                      >
                        {t('footer:blog')}
                      </a>
                    </GroupLink>
                  </FooterGroup>
                </GroupTitle>
              </FooterGroup>
            </Col>

            <Col md={2} className="pr-0">
              <FooterGroup className={`h-100 ${isDesktop ? '' : ''}`}>
                <TextBold18 className="font-weight-bolder">
                  {t('footer:aboutUs')}
                </TextBold18>
                <GroupTitle className="h-75 mt-2 text-secondary">
                  <FooterGroup className="h-100 d-flex flex-column justify-content-around">
                    <GroupLink>
                      <NavLink href="/how-it-works">
                        {t('footer:howItWorks')}
                      </NavLink>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="/how-it-works/how-to-use">
                        {t('footer:getStarted')}
                      </NavLink>
                    </GroupLink>
                    <GroupLink>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://virpp.zendesk.com/hc/en-us"
                      >
                        {t('footer:support')}
                      </a>
                    </GroupLink>
                    <GroupLink>
                      <NavLink href="mailto:hello@virpp.com">
                        {t('footer:careers')}
                      </NavLink>
                    </GroupLink>
                  </FooterGroup>
                </GroupTitle>
              </FooterGroup>
            </Col>
          </div>
        </Row>
        <Row className="w-100 mx-0">
          <FooterRights
            className={`w-100 d-flex  ml-1 ${
              isDesktop ? 'justify-content-between' : 'px-0 flex-column'
            }`}
          >
            <TextRegular14>
              {t('footer:copyright.copyright')} {new Date().getFullYear()}{' '}
              {t('footer:copyright.virppRights')}
            </TextRegular14>
            <div
              className={`links d-flex justify-content-between font-weight-bold ${
                isDesktop ? '' : 'mt-1 mr-1'
              }`}
            >
              <div className={`${isDesktop ? '' : 'mb-1'}`}>
                <a href="https://tracks.virpp.com/Terms-conditions-and-license-agreement-Virpp.pdf">
                  {t('footer:copyright.terms')}
                </a>
              </div>
              <div className={`${isDesktop ? '' : 'mb-1'}`}>
                <a href="/hello/privacy-statement-virpp/">
                  {t('footer:copyright.privacyPolicy')}
                </a>
              </div>
              <div className={`${isDesktop ? '' : 'mb-1 mr-1'}`}>
                <a target="_blank" href="/hello/privacy-statement-virpp/">
                  {t('footer:copyright.cookies')}
                </a>
              </div>
              {isDesktop ? (
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://virpp.zendesk.com/hc/en-us"
                  >
                    {t('footer:refund')}
                  </a>
                </div>
              ) : null}
            </div>
          </FooterRights>
        </Row>
      </StyledContainer>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer<{ hideTopMargin: boolean }>`
  width: 100%;
  margin-top: ${(p) => (p.hideTopMargin ? 0 : 50)}px;
  padding: 40px 0 50px 0;
  & a {
    text-decoration: none;
    color: ${style.black};
  }
  flex-shrink: 0;

  h4 {
    font-size: 16px;
  }

  .downloadApp__buttons {
    display: flex;
    flex-direction: column;
  }

  .virppSocial__icons {
    display: flex;

    .virppSocial__icon img {
      object-fit: contain;
      height: 30px;
      margin-right: 10px;
    }
  }
`;

const LogoContainer = styled.div`
  height: 39px;
  width: 160px;

  @media ${noDesktop} {
    width: 120px;
    text-align: center;
  }
`;

const StyledContainer = styled(Container)`
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
`;

const FooterGroup = styled.div`
  list-style-type: none;
`;

const GroupTitle = styled.div`
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 41px;
  @media ${noDesktop} {
    margin-bottom: 21px;
  }
`;

const GroupLink = styled.li`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
`;

const FooterRights = styled.div`
  color: ${style.grey_main_new};
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid whitesmoke;

  .links {
    width: 35%;
    @media ${noDesktop} {
      width: 100%;
    }
  }
`;
