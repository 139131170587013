import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { Pagination } from './Pagination';
import { User } from './user';
import { Comment } from './comments';

export interface CommunityPostsListResponse {
  data: CommunityPostResponse[];
  pagination: Pagination;
  debouncedSearchKeyword?: string;
}
export interface CommunityPostResponse {
  id: number;
  title: string;
  slug: string;
  body: string;
  images: [];
  community: {
    id: number;
    user_id: number;
    name: string;
    color: string;
    status: number;
    accept_demos: boolean;
    accept_all_genres: boolean;
    free_users_allowed: boolean;
    label_started_on: null;
    created_at: string;
    updated_at: string;
    genre_id: number;
    slug: string;
  };
  created_at: number;
  votes: number;
  logged_in_user_voted: number | null;
  comments: string;
  total_comments: number;
  upvotes: number;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
}
export interface CommunityResponse {
  id: number;
  name: string;
  slug: string;
  accept_demos: boolean;
  accept_all_genres: boolean;
  genres: [];
  label_started_on: string | null;
  posts: string;
  color: string;
  total_discussion: number;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
}
export interface CommunityAndPostsSearch {
  id: number;
  title: string;
  slug: string;
  type: string;
}
export interface CommunityListResponse {
  data: CommunityResponse[];
  pagination: Pagination;
  debouncedSearchKeyword?: string;
}
export interface CommunityAndPostsSearchResponse {
  data: CommunityAndPostsSearch[];
  pagination: Pagination;
  debouncedSearchKeyword?: string;
}
export interface CommentForFetchById {
  id: number;
  user: User;
  user_liked_comment: boolean;
  replies: Comment[];
  track_id: number;
  body: string;
  like_count: number;
  user_id: number;
  post_comment_id: null;
  created_at: number;
  logged_in_user_liked: number | null;
}
export interface CommunityPostInfo {
  id: number;
  title: string;
  slug: string;
  body: string;
  community: CommunityResponse;
  created_at: number;
  votes: number;
  logged_in_user_voted: number | null;
  total_comments: number;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
}
/* ------------------------------------- community ------------------------------------ */
export const getCommunityInfoDataBySlug = async (slug: string) =>
  axios
    .get<ResponseWrapper<CommunityResponse>>(`communities/${slug}`)
    .then(({ data }) => data.data);

export const getAllCommunityList = (
  page: number,
  perPage = 10,
  keyword = '',
  sort = '',
): Promise<CommunityListResponse> =>
  axios({
    method: 'GET',
    url: `communities`,
    params: { page, perPage, keyword, sort },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));

export const getSearchValueForCommunityAndPosts = (
  page: number,
  perPage = 10,
  keyword = '',
): Promise<CommunityAndPostsSearchResponse> =>
  axios({
    method: 'GET',
    url: `communities/search`,
    params: { page, perPage, keyword },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));
/* -------------------------------------------- posts ------------------------------------------- */
export const getCommunityReleatedPostData = async (
  slug: string,
  page = 1,
  perPage = 10,
  sort = '',
  keyword = '',
): Promise<CommunityPostsListResponse> =>
  axios({
    method: 'GET',
    url: `communities/${slug}/posts`,
    params: { page, perPage, keyword, sort },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));

export const getPostListData = async (
  page: number,
  perPage = 10,
  sort = '',
): Promise<CommunityPostsListResponse> =>
  axios({
    method: 'GET',
    url: `posts`,
    params: { page, perPage, sort },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));

export const getPostInfoDataBySlug = async (slug: string) =>
  axios
    .get<ResponseWrapper<CommunityPostInfo>>(`posts/${slug}`)
    .then(({ data }) => data.data);

export const upvoteOnPost = async (postId: number) =>
  axios
    .post<ResponseWrapper<CommunityPostInfo>>(`posts/${postId}/upvote`)
    .then(({ data }) => data.data);
export const downvoteOnPost = async (postId: number) =>
  axios
    .post<ResponseWrapper<CommunityPostInfo>>(`posts/${postId}/downvote`)
    .then(({ data }) => data.data);

export const reverseVote = async (postId: number) =>
  axios
    .delete<ResponseWrapper<CommunityPostInfo>>(`posts/${postId}/delete-vote`)
    .then(({ data }) => data.data);
/* ------------------------------------------ comments ------------------------------------------ */
export const getCommentsListData = async (
  postId: number,
  page = 1,
  perPage = 20,
) =>
  axios
    .get<ResponseWrapperPaginated<Comment[]>>(`posts/${postId}/comments`, {
      params: { page, perPage },
    })
    .then(({ data }) => ({
      comments: data.data || [],
      pagination: data.pagination,
    }));
export const getFeedbackData = async (url: string, page?: number) =>
  axios
    .get<ResponseWrapperPaginated<Comment[]>>(url, {
      params: { page },
    })
    .then(({ data }) => data.data);

export const addCommentData = async (postId: number, comment: string) =>
  axios
    .post<ResponseWrapper<Comment>>(`posts/${postId}/comment`, {
      body: comment,
    })
    .then(({ data }) => data);

export const replyToCommentData = async (
  postId: number,
  commentId: number,
  text: string,
) =>
  axios
    .post<ResponseWrapper<Comment>>(`posts/${postId}/comment`, {
      post_comment_id: commentId,
      body: text,
    })
    .then(({ data }) => data.data);

export const likeOnCommentData = async (commentId: number) =>
  axios
    .post<ResponseWrapper<Comment>>(`post-comments/${commentId}/like`)
    .then(({ data }) => data.data);

export const removeLikeOnCommentData = async (commentId: number) =>
  axios
    .delete<ResponseWrapper<Comment>>(`post-comments/${commentId}/like`)
    .then(({ data }) => data.data);

export const deleteCommentData = async (commentId: number) =>
  axios
    .delete<ResponseWrapper<Comment>>(`post-comments/${commentId}`)
    .then(({ data }) => data.data);

export const fetchCommentById = async (id: number) =>
  axios
    .get<ResponseWrapper<CommentForFetchById>>(`post-comments/${id}`)
    .then(({ data }) => data.data);
