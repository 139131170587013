import { Pagination } from './Pagination';

export default interface ResponseWrapper<T> {
  data: T;
  success: boolean;
  message: string;
  error: string | string[];
  status?: string;
}
export interface ResponseWrapperPaginated<T> extends ResponseWrapper<T> {
  pagination: Pagination;
}

export interface ErrorResponse {
  message: string;
  data: { data: Record<string, string[]> };
}

export const defaultResponse = <T>(
  data: T,
  errorMessage = '',
): ResponseWrapper<T> => ({
  data,
  success: false,
  error: errorMessage,
  message: errorMessage,
});

export const defaultResponsePaginated = <T>(
  data: T,
  errorMessage?: string,
): ResponseWrapperPaginated<T> => ({
  ...defaultResponse(data, errorMessage),
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 25,
    total: 0,
  },
});
