import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { RaffleGetArgs, RaffleFilter } from '../../lib/api/raffles';
import * as tracksAPI from '../../lib/api/tracks';
import * as rafflesAPI from '../../lib/api/raffles';
import notUndefinedOrNull from '../../utils/notUndefinedOrNull';
import { UserSimple } from '../../index';
import {
  defaultResponsePaginated,
  ResponseWrapperPaginated,
} from '../../lib/api/ResponseWrapper';
import { getEntries, getNextPageParam, getTotal } from '../../utils/pagination';
import { useBalance } from './vcoins';
import {
  useMergeWithPreviousData,
  useUpdateEntriesInfiniteQuery,
} from './common/queryUtils';

export const identifier = 'raffles';

export const useRafflesOrContests = ({
  filter,
  sort,
  countries,
  genres,
  // page,
  open,
  highlight,
}: RaffleGetArgs) => {
  const args = { sort, filter, countries, genres, open, highlight };
  const updateRaffleData = useUpdateEntriesInfiniteQuery();
  const {
    data,
    isLoading: loading,
    fetchNextPage: nextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [identifier, 'all', args],
    ({ pageParam }) => rafflesAPI.getAll({ ...args, page: pageParam }),
    {
      getNextPageParam,
      onSuccess: (raffles) =>
        updateRaffleData(raffles, (raffle) => [identifier, raffle.id]),
    },
  );

  const result = getEntries(data);
  const total = getTotal(data);

  return [result, loading, nextPage, !hasNextPage, total] as const;
};

export const prefetchRafflesOrContests = (
  args: Parameters<typeof useRafflesOrContests>[0],
) => async (queryClient: QueryClient) => {
  await queryClient.prefetchInfiniteQuery(
    [identifier, 'all', args],
    ({ pageParam }) => rafflesAPI.getAll({ page: pageParam }),
  );
};

export const useRafflePreview = (token: string) =>
  useQuery([identifier, token], () => rafflesAPI.getPreview(token), {
    enabled: !!token,
  });

export const useRaffle = (contestId: number | undefined) => {
  const key = [identifier, contestId];
  const merge = useMergeWithPreviousData(key);
  return useQuery(
    key,
    () => (contestId ? rafflesAPI.fetchSingle(contestId) : undefined),
    {
      select: merge,
      keepPreviousData: true,
    },
  );
};
export const prefetchRaffle = (contestId: number) => async (
  queryClient: QueryClient,
) => {
  await queryClient.prefetchQuery(
    [identifier, contestId],
    () => rafflesAPI.fetchSingle(contestId),
    {},
  );
};

export const useRaffles = (ids: number[] = []) =>
  useQuery([identifier, ids], () =>
    rafflesAPI.fetch(ids.filter(notUndefinedOrNull)),
  );

export const useRafflesMutation = () =>
  useMutation((ids: number[]) =>
    rafflesAPI.fetch(ids.filter(notUndefinedOrNull)),
  );

export const useRaffleContestants = (raffleId: number) => {
  const { isLoading: loading, data, fetchNextPage } = useInfiniteQuery<
    ResponseWrapperPaginated<UserSimple[]>
  >(
    [identifier, raffleId, 'contestants'],
    ({ pageParam }) => rafflesAPI.getContestants(raffleId, pageParam),
    { getNextPageParam },
  );

  const contestants = getEntries(data);
  const total = getTotal(data);

  return { fetchNextPage, data, loading, contestants, total };
};

export const useTracksForRaffle = (
  raffleId: number,
  filters: tracksAPI.Filter = {},
) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [identifier, raffleId, 'tracks', filters],
    () => rafflesAPI.fetchRaffleTracks(raffleId, filters),
    { getNextPageParam },
  );

  return {
    tracks: getEntries(data),
    loading: isLoading,
    nextPage: fetchNextPage,
    lastPageReached: hasNextPage,
    total: getTotal(data),
  };
};

export const useRaffleSubscribe = () => {
  const client = useQueryClient();
  const { refetch: refetchBalance } = useBalance();

  const { mutateAsync } = useMutation(
    (raffleId: number) => rafflesAPI.subscribe(raffleId),
    {
      onSettled: (_, __, raffleId) => {
        client.invalidateQueries([identifier, raffleId]);
        refetchBalance();
      },
    },
  );

  return mutateAsync;
};

export const useRafflesJoinedByUser = (
  userId: number | undefined,
  query: RaffleGetArgs = {},
) => {
  const { data, ...rest } = useInfiniteQuery(
    [identifier, userId, query],
    () =>
      userId !== undefined
        ? rafflesAPI.fetchJoinedByUser(userId, query)
        : defaultResponsePaginated([]),
    { getNextPageParam },
  );

  return {
    data: getEntries(data),
    ...rest,
  };
};

export const useRafflesByLabel = (
  labelId: number | undefined,
  query: RaffleGetArgs = {},
) => {
  const updateRaffleData = useUpdateEntriesInfiniteQuery();
  const nextQuery: RaffleGetArgs & { filter: RaffleFilter } = {
    filter: 'contest',
    ...query,
  };

  const { data, ...rest } = useInfiniteQuery(
    [identifier, 'for-label', labelId, query],
    () =>
      labelId !== undefined
        ? rafflesAPI.getForUser(labelId, nextQuery)
        : defaultResponsePaginated([]),
    {
      getNextPageParam,
      enabled: !!labelId,
      onSuccess: (res) =>
        updateRaffleData(res, (raffle) => [identifier, raffle.id]),
    },
  );

  return {
    data: getEntries(data),
    ...rest,
  };
};

export const useSubmitTrackToContest = (contestId: number) => {
  const { data: contest, refetch: refetchContest } = useRaffle(contestId);

  return useMutation(
    async (trackId: number) => {
      const track = await tracksAPI.getTrackById(trackId);
      if (
        contest?.type === 'open_demo_submission' ||
        contest?.type === 'remix_contest' ||
        (contest?.type === 'contest' && !contest.private)
      ) {
        await tracksAPI.updateTrack({
          id: trackId,
          contests: [...track.contests, contestId],
        });
      } else {
        throw new Error('Cannot add track to this contest');
      }
    },
    {
      useErrorBoundary: false,
      onSuccess: () => {
        refetchContest();
      },
    },
  );
};
