import React, { CSSProperties } from 'react';
import Image from 'next/image';

export const VuexyIcon = ({
  className,
  src,
  alt,
  width,
  height,
  style,
}: VuexyIconProps) => (
  <Image
    src={src}
    alt={alt}
    width={width}
    height={height}
    className={`object-fit-contain ${className}`}
    style={{
      ...style,
      maxWidth: '100%',
    }}
  />
);

interface VuexyIconProps {
  className?: string;
  src: string;
  alt: string;
  width: number;
  height: number;
  style?: CSSProperties;
}

export default VuexyIcon;
