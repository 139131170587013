import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
  position,
  PositionProps,
  background,
  BackgroundProps,
  shadow,
  ShadowProps,
  zIndex,
  ZIndexProps,
} from 'styled-system';

export type BoxProps = Omit<
  SpaceProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    TypographyProps &
    PositionProps &
    BorderProps &
    BackgroundProps &
    ShadowProps &
    ZIndexProps,
  'color'
>;

// NOTE: Omit here is temp fix for type inheritence issue styled-components
// @see https://github.com/microsoft/TypeScript/issues/34933
const Box = styled.div<BoxProps>(
  {
    boxSizing: 'border-box',
  },
  space,
  color,
  layout,
  flexbox,
  typography,
  position,
  border,
  background,
  shadow,
  zIndex,
);

export default Box;
