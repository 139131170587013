// elements colors;
export const mainBg = '#fff';
export const white = '#fff';
export const whiteGradient = '#efefef';
export const white2 = '#f6f6f6';
export const whiteOpaque = 'rgba(255, 255, 255, 0.98)';
export const brownGrey = '#a7a7a7';
export const brownGreyThree = '#a4a4a4';
export const brownGrey2 = '#b2b2b2';
export const brownGreyFour = '#838383';
export const brownGreyFive = '#9b9b9b';
export const brownGreySix = '#676767';
export const lightGray = '#999999';
export const greyishBrown = '#4a4a4a';
export const veryLightPink2 = '#d8d8d8';
export const silver = '#e6e6e6';
export const lightSilver = '#f9f9f9';
export const pink = '#f71454';
export const pinkGradient = '#BF0539';
export const pinkDark = '#e60f4c';
export const success = '#28c76f';
export const warning = '#ff9f43';

export const fog = '#f1f4f8';
export const confirmationGreen = '#00a65b';
export const greenGradientlLight = '#84da91';
export const greenGradientDark = '#4db358';
export const green = '#36af47';
export const emerald = '#00a65b';
export const appleGreen = '#7ed321';
export const transparentBlack = 'rgba(0,0,0,.5)';
export const pink0 = 'rgba(238, 137, 142, 0.3)';
export const tableGrey = '#ebebeb';
export const borderGrey = '#b9b9b9';
export const progressBarGrey = '#ababab';

// text colors
export const black = '#2e2e2e';
export const blackTwo = '#282828';
export const blackThree = '#181818';
export const blackOpaque = 'rgba(0, 0, 0, 0.6)';
export const blackOpaque20 = 'rgba(0, 0, 0, 0.2)';
export const dark = '#474747';
export const darkOpaque = 'rgba(71,71,71,0.6)';
export const grey1 = '#A4A4A4';
export const grey2 = '#7f7f7f';
export const grey3 = '#cacaca';
export const grey4 = '#696969';
export const pageTitleDividerGrey = '#eaeaea';
export const light = '#FFFFFF';
export const veryLightPink = '#e5e5e5';

// social icons colors
export const fbColor = '#3b5998';
export const dribColor = '#f396ba';
export const gplusColor = '#dd4b39';
export const twColor = '#55acee';
export const instaColor = '#125688';
export const waColor = '#2ae676';
export const spotifyColor = '#1db954';

// gradients
export const buttonGradient = `linear-gradient(to right, ${pink}, ${pinkGradient})`;
export const coverGradient =
  'linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%)';
export const labelGradient =
  'linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0.2) 100%)';
export const sliderGrad =
  'linear-gradient(80deg, rgba(247, 20, 83, 0.2) 0%, rgba(193, 5, 58, 0.2) 100%)';
export const sliderPendingGrad =
  'linear-gradient(80deg, rgba(247, 20, 83, 0.4) 0%, rgba(193, 5, 58, 0.4) 100%)';
export const proMembershipGradient =
  'transparent linear-gradient(113deg, #FD004C 0%, #2B85FF 100%) 0% 0% no-repeat padding-box';
export const distributionGradient =
  'linear-gradient(315.97deg, #1159F1 1.64%, #F41657 100%)';
export const distributionGradient2 =
  'linear-gradient(168.59deg, #FD004C -76.44%, #2B85FF 317.3%)';

// Box shadows
export const listBoxShadow = '0px 5px 9px -1px rgba(0,0,0,0.5)';
export const smallBoxShadow = '0px 5px 9px 0px rgba(0,0,0,0.5)';
export const playBtnBoxShadow = '0 15px 28px 5px rgba(44, 44, 44, 0.43)';
export const popupBoxShadow = '0 8px 19px 2px rgba(0, 0, 0, 0.2)';
export const playlistShadow = '0 8px 14px 3px rgba(44, 44, 44, 0.15)';
export const contentBoxShadow = '7.5px 12.99px 32px rgba(44,44,44,0.26)';
export const tableBoxShadow = '0 2px 10px rgba(0, 0, 0, 0.5)';
export const lightBoxShadow =
  '0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08)';
export const btnBoxShadow = '0px 5px 9px 0px rgba(0,0,0,0.3)';
// Text shadows

export const titleShadow = '0 6px 13px rgba(44, 44, 44, 0.2)';

// Transitions

export const transitionTime = '0.2s';

/**
 * ALL THE NEW COLORS USED IN DESIGN 2.0 BY KRUNAL
 */

export const black_new = '#1F1F1F';
export const secondary_white_new = '#FFFFFF';

export const grey_main_new = '#b7b7b7';
export const grey_main_opaque = (alpha: number) =>
  `rgba(183,183,183, ${alpha})`;
export const grey_light_1_new = '#F5F5F5';
export const grey_light_2_new = '#E5E5E5';
export const grey_dark_new = '#767676';

export const primary_pink_new = '#F71454';
export const primary_pink_new_transparent = '#FCADC3';
export const primary_pink_alpha_new = (alpha: number) =>
  `#rgba(247, 20, 84, ${alpha})`;
export const primary_pink_transparent = '#rgba(247, 20, 84, 0)';

export const success_new = '#32BA00';
export const success_light_new = '#E5F6DE';
export const error_new = '#FC5435';
export const warning_new = '#FFBC05';

// Social media colors
export const spotify_new = '#1db954';
export const facebook_new = '#4267B2';
export const twitter_new = '#1da1f2';
export const instagram_new = '#e4717a';
export const soundcloud_new = '#ff7700';
export const youtube_new = '#FF0000';
export const snapchat_new = '#FFFC00';
export const bandcamp_new = '#629AA9';
export const apple_music_new = '#f94c57';
export const amazon_new = '#FF9900';

// Box shadows
export const info_new = '#2280ff';

export const box_shadow_buttons = '1px 2px 3px #00000029';
export const box_shadow_card = '0px -6px 20px #00000089';

// Genre colors
export const techno_title = '#4F566E';
export const techno_bg = '#D6D6D6';
export const future_bass_title = '#5ED086';
export const future_bass_bg = '#CEFFDF';
export const deep_house_title = '#FF9E1D';
export const deep_house_bg = '#FBE7CB';
export const tech_house_title = '#AAA404';
export const tech_house_bg = '#F5F5F5';
export const hardcore_title = '#626EFF';
export const hardcore_bg = '#D6D6FF';
export const drum_n_bass_title = '#767676';
export const drum_n_bass_bg = '#EDEDED';
export const trance_title = '#008EB9';
export const trance_bg = '#A5F1FF';
export const hardstyle_title = '#212989';
export const hardstyle_bg = '#D5D6E9';
export const urban_title = '#FF0000';
export const urban_bg = '#FFBEC3';
export const latin_title = '#CBBA00';
export const latin_bg = '#FFFAC4';
export const bass_house_title = '#FF9E1D';
export const bass_house_bg = '#FBF3ED';
export const electro_house_title = '#FD6292';
export const electro_house_bg = '#FFDFE9';
export const afro_house_title = '#058623';
export const afro_house_bg = '#E1F0E3';
export const big_room_title = '#7DAC00';
export const big_room_bg = '#E5EED2';
export const dance_title = '#66008B';
export const dance_bg = '#E6D7EC';
export const dub_step_title = '#B50000';
export const dub_step_bg = '#F0CDCF';
export const house_title = '#DB9A31';
export const house_bg = '#F8ECD8';
export const pro_house_title = '#A55092';
export const pro_house_bg = '#EFD6FF';
export const trap_title = '#00AAC9';
export const trap_bg = '#D1EEF4';
export const moombahton_title = '#FF4E00';
export const moombahton_bg = '#FED2C6';
export const pop_title = '#FF00C4';
export const pop_bg = '#FFD2F3';
export const hiphop_title = '#530000';
export const hiphop_bg = '#DCCCCC';
export const rnb_title = '#19FF94';
export const rnb_bg = '#EEFFF7';
export const reggae_title = '#7B492F';
export const reggae_bg = '#E4DAD5';
export const psytrance_title = '#48AA00';
export const psytrance_bg = '#DEF0D6';
export const electronica_title = '#AA006F';
export const electronica_bg = '#F1D8E8';
export const disco_title = '#DC8EFF';
export const disco_bg = '#FAEFFF';
export const minimal_title = '#B7B7B7';
export const minimal_bg = '#F4F4F4';

// Label widget colors
export const label_widget_dark_bg = 'rgba(0, 0, 0, 0.90)';
export const label_widget_light_bg = 'rgba(255, 255, 255, 0.90)';

export const genreIdColorTable: Record<
  number,
  { primary: string; bg: string } | undefined
> = {
  1: {
    primary: techno_title, // Techno
    bg: techno_bg,
  },
  2: {
    primary: electro_house_title, // Electro House
    bg: electro_house_bg,
  },
  3: {
    primary: afro_house_title, // Afro House
    bg: afro_house_bg,
  },
  4: {
    primary: big_room_title, // Big Room
    bg: big_room_bg,
  },
  5: {
    primary: dance_title, //  Dance
    bg: dance_bg,
  },
  6: {
    primary: deep_house_title, // Deep House
    bg: deep_house_bg,
  },
  7: {
    primary: drum_n_bass_title, // Drum & Bass
    bg: drum_n_bass_bg,
  },
  8: {
    primary: dub_step_title, // Dubstep
    bg: dub_step_bg,
  },
  11: {
    primary: future_bass_title, // Future House
    bg: future_bass_bg,
  },
  12: {
    primary: hardstyle_title, // Hardstyle
    bg: hardstyle_bg,
  },
  13: {
    primary: hardcore_title, // Hardcore
    bg: hardcore_bg,
  },
  14: {
    primary: house_title, // House
    bg: house_bg,
  },
  15: {
    primary: pro_house_title, // Progressive House
    bg: pro_house_bg,
  },
  16: {
    primary: tech_house_title, // Tech House
    bg: tech_house_bg,
  },
  19: {
    primary: trance_title, // Trance
    bg: trance_bg,
  },
  20: {
    primary: trap_title, // Trap
    bg: trap_bg,
  },
  21: {
    primary: moombahton_title, // Moombahton
    bg: moombahton_bg,
  },
  25: {
    primary: pop_title, // Pop
    bg: pop_bg,
  },
  26: {
    primary: hiphop_title, // Hip Hop
    bg: hiphop_bg,
  },
  27: {
    primary: latin_title, // Latin
    bg: latin_bg,
  },
  28: {
    primary: rnb_title, // R&B
    bg: rnb_bg,
  },
  29: {
    primary: reggae_title, // Reggae
    bg: reggae_bg,
  },
  30: {
    primary: urban_title, // Urban
    bg: urban_bg,
  },
  32: {
    primary: psytrance_title, // Psytrance
    bg: psytrance_bg,
  },
  33: {
    primary: bass_house_title, // Bass House
    bg: bass_house_bg,
  },
  34: {
    primary: electronica_title, // Electronica
    bg: electronica_bg,
  },
  35: {
    primary: disco_title, // Disco
    bg: disco_bg,
  },
  36: {
    primary: minimal_title, // Minimal
    bg: minimal_bg,
  },
  37: {
    primary: future_bass_title, // Future Bass
    bg: future_bass_bg,
  },
};

// Selection colors
export const virpp_selection_bg = '#FFE7ED';
export const virpp_editors_picks_bg = '#FFF79B';
export const virpp_new_selections_bg = '#9ED3C7';
export const virpp_weekly_selections_bg = '#9ED3C7';

export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgba(${r}, ${g}, ${b})`;
};

export const lighterGreen = '#D1DFCA';
export const lightGreen = '#67C639';
export const red = '#E43E1B';
export const gray = '#F2F2F2';
