module.exports = {
  locales: ['en', 'de', 'fr', 'es'],
  defaultLocale: 'en',
  loader: false,
  loadLocaleFrom: async (locale, namespace) =>
    import(`./locales/${locale}/${namespace}`).then((r) => r.default),
  pages: {
    '*': [
      'header',
      'notifications',
      'howItWorks',
      'footer',
      'language',
      'search',
      'organisms',
      'common',
      'modals',
      'myTracks',
      'chat',
      'upload',
      'profile',
      'register',
      'login',
      'pricing',
    ],
    '/': ['home', 'organisms'],
    '/music-distribution': ['distributionLanding'],
    '/distribution/ad-landing': ['distributionLanding'],
    '/explore': ['explore', 'pricing', 'tracks', 'modals', 'awards'],
    '/connect': ['contests', 'common'],
    '/contests': ['contests'],
    '/contests/[raffleId]': ['contests', 'modals'],
    '/contests/joined/[raffleId]': ['contests'],
    '/contests/preview/[previewId]': ['contests'],
    '/raffles/joined/[raffleId]': ['contests'],
    '/raffles/preview/[previewId]': ['contests'],
    '/raffles/[raffleId]': ['contests'],
    '/raffles': ['contests'],
    '/become-pro': ['pricing', 'modals'],
    '/my-pro': ['pricing', 'modals'],
    '/register': ['register', 'pricing'],
    '/login': ['register', 'pricing'],
    '/profile/[profileId]': [
      'profile',
      'modals',
      'awards',
      'tracks',
      'contests',
    ],
    '/profile/edit': [
      'profile',
      'modals',
      'awards',
      'tracks',
      'contests',
      'settings',
    ],
    '/profile': ['profile', 'modals', 'awards', 'tracks', 'contests'],
    '/releases': ['releases'],
    '/releases/[releaseId]': ['releases'],
    '/how-it-works': ['howItWorks'],
    '/how-it-works/how-to-use': ['howItWorks'],
    '/how-it-works/how-to-upload': ['howItWorks'],
    '/how-it-works/get-feedback': ['howItWorks'],
    '/how-it-works/join-contests': ['howItWorks'],
    '/how-it-works/releasing-music': ['howItWorks'],
    '/how-it-works/statistics': ['howItWorks'],
    '/how-it-works/what-are-vcoins': ['howItWorks'],
    '/my-tracks': ['myTracks', 'upload', 'artist'],
    '/edit-track/[trackId]': ['editTrack'],
    '/edit-track-preview/[trackId]': ['edit-track-preview'],
    '/chat': ['chat', 'upload'],
    '/chat/[conversationId]': ['chat', 'upload'],
    '/notifications': ['notifications'],
    '/my-wallet': ['wallet', 'stats', 'pricing'],
    '/statistics': ['stats', 'pricing'],
    '/settings': ['settings', 'profile', 'pricing'],
    '/ai-music-chat': ['aiMusicChat', 'stats'],
    '/ai-music-chat/[aiExpert]': ['aiMusicChat', 'stats'],
    '/distribution/completed': ['artist'],
    '/distribution': ['artist', 'pricing'],
    '/distribution/welcome': ['artist', 'distributionLanding', 'pricing'],
    '/genre/[genreId]': ['genre'],
    '/music-knowledge-hub': ['musicKnowledgeHub'],
    '/music-knowledge-hub/[slug]': ['musicKnowledgeHub', 'tracks'],
    '/music-knowledge-hub/community/[slug]': ['musicKnowledgeHub'],
    '/password/reset/[tokenId]': ['profile'],
    '/upload-landing': ['upload'],
    '/reset-password': ['profile'],
    '/register/email/initial': ['register', 'pricing'],
    '/upload': ['upload'],
    '/settings/delete': ['settings'],
    '/settings/deactivate': ['settings'],
    '/track-release/[trackReleaseId]': ['releases'],
    '/track-releases/[trackId]': ['releases'],
    '/track-report/[trackId]': ['tracks'],
    '/tracks/[trackId]': ['tracks', 'profile'],
    '/verify-email/[token]': ['profile'],
    '/widgets/label-upload/[apikey]/button': ['widget'],
    '/widgets/label-upload/[apikey]/contest-or-demo': ['widget'],
    '/widgets/label-upload/[apikey]/edit': ['widget'],
    '/widgets/label-upload/[apikey]': ['widget'],
    '/widgets/label-upload/[apikey]/select-track': ['widget'],
    '/widgets/label-upload/[apikey]/submit': ['widget'],
    '/widgets/label-upload/[apikey]/success': ['widget'],
    '/widgets/label-upload/[apikey]/test': ['widget'],
    '/widgets/label-upload/[apikey]/upload': ['widget'],
    '/widgets/label-upload': ['widget'],
    '/widgets/register-email': ['widget'],
    '/widgets/register': ['widget'],
    '/widgets/reset-password': ['widget'],
    '/widgets/wrong-redirect': ['widget'],
    '/widgets/login': ['widget'],
    '/_error': ['chat'],
    '/awards': ['awards'],
    '/buy-coins': ['pricing', 'wallet'],
    '/home-v2': ['home'],
    '/payment-error': ['pricing'],
    '/payment-success': ['pricing'],
    '/payment': ['pricing'],
    '/pro-membership': ['pricing'],
    '/pro-payment': ['pricing'],
    '/reactivate': ['profile'],
    '/success-stories': ['successStories'],
    '/thank-you-for-uploading': ['thankYouForUploading', 'common'],
    '/amsterdam-dance-event-demo-drops-2024': ['contests', 'home'],
    '/playlist/[playlistId]': ['playlist', 'modals'],
    '/charts': ['tracks'],
    '/labels': ['common'],
    '/search': ['search'],
    '/thank-you': ['common'],
    '/artist-feedback': ['artist-feedback'],
  },
};
