import { useQuery } from 'react-query';

import * as genreAPI from '../../lib/api/genres';

export const genresIdentifier = 'genres';
const useFetchGenres = () =>
  useQuery(genresIdentifier, genreAPI.fetch, { staleTime: Infinity });

export const useGenres = ({
  withActiveTracksOnly,
}: { withActiveTracksOnly?: boolean } = {}) => {
  const { data = [] } = useFetchGenres();

  if (withActiveTracksOnly) {
    return data?.filter((g) => g.has_active_tracks);
  }

  return data;
};

export const useGenre = (genreId?: number) => {
  const { data } = useFetchGenres();

  return (data || []).find((g) => g.id === genreId);
};
