import { Dispatch } from '../../index';
import { RootState } from '../reducers';
import * as notificationAPI from '../../lib/api/notifications';
import * as notificationActions from '../actions/notifications';
import { ThunkResult } from './ThunkDispatch';
import {
  trackActions,
  usersActions as usersDispatch,
  chatActions,
} from '../index';
import { logError } from '../../utils/logging';
import {
  zeroifyLabelNotificationsCounter,
  zeroifyNotificationsCounter,
} from '../actions/pusher';
import { handleError } from './alerts';

// Collect the ids of all tracks, raffles and users necessary
// to display the notifications. Get these objects from backend and put into redux
// return unhandled notifications ids
export const fetchNotificationsData = (
  notifications: notificationAPI.Notification[],
  token?: string,
): ThunkResult<Promise<string[]>> => async (dispatch) => {
  const unknownIds: string[] = [];

  if (notifications.length > 0) {
    const tracks: number[] = [];
    const users: number[] = [];
    const conversations: number[] = [];
    const raffles: number[] = [];
    const trackPulls: { pullId: number; noteId: string }[] = [];

    notifications.forEach(({ data, id, read_at }) => {
      switch (data.type) {
        case 'VcoinsTransaction':
        case 'UserAwardEarned':
        case 'General': {
          break;
        }
        case 'AwardEarned': {
          tracks.push(data.award.track_id);
          break;
        }
        case 'ConversationMessageReceived': {
          const { recipient_id, conversation_id } = data;
          users.push(recipient_id);
          conversations.push(conversation_id);
          break;
        }
        case 'LikedTrackGetPulled': {
          tracks.push(data.track_id);
          break;
        }
        case 'ListenerInvitationAccepted': {
          users.push(data.listener_id);
          break;
        }

        case 'ListenerInvitationDenied': {
          users.push(data.listener_id);
          break;
        }
        case 'TrackApproved': {
          tracks.push(data.track_id);
          break;
        }
        case 'TrackCollaborationRequest': {
          tracks.push(data.track_id);
          users.push(data.user_id);
          break;
        }
        case 'TrackCollaborationApproved': {
          tracks.push(data.track_id);
          users.push(data.collaborator_id);
          break;
        }
        case 'TrackCollaborationDeclined': {
          tracks.push(data.track_id);
          users.push(data.collaborator_id);
          break;
        }
        case 'TrackCommented': {
          tracks.push(data.track_id);
          users.push(data.commentator_id);
          break;
        }
        case 'TrackCommentReplied': {
          tracks.push(data.track_id);
          users.push(data.commentator_id);
          break;
        }
        case 'TrackFeedbackRequested': {
          tracks.push(data.track_id);
          users.push(data.user_id);
          break;
        }
        case 'ContestEntryPublic':
        case 'TrackInCharts': {
          tracks.push(data.track_id);
          break;
        }
        case 'TrackLiked': {
          tracks.push(data.track_id);
          users.push(data.user_id);
          break;
        }
        case 'TrackPullRequestAccepted': {
          tracks.push(data.track_id);
          users.push(data.producer_id);
          break;
        }
        case 'TrackPullRequestDenied': {
          tracks.push(data.track_id);
          users.push(data.producer_id);
          break;
        }
        case 'UserFollowed': {
          users.push(data.follower_id);
          break;
        }
        case 'WonRaffle': {
          conversations.push(data.conversation_id);
          raffles.push(data.raffle_id);
          break;
        }
        case 'RaffleEnded': {
          raffles.push(data.raffle_id);
          break;
        }
        case 'TrackPlayedByLabel': {
          tracks.push(data.track_id);
          users.push(data.label_id);
          break;
        }
        case 'TrackPullRequestMade': {
          tracks.push(data.track_id);
          users.push(data.label_id);
          trackPulls.push({ pullId: data.pull_id, noteId: id });
          break;
        }
        case 'ListenerInvited': {
          users.push(data.label_id);
          break;
        }
        case 'UserJoinedRemixContest':
        case 'UserJoinedContest': {
          raffles.push(data.raffle_id);
          break;
        }
        case 'ReceivedTrackFeedback': {
          tracks.push(data.track_id);
          users.push(data.commentator_id);
          break;
        }
        case 'RemixContestEnded': {
          raffles.push(data.raffle_id);
          break;
        }

        case 'WonContest':
        case 'WonRemixContest':
        case 'ContestEnded': {
          raffles.push(data.raffle_id);
          break;
        }
        case 'TrackSelectedForRelease':
        case 'TrackReleaseReleased': {
          if (!read_at) {
            dispatch(
              notificationActions.setTrackForRelease({ id: data.track_id }),
            );
          }
          break;
        }
        case 'VcoinsOrder':
        case 'TrackPlayedSecondTimeByLabel':
        case 'TrackLikedByLabel':
        case 'InvoicePaymentFailed':
        case 'PendingPullRequest':
        case 'PaymentConfirmation':
        case 'UserAddedToTrackRelease':
        case 'NewUserAddedToTrackRelease':
        case 'TrackReleaseRequestForChanges': {
          break;
        }
        default: {
          const impossibleNotification: never = data;

          console.warn('Unhandled notification:');
          console.info(impossibleNotification);

          unknownIds.push(id);
          // TODO: report to sentry
        }
      }
    });

    // Fetch all necessary resources
    await Promise.all([
      dispatch(trackActions.fetch(tracks, token)),
      dispatch(usersDispatch.fetch(users)),
      dispatch(chatActions.fetch(conversations)),
    ]);
  }

  return unknownIds;
};

interface Options {
  page?: number;
  size?: number;
  filter?: 'labels' | 'activity';
}

export const fetch = (
  { page = 1, size = 12, filter }: Options,
  token?: string,
): ThunkResult => async (dispatch, getState) => {
  const {
    user: { id },
  } = getState();

  if (id === undefined) return;

  dispatch(notificationActions.requestNotifications());

  try {
    const {
      notifications: beNotifications,
      pagination,
    } = await notificationAPI.fetch(id, page, size, filter);

    // get notifications data, filter out unknown notifications
    const unknown = await dispatch(
      fetchNotificationsData(beNotifications, token),
    );
    const notifications = beNotifications.filter(
      (n) => !unknown.includes(n.id),
    );

    // Add the notifications to the redux store
    dispatch(notificationActions.addNotificationsEntries(notifications));

    if (filter === 'labels') {
      dispatch(
        notificationActions.receiveLabelNotifications({
          notifications,
          pagination,
        }),
      );
    } else if (filter === 'activity') {
      dispatch(
        notificationActions.receiveActivityNotifications({
          notifications,
          pagination,
        }),
      );
    } else {
      dispatch(
        notificationActions.receiveNotifications({ notifications, pagination }),
      );
    }
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const fetchAllTypes = (token?: string): ThunkResult => async (
  dispatch,
) => {
  const all = dispatch(fetch({}, token));
  const label = dispatch(fetch({ filter: 'labels' }, token));
  const activity = dispatch(fetch({ filter: 'activity' }, token));

  await Promise.all([all, label, activity]);
};

export const getUnreadNotificationsNumber = () => async (
  dispatch: Dispatch,
) => {
  dispatch(notificationActions.requestUnreadNotificationsNumber());
  try {
    const n = await notificationAPI.getUnreadNotificationsNumber();
    dispatch(notificationActions.recieveUnreadNotificationsNumber(n));
  } catch (error: any) {
    logError(error.message);
  }
};

// TODO move to user.ts
export const uploadPushDeviceToken = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { isLoggedIn } = getState().authentication;
  const {
    pushNotificationsDeviceToken: token,
    pushNotificationsPlatformIsIOS: isIOS,
  } = getState().notifications;

  if (!token || isIOS === undefined || !isLoggedIn) return;

  dispatch(notificationActions.uploadPushDeviceToken());

  try {
    await notificationAPI.uploadPushDeviceToken(token, isIOS);
  } catch (error: any) {
    dispatch(notificationActions.uploadPushDeviceTokenError(error.message));
  }
};

export const setPushDeviceToken = (token: string, isIOS: boolean) => (
  dispatch: Dispatch,
) => {
  dispatch(notificationActions.setPushDeviceToken({ token, isIOS }));

  dispatch(uploadPushDeviceToken());
};

export const readNotifications = () => (dispatch: Dispatch) => {
  dispatch(zeroifyNotificationsCounter());
};

export const readLabelNotifications = () => (dispatch: Dispatch) => {
  dispatch(zeroifyLabelNotificationsCounter());
};
