import { Dispatch } from 'redux';
import shuffleArray from 'lodash/shuffle';
import player from '../reducers/player';
import { getWaveformData } from '../../lib/api/tracks';
import { ThunkResult } from './ThunkDispatch';
import { API } from '../../index';
import { handleError } from './alerts';
// import { vcoinActions } from '../reducers/vcoins';

const { actions: playerActions } = player;

export interface PlaylistData {
  playlistType: PlaylistType;
  playlistIds: number[];
}

export type PlaylistType =
  | 'swiper'
  | 'chart'
  | 'search'
  | 'related'
  | 'userTracks'
  | 'userPlaylist'
  | 'upload'
  | 'contest'
  | 'unknown';

export const fetchTrackWaveform = (
  trackId: number,
  isFullData = false,
  forcedUrl?: string,
): ThunkResult => async (dispatch, getState) => {
  const track = getState().tracks.entries[trackId];
  const fetching = getState().player.fetchingWaveforms[trackId];
  const waveformUrl = isFullData ? track?.waveform_upload : track?.waveform;

  if (fetching || (waveformUrl === undefined && !forcedUrl)) return;

  dispatch(playerActions.requestWaveform(trackId));
  try {
    const waveform = await getWaveformData(forcedUrl || waveformUrl);
    if (isFullData) {
      dispatch(playerActions.receiveFullWaveform({ waveform, trackId }));
    } else {
      dispatch(playerActions.receiveWaveform({ waveform, trackId }));
    }
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.warn('Waveform for', trackId, 'not found');
      dispatch(playerActions.receiveWaveform({ waveform: [], trackId }));
    } else {
      dispatch(handleError(error));
      dispatch(playerActions.stopFetchingWaveform(trackId));
    }
  }
};

export const addTracksToPlaylistById = ({
  ids,
  resetList,
  playlistType,
  startPlayerOnLoad = true,
  shuffle = false,
}: {
  ids: number[];
  resetList: boolean;
  playlistType?: PlaylistType;
  startPlayerOnLoad?: boolean;
  shuffle?: boolean;
}) => (dispatch: Dispatch) => {
  dispatch(
    playerActions.addTracksToPlaylistById({
      ids: shuffle ? shuffleArray(ids) : ids,
      resetList,
      playlistType,
    }),
  );

  if (startPlayerOnLoad) {
    dispatch(playerActions.startPlayer());
  } else {
    dispatch(playerActions.showPlayer());
  }
};

export const addNext = (id: number) => (dispatch: Dispatch) => {
  dispatch(playerActions.addNext(id));
};

export const reportPlay = (
  trackId: number,
  positionSeconds: number,
): ThunkResult => async (dispatch) => {
  try {
    const result = await API.track.reportTrackListen(
      trackId,
      Math.round(positionSeconds),
    );

    if (result.id !== undefined) {
      await API.track.updateTrackListen(result.id, result.time_played);

      // ! Refetch vcoin balance on reporting play on mobile @Hleb
      // if (updateResult.vcoin_balance !== undefined) {
      //   dispatch(vcoinActions.receiveBalance(updateResult.vcoin_balance));
      // }
    }
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const {
  skipCurrentTrack,
  replayTrack,
  playPauseTrack,
  startPlayer,
  setPosition,
  allowSetPosition,
  play,
  pause,
  loading,
  ready,
  showPlayer,
  toggleEditMode,
  playTrackById,
  setCurrentTrack,
  playPrev,
  setUserplaylistId,
  setTrackId,
  setCurrentTrackIndex,
} = playerActions;
