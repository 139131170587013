import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState,
} from 'react';

import round from 'lodash/round';
import useAudio from 'react-use/lib/useAudio';
import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';
// import usePrevious from 'react-use/lib/usePrevious';
// import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import {
  HTMLMediaProps,
  HTMLMediaState,
  HTMLMediaControls,
} from 'react-use/lib/util/createHTMLMediaHook';
import { playerActions, usePlayer, Track, useDispatch } from '../../api';

import DevControls from './DevControls';

interface Props {
  player: PlayerInstance;
  children: React.ReactNode;
}

interface HTMLMediaControlsExtended extends HTMLMediaControls {
  replay: () => void;
  toggle: () => void;
}

interface HTMLMediaStateExtended extends HTMLMediaState {
  loading: boolean;
}

export interface PlayerInstance {
  audio: ReactElement<HTMLMediaProps>;
  state: HTMLMediaStateExtended;
  controls: HTMLMediaControlsExtended;
  ref: { current: HTMLAudioElement | null };
}

interface HTMLMediaControlsSharedExtended extends HTMLMediaControlsExtended {
  skip: () => void;
}

export interface SharedPlayerInstance extends PlayerInstance {
  controls: HTMLMediaControlsSharedExtended;
}

interface PlayerManagerContextValue {
  pause: () => void;
  registerPlayer: (instance: PlayerInstance) => void;
  unregisterPlayer: (instance: PlayerInstance) => void;
  pauseOtherPlayers: (ref: PlayerInstance['ref']) => void;
}

const PlayerManagerContext = React.createContext<PlayerManagerContextValue>({
  pause: () => null,
  registerPlayer: () => null,
  unregisterPlayer: () => null,
  pauseOtherPlayers: () => null,
});

const usePlayerManager = () => useContext(PlayerManagerContext);

export const PlayerManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const players = useRef<PlayerInstance[]>([]);

  const registerPlayer = useCallback((instance: PlayerInstance) => {
    players.current.push(instance);
  }, []);

  const unregisterPlayer = useCallback((instance: PlayerInstance) => {
    players.current = players.current.filter(
      (player) => player.ref.current !== instance?.ref.current,
    );
  }, []);

  const pause = useCallback(() => {
    players.current.forEach((player) => player.controls.pause());
  }, []);

  const pauseOtherPlayers = useCallback((instance: PlayerInstance['ref']) => {
    players.current.forEach((player) => {
      if (player.ref.current !== instance?.current) {
        player.controls.pause();
      }
    });
  }, []);

  return (
    <PlayerManagerContext.Provider
      value={{
        pause,
        pauseOtherPlayers,
        registerPlayer,
        unregisterPlayer,
      }}
    >
      <div id="__player-manager" />
      {children}
    </PlayerManagerContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const PlayerContext = React.createContext<PlayerInstance>({});

export const usePlayerContext = () =>
  useContext(PlayerContext) as PlayerInstance | undefined;

const useContextPlayerState = (
  context: React.Context<PlayerInstance | SharedPlayerInstance>,
) => {
  const instance = useContext(context);

  return instance?.state;
};

const useContextPlayerControls = (
  context: React.Context<PlayerInstance | SharedPlayerInstance>,
) => {
  const instance = useContext(context);

  return instance?.controls;
};

/**
 * Helper hook for managing progress of the player song ranging
 * from 0-1
 */
const useContextPlayerPosition = (
  context: React.Context<PlayerInstance | SharedPlayerInstance>,
) => {
  const instance = useContext(context);

  /**
   * Progress from 0-1 rounded up on 5 decimals.
   * Number of decimals can be higher, but will have more frequent updates.
   * 5 seams like a sweet spot
   */
  const position = useMemo(() => {
    if (!instance?.state) {
      return 0;
    }

    const {
      state: { time, duration },
    } = instance;

    return round(time / duration, 5) || 0;
  }, [instance]);

  /**
   * Expects a number from 0-1 which will then be converted to
   * actual seconds elapsed based on percentage to forward
   * to seek function
   */
  const setPosition = useCallback(
    (value: number) => {
      if (!instance?.controls) {
        return;
      }

      instance.controls.seek(Math.round(value * instance.state.duration));
    },
    [instance],
  );

  return useMemo(
    () => ({
      position,
      setPosition,
    }),
    [position, setPosition],
  );
};

export const usePlayerState = () => useContextPlayerState(PlayerContext);
export const usePlayerControls = (): HTMLMediaControlsExtended =>
  useContextPlayerControls(PlayerContext);
export const usePlayerPosition = () => useContextPlayerPosition(PlayerContext);

/**
 *
 * Main hook used to create an audio player
 */
export const useAudioPlayer = (props: HTMLMediaProps) => {
  const manager = usePlayerManager();

  const [loading, setLoading] = useState(true);

  const { onPlay, onLoadedData, onLoadStart, src, autoPlay, ...rest } = props;

  const [audio, state, controls, ref] = useAudio({
    ...rest,
    src,
    autoPlay,
    onPlay: (e) => {
      /**
       * Ensure we pause all other players except this one
       */
      manager.pauseOtherPlayers(ref);

      onPlay?.(e);
    },
    onLoadStart: (e) => {
      controls?.seek(0);

      setLoading(true);

      onLoadStart?.(e);
    },
    onLoadedData: (e) => {
      setLoading(false);

      onLoadedData?.(e);
    },
  });

  /**
   *  Extend controls and add additional required features
   */
  const extendedControls = useMemo(
    () => ({
      ...controls,
      toggle: () => (state.paused ? controls.play() : controls.pause()),
      replay: () => {
        controls.seek(0);
        controls.play();
      },
    }),
    [controls, state.paused],
  );

  const extendedState = useMemo(
    () => ({
      ...state,
      loading,
    }),
    [state, loading],
  );

  /**
   * Make a more usable instance as an object with the extended control options
   */
  const instance = useMemo(
    () => ({ audio, state: extendedState, controls: extendedControls, ref }),
    [audio, extendedControls, extendedState, ref],
  );

  /**
   * Enure player auto-starts if possible
   * if it was initially blank
   */
  // useEffect(() => {
  //   if (autoPlay && loading && extendedState.paused) {
  //     controls?.play();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [autoPlay, extendedState.paused, loading]);

  /**
   * Ensure player instance get registered in manager
   */
  useEffect(() => {
    manager.registerPlayer(instance);

    return () => manager.unregisterPlayer(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Ensure resources get loaded for safari after mount
   */
  useMount(() => {
    ref.current?.load();
  });

  useUnmount(() => {
    ref.current?.pause();
  });

  return instance;
};

export const PlayerProvider: React.FC<Props> = ({ children, player }) => (
  <PlayerContext.Provider value={player}>
    {children}
    {player?.audio}
    {process.env.NODE_ENV !== 'production' && <DevControls player={player} />}
  </PlayerContext.Provider>
);

/**
 *
 * For cases when you just want to pass the track to the local player
 * without worrying about player controls.
 */
export const TrackPlayerProvider: React.FC<{
  track: Track;
  children: React.ReactNode;
}> = ({ children, track }) => {
  const player = useAudioPlayer({
    src: track.track_url ?? '',
  });

  return <PlayerProvider player={player}>{children}</PlayerProvider>;
};

const SharedPlayerContext = React.createContext<
  SharedPlayerInstance | PlayerInstance
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
>({});

export const useSharedPlayerContext = () => useContext(SharedPlayerContext);

export const useSharedPlayerState = () =>
  useContextPlayerState(SharedPlayerContext);

export const useSharedPlayerControls = () =>
  useContextPlayerControls(
    SharedPlayerContext,
  ) as HTMLMediaControlsSharedExtended;

export const useSharedPlayerPosition = () =>
  useContextPlayerPosition(SharedPlayerContext);

export const SharedPlayerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const { track, pause, play, isPlaying, skipCurrentTrack } = usePlayer();

  // const prevTrack = usePreviousDistinct(track);

  // const played = useRef(false);
  const skipped = useRef(false);

  /**
   * When song ends ensure next one will be played
   * and synced with redux store
   */
  const onEnded = () => {
    skipCurrentTrack();
  };

  /**
   *
   * Ensure we sync with redux store for backwards computability
   */
  const onPause = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    // ! Important to prevent it
    e.preventDefault();

    pause();
  };

  /**
   *
   * Ensure we sync with redux store for backwards computability
   */
  const onPlay = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    // ! Important to prevent it
    e.preventDefault();

    // played.current = true;

    play();
  };

  const onLoadStart = useCallback(() => {
    // played.current = false;
  }, []);

  /**
   * Reset the skipped flag whenever a new track loads.
   * This step is very important to ensure proper logging
   * of events
   */
  const onLoadedData = useCallback(() => {
    skipped.current = false;
  }, []);

  const player = useAudioPlayer({
    src: track?.track_url ?? '',
    onEnded,
    onPause,
    onPlay,
    onLoadStart,
    onLoadedData,
    autoPlay: isPlaying, // Ensure song continues playing if its set from redux store
  });

  const reportPlay = useCallback(() => {
    /**
     * Do not send played event for
     * skipped tracks
     */
    if (skipped.current) {
      return;
    }

    if (!(track?.id && player?.state.time)) {
      return;
    }

    dispatch(playerActions.reportPlay(track.id, player.state.time));
  }, [dispatch, player, track]);

  /**
   * Report track player on track change
   */
  useEffect(() => {
    reportPlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);

  /**
   * Report track played when page gets unloaded
   */
  useEffect(() => {
    window.addEventListener('beforeunload', reportPlay);

    return () => {
      window.removeEventListener('beforeunload', reportPlay);
    };
  }, [reportPlay]);

  /**
   * TODO: Check if we should also implement onSkip method in Swiper not to record plays.
   */
  const onSkip = useCallback(() => {
    skipped.current = true;

    skipCurrentTrack();
  }, [skipCurrentTrack]);

  /**
   * Ensure player is in sync with redux store
   * if state change occurs from somewhere else
   * than player controls
   *
   */
  useEffect(() => {
    if (isPlaying) {
      player?.controls?.play();
    } else {
      player?.controls?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const playerExtended = useMemo(() => {
    const { controls, ...restOfPlayer } = player;

    return {
      ...restOfPlayer,
      controls: {
        ...controls,
        skip: onSkip,
      },
    };
  }, [player, onSkip]);

  return (
    <SharedPlayerContext.Provider value={playerExtended}>
      <PlayerProvider player={player}>{children}</PlayerProvider>
    </SharedPlayerContext.Provider>
  );
};
