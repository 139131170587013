import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { UserMinus, UserPlus } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { useFollow, useAuthentication } from '../../../../api';
import AuthenticationPopupDialog from '../../../Modals/AuthenticationPopupDialog';
import Button from '../../../../VuexyDesign/atoms/Button';

export const FollowButton = styled(
  ({
    userId,
    disabled,
    buttonColor,
  }: {
    userId: number;
    disabled?: boolean;
    className?: string;
    buttonColor?: string;
  }) => {
    const { toggle, following, follower, loading } = useFollow(userId);
    const { isLoggedIn } = useAuthentication();
    const [
      isAuthenticationDialogOpen,
      setIsAuthenticationDialogOpen,
    ] = useState(false);
    const { t } = useTranslation('profile');
    const children = useMemo(() => {
      // if (!following && follower) {
      //   return 'Follow back';
      // }

      const result = following ? t('profile:following') : t('profile:follow');

      return result;
    }, [following, follower]);

    const handleAuthenticationDialogClose = () => {
      setIsAuthenticationDialogOpen(false);
    };
    const handleAuthenticationDialogOpen = () => {
      setIsAuthenticationDialogOpen(true);
    };

    const onClick = () => {
      if (isLoggedIn) {
        toggle();
      } else {
        handleAuthenticationDialogOpen();
      }
    };

    return (
      <>
        <AuthenticationPopupDialog
          isOpen={isAuthenticationDialogOpen}
          onClose={handleAuthenticationDialogClose}
        />
        {following ? (
          <Button
            color="secondary"
            outline
            disabled={disabled || loading}
            onClick={onClick}
            active={following}
          >
            <UserMinus size={14} style={{ marginRight: 8 }} />
            <span className="align-middle">{children}</span>
          </Button>
        ) : (
          <Button
            color={buttonColor || 'secondary'}
            disabled={disabled || loading}
            onClick={onClick}
            active={following}
          >
            <UserPlus size={14} style={{ marginRight: 8 }} />
            <span className="align-middle">{children}</span>
          </Button>
        )}
      </>
    );
  },
)``;
