import React from 'react';
import useMedia from '@material-ui/core/useMediaQuery';

type Props = {
  query: string;
  children?: React.ReactNode;
};

const MediaQuery: React.FC<Props> = ({ query, children }) => {
  const visible = useMedia(query);

  if (!visible) {
    return null;
  }

  return <>{children}</>;
};

export default MediaQuery;
