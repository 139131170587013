import { ThunkResult } from './ThunkDispatch';
import * as followAPI from '../../lib/api/followers';
import * as followActions from '../actions/followers';
import { RootState } from '../reducers';
import { FollowTypes } from '../reducers/followers';
import usersReducer from '../reducers/users';
import { logError } from '../../utils/logging';
import { handleError } from './alerts';

const {
  actions: { changeFollowingByUser },
} = usersReducer;

export const fetchFollowers = (
  userId: number,
  page = 1,
  mutual?: boolean,
): ThunkResult => async (dispatch) => {
  dispatch(followActions.requestFollowers());
  try {
    const { data: followers, pagination } = await followAPI.getFollowers(
      userId,
      page,
      mutual,
    );
    dispatch(followActions.addFollowEntries(followers));
    if (page === 1) {
      dispatch(followActions.resetPagination(FollowTypes.follower));
    }

    if (mutual) {
      dispatch(
        followActions.receiveMutualFollowers({
          pagination,
          followers,
        }),
      );
    } else {
      dispatch(
        followActions.receiveFollowers({
          pagination,
          followers,
        }),
      );
    }
  } catch (e: any) {
    dispatch(followActions.errorFollowers(e.message));
  }
};

export const fetchFollowing = (userId: number, page = 1): ThunkResult => async (
  dispatch,
) => {
  dispatch(followActions.requestFollowing());
  try {
    const { following, pagination } = await followAPI.getFollowing(
      userId,
      page,
    );
    dispatch(followActions.addFollowEntries(following));
    if (page === 1) {
      dispatch(followActions.resetPagination(FollowTypes.following));
    }

    dispatch(
      followActions.receiveFollowing({
        pagination,
        following,
      }),
    );
  } catch (e: any) {
    dispatch(followActions.errorFollowing(e.message));
  }
};

export { changeFollowingByUserInFollowers } from '../actions/followers';

export const follow = (id: number, linkId?: number): ThunkResult => async (
  dispatch,
  getState: () => RootState,
) => {
  const {
    user: { id: loggedInId, username, display_name, profile_photo, role },
  } = getState();

  dispatch(followActions.requestFollowUser());
  dispatch(followActions.addRequestingId(id));

  try {
    await followAPI.followUser(id);

    dispatch(changeFollowingByUser({ id, flag: true }));
    dispatch(
      followActions.changeFollowingByUserInFollowers({ id, flag: true }),
    );
    if (
      id === linkId &&
      loggedInId &&
      username &&
      display_name &&
      profile_photo &&
      role
    ) {
      dispatch(
        followActions.addToFollowers({
          id: loggedInId,
          username,
          display_name,
          profile_photo,
          role_id: role,
          role: null,
          following: false,
          key: '',
        }),
      );
    }

    dispatch(followActions.confirmFollowUser(id));
  } catch (error: any) {
    logError(error);
    dispatch(handleError(error));
    dispatch(followActions.errorFollowUser(error));
  } finally {
    dispatch(followActions.removeRequestingId(id));
  }
};

export const unfollow = (id: number, linkId?: number): ThunkResult => async (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const loggedInId = state.user.id;

  dispatch(followActions.requestUnfollowUser());
  dispatch(followActions.addRequestingId(id));

  try {
    await followAPI.unfollowUser(id);

    dispatch(changeFollowingByUser({ id, flag: false }));
    dispatch(
      followActions.changeFollowingByUserInFollowers({ id, flag: false }),
    );
    dispatch(followActions.confirmUnfollowUser(id));
    if (loggedInId === linkId) {
      dispatch(followActions.removeFromFollowing(id));
    } else if (id === linkId && loggedInId) {
      dispatch(followActions.removeFromFollowers(loggedInId));
    }
  } catch (error: any) {
    logError(error);
    dispatch(handleError(error));
    dispatch(followActions.errorUnfollowUser(error));
  } finally {
    dispatch(followActions.removeRequestingId(id));
  }
};

export const getFollowingIds = (): ThunkResult => async (dispatch) => {
  dispatch(followActions.requestFollowingIds());
  try {
    const ids = await followAPI.getFollowingIds();
    dispatch(followActions.confirmFollowingIds(ids));
  } catch (error: any) {
    dispatch(followActions.errorFollowingIds(error.message));
  }
};

export const getFollowersIds = (): ThunkResult => async (dispatch) => {
  try {
    const ids = await followAPI.getFollowersIds();
    dispatch(followActions.followersIdsReceived(ids));
  } catch (error: any) {
    logError(error);
  }
};

export { addRequestingId, removeRequestingId } from '../actions/followers';
