import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { flatten, last } from 'lodash';
import { useSelect } from './common/useSelect';
import { GenreIdentifier } from '../../lib/api/genres';
import {
  commentsActions,
  selectTrack,
  trackActions,
  useDispatch,
  useFetchWithLoading,
} from '../index';
import { selectors } from '../../index';
import * as tracksAPI from '../../lib/api/tracks';

const { getAllPaginatedItems } = selectors;

const identifier = 'tracks';
export const useQueryTrack = (trackId?: number) =>
  useQuery([identifier, trackId], () =>
    trackId ? tracksAPI.getTrackById(trackId) : undefined,
  );

export const useTrack = (id: number | undefined) => {
  const dispatch = useDispatch();

  const [loading, fetchTrack] = useFetchWithLoading(async () => {
    if (!id) return;
    await dispatch(trackActions.fetch(id));
  });

  useEffect(() => {
    fetchTrack();
  }, [id]);

  return [useSelector(selectTrack(id)), loading] as const;
};

export const useShowTrack = (id: number | undefined) => {
  const dispatch = useDispatch();

  const [loading, fetch] = useFetchWithLoading(async () => {
    if (!id) return;
    await dispatch(trackActions.fetchTrackById(id));
  });

  useEffect(() => {
    fetch();
  }, [id]);

  return [useSelector(selectTrack(id)), loading] as const;
};

export const useTracks = (ids: (number | undefined)[] = []) => {
  const dispatch = useDispatch();

  const idsWithoutUndefined = ids.filter(Boolean) as number[];

  useEffect(() => {
    dispatch(trackActions.fetch(idsWithoutUndefined));
  }, [JSON.stringify(ids)]);

  return useSelect(({ tracks: { entries } }) =>
    idsWithoutUndefined.map((id) => entries[id]).filter(Boolean),
  );
};

export const useGenreSet = (
  genreId: number,
  page?: number,
  sort?: tracksAPI.SortType,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackActions.fetchGenreSet(genreId, page, sort));
  }, [genreId, page, sort]);

  return useSelect(({ tracks: { genreSets, entries } }) =>
    getAllPaginatedItems(genreSets[genreId], entries),
  );
};

export const useIsFetchingGenreSet = (genreId: GenreIdentifier) =>
  useSelect(
    ({ tracks: { isFetchingGenreSets } }) => isFetchingGenreSets[genreId],
  );

export const useTopArtistsGenreSet = (genreId: number, page?: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackActions.fetchTopArtistsGenreSet(genreId, page));
  }, [genreId, page]);

  return useSelect(({ tracks: { topArtistsGenreSets, entries } }) =>
    getAllPaginatedItems(topArtistsGenreSets[genreId], entries),
  );
};

export const useSelectionSet = (
  selection: tracksAPI.Selection | 'topTen',
  page?: number,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (selection === 'topTen') {
      dispatch(trackActions.fetchTracksTopTen());
    } else {
      dispatch(trackActions.fetchSelection(selection));
    }
  }, [selection, page]);

  return useSelect(({ tracks: { entries, selections, topTenIds } }) => {
    if (selection === 'topTen') {
      return topTenIds.map((id) => entries[id]);
    }
    return getAllPaginatedItems(selections[selection], entries);
  });
};

export const useTrackComments = (trackId: number | undefined, page = 1) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (trackId !== undefined) {
      dispatch(commentsActions.fetchComments(trackId, page));
    }
  }, [trackId, page]);

  return useSelect(
    ({ comments: { tracksComments, comments, commentsRepliesIds } }) => {
      if (trackId === undefined || tracksComments[trackId] === undefined) {
        return [];
      }

      const data = getAllPaginatedItems(tracksComments[trackId], comments);
      const totalComments = tracksComments[trackId]?.pagination?.total;

      return {
        comments: data.map((comment) => {
          const replies = commentsRepliesIds[comment.id].map(
            (replyId) => comments[replyId],
          );

          return { ...comment, replies };
        }),
        totalComments,
      };
    },
  );
};

export const useLikeTrack = (trackId?: number) => {
  const dispatch = useDispatch();
  const [track] = useTrack(trackId);

  function toggleLike() {
    if (!trackId) return;

    if (track?.logged_in_user_liked_track) {
      dispatch(trackActions.removeLike(trackId));
    } else {
      dispatch(trackActions.likeTrack(trackId));
    }
  }

  const like = () => {
    if (trackId) {
      dispatch(trackActions.likeTrack(trackId));
    }
  };

  const unlike = () => {
    if (trackId) {
      dispatch(trackActions.removeLike(trackId));
    }
  };

  return {
    like,
    unlike,
    toggleLike,
    liked: track?.logged_in_user_liked_track,
  };
};

export const useTopTenTracks = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trackActions.fetchChartsTracks({}, true));
  });
  return useSelect(({ tracks: { topTenIds, entries } }) =>
    topTenIds.map((id) => entries[id]),
  );
};

export const usePopularGenres = ({
  params,
}: Parameters<typeof tracksAPI.getPopularGenres>[0]) => {
  const dispatch = useDispatch();

  const period = params?.period;

  useEffect(() => {
    dispatch(trackActions.fetchPopularGenres({ params }));
  }, [period]);

  return useSelect(
    ({
      tracks: {
        isFetchingPopularGenres: loading,
        errorPopularGenres: error,
        popularGenres,
        entries,
      },
    }) => ({
      loading,
      error,
      /* Pull out tracks from cached tracks */
      data: popularGenres.map((id) => entries[id]),
    }),
  );
};

export const useDeleteTrack = () => {
  const dispatch = useDispatch();

  return async (trackId: number) => {
    await dispatch(trackActions.deleteTrack(trackId));
  };
};

export const useUpdateTrack = () => {
  const dispatch = useDispatch();

  const { mutate: updateTrack, isLoading } = useMutation(
    tracksAPI.updateTrack,
    {
      onSuccess: (track) => {
        dispatch(trackActions.addTrackEntries(track as tracksAPI.Track));
      },
    },
  );

  return { updateTrack, isLoading };
};

export const useQueryTracks = (
  filter?: tracksAPI.Filter | tracksAPI.SwiperFilter,
) => {
  const dispatch = useDispatch();
  const { data, ...hook } = useInfiniteQuery(
    [identifier, 'list', filter],
    ({ pageParam }) => tracksAPI.getAll({ ...(filter || {}), page: pageParam }),
    {
      getNextPageParam: ({ pagination: { lastPage, currentPage } }) =>
        lastPage > currentPage ? currentPage + 1 : undefined,
      onSuccess: ({ pages }) => {
        const lastPage = last(pages);
        if (lastPage) {
          dispatch(trackActions.addTrackEntries(lastPage.tracks));
        }
      },
    },
  );

  return {
    ...hook,
    data: flatten(data?.pages.map((p) => p.tracks)) || [],
  };
};

export const useQueryTracksPopularGenres = (
  filter: Parameters<typeof tracksAPI.getPopularGenres>[0]['params'],
) =>
  useQuery([`${identifier}/popular-genres`, filter], () =>
    tracksAPI.getPopularGenres({ params: filter }),
  );

export const useIsReleaseTrack = (releaseStatus?: string) => {
  if (
    releaseStatus &&
    (releaseStatus === 'live' ||
      releaseStatus === 'submitted' ||
      releaseStatus === 'approved' ||
      releaseStatus === 'incorrect details' ||
      releaseStatus === 'draft')
  ) {
    return true;
  }
  return false;
};
