/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import cookie from 'js-cookie';

dayjs.extend(duration);

const settings = {
  affiliateParam: 'affiliateId',
  lifetime: dayjs.duration(2, 'weeks'),
};

interface AffiliateContextProps {
  getAffiliateId: () => string | undefined;
  setAffiliateId: (affiliateId: string) => void;
  flushAffilateId: () => void;
}

const AffiliateContext = React.createContext<AffiliateContextProps>({
  getAffiliateId: () => undefined,
  setAffiliateId: () => undefined,
  flushAffilateId: () => undefined,
});

export const AffiliateProvider = ({ children }: { children: JSX.Element }) => {
  const router = useRouter();

  const flushAffilateId = () => cookie.remove(settings.affiliateParam);

  const setAffiliateId = useCallback((affiliateId: string) => {
    cookie.set(settings.affiliateParam, affiliateId, {
      expires: dayjs().add(settings.lifetime).toDate(),
    });
  }, []);

  const getAffiliateId = () => cookie.get(settings.affiliateParam);

  useEffect(() => {
    const routeId = router.query[settings.affiliateParam];
    if (routeId) {
      cookie.set(settings.affiliateParam, routeId, {
        expires: dayjs().add(settings.lifetime).toDate(),
      });
      delete router.query[settings.affiliateParam];
      router.push(
        {
          pathname: router.pathname,
          query: router.query,
        },
        router.asPath.split('?', 1)[0],
        {
          shallow: true,
        },
      );
    }
  }, [router.query]);

  return (
    <AffiliateContext.Provider
      value={{
        getAffiliateId,
        setAffiliateId,
        flushAffilateId,
      }}
    >
      {children}
    </AffiliateContext.Provider>
  );
};

export const useAffiliate = () => {
  const context = useContext(AffiliateContext);

  return {
    ...context,
  };
};
