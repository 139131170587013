import { createAction } from '@reduxjs/toolkit';
import { PlaylistBase, PlaylistResponse } from '../../lib/api/playlists';
import { Pagination } from '../../lib/api/Pagination';

export const requestGetPlaylist = createAction<number[]>(
  'REQUEST_GET_PLAYLIST',
);
export const receiveGetPlaylist = createAction<
  PlaylistResponse | PlaylistResponse[]
>('RECEIVE_GET_PLAYLIST');

export const requestCreatePlaylist = createAction('REQUEST_CREATE_PLAYLIST');
export const receiveCreatePlaylist = createAction<PlaylistBase>(
  'RECEIVE_CREATE_PLAYLIST',
);
export const errorCreatePlaylist = createAction<string>(
  'ERROR_CREATE_PLAYLIST',
);

export const requestRemovePlaylist = createAction('REQUEST_REMOVE_PLAYLIST');
export const receiveRemovePlaylist = createAction<number>(
  'RECEIVE_REMOVE_PLAYLIST',
);
export const errorRemovePlaylist = createAction<string>(
  'ERROR_REMOVE_PLAYLIST',
);

export const requestAddTrack = createAction('REQUEST_ADD_TRACK');
export const receiveAddTrack = createAction('RECEIVE_ADD_TRACK');
export const errorAddTrack = createAction<string>('ERROR_ADD_TRACK');

export const requestRemoveTrack = createAction('REQUEST_REMOVE_TRACK');
export const receiveRemoveTrack = createAction<{
  playlistId: number;
  trackId: number;
}>('RECEIVE_REMOVE_TRACK');
export const errorRemoveTrack = createAction<string>('ERROR_REMOVE_TRACK');

export const requestLikePlaylist = createAction('REQUEST_LIKE_PLAYLIST');
export const receiveLikePlaylist = createAction<number>(
  'RECEIVE_LIKE_PLAYLIST',
);
export const errorLikePlaylist = createAction<string>('ERROR_LIKE_PLAYLIST');

export const requestUnlikePlaylist = createAction('REQUEST_UNLIKE_PLAYLIST');
export const receiveUnlikePlaylist = createAction<number>(
  'RECEIVE_UNLIKE_PLAYLIST',
);
export const errorUnlikePlaylist = createAction<string>(
  'ERROR_UNLIKE_PLAYLIST',
);

export const addPlaylistLinkId = createAction<number>('ADD_PLAYLIST_LINK_ID');

export const toggleEditMode = createAction<boolean>('TOGGLE_EDIT_MODE');

export const requestUpdatePlaylist = createAction('REQUEST_UPDATE_PLAYLIST');
export const receiveUpdatePlaylist = createAction('RECEIVE_UPDATE_PLAYLIST');
export const errorUpdatePlaylist = createAction<string>(
  'ERROR_UPDATE_PLAYLIST',
);

export const resetPagination = createAction('RESET_PAGINATION_FOR_PLAYLISTS');

export const addToQueue = createAction<number>('ADD_PlAYLIST_TO_QUEUE');
export const removeFromQueue = createAction<number>(
  'REMOVE_PLAYLIST_FROM_QUEUE',
);

export const requestUserPlaylists = createAction('REQUEST_USER_PLAYLISTS');
export const recieveUserPlaylists = createAction<{
  playlists: PlaylistResponse[];
  pagination: Pagination;
}>('RECEIVE_USER_PLAYLISTS');
export const errorUserPlaylists = createAction<string>('ERROR_USER_PLAYLISTS');

export const receiveAllMyPlaylists = createAction<PlaylistResponse[]>(
  'RECEIVE_ALL_MY_PLAYLISTS',
);
