import React from 'react';
import styled from 'styled-components';
import enhanceWithClickOutside from 'react-onclickoutside';
import Router, { withRouter, NextRouter } from 'next/router';
import Link from 'next/link';
import Trans from 'next-translate/Trans';
import { Bell } from 'react-feather';
import { style, Notification as NotificationType } from '../../../api';

import NotificationIndicator from './NotificationIndicator';

import Notification from '../../Notification';
import { CenteredLoader } from '../../common/Loader';

export interface StateProps {
  notifications: NotificationType[];
  newNotifications?: number;
  loading: boolean;
  userId?: number;
}

export interface DispatchProps {
  fetchNotifications: () => void;
}

export interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps & { router: NextRouter };

interface State {
  showNotificationDropdown: boolean;
}
class NotificationsMenu extends React.Component<Props, State> {
  state: State = {
    showNotificationDropdown: false,
  };

  componentDidMount() {
    const { fetchNotifications } = this.props;
    fetchNotifications();

    Router.events.on('routeChangeStart', this.handleRouteChangeStart);
  }

  componentWillReceiveProps({ userId: nextUserId }: Props) {
    const { fetchNotifications, userId } = this.props;
    if (nextUserId !== userId) {
      fetchNotifications();
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleRouteChangeStart);
  }

  handleRouteChangeStart = () => {
    this.setShowNotificationDropdown(false);
  };

  setShowNotificationDropdown = (show: boolean) =>
    this.setState({ showNotificationDropdown: show });

  handleClickOutside = () => this.setShowNotificationDropdown(false);

  toggleNotificationsMenu = () =>
    this.setState((state) => ({
      showNotificationDropdown: !state.showNotificationDropdown,
    }));

  render() {
    const { showNotificationDropdown } = this.state;
    const { notifications, newNotifications, loading } = this.props;
    const NewNotifications = [...new Set(notifications)];
    return (
      <Container open={showNotificationDropdown}>
        <Bell size={25} onClick={this.toggleNotificationsMenu} />
        {newNotifications !== 0 && (
          <>
            <NotificationIndicator>
              {' '}
              <NotificationsCount>{newNotifications}</NotificationsCount>
            </NotificationIndicator>
          </>
        )}
        {showNotificationDropdown && (
          <DropdownContainer>
            <DropdownInnerContainer>
              {loading ? (
                <CenteredLoader style={{ paddingTop: 10 }} />
              ) : (
                NewNotifications.map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                  />
                ))
              )}
            </DropdownInnerContainer>
            <Link href="/notifications">
              <ViewAllRow>
                <Trans i18nKey="header:viewAll" />
              </ViewAllRow>
            </Link>
          </DropdownContainer>
        )}
      </Container>
    );
  }
}

export default withRouter(enhanceWithClickOutside(NotificationsMenu));

const DropdownInnerContainer = styled.div`
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: auto;
  background-color: ${style.white};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  padding: 5px 0px;
  ::-webkit-scrollbar {
    display: none;
  }

  span {
    white-space: nowrap;
    width: 315px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Container = styled.div<{ open: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 8px;
`;

const NotificationsCount = styled.span`
  position: absolute;
  bottom: 23px;
  font-size: 10px !important;
  color: #ffffff;
  right: 9px;
  z-index: 999;
`;

const ViewAllRow = styled.div`
  min-height: 50px;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${style.primary_pink_new};
  font-size: 0.9rem;
  font-weight: 500;
  background-color: ${style.white};
  transition: 0.2s;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  :hover {
    color: black;
    background: whitesmoke;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 40px;
  right: -85px;
  border-radius: 10px;

  width: 440px;

  display: flex;
  flex-direction: column;

  box-shadow: ${style.lightBoxShadow};

  max-height: 450px;
`;
