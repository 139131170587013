import { useInfiniteQuery, useQuery } from 'react-query';
import dayjs from 'dayjs';

import * as vcoinsAPI from '../../lib/api/vcoins';
import { getEntries, getNextPageParam } from '../../utils/pagination';
import { useAuthentication } from './authentication';

const identifier = 'vcoins';

export const useBalance = () => {
  const { isLoggedIn } = useAuthentication();
  return useQuery([identifier, 'balance'], vcoinsAPI.fetchBalance, {
    select: (res) => res.total,
    enabled: isLoggedIn,
  });
};

export const useBalanceWithPeriodFilter = (period: string) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [identifier, 'balance', period],
    () => vcoinsAPI.fetchBalanceWithPeriod(period),
    {
      select: (res) => res.total,
      enabled: isLoggedIn,
    },
  );
};

export const useVcoinsHistory = () => {
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    data,
    refetch,
    hasNextPage,
  } = useInfiniteQuery(
    [identifier, 'history'],
    ({ pageParam }) => vcoinsAPI.fetchTransactions(pageParam),
    { getNextPageParam },
  );

  const history = getEntries(data);

  const todayStart = dayjs().subtract(1, 'day').unix();

  const earnedToday = history
    .filter((item) => item.created_at > todayStart)
    .filter((item) => item.amount > 0)
    .reduce((total, { amount }) => total + amount, 0);

  return {
    earnedToday,
    history,
    isLoading,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};

export const useVcoinsHistoryWithPeriodFilter = (period: string) => {
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    data,
    refetch,
    hasNextPage,
  } = useInfiniteQuery(
    [identifier, 'history', period],
    ({ pageParam }) => vcoinsAPI.fetchTransactionsWithPeriod(pageParam, period),
    { getNextPageParam },
  );

  const history = getEntries(data);

  const todayStart = dayjs().subtract(1, 'day').unix();

  const earnedToday = history
    .filter((item) => item.created_at > todayStart)
    .filter((item) => item.amount > 0)
    .reduce((total, { amount }) => total + amount, 0);

  return {
    earnedToday,
    history,
    isLoading,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};

export const usePackagesWeb = () =>
  useQuery([identifier, 'packages'], vcoinsAPI.fetchWebVcoinPackages, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const usePackagesMobile = () =>
  useQuery([identifier, 'packages'], vcoinsAPI.fetchMobilePackages, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
