import React from 'react';
import { Table } from 'reactstrap';

export const VuexyTable = ({ children, ...props }: VuexyButtonProps) => (
  <Table {...props}>{children}</Table>
);

interface VuexyButtonProps {
  tag?: string | (() => void);
  size?: string;
  bordered?: boolean;
  borderless?: boolean;
  striped?: boolean;
  dark?: boolean;
  hover?: boolean;
  responsive?: boolean;
  className?: string;
  children?: JSX.Element | JSX.Element[] | Node | (() => void);
  innerRef?: string | Record<string, unknown> | (() => void);
}

export default VuexyTable;
