import { Dispatch } from 'redux';
import * as userAPI from '../../lib/api/user';
import userReducer from '../reducers/user';
import { RootState } from '../reducers';
import { verifyLoginOnStartup } from './authentication';
import { successAction } from '../reducers/alerts';
import { logError } from '../../utils/logging';
import { handleError } from './alerts';
import { User } from '../../lib/api/user';
import { ThunkResult } from './ThunkDispatch';

const { actions: userActions } = userReducer;

export const getCurrent = (
  token?: string,
  withoutAxiosApi = false,
): ThunkResult<Promise<User | undefined>> => async (dispatch) => {
  dispatch(userActions.requestCurrent());
  try {
    if (withoutAxiosApi) {
      const user = await userAPI.getCurrentWithoutAxios(token);
      await dispatch(userActions.receiveCurrent(user.data));

      return user.data;
    }

    const user = await userAPI.getCurrent(token);

    await dispatch(userActions.receiveCurrent(user));

    return user;
  } catch (error: any) {
    dispatch(handleError(error));
  }

  return undefined;
};

export const updateUser = (
  data: userAPI.UpdateUserFormData,
): ThunkResult => async (dispatch, getState) => {
  const {
    user: { id },
  } = getState();

  if (!id) return;

  dispatch(userActions.requestUserUpdate());
  try {
    const user = await userAPI.updateUser(id, data);

    await dispatch(userActions.receiveUserUpdate(user));
    dispatch(successAction('Profile was updated'));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const getSettings = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    user: { id },
  } = getState();

  dispatch(userActions.requestSettings());
  try {
    const settings = await userAPI.getSettings(id as number);
    dispatch(userActions.updateSettings(settings));
  } catch (error: any) {
    dispatch(userActions.errorSettings(error.message));
  }
};

export const updateSettings = (
  data: userAPI.UpdateSettingsFormData,
): ThunkResult => async (dispatch, getState) => {
  const {
    user: { id },
  } = getState();

  if (!id) return;

  const settingsBefore = data;

  dispatch(userActions.requestSettings());
  try {
    dispatch(userActions.updateSettings(data));
    const settings = await userAPI.updateSettings(id, data);
    dispatch(userActions.updateSettings(settings));
  } catch (error: any) {
    dispatch(handleError(error));
    dispatch(userActions.errorSettings(error.message));
    dispatch(userActions.updateSettings(settingsBefore));
  }
};

export const updatePhoto = (img: FormData): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    user: { id },
  } = getState();

  if (!id) return;

  dispatch(userActions.requestPhotoUpdate());

  try {
    const user = await userAPI.updatePhoto(id, img);

    dispatch(userActions.receiveUserUpdate(user));
    dispatch(successAction('Profile image updated'));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const updateCover = (img: FormData): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    user: { id },
  } = getState();

  if (!id) return;

  dispatch(userActions.requestCoverUpdate());

  try {
    const user = await userAPI.updateCover(id, img);

    dispatch(userActions.receiveUserUpdate(user));
    dispatch(successAction('Cover updated'));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const updateSocial = (
  data: userAPI.UpdateSocialSettingsFormData,
): ThunkResult => async (dispatch, getState) => {
  const {
    user: { id },
  } = getState();

  dispatch(userActions.requestUserUpdate());
  try {
    const user = await userAPI.updateSocial(id as number, data);
    dispatch(userActions.receiveUserUpdate(user));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const updateUserInfo = (user: userAPI.User): ThunkResult => async (
  dispatch,
) => {
  await dispatch(userActions.receiveUserUpdate(user));
};

export const updateSettingsInfo = (data: userAPI.Settings) => (
  dispatch: Dispatch,
) => {
  dispatch(userActions.updateSettings(data));
};

export const getAllTracks = (token?: string): ThunkResult => async (
  dispatch,
  getState: () => RootState,
) => {
  const { id } = getState().user;
  if (!id) return;

  dispatch(userActions.requestTracksAll());
  try {
    const tracks = await userAPI.getTracksAll(id, token);
    dispatch(userActions.receiveTracksAll(tracks));
  } catch (error: any) {
    dispatch(userActions.errorTracksAll(error.message));
  }
};

export const setInstructedStatus = (): ThunkResult => async (dispatch) => {
  try {
    const result = await userAPI.confirmInstructedStatus();
    if (result) {
      dispatch(userActions.setInstructedStatus());
    }
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const signInWithGoogle = (idToken: string): ThunkResult => async (
  dispatch,
) => {
  const { token } = await userAPI.signInWithGoogle(idToken);

  await dispatch(verifyLoginOnStartup(token));
};

export const fetchInvoices = (): ThunkResult => async (dispatch) => {
  dispatch(userActions.requestInvoices());

  try {
    const invoices = await userAPI.getInvoices();
    dispatch(userActions.receiveInvoices(invoices));
  } catch (error: any) {
    logError(error);
  }
};

export const tracksArchivedNotified = (): ThunkResult => async (
  dispatch,
  getState,
) => {
  const userId = getState().user.id;

  if (!userId) return;

  try {
    await userAPI.tracksArchivedNotified(userId);
    dispatch(userActions.tracksArchivedNotified());
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const sendOtp = (): ThunkResult => async (dispatch) => {
  dispatch(userActions.sendingOtp());

  try {
    await userAPI.sendOtp();
    dispatch(userActions.otpSent());
  } catch (error: any) {
    dispatch(userActions.otpError(error.message));
    dispatch(handleError(error));
  }
};

export const deactivateAccount = (
  otp: string,
  cb?: VoidFunction,
): ThunkResult => async (dispatch, getState) => {
  const userId = getState().user.id;

  if (!userId) return;

  dispatch(userActions.requestDeactivateAccount);

  try {
    await userAPI.deactivateAccount(userId, otp);
    dispatch(userActions.accountDeactivated());
    dispatch(successAction('Your account was deactivated'));

    if (cb) cb();
  } catch (error: any) {
    dispatch(userActions.accountDeactivatingError());
    dispatch(handleError(error));
  }
};

export const deleteAccount = (
  otp: string,
  cb?: VoidFunction,
): ThunkResult => async (dispatch, getState) => {
  const userId = getState().user.id;

  if (!userId) {
    return;
  }

  dispatch(userActions.requestDeleteAccount());

  try {
    await userAPI.deleteAccount(userId, otp);
    dispatch(userActions.accountDeleted());
    dispatch(successAction('Your account was deleted successfully.'));

    if (cb) cb();
  } catch (error: any) {
    dispatch(userActions.accountDeletingError());
    dispatch(handleError(error));
  }
};

export const reactivateAccount = (otp: string): ThunkResult => async (
  dispatch,
  getState,
) => {
  const userId = getState().user.id;

  if (!userId) {
    return;
  }

  try {
    await userAPI.reactivateAccount(userId, otp);
    dispatch(userActions.accountReactivated());
    dispatch(getCurrent());
  } catch (error: any) {
    dispatch(userActions.accountReactivationError());
    dispatch(handleError(error));
  }
};

export const {
  receiveCurrent,
  resetOtp,
  accountDeleteReset,
  accountDeactivateReset,
  accountReactivationReset,
  receiveUserUpdate,
} = userActions;
