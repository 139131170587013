import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { RootState, Dispatch, notificationActions, selectors } from '../api';
import Notifications, {
  StateProps,
  DispatchProps,
  OwnProps,
} from '../components/Header/UserMenu/NotificationsMenu';

const { getAllPaginatedItems } = selectors;

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  ({
    notifications: {
      notifications,
      paginatedNotifications,
      paginatedNotificationsByLabel,
      unreadNotificationsNumber,
      requestingNotifications,
    },
    user: { id },
  }) => ({
    userId: id,
    newNotifications: unreadNotificationsNumber,
    notifications: sortBy(
      [
        ...getAllPaginatedItems(paginatedNotifications, notifications),
        ...getAllPaginatedItems(paginatedNotificationsByLabel, notifications),
      ],
      (x) => x.created_at,
    ).reverse(),
    loading: requestingNotifications,
  }),
  (dispatch: Dispatch) => ({
    fetchNotifications: () => dispatch(notificationActions.fetchAllTypes()),
  }),
)(Notifications);
