import { Dispatch } from 'redux';
import * as chatAPI from '../../lib/api/chat';
import * as chatActions from '../actions/chat';
import { RootState } from '../reducers';
import { ThunkResult } from './ThunkDispatch';
import { Role } from '../../lib/api/user';
import { playerActions, trackActions } from '../index';
import { successAction } from '../reducers/alerts';
import { handleError } from './alerts';
import determineIdsToFetch from '../../utils/determineIdsToFetch';
import { notUndefinedOrNull } from '../../index';
import { usersActions } from '../reducers/users';

export const fetchConversations = (
  page = 1,
  filter?: chatAPI.ChatFilterType,
): ThunkResult<Promise<chatAPI.Conversation[] | undefined>> => async (
  dispatch,
) => {
  dispatch(chatActions.requestConversations());
  try {
    const { data: conversations, pagination } = await chatAPI.getConversations(
      page,
      filter,
    );

    dispatch(
      chatActions.receiveConversations({ conversations, pagination, filter }),
    );

    return conversations;
  } catch (error: any) {
    dispatch(chatActions.errorConversations(error));
  }

  return undefined;
};

export const fetch = (
  ids: number | number[],
  token?: string,
): ThunkResult => async (dispatch, getState) => {
  // Collect all ids that are not available in redux yet
  const { conversations, fetching } = getState().chat;
  const idsToFetch = determineIdsToFetch(ids, conversations, fetching);

  // If we don't have some tracks yet, request them from the backend
  if (idsToFetch.length > 0) {
    try {
      dispatch(chatActions.requestFetch(idsToFetch));

      const chats = await chatAPI.fetch(idsToFetch, token);
      if (chats.length > 0) {
        dispatch(chatActions.add(chats));
      }
      dispatch(chatActions.receiveFetch(idsToFetch));
    } catch (error: any) {
      dispatch(handleError(error));
    }
  }
};

// you can set recipients field in data or use optional 2nd param
export const createConversation = (
  data: FormData,
  recipientId: number,
): ThunkResult<Promise<number | undefined>> => async (dispatch) => {
  dispatch(chatActions.requestCreateConversations());

  if (recipientId) {
    data.append('recipients', String(recipientId));
  }

  try {
    const conversation = await chatAPI.createConversation(data);

    dispatch(chatActions.recieveCreateConversations(conversation));
    dispatch(
      usersActions.createConversation({
        conversationId: conversation.id,
        userId: recipientId,
      }),
    );

    return conversation.id;
  } catch (e: any) {
    if (e.response.status === 406) {
      throw e;
    }

    dispatch(chatActions.errorCreateConversations(e.response.data.message));
  }

  return undefined;
};

export const hideErrorCreateConversations = () => async (
  dispatch: Dispatch,
) => {
  dispatch(chatActions.errorCreateConversations(''));
};

export const showConversation = (id: number) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  dispatch(chatActions.requestShowConversation());
  try {
    const conversations = await chatAPI.showConversation(id);
    dispatch(chatActions.recieveShowConversation(conversations));
  } catch (e: any) {
    const { conversations } = getState().chat;

    if (conversations[id]) {
      const conversation = conversations[id];

      dispatch(chatActions.recieveShowConversation(conversation));
    }

    if (e.response.data && e.response.data.message === 'CHAT_DELETED') {
      dispatch(chatActions.errorConversationDeleted(id));
    }

    dispatch(chatActions.errorShowConversation(e.message));
  }
};

export const getConversationReplies = (
  id: number,
  page = 1,
  isMobile = false,
): ThunkResult => async (dispatch, getState: () => RootState) => {
  if (!id) return;

  // do not refetch existing pages
  if (!isMobile) {
    const { repliesPerConversation } = getState().chat;
    if (
      repliesPerConversation[id] &&
      repliesPerConversation[id].data &&
      repliesPerConversation[id].data[page]
    )
      return;
  }

  dispatch(chatActions.requestShowConversationReplies());
  try {
    const { pagination, data: replies } = await chatAPI.getConversationReplies(
      id,
      page,
    );

    if (!isMobile && page === 1) dispatch(chatActions.resetPagination());
    dispatch(chatActions.addReplyEntries(replies));

    // load track & waveform data for every message with track_id
    // const trackIdsFromMessages = replies
    //   .map((reply) => reply.track_id)
    //   .filter(Boolean) as number[];
    // const uniqTrackIds = uniq(trackIdsFromMessages);

    const tracks = replies
      .map((reply) => reply.track)
      .filter(notUndefinedOrNull);

    if (tracks.length) {
      dispatch(trackActions.addTrackEntries(tracks));
      // await dispatch(trackActions.fetch(uniqTrackIds));

      if (!isMobile) {
        tracks.forEach((track) =>
          dispatch(playerActions.fetchTrackWaveform(track.id)),
        );
      }
    }

    dispatch(
      chatActions.recieveShowConversationReplies({ id, replies, pagination }),
    );
  } catch (e: any) {
    dispatch(chatActions.errorShowConversationReplies(e.message));
  }
};

export const createConversationReply = (
  data: FormData,
  conversationId: number,
  feedbackResponseTrackId?: number,
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(chatActions.requestCreateConversationsReply());

  const isFeedback = Boolean(feedbackResponseTrackId);
  if (isFeedback) {
    data.append('track_id', String(feedbackResponseTrackId));
    data.append('is_feedback_answer', 'true');
  }

  try {
    const { user } = getState();

    const mockId = new Date().getTime();
    dispatch(
      chatActions.recieveCreateConversationsReply({
        reply: {
          timestamp: Date.now(),
          id: mockId,
          conversation_id: conversationId,
          body: data.get('body')?.toString() || '',
          user: {
            id: user.id || 0,
            profile_photo: user.profile_photo || '',
            username: user.username || '',
            display_name: user.display_name || '',
          },
          is_feedback_answer: isFeedback,
        },
        mid: mockId,
      }),
    );

    const reply = await chatAPI.createConversationReply(data, conversationId);

    dispatch(
      chatActions.recieveCreateConversationsReply({ reply, mid: mockId }),
    );

    if (feedbackResponseTrackId) {
      dispatch(trackActions.feedbackSent(feedbackResponseTrackId));
      dispatch(successAction('Feedback sent!'));
      dispatch(
        chatActions.feedbackSent({
          trackId: feedbackResponseTrackId,
          conversationId,
          reply,
        }),
      );
    }
  } catch (error: any) {
    console.log(error);
    dispatch(handleError(error));
    dispatch(chatActions.errorCreateConversationsReply(error.message));
  }
};

export const deleteConversationReply = (id: number, reply: number) => async (
  dispatch: Dispatch,
) => {
  dispatch(chatActions.requestDeleteConversationsReply());
  try {
    await chatAPI.deleteConversationReply(id, reply);
    dispatch(chatActions.recieveDeleteConversationsReply({ id, reply }));
  } catch (e: any) {
    dispatch(chatActions.errorDeleteConversationsReply(e.message));
  }
};

// currently mobile-only
export const searchConversation = (keyword: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(chatActions.clearSearchResult());
  dispatch(chatActions.requestSearchConversations());
  try {
    const conversations = await chatAPI.searchConversation(keyword);
    dispatch(chatActions.recieveSearchConversations(conversations));
  } catch (error: any) {
    dispatch(chatActions.errorSearchConversations(error.message));
  }
};

export const deleteConversation = (id: number) => async (
  dispatch: Dispatch,
) => {
  dispatch(chatActions.requestDeleteConversations());
  try {
    await chatAPI.deleteConversation(id);
    dispatch(chatActions.recieveDeleteConversations(id));
  } catch (error: any) {
    dispatch(chatActions.errorDeleteConversations(error.message));
  }
};

export const findUsersByName = (
  username: string,
  page = 1,
  role?: Role,
): ThunkResult => async (dispatch) => {
  dispatch(chatActions.requestFindUserByName());
  try {
    if (page === 1) dispatch(chatActions.clearUserSearch());

    const { users, pagination } = await chatAPI.findByName(
      username,
      role,
      page,
    );

    dispatch(chatActions.receiveUserSearchResult({ users, pagination }));
  } catch (error: any) {
    dispatch(chatActions.errorFindUserByName(error.message));
  }
};

export const getUnreadMessagesNumber = () => async (dispatch: Dispatch) => {
  dispatch(chatActions.requestUnreadMessagesNumber());
  try {
    const n = await chatAPI.getUnreadMessagesNumber();
    dispatch(chatActions.recieveUnreadMessagesNumber(n));
  } catch (error: any) {
    dispatch(chatActions.errorUnreadMessagesNumber(error.message));
  }
};

export const read = (conversationId: number): ThunkResult => async (
  dispatch,
) => {
  try {
    await chatAPI.markConversationRead(conversationId);
    dispatch(chatActions.conversationRead(conversationId));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const reportMessage = (messageId: number, reason: string) => () =>
  chatAPI.reportMessage(messageId, reason);

export const setChatModalOpenOrClose = (
  stateForModal: boolean,
): ThunkResult => async (dispatch) => {
  dispatch(chatActions.modalStateType(stateForModal));
};

export {
  clearSearchResult,
  clearUserSearch,
  setAutoSelectedUserId,
} from '../actions/chat';
