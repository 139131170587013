import React, { useMemo, useCallback } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import merge from 'lodash/merge';
import { StyledIconProps } from '@styled-icons/styled-icon';
import { style } from '../../api';

import { Close } from './Buttons';
import useDisableBackgroundScrolling from '../../hooks/useDisableBackgroundScrolling';

const customStyles = (width: number, height?: number) => ({
  overlay: {
    backgroundColor: style.transparentBlack,
    zIndex: 30,
  },
  content: {
    width: '90%',
    maxWidth: `${width}px`,
    minHeight: 'fit-content',
    maxHeight: '95vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: style.mainBg,
    borderRadius: '10px',
    padding: '20px',
    boxShadow: style.popupBoxShadow,
    border: 'none',
    display: 'block',
    ...(height ? { height } : {}),
  },
});

try {
  ReactModal.setAppElement('#__next');
} catch (err) {
  console.debug('[Modal] React-modal will not be available');
  // console.error(err);
}

type ModalProps = {
  isOpened: boolean;
  children: React.ReactNode;
  toggle: (open: boolean) => void;
  noClose?: boolean;
  title?: string;
  width?: number;
  height?: number;
  colorClose?: string;
  style?: ReactModal.Styles;
};

const BaseModal: React.FC<ModalProps> = ({
  isOpened,
  toggle,
  children,
  width = 650,
  height,
  style: modalStyleProps,
}) => {
  const onRequestClose = useCallback(() => {
    toggle(false);
  }, [toggle]);

  useDisableBackgroundScrolling(isOpened);

  const modalStyle = useMemo(
    () => merge(customStyles(width, height), modalStyleProps),
    [width, height, modalStyleProps],
  );

  return (
    <ReactModal
      isOpen={isOpened}
      onRequestClose={onRequestClose}
      style={modalStyle}
      closeTimeoutMS={200}
      contentLabel="modal"
    >
      {children}
    </ReactModal>
  );
};

BaseModal.defaultProps = {
  noClose: false,
  width: 650,
  colorClose: style.black,
};

const ModalClose: React.FC<
  StyledIconProps & { onClick: React.MouseEventHandler }
> = (props) => <AbsClose {...props} />;

ModalClose.defaultProps = {
  size: 24,
  color: style.black,
};

const ModalTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <h3>{children}</h3>
);

const ModalPortal: React.FC<ModalProps> = (props) => {
  const {
    children,
    toggle,
    noClose = false,
    title,
    colorClose = style.black,
  } = props;

  const close = () => toggle(false);

  return (
    <BaseModal {...props}>
      <TopContainer>
        {title && <h3>{title}</h3>}
        {!noClose && (
          <ModalClose onClick={close} size={24} color={colorClose} />
        )}
        {title && <Divider />}
      </TopContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </BaseModal>
  );
};

const ChildrenContainer = styled.div``;

const TopContainer = styled.div`
  margin-bottom: 15px;
  h3 {
    width: 100%;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${style.pageTitleDividerGrey};
`;

const AbsClose = styled(Close)`
  z-index: 1;
  position: absolute;
  margin-left: auto;
  top: 18px;
  right: 16px;
  cursor: pointer;
`;

export default ModalPortal;

export { BaseModal as Modal, ModalClose, ModalTitle };
