import React from 'react';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { Headphones } from 'react-feather';
import Button from '../../../../VuexyDesign/atoms/Button';

export const RequestFeedbackButton = styled(
  ({ onClick, className }: { onClick: () => void; className?: string }) => {
    const { t } = useTranslation('profile');
    return (
      <Button
        color="info"
        text={t('profile:requestFeedback') as string}
        onClick={onClick}
        className={className}
      >
        <Headphones size={16} />
      </Button>
    );
  },
)``;
