import { QueryClient, useQuery } from 'react-query';
import { API } from '../api';
import * as widgetAPI from '../api/lib/api/widget';

const identifier = 'widget';

export const useLabelWidgetSettings = (apiKey?: string) =>
  useQuery(
    [identifier, 'settings', apiKey],
    () => (apiKey ? widgetAPI.fetchLabelWidgetSettings(apiKey) : undefined),
    { useErrorBoundary: true },
  );

export const prefetchLabelWidgetSettings = (apiKey?: string) => async (
  queryClient: QueryClient,
) => {
  await queryClient.prefetchQuery([identifier, 'settings', apiKey], () =>
    apiKey ? API.widget.fetchLabelWidgetSettings(apiKey) : undefined,
  );
};
