import { useState } from 'react';
import { useRouter } from 'next/router';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { API } from '../..';
import { useAffiliate } from '../../../utils/affiliate';
import { fireEventGA } from '../../../utils/googleAnalytics';
import { useSelect } from './common/useSelect';
import { pagesGoogleSignupPopupHidden } from '../../../constants/pagesWithHiddenGoogleSignupPopup';

export const useAuthentication = () => {
  const authentication = useSelect((state) => state.authentication);

  return {
    ...authentication,
    authenticated:
      authentication.isLoggedIn && authentication.hasCheckedInitialLogin,
  };
};

export const useOneTapGoogleLogin = () => {
  const { isLoggedIn } = useAuthentication();
  const router = useRouter();
  const [dataGoogleLogin, setDataGoogleLogin] = useState<{
    token: string;
    new_user: boolean;
  }>();
  const [errorMessage, setErrorMessage] = useState('');
  const affiliate = useAffiliate();

  const disableLoginPopup = () => {
    if (
      isLoggedIn ||
      pagesGoogleSignupPopupHidden.includes(router.pathname) ||
      router.pathname.includes('widget')
    ) {
      return true;
    }

    return false;
  };
  /* -------------------------------- google login success handler -------------------------------- */
  const responseGoogle = async ({ credential }: { credential?: string }) => {
    setErrorMessage('');

    if (!credential) {
      setErrorMessage('Login Failed');
    } else {
      try {
        const data = await API.authentication.googleSignInWeb(
          credential,
          affiliate.getAffiliateId(),
        );

        if (data.new_user) {
          fireEventGA({
            category: 'User',
            action: 'Registered',
            label: 'through Google',
          });
        }
        setDataGoogleLogin(data);
        // finalize(data);
      } catch (err) {
        const message = err?.response?.data?.message;
        setErrorMessage(message);
      }
    }
  };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      responseGoogle(credentialResponse);
    },
    onError: () => {
      setErrorMessage('Login Failed');
    },
    disabled: disableLoginPopup(),
    cancel_on_tap_outside: false,
  });

  return {
    googleLoginCredentialData: dataGoogleLogin,
    errorMessageGoogleLogin: errorMessage,
  };
};
