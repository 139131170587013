/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, {
  Component as ReactComponent,
  useEffect,
  ReactNode,
} from 'react';
import { NextPage } from 'next';
import Router from 'next/router';
import nextCookie from 'next-cookies';
import cookie from 'js-cookie';
import Head from 'next/head';
import qs from 'query-string';
import { ConnectedComponent } from 'react-redux';
import {
  authenticationActions,
  useAuthentication,
  userActions,
  useDispatch,
} from '../api';
import { VirpPageContext } from '../pages/_app';

import isProduction from './isProduction';
import NotFound from '../pages/_error';
import isClientside from './isClientside';
import { wrapper } from '../redux-tools';

const tokenIdentifier = 'virpp.auth.token';

export const hasLoggedInBefore = () =>
  isClientside && Boolean(localStorage.getItem('hasLoggedInBefore'));

export const login = async ({
  token,
  cb,
}: {
  token: string;
  cb: (t: string) => void;
}) => {
  // Set cookie for an hour
  if (isClientside) {
    try {
      localStorage.setItem(tokenIdentifier, token);
      localStorage.setItem('hasLoggedInBefore', 'true');
    } catch (e) {
      console.error('Cannot set local storage when logging in', e);
    }
    try {
      cookie?.set('token', token);
    } catch (e) {
      console.error('Cannot set cookie when logging in', e);
    }

    console.log('i am after all cookies and Local storage set');
  }
  cb(token);
};

export const logout = (cb?: () => void) => {
  if (cb) {
    cb();
  }
  // to support logging out from all windows
  if (isClientside) {
    try {
      localStorage.setItem('logout', Date.now().toString());
      localStorage.removeItem(tokenIdentifier);
    } catch (e) {
      console.error('Cannot set local storage when logging out', e);
    }
    try {
      cookie?.remove('token');
    } catch (e) {
      console.error('Cannot set cookie when logging in', e);
    }
  }
};

// Gets the display name of a JSX component for dev tools
const getDisplayName = <P,>(Component: NextPage<P>) =>
  Component.displayName || Component.name || 'Component';

export const withAuthSync = <
  P extends Record<string, unknown> & { children?: ReactNode }
>(
  WrappedComponent: NextPage<P> | ConnectedComponent<NextPage<P>, P>,
  options: {
    /** Wether this page should only be shown to logged in users. Default is false. */
    protected?: boolean;
    /** Wether it should be shown in production yet. Default is false. */
    noProduction?: boolean;
    /** Wether the user should be redirected to the page after being routed through login */
    redirect?: boolean;
  } = {
    protected: false,
    noProduction: false,
  },
): React.ComponentClass =>
  class extends ReactComponent {
    static displayName = `withAuthSync${
      options.protected ? 'protected' : ''
    }(${getDisplayName(WrappedComponent)})`;

    static getInitialProps = wrapper.getInitialPageProps(
      (store) => async (ctx: VirpPageContext) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let pageProps: any = {};

        const token =
          auth(ctx, options.protected, options.redirect) ||
          store.getState().authentication.token;

        pageProps = {
          ...pageProps,
          token,
        };

        if (token && ctx.req) {
          await store.dispatch(
            authenticationActions.verifyLoginOnStartup(token),
          );
          await store.dispatch(userActions.getCurrent(token, true));
        }

        if (WrappedComponent.getInitialProps) {
          const payload = await WrappedComponent.getInitialProps({
            ...ctx,
            token,
            store,
          });
          pageProps = {
            ...pageProps,
            ...payload,
          };
        }

        return pageProps;
      },
    );

    render() {
      const indexPage = isProduction && !options.protected;

      if (options.noProduction && isProduction) {
        return <NotFound />;
      }

      return (
        <>
          <Head>
            <meta name="robots" content={indexPage ? 'all' : 'none'} />
          </Head>

          <WrappedComponent {...(this.props as P)} />
        </>
      );
    }
  };

const auth = (
  ctx: VirpPageContext,
  isProtected?: boolean,
  redirect?: boolean,
) => {
  const token = ctx.req ? nextCookie(ctx).token : getCookieTokenSync();

  const path = `${ctx.pathname}`;
  const redirectQuery = qs.stringify(ctx.query);

  /*
   * This happens on server only, ctx.req is available means it's being
   * rendered on server. If we are on server and token is not available,
   * means user is not logged in.
   */
  if (isProtected && ctx.req && !token && ctx.res) {
    ctx.res.setHeader('SetCookie', "token=''");
    const query = qs.stringify({
      redirect: redirect ? path : undefined,
      redirectQuery:
        redirect && ctx.query ? qs.stringify(ctx.query) : undefined,
    });
    const location = !query ? '/login' : `/login?${query}`;
    ctx.res.writeHead(302, { Location: location });
    ctx.res.end();
    return undefined;
  }

  // We already checked for server. This should only happen on client.
  if (isProtected && !token) {
    Router.push({
      pathname: '/login',
      query: {
        redirect: path,
        // line below should be : redirectQuery: redirectQuery, shorthand notiation used because of linter
        redirectQuery,
      },
    });
    removeCookieToken();
    return undefined;
  }

  return token;
};

export const updateCookieToken = (token: string) => {
  if (isClientside) {
    try {
      localStorage.setItem(tokenIdentifier, token);
    } catch (e) {
      console.error('Couldnt update localstorage', e);
    }
    try {
      cookie?.set('token', token);
    } catch (e) {
      console.error('Couldnt update cookie', e);
    }
  }
};

export const removeCookieToken = () => {
  if (isClientside) {
    try {
      localStorage.removeItem(tokenIdentifier);
    } catch (e) {
      console.error('Couldnt remove token', e);
    }
    try {
      cookie?.remove('token');
    } catch (e) {
      console.error('Couldnt remove cookie', e);
    }
  }
};

export const getCookieTokenSync = () => {
  if (isClientside) {
    try {
      const token = localStorage.getItem(tokenIdentifier);
      return token;
    } catch (e) {
      console.error('Couldnt get token from localstorage', e);
    }

    try {
      const token = cookie?.get('token');
      return token;
    } catch (e) {
      console.error('Couldnt get token from cookie');
    }
  }
  return undefined;
};

export const getCookieToken = () => Promise.resolve(getCookieTokenSync());

export const useCheckInitialLogin = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, hasCheckedInitialLogin } = useAuthentication();
  useEffect(() => {
    const checkLogin = async () => {
      if (isClientside && !hasCheckedInitialLogin && !isLoggedIn) {
        const token = await getCookieToken();
        if (token) {
          dispatch(authenticationActions.verifyLoginOnStartup(token));
        }
      }
    };
    checkLogin();
  }, [hasCheckedInitialLogin, isLoggedIn, isClientside]);
};
