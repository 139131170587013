import React from 'react';
import styled from 'styled-components';
import { usePlayer } from '../../../../api';

import Box, { BoxProps } from '../../Box';
import { Play, Pause } from '../../Icons';
import {
  usePlayerState,
  usePlayerControls,
} from '../../../Player/PlayerContext';

const BoxHover = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5%;
`;

interface PlayButtonProps {
  circle?: boolean;
  local?: boolean;
  playTrack?: (trackId: number) => void;
  trackId?: number;
}

const PlayButton = ({
  circle,
  trackId,
  local,
  playTrack,
  ...props
}: BoxProps & PlayButtonProps) => {
  const player = usePlayer();

  const playerState = usePlayerState();
  const controls = usePlayerControls();

  const PauseIcon = circle ? Pause : Pause;
  const PlayIcon = circle ? Play : Play;
  const playing =
    trackId && !local
      ? trackId === player.track?.id && !playerState?.paused
      : !playerState?.paused;

  const togglePauseTrack = () => {
    if (local || !trackId || trackId === player.track?.id) {
      controls?.toggle();
    } else if (playTrack) {
      playTrack(trackId);
    } else {
      player.playTrackById(trackId);
    }
  };

  return (
    <BoxHover
      {...props}
      onClick={togglePauseTrack}
      className="bg-white text-dark"
    >
      {playing ? (
        <div className="h-100 w-100 d-flex justify-content-center">
          <PauseIcon size="100%" fontSize="10" strokeWidth={2} />
        </div>
      ) : (
        <div className="h-100 w-100 d-flex justify-content-center">
          <PlayIcon size="100%" fontSize="10" strokeWidth={2} />
        </div>
      )}
    </BoxHover>
  );
};

export default PlayButton;
