import { RootState } from '../reducers';
import { User } from '../../lib/api/user';

export const selectUser = (userId?: number) => ({ users }: RootState) =>
  userId ? users.entries[userId] : undefined;

export const selectCurrentUser = ({ user }: RootState) => user as User;

// // genres are in genres.ts
// export const selectCountries = ({ countries }: RootState) => countries.entries;

export const selectUserPlaylists = ({ playlists }: RootState) =>
  playlists.entries;

export const selectMySettings = ({ user }: RootState) => user.settings;
