/**
 * Until the user flow and design gets improved,
 * we are disabling this feature as a part of: https://yourbrandhub.atlassian.net/browse/VIR-1260
 *
 * NOTE: Do not remove `collaborators` from track update, not to disturb previously
 * added collaborations
 */
export const FEATURE_COLLABORATION_ENABLED = false;

/**
 * Upon verifying users email user should be redirected to explore page
 * if `redirect_after_verify_uri` contains any of the following routes
 *
 * https://yourbrandhub.atlassian.net/browse/VIR-1226?focusedCommentId=11502
 */
export const VERIFY_EMAIL_REDIRECT_ROUTES = [
  '/my-tracks',
  '/my-wallet',
  '/statistics',
  '/awards',
  '/settings',
];
