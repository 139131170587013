import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

function Alert({ children, color, show }: AlertProps) {
  return (
    <UncontrolledAlert color={color} isOpen={show}>
      {children}
    </UncontrolledAlert>
  );
}

export default Alert;

interface AlertProps {
  children: JSX.Element[] | JSX.Element | string;
  show: boolean;
  color: string;
}
