import { useQuery } from 'react-query';
import * as countriesAPI from '../../lib/api/countries';
import { Country } from '../../lib/api/countries';

export const countriesIdentifier = 'countries';

export const useCountries = (
  { withActiveTracksOnly }: { withActiveTracksOnly: boolean } = {
    withActiveTracksOnly: false,
  },
) => {
  const { data, isFetching } = useQuery(
    countriesIdentifier,
    countriesAPI.fetch,
    { refetchOnWindowFocus: false },
  );
  let result = data;

  if (withActiveTracksOnly) {
    result = data?.filter((c: Country) => c.has_active_tracks);
  }

  return {
    isFetching,
    result: result || [],
  };
};
