/* eslint-disable import/no-unresolved */
import React from 'react';
import styled from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { Release } from '../../api/lib/api/releases';
import { style } from '../../api';
import Dialog, { DialogProps } from '../common/Dialog';
import { desktop, noDesktop } from '../../style/constants';
import Flex from '../common/Flex';
import { TrackImage } from '../common/ReportStyles';
import {
  Spotify,
  Applemusic,
  Amazon,
  Tidal,
  Youtubemusic,
} from '../common/Icons';
import { TextNoMargin } from '../common/Text';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    background: none;
    box-shadow: none;
    overflow: hidden;
  }
  @media ${desktop} {
    .MuiDialog-paper {
      width: 763px;
    }
  }
  @media ${noDesktop} {
    padding: 0px 20px;
    .MuiDialog-container {
      justify-content: flex-start;
    }
  }
`;

const ReleasedTrackDialog = ({
  onClose,
  release,
  ...props
}: { release?: Release } & Omit<DialogProps, 'children' | 'open'>) => {
  const { t } = useTranslation('releases');
  if (!release) {
    return null;
  }

  const open = release !== undefined;
  const year = new Date(release.release_date).getFullYear();
  const platforms = [
    'spotify',
    'apple_music',
    'amazon_music',
    'tidal',
    'youtube_music',
  ] as (keyof Release)[];
  return (
    <StyledDialog
      disableFullScreen
      {...props}
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      hideCloseButton
      BackdropProps={{
        open,
        style: {
          background: `url(${release?.track.track_img})`,
          backgroundSize: 'cover',
          filter: 'blur(10px)',
          boxShadow: 'box-shadow: 1px 2px 3px #00000027;',
        },
      }}
    >
      <Flex
        flexDirection={['column', 'row', 'row']}
        justifyContent="flex-start"
      >
        <TrackImage src={release?.track.track_img} size={215} />
        <InfoWrapper>
          <span className="modal-title">{release.track.title}</span>
          <span className="modal-artist-name">
            <Trans
              i18nKey="releases:by artist"
              values={{
                artistName: release.track.user.display_name,
              }}
            />
          </span>
          <span className="modal-year">{year}</span>
        </InfoWrapper>
      </Flex>
      <TextNoMargin marginY="18px" color="white">
        {t('releases:available on')}
      </TextNoMargin>
      <Flex flexWrap="wrap">
        {platforms.map(
          (key) =>
            release[key] && (
              <ReleasePlatformTile
                platform={key}
                href={release[key] as string}
              />
            ),
        )}
      </Flex>
    </StyledDialog>
  );
};

const InfoWrapper = styled(Flex)`
  flex-direction: column;
  span {
    color: white;
  }

  @media ${desktop} {
    padding: 16px;
  }

  .modal-title {
    font-weight: bold;
    @media ${desktop} {
      font-size: 30px;
      margin-bottom: 12px;
    }
    @media ${noDesktop} {
      font-size: 20px;
      margin-bottom: 8px;
      margin-top: 20px;
    }
  }

  .modal-artist-name {
    @media ${desktop} {
      font-size: 20px;
      margin-bottom: 12px;
    }
    @media ${noDesktop} {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  .modal-year {
    color: ${style.grey_dark_new};
    @media ${desktop} {
      font-size: 18px;
    }
    @media ${noDesktop} {
      font-size: 16px;
    }
  }
`;

const ReleasePlatformTile = ({
  platform: key,
  href,
}: {
  href: string;
  platform: string;
}) => {
  const Icon = keyToIcon[key];
  return (
    <ReleasePlatformTileWrapper href={href}>
      {Icon}
      <TextNoMargin ml="8px">{keyToPlatformName[key]}</TextNoMargin>
    </ReleasePlatformTileWrapper>
  );
};

const ReleasePlatformTileWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 12px;
  height: 50px;
  color: white;
  font-size: 14px;
  font-weight: bold;
`;

const keyToIcon: Record<string, React.ReactNode> = {
  spotify: <Spotify size={22} color={style.spotify_new} />,
  apple_music: <Applemusic size={20} color={style.apple_music_new} />,
  amazon_music: <Amazon size={20} color={style.amazon_new} />,
  tidal: <Tidal size={20} color="white" />,
  youtube_music: <Youtubemusic size={20} color={style.youtube_new} />,
};

const keyToPlatformName: Record<string, string> = {
  spotify: 'Spotify',
  apple_music: 'Apple Music',
  amazon_music: 'Amazon Music',
  tidal: 'Tidal',
  youtube_music: 'Youtube Music',
};

export default ReleasedTrackDialog;
