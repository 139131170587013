import React from 'react';
import { Dialog, DialogProps as MUIDialogProps } from '@material-ui/core';
import useMedia from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import Flex from './Flex';
import { Close } from './Icons';
import Box from './Box';
import { tabletWidth, mobile } from '../../style/constants';
import useDisableBackgroundScrolling from '../../hooks/useDisableBackgroundScrolling';

export type DialogProps = Omit<MUIDialogProps, 'onClose' | 'title'> & {
  disableFullScreen?: boolean;
  hideCloseButton?: boolean;
  title?: JSX.Element | string;
  onClose?: {
    bivarianceHack(
      event: React.MouseEvent | Record<string, never>,
      reason: 'backdropClick' | 'escapeKeyDown' | 'close',
    ): void;
  }['bivarianceHack'];
};

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px;
    max-width: 100%;
    @media ${mobile} {
      margin: 16px;
    }
  }

  .MuiDialog-paperFullScreen {
    margin: initial;
  }
`;

const DialogComponent = ({
  onClose,
  disableFullScreen,
  hideCloseButton,
  title,
  children,
  ...props
}: DialogProps) => {
  const isMobile = useMedia(`(min-width: ${tabletWidth}px)`);

  const { open } = props;

  useDisableBackgroundScrolling(open);

  return (
    <StyledDialog
      disableScrollLock
      onClose={onClose}
      fullScreen={!disableFullScreen && !isMobile}
      {...props}
    >
      {(title || !hideCloseButton) && (
        <Flex
          pt={[3, '25px']}
          px={[3, '25px']}
          pb={[2, 3]}
          width="100%"
          alignItems="center"
          justifyContent={!title ? 'flex-end' : 'space-between'}
        >
          {title && (
            <Box fontWeight="bold" fontSize={[4, 6]} width="100%">
              {title}
            </Box>
          )}
          {!hideCloseButton && (
            <Box
              style={{
                cursor: 'pointer',
                zIndex: 999,
              }}
              onClick={(event) => onClose && onClose(event, 'close')}
            >
              <Close size={22} />
            </Box>
          )}
        </Flex>
      )}
      {children}
    </StyledDialog>
  );
};

export default DialogComponent;
