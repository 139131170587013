import { useRouter } from 'next/router';
import { usePlayer } from '../api';
import { useIsDesktop } from '../style/constants';
import {
  pagesWithHiddenAudioPlayer,
  pagesWithHiddenAudioPlayerOnMobile,
} from '../constants/pages-with-hidden-elements';

const useFooterPlayerVisibility = () => {
  const isDesktop = useIsDesktop();

  const { route } = useRouter();
  const { track } = usePlayer();

  if (!track) {
    return false;
  }

  const visibleDesktop = !pagesWithHiddenAudioPlayer.includes(route);
  const visibleMobile = !pagesWithHiddenAudioPlayerOnMobile.includes(route);

  /**
   * Ensure player keeps running when you resize the window
   */
  if (visibleMobile && visibleDesktop) {
    return true;
  }

  if (isDesktop) {
    return visibleDesktop;
  }

  return visibleMobile;
};

export default useFooterPlayerVisibility;
