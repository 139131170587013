import { logError } from '../api';
import isClientside from '../utils/isClientside';

export const useCheckCookiesAvailable = () => {
  if (!isClientside) {
    return false;
  }

  try {
    // See if we can use localstorage for storing the auth token
    if (window && window.localStorage) {
      return true;
    }
  } catch (error: any) {
    logError(error);
  }

  const { cookieEnabled } = navigator;

  if (cookieEnabled) {
    return true;
  }

  document.cookie = 'testcookie=testcookie; SameSite=None; Secure';
  return document.cookie.indexOf('testcookie') !== -1;
};
