import { useCurrentUser } from '../api';
import isClientside from '../utils/isClientside';

/**
 * Use a local storage boolean flag.
 * Will always return false on the server side while rendering.
 * Be sure to use a unique identifier.
 */
export default (identifier: string) => {
  const set = (val: boolean) => {
    if (isClientside) {
      localStorage.setItem(identifier, String(val));
    }
  };

  const val = isClientside && Boolean(localStorage.getItem(identifier));

  return [val, set] as const;
};

/**
 * Use a local storage flag, which only applies for the current user
 */
const userFlagsPrefix = 'privateStorageFlags';
type UserFlags = Record<string, Record<string, boolean>>;
export const useLocalStorageFlagForCurrentUser = (identifier: string) => {
  const { id } = useCurrentUser();

  const getValue = () => {
    if (id === undefined || !isClientside) return undefined;
    const currentValue = localStorage.getItem(userFlagsPrefix) || '{}';
    const parsedValue = JSON.parse(currentValue) as UserFlags;
    return parsedValue;
  };

  const set = (val: boolean) => {
    if (isClientside && id !== undefined) {
      const parsedValue = getValue();
      if (!parsedValue) {
        return;
      }
      const idAsString = id.toString();
      parsedValue[idAsString] = {
        ...(parsedValue[idAsString] || {}),
        [identifier]: val,
      };

      localStorage.setItem(userFlagsPrefix, JSON.stringify(parsedValue));
    }
  };

  const parsedValue = getValue();
  const idAsString = id ? id.toString() : undefined;
  const val =
    parsedValue !== undefined &&
    idAsString !== undefined &&
    !!parsedValue[idAsString]?.[identifier];

  return [val, set] as const;
};
