import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { style, useCurrentUserTracks, useAuthentication } from '../../api';

import Dialog from '../common/Dialog';
import { TextNoMargin } from '../common/Text';
import Flex from '../common/Flex';
import { EditTrackLink } from '../common/Link';
import { useLocalStorageFlagForCurrentUser } from '../../hooks/useLocalstorageFlag';
import { Button } from '../../VuexyDesign/atoms/_index';

const LOCALSTORAGE_IDENTIFIER =
  'HAS_SHOWN_TRACKS_WITHOUT_INFORMATION_DIALOG_IDENTIFIER';

const dontShowOnPages = ['/edit-track/[trackId]'];

const TrackWithoutInformationDialog = () => {
  const { t } = useTranslation('modals');
  const [show, setShow] = useState(false);
  const { data: myTracks = [] } = useCurrentUserTracks();
  const { isLoggedIn } = useAuthentication();
  const router = useRouter();
  const [shownBefore, setShownBefore] = useLocalStorageFlagForCurrentUser(
    LOCALSTORAGE_IDENTIFIER,
  );

  const tracksWithoutInformation = myTracks.filter(
    (track) => track.status === 'uploaded',
  );

  const close = () => {
    setShow(false);
  };

  const numberOfTracks = tracksWithoutInformation.length;
  const showOnPage = !dontShowOnPages.includes(router.pathname);

  useEffect(() => {
    if (!shownBefore && isLoggedIn && numberOfTracks > 0 && showOnPage) {
      setShow(true);
      setShownBefore(true);
    }
    if (!showOnPage) {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownBefore, isLoggedIn, numberOfTracks, showOnPage]);

  return (
    <Dialog open={show} onClose={close}>
      <Flex
        flexDirection="column"
        maxWidth="590px"
        alignItems="center"
        paddingX="60px"
      >
        <TextNoMargin fontSize="18px" fontWeight="bold">
          {t('modals:youOwn') as string}{' '}
          {numberOfTracks > 1 ? 'VIRPPS' : (t('modals:aVirpp') as string)}{' '}
          {t('modals:withoutTrackInfo') as string}
        </TextNoMargin>
        <TextNoMargin fontSize="14px" mt={3} textAlign="center">
          {t('modals:weFound') as string}{' '}
          {numberOfTracks > 1 ? 'VIRPPS' : (t('modals:aVirpp') as string)}{' '}
          {t('modals:withoutTrackInfoVisibility') as string}
        </TextNoMargin>

        <Flex flexDirection="column" mt={3}>
          {tracksWithoutInformation.map((track) => (
            <EditTrackLink trackId={track.id} onClick={close} key={track.id}>
              {track.title || 'Untitled'}
            </EditTrackLink>
          ))}
        </Flex>
      </Flex>

      <ButtonWrapper flexDirection={['column', 'row']}>
        <Button onClick={close} outline>
          {t('modals:close') as string}
        </Button>
        <GoButton href="/my-tracks" color="primary" onClick={close}>
          {t('modals:goToTracks') as string}
        </GoButton>
      </ButtonWrapper>
    </Dialog>
  );
};

const GoButton = styled(Button)`
  color: #fff;
  background-color: ${style.primary_pink_new};
`;

const ButtonWrapper = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;

  button,
  a {
    margin: 0 16px 16px 16px;
    width: 230px;
  }
`;

export default TrackWithoutInformationDialog;
