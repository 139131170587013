import axios from './axios';
import ResponseWrapper from './ResponseWrapper';
import { NewTrack } from './tracks';
import { Raffle } from './raffles';

export type LabelWidgetType = 'widget' | 'page';

type LabelButtonPosition = 'left-center' | 'bottom-center';

export type LabelWidgetTheme = 'dark' | 'light';

export interface WidgetLayout {
  backgroundColor?: string;
  buttonPosition?: LabelButtonPosition;
  textColor?: string;
  theme?: LabelWidgetTheme;
}

export interface LabelWidgetSettings {
  username: string;
  display_name: string;
  url: string;
  enable: boolean;
  type: LabelWidgetType;
  user_id: number;
  background_color?: string;
  hide_on_pages?: string[];
  show_on_pages?: string[];
  primary_color?: string;
  profile_photo?: string;
  layout?: WidgetLayout;
  contests: Raffle[];
  show_upload: boolean;
  style?: string;
}

export interface UploadLabelWidgetTrack {
  api_key: string;
  track?: File;
  data?: Blob;
  track_url?: string;
  contests: number[];
  onUploadProgress?: (event: ProgressEvent) => void;
}

export interface UpdateLabelWidgetTrack {
  title: string;
  track_desc: string;
  genre_id: number;
  start: number;
  bpm: number;
  api_key: string;
  contests: number[];
}

export interface LabelWidgetTrack extends NewTrack {
  widget_hash?: string;
  start: number;
}

export const fetchLabelWidgetSettings = (apikey: string) =>
  axios
    .get<ResponseWrapper<LabelWidgetSettings>>(`/widget/${apikey}`)
    .then(({ data }) => data.data);

export const uploadTrackLabelWidget = ({
  api_key,
  track,
  data,
  track_url,
  contests,
  onUploadProgress,
}: UploadLabelWidgetTrack) => {
  const formData = new FormData();
  formData.append('api_key', api_key);

  if (track) formData.append('track', track);
  if (data) formData.append('data', data);
  if (track_url) formData.append('track_url', track_url);

  /**
   * Validation fails on server and is cough by sentry if
   * FE sends raffle_if="0" which could occur with previous check
   *
   * https://yourbrandhub.slack.com/files/UEJQQSGE7/F01DD30217Z/image.png
   */
  if (contests.length > 0) {
    formData.append('contests[]', String(contests[0]));
  }

  return axios
    .post<ResponseWrapper<LabelWidgetTrack>>('/widget/upload', formData, {
      onUploadProgress,
    })
    .then(({ data: responseData }) => responseData.data);
};

export const getTrackLabelWidget = (widget_hash: string) =>
  axios
    .get<ResponseWrapper<LabelWidgetTrack>>(`/widget/track/${widget_hash}`)
    .then(({ data }) => data.data);

export const updateTrackLabelWidget = async (
  widgetHash: string,
  {
    title,
    track_desc,
    genre_id,
    start,
    bpm,
    api_key,
    contests,
  }: Partial<UpdateLabelWidgetTrack>,
) => {
  const body: Partial<UpdateLabelWidgetTrack> = {
    title,
    track_desc,
    genre_id,
    start,
    bpm,
    api_key,
    contests,
  };

  return axios
    .put<ResponseWrapper<LabelWidgetTrack>>(`/widget/track/${widgetHash}`, body)
    .then(({ data }) => data.data);
};
