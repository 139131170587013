import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { flatten } from 'lodash';

import { useSelect } from './common/useSelect';
import { API, searchActions, selectors, useDispatch } from '../../index';
import { getEntries, getNextPageParam } from '../../utils/pagination';
import { useRaffles } from './raffles';

const { getAllPaginatedItems } = selectors;

export const useFetchSearch = (
  searchQuery: string,
  tracksPage?: number,
  usersPage?: number,
  rafflesPage?: number,
  contestsPage?: number,
) => {
  const dispatch = useDispatch();
  // console.debug('[Hooks/search] fetching search', searchQuery);
  useEffect(() => {
    if (searchQuery.length >= 2) {
      dispatch(
        searchActions.search_DEPRECATED(
          searchQuery,
          tracksPage,
          usersPage,
          rafflesPage,
          contestsPage,
        ),
      );
      dispatch(searchActions.getSearchesRecentPopular());
    }
  }, [searchQuery, tracksPage, usersPage, rafflesPage, contestsPage]);
};

export const useSearch = (keyword?: string) => {
  const [debouncedKeyword] = useDebounce(keyword, 400);

  useEffect(() => {
    refetch();
  }, [debouncedKeyword]);

  const {
    data,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    'search',
    ({ pageParam }) => API.search.getSearchResult(debouncedKeyword, pageParam),
    { getNextPageParam, enabled: Boolean(debouncedKeyword) },
  );

  const entries = getEntries(data);

  return {
    entries,
    nextPage: fetchNextPage,
    isFetchingNextPage,
    isLoading,
  };
};

export const useSearchResults = () => {
  const { raffleIds, contestIds, ...res } = useSelect((state) => {
    const {
      tracks: { entries: tracksEntries },
      search: {
        searchResultTracks,
        searchResultUsers,
        searchResultRaffles,
        searchResultContests,
        searchResultGenres,
        popularSearches,
        lastKeyword,
        searchResultUserEntries,
      },
    } = state;

    return {
      tracks: getAllPaginatedItems(searchResultTracks, tracksEntries),
      users: getAllPaginatedItems(searchResultUsers, searchResultUserEntries),
      raffleIds: flatten(Object.values(searchResultRaffles.data)),
      contestIds: flatten(Object.values(searchResultContests.data)),
      genres: searchResultGenres,
      popularSearches,
      lastKeyword,
      totalResults:
        Number(searchResultTracks.pagination?.total) +
        Number(searchResultUsers.pagination?.total),
      pagination: {
        tracks: searchResultTracks.pagination,
        users: searchResultUsers.pagination,
        raffles: searchResultRaffles.pagination,
        contests: searchResultContests.pagination,
      },
    };
  });

  const { data: raffles = [] } = useRaffles(raffleIds);
  const { data: contests = [] } = useRaffles(contestIds);

  return {
    raffles,
    contests,
    ...res,
  };
};
