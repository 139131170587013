import axios from './axios';
import ResponseWrapper from './ResponseWrapper';
import { TrackPullStatus } from './notifications';

export const get = (pullId: number) =>
  axios
    .get<ResponseWrapper<TrackPullStatus>>(`/pull/${pullId}`)
    .then(({ data }) => data.data);

export const accept = (pullId: number) =>
  axios
    .put<ResponseWrapper<TrackPullStatus>>(`pull/${pullId}/accept`)
    .then(({ data }) => data);

export const decline = (pullId: number) =>
  axios
    .put<ResponseWrapper<TrackPullStatus>>(`pull/${pullId}/decline`)
    .then(({ data }) => data);

export const getUnshown = () =>
  axios
    .get<ResponseWrapper<PullRequest[]>>('/pull')
    .then(({ data }) => data.data);

export const markAsShown = (pullId: number) =>
  axios.put(`/pull/${pullId}`).then(({ data }) => data);

export interface PullRequest {
  id: number;
  track_id: number;
  user_id: number;
  message: string;
  accepted: 0 | 1;
  read_at: string;
  created_at: string;
  updated_at: string;
}
