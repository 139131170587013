import { RootState } from '../reducers';

export const selectSearchResults = (state: RootState) =>
  state.search.searchResults;

export const selectSearchesRecent = (state: RootState) =>
  state.search.recentSearches;

export const selectSearchesPopular = (state: RootState) =>
  state.search.popularSearches;
