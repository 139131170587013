import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { UserSimple } from './user';

export interface Follower extends UserSimple {
  role_id: string | null;
  display_name: string;
  following: boolean;
}

export const getFollowers = async (
  userId: number,
  page = 1,
  mutual?: boolean,
) =>
  axios
    .get<ResponseWrapperPaginated<Follower[]>>(`/follow/${userId}`, {
      params: { page, mutual },
    })
    .then(({ data }) => data);
// .then(({ data }) => ({
//   followers: data.data,
//   pagination: data.pagination,
// }));

export const getFollowing = async (userId: number, page = 1) =>
  axios
    .get<ResponseWrapperPaginated<Follower[]>>(`/following/${userId}`, {
      params: { page },
    })
    .then(({ data }) => ({
      following: data.data,
      pagination: data.pagination,
    }));

export const followUser = async (userId: number) =>
  axios.post(`/follow/${userId}`).then(() => true);

export const unfollowUser = async (userId: number) =>
  axios.delete(`/follow/${userId}`).then(() => true);

export const getFollowingIds = async () =>
  axios
    .get<ResponseWrapper<number[]>>('/following')
    .then(({ data }) => data.data);

export const getFollowersIds = async () =>
  axios
    .get<ResponseWrapper<number[]>>('/followers')
    .then(({ data }) => data.data);
