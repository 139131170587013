import { useStore } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { Config, Pusher as PusherType } from 'pusher-js';
import {
  chatQueryIdentifier,
  StoreType,
  useBalance,
  useQueryAddReply,
  useQueryUnreadMessagesCount,
} from './index';
import {
  conversationCreated,
  pusherConversationReplyCreated,
  pusherNotificationReceived,
  incrementNotificationsCounter,
  incrementLabelNotificationsCounter,
} from './actions/pusher';
import { fetchNotificationsData } from './dispatch/notifications';
import {
  isNotificationByLabel,
  PusherNotification,
} from './reducers/notifications';
import { NotificationDataCommon, Notification } from '../lib/api/notifications';
import { incrementMessagesCounter } from './actions/chat';
import tracksReducer from './reducers/tracks';
import { Conversation, Reply } from '../lib/api/chat';
import { useCurrentUser } from './hooks';

const {
  actions: { feedbackRequested },
} = tracksReducer;

const echoEventName =
  'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
const conversationCreatedEvent = 'App\\Events\\ConversationCreated';
const conversationReplyCreatedEvent = 'App\\Events\\ConversationReplyCreated';

export interface UserInfo {
  id: number;
  username: string;
}

let _socket: PusherType | undefined;

export const getSocket = () => _socket;

const restructurePusherNotification = (
  pusherNotification: PusherNotification,
) => {
  // Remove the prefix that gets added to the type string from the backend
  const splitNotificationType = pusherNotification.type.split('\\');

  const notificationType = (splitNotificationType.length === 1
    ? splitNotificationType[0]
    : splitNotificationType[2]) as NotificationDataCommon['type'];

  const { id, ...notificationData } = pusherNotification;

  // Restructure to the notification format we are using
  // @ts-ignore
  const notification: Notification = {
    id,
    // TODO should have the same format as date that comes from BE
    created_at: Date.now(),
    read_at: undefined,
    updated_at: undefined,
    data: {
      ...notificationData,
      type: notificationType || pusherNotification.type,
    } as NotificationDataCommon,
  };

  return notification;
};

export const setupPusher = (store: StoreType, socket: PusherType) => {
  const { id: userId } = store.getState().user;
  const { token } = store.getState().authentication;

  const conversationsChannelName = `presence-user.${userId}`;
  const notificationsChannelName = `private-App.User.${userId}`;
  const onlineChannelName = `presence-user-online.${userId}`;

  if (!userId || !token) return;

  const conversationsChannel = socket.subscribe(conversationsChannelName);

  conversationsChannel.bind(conversationCreatedEvent, (data) =>
    store.dispatch(conversationCreated(data)),
  );
  conversationsChannel.bind(conversationReplyCreatedEvent, (data) => {
    store.dispatch(pusherConversationReplyCreated(data));
  });

  const notificationsChannel = socket.subscribe(notificationsChannelName);

  notificationsChannel.bind(
    echoEventName,
    async (pusherNote: PusherNotification) => {
      const notification = restructurePusherNotification(pusherNote);
      const { data } = notification;

      await store.dispatch(fetchNotificationsData([notification]));
      store.dispatch(pusherNotificationReceived(notification));

      if (data.type === 'ConversationMessageReceived') {
        store.dispatch(incrementMessagesCounter(data.conversation_id));
      }

      if (data.type === 'VcoinsTransaction') {
        // ! Refetch balance on notification of this type
        // store.dispatch(
        //   vcoinActions.updateVcoinBalance(
        //     data.amount,
        //     data.notification_default_text || data.message,
        //   ),
        // );
      }

      if (data.type === 'TrackFeedbackRequested') {
        store.dispatch(feedbackRequested(data.track_id));
      }

      if (isNotificationByLabel(data)) {
        store.dispatch(incrementLabelNotificationsCounter());
      } else {
        store.dispatch(incrementNotificationsCounter());
      }
    },
  );

  socket.subscribe(onlineChannelName);
};

export const stopPusher = (store: StoreType, socket?: PusherType) => {
  const { id: userId } = store.getState().user;

  if (!userId) return;

  console.log('Unsubscribing pusher');

  socket?.unsubscribe(`presence-user.${userId}`);
  socket?.unsubscribe(`private-App.User.${userId}`);
  socket?.unsubscribe(`presence-user-online.${userId}`);
};

export const usePusher = (
  isClientside = true,
  createSocket: (config: Config) => PusherType,
) => {
  const store: any = useStore();
  const { token } = store.getState().authentication;

  const addReply = useQueryAddReply();
  const queryClient = useQueryClient();
  const { refetch: refetchUnreadMessagesCount } = useQueryUnreadMessagesCount();
  const { refetch: refetchBalance } = useBalance();
  const { refetch: refetchCurrentUser } = useCurrentUser();
  const [pusherSocket, setSocket] = useState<PusherType>();

  useEffect(() => {
    if (token && isClientside) {
      const socket = createSocket({
        cluster: 'eu',
        forceTLS: true,
        authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
        auth: { headers: { Authorization: token } },
      });
      setSocket(socket);
      _socket = socket;

      console.debug('Created new pusher socket');

      const { id: userId } = store.getState().user;

      if (!userId || !token) return;

      /**
       * Handle all chat pusher events
       */
      const conversationsChannelName = `presence-user.${userId}`;
      const conversationsChannel = socket.subscribe(conversationsChannelName);

      // Add the conversation when its added to the queryClient
      conversationsChannel.bind(
        conversationCreatedEvent,
        (conversation: Conversation) => {
          queryClient.setQueryData(
            [chatQueryIdentifier, conversation.id],
            conversation,
          );
          refetchUnreadMessagesCount();
        },
      );

      // Add the reply to the queryClient when its received from pusher
      conversationsChannel.bind(
        conversationReplyCreatedEvent,
        (reply: Reply) => {
          addReply(reply.conversation_id, reply);
          refetchUnreadMessagesCount();
        },
      );

      /**
       * TODO: Handle all notification pusher events
       */

      const notificationsChannelName = `private-App.User.${userId}`;
      const notificationsChannel = socket.subscribe(notificationsChannelName);

      notificationsChannel.bind(
        echoEventName,
        async (pusherNote: PusherNotification) => {
          // const notification = API.noti;

          // await store.dispatch(fetchNotificationsData([notification]));
          // store.dispatch(pusherNotificationReceived(notification));

          // if (pusherNote.type === 'ConversationMessageReceived') {
          //   refetchUnreadMessagesCount();
          // }

          if (pusherNote.type === 'PaymentConfirmation') {
            refetchCurrentUser();
          }

          if (pusherNote.type === 'VcoinsTransaction') {
            refetchBalance();
          }

          // if (data.type === 'TrackFeedbackRequested') {
          //   store.dispatch(feedbackRequested(data.track_id));
          // }

          // if (isNotificationByLabel(data)) {
          //   store.dispatch(incrementLabelNotificationsCounter());
          // } else {
          //   store.dispatch(incrementNotificationsCounter());
          // }
        },
      );

      const onlineChannelName = `presence-user-online.${userId}`;
      socket.subscribe(onlineChannelName);

      setupPusher(store, socket);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (isClientside) {
        stopPusher(store, pusherSocket);
      }
    };
  }, [token, isClientside]);
};
