import React from 'react';
import ReactGA from 'react-ga';
import { withRouter, NextRouter } from 'next/router';

const pagesToMonitor = [
  /^\/$/,
  /^\/profile\/\d+$/,
  /^\/profile$/,
  /^\/explore$/,
  /^\/charts$/,
  /^\/labels$/,
  /^\/profile$/,
  /^\/upload$/,
  /^\/chat$/,
  /^\/chat\/\d+$/,
  /^\/notifications$/,
  /^\/tracks\/\d+\/.*\/.*$/,
  /^\/search$/,
  /^\/thank-you$/,
];

const checkEnv = (env: string) => env === process.env.REACT_APP_ENVIRONMENT;

// ! FOR TESTING PURPOSES ONLY
// eslint-disable-next-line
// const checkEnv = (env: string) => env === env;

export const initGA = (options = {}) => {
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  const isGAEnabled = checkEnv('production');

  if (isGAEnabled && gaId) {
    ReactGA.initialize(gaId, options);
  }

  return isGAEnabled;
};

const pageViewGA = (page: string) => {
  if (checkEnv('production')) ReactGA.pageview(page);
};

export const fireEventGA = (payload: ReactGA.EventArgs) => {
  if (checkEnv('production')) ReactGA.event(payload);
};

class GARouteTracker extends React.Component<{ router: NextRouter }> {
  componentDidMount() {
    if (!checkEnv('production')) return;

    const { router } = this.props;
    if (router) {
      const { pathname } = router;
      this.logPageView(pathname);
    }
  }

  componentDidUpdate({
    router: { pathname: prevPathName },
  }: {
    router: NextRouter;
  }) {
    if (!checkEnv('production')) return;

    const {
      router: { pathname },
    } = this.props;

    const isDifferentPathname =
      !this.chatPageNotChanged(pathname, prevPathName) &&
      pathname !== prevPathName;

    if (isDifferentPathname) {
      this.logPageView(pathname);
    }
  }

  logPageView = (pathname: string) => {
    // thank-you page visits only count after actual registration
    if (pathname === '/thank-you') {
      const {
        router: { query },
      } = this.props;

      if (!query || !query.redirected) {
        return;
      }
    }

    if (pagesToMonitor.some((rgx) => pathname.match(rgx))) {
      pageViewGA(pathname);
    }
  };

  // needed to prevent firing page view events on route transitions within the chat subset of pages
  chatPageNotChanged = (pathname: string, prevPathName: string) => {
    const chatCheckArray = ['/chat'];
    return (
      chatCheckArray.some((chatPath) => pathname.startsWith(chatPath)) &&
      chatCheckArray.some((chatPath) => prevPathName.startsWith(chatPath))
    );
  };

  render() {
    return null;
  }
}

export const addToDataLayerGA = (payload: unknown) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(payload);
};

export const GARouteTrackerRoute = withRouter(GARouteTracker);
