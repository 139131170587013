import React from 'react';
import styled from 'styled-components';
import { Track } from '../../api';
import { TrackLink } from './Link';

const Image = styled.img<{ size?: number }>`
  height: ${(p) => p.size || 42}px;
  width: ${(p) => p.size || 42}px;
`;

export default Image;

export const TrackImageLink = styled(
  ({
    track,
    size,
    className,
    trackCover,
  }: {
    track: Pick<Track, 'id' | 'track_img'>;
    size?: number;
    className?: string;
    trackCover?: string;
  }) => (
    <TrackLink trackId={track.id} className={className}>
      <Image src={track !== null ? track.track_img : trackCover} size={size} />
    </TrackLink>
  ),
)`
  display: flex;
  align-items: center;
`;
