export const pagesWithGreyBackground = [
  '/upload-landing',
  '/music-distribution',
  '/become-pro',
  '/distribution-landing/ad-landing',
  '/my-tracks',
  '/music-knowledge-hub',
  '/music-knowledge-hub/community/[slug]',
  '/music-knowledge-hub/[slug]',
];
