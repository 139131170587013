import { RootState } from '../reducers';
import { getAllPaginatedItems } from './index';
import { Pagination } from '../../lib/api/Pagination';

export const selectUserTracks = (userId?: number) => ({
  tracks,
}: RootState) => {
  if (!userId) return [];
  const trackIds = tracks.userTracks[userId];

  if (!trackIds) return [];

  return getAllPaginatedItems(trackIds, tracks.entries);
};

export const selectAllMyTracksSimple = ({ user }: RootState) => user.allTracks;

export const selectMyTracks = ({ user, tracks }: RootState) => {
  if (!user.id) return [];

  return getAllPaginatedItems(tracks.userTracks[user.id], tracks.entries);
};

export const selectMyTracksPagination = ({
  tracks,
  user,
}: RootState): Pagination | undefined => {
  if (!user.id) return undefined;

  return tracks.userTracks[user.id]?.pagination;
};

export const selectLikedTracks = (userId?: number) => ({
  tracks,
}: RootState) => {
  if (!userId) return [];
  const trackIds = tracks.likedTracks;

  if (!trackIds) return [];

  return getAllPaginatedItems(trackIds, tracks.entries);
};

export const selectSimilarTracks = (trackId: number) => (state: RootState) => {
  const trackIds = state.tracks.relatedTracksIds[trackId];

  if (!trackIds) return [];

  return trackIds.map((id) => state.tracks.entries[id]);
};

export const selectTracksRandom = ({ tracks }: RootState) => {
  const trackIds = tracks.filteredRandom;

  return getAllPaginatedItems(trackIds, tracks.entries);
};

export const selectTracksRandomPagination = ({ tracks }: RootState) =>
  tracks.filteredRandom.pagination;

export const selectTrack = (trackId?: number) => ({ tracks }: RootState) =>
  trackId ? tracks.entries[trackId] : undefined;
