import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import * as playlistActions from '../actions/playlists';
import { PlaylistResponse, PlaylistBase } from '../../lib/api/playlists';
import PaginatedEntries from '../PaginatedEntries';
import { Pagination } from '../../lib/api/Pagination';
import deleteItemFromObj from '../../lib/utils';
import toArray from '../../utils/toArray';

export interface StatePlaylist extends PlaylistBase {
  tracks: number[];
  user: number;
}

export interface State {
  isRequestingUserPlaylists: boolean;
  userPlaylists: PaginatedEntries;
  allMyPlaylists: PlaylistResponse[];
  errorUserPlaylists: string;
  isFetchingPlaylist: Record<number, boolean | undefined>;
  hasFetchedPlaylist: boolean;
  errorFetchPlaylist?: string;
  isAddingTrack: boolean;
  errorAddTrack?: string;
  isRemovingTrack: boolean;
  errorRemoveTrack?: string;
  isCreatingPlaylist: boolean;
  errorCreatePlaylist?: string;
  isLikingPlaylist: boolean;
  errorLikingPlaylist?: string;
  isUnlikingPlaylist: boolean;
  errorUnlikingPlaylist?: string;
  isRemovingPlaylist: boolean;
  errorRemovingPlaylist?: string;
  isUpdatingPlaylist: boolean;
  errorUpdatePlaylist?: string;
  entries: Record<number, StatePlaylist>;
  linkId: number;
  editMode: boolean;
  queue: number[];
}

export const defaultState: State = {
  isRequestingUserPlaylists: false,
  userPlaylists: {
    data: {},
  },
  allMyPlaylists: [],
  errorUserPlaylists: '',
  isFetchingPlaylist: {},
  hasFetchedPlaylist: false,
  errorFetchPlaylist: undefined,
  isAddingTrack: false,
  errorAddTrack: undefined,
  isRemovingTrack: false,
  errorRemoveTrack: undefined,
  isCreatingPlaylist: false,
  errorCreatePlaylist: undefined,
  isLikingPlaylist: false,
  errorLikingPlaylist: undefined,
  isUnlikingPlaylist: false,
  errorUnlikingPlaylist: undefined,
  isRemovingPlaylist: false,
  errorRemovingPlaylist: undefined,
  isUpdatingPlaylist: false,
  errorUpdatePlaylist: undefined,
  entries: {},
  linkId: 0,
  editMode: false,
  queue: [],
};
export default createReducer<State>(defaultState, {
  [playlistActions.requestGetPlaylist.type]: (
    state,
    { payload: playlistIds }: PayloadAction<number[]>,
  ) => {
    playlistIds.forEach((id) => {
      state.isFetchingPlaylist[id] = true;
    });
  },
  [playlistActions.receiveGetPlaylist.type]: (
    state,
    { payload: response }: PayloadAction<PlaylistResponse[] | PlaylistResponse>,
  ) => {
    const playlists = toArray(response);

    playlists.forEach((playlist) => {
      state.entries[playlist.id] = {
        ...playlist,
        tracks: playlist.tracks.map((track) => track.id),
        user: playlist.user.id,
      };

      state.isFetchingPlaylist[playlist.id] = false;
    });
  },
  [playlistActions.requestAddTrack.type]: (state) => {
    state.isAddingTrack = true;
  },
  [playlistActions.receiveAddTrack.type]: (state) => {
    state.isAddingTrack = false;
  },
  [playlistActions.requestRemoveTrack.type]: (state) => {
    state.isRemovingTrack = true;
  },
  [playlistActions.receiveRemoveTrack.type]: (
    state,
    { payload }: PayloadAction<{ playlistId: number; trackId: number }>,
  ) => {
    state.isRemovingTrack = false;
    state.entries[payload.playlistId] = {
      ...state.entries[payload.playlistId],
      tracks: state.entries[payload.playlistId].tracks.filter(
        (track) => track !== payload.trackId,
      ),
    };
  },
  [playlistActions.errorAddTrack.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isRemovingTrack = false;
    state.errorRemoveTrack = payload;
  },
  [playlistActions.requestCreatePlaylist.type]: (state) => {
    state.isCreatingPlaylist = true;
  },
  [playlistActions.receiveCreatePlaylist.type]: (
    state,
    { payload }: PayloadAction<PlaylistResponse>,
  ) => {
    state.isCreatingPlaylist = false;
    if (state.userPlaylists.data[1])
      state.userPlaylists.data[1].unshift(payload.id);
    else state.userPlaylists.data[1] = [payload.id];
    state.entries[payload.id] = {
      ...payload,
      tracks: payload.tracks.map((track) => track.id),
      user: payload.user.id,
      total_likes: 0,
    };
  },
  [playlistActions.errorCreatePlaylist.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isCreatingPlaylist = false;
    state.errorCreatePlaylist = payload;
  },
  [playlistActions.requestRemovePlaylist.type]: (state) => {
    state.isRemovingPlaylist = true;
  },
  [playlistActions.receiveRemovePlaylist.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.isRemovingPlaylist = false;
    state.userPlaylists.data = deleteItemFromObj(
      state.userPlaylists.data,
      payload,
    );
    delete state.entries[payload];
  },
  [playlistActions.errorRemovePlaylist.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isRemovingPlaylist = false;
    state.errorCreatePlaylist = payload;
  },
  [playlistActions.requestLikePlaylist.type]: (state) => {
    state.isLikingPlaylist = true;
  },
  [playlistActions.receiveLikePlaylist.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.isLikingPlaylist = false;
    state.entries[payload].total_likes += 1;
    state.entries[payload].user_liked_playlist = true;
  },
  [playlistActions.errorLikePlaylist.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isLikingPlaylist = false;
    state.errorLikingPlaylist = payload;
  },
  [playlistActions.requestUnlikePlaylist.type]: (state) => {
    state.isUnlikingPlaylist = true;
  },
  [playlistActions.receiveUnlikePlaylist.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.isUnlikingPlaylist = false;
    state.entries[payload].total_likes -= 1;
    state.entries[payload].user_liked_playlist = false;
  },
  [playlistActions.errorUnlikePlaylist.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isUnlikingPlaylist = false;
    state.errorUnlikingPlaylist = payload;
  },
  [playlistActions.addPlaylistLinkId.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.linkId = payload;
  },
  [playlistActions.toggleEditMode.type]: (
    state,
    { payload }: PayloadAction<boolean>,
  ) => {
    state.editMode = payload;
  },
  [playlistActions.requestUpdatePlaylist.type]: (state) => {
    state.isUpdatingPlaylist = true;
  },
  [playlistActions.receiveUpdatePlaylist.type]: (state) => {
    state.isUpdatingPlaylist = false;
    state.errorUpdatePlaylist = undefined;
  },
  [playlistActions.errorUpdatePlaylist.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isUpdatingPlaylist = false;
    state.errorUpdatePlaylist = payload;
  },
  [playlistActions.addToQueue.type]: (
    { queue },
    { payload }: PayloadAction<number>,
  ) => {
    queue.push(payload);
  },
  [playlistActions.removeFromQueue.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.queue = state.queue.filter((id) => id !== payload);
  },
  [playlistActions.requestUserPlaylists.type]: (state) => {
    state.isRequestingUserPlaylists = true;
  },
  [playlistActions.recieveUserPlaylists.type]: (
    state,
    {
      payload,
    }: PayloadAction<{
      playlists: PlaylistResponse[];
      pagination: Pagination;
    }>,
  ) => {
    const { playlists, pagination } = payload;
    state.isRequestingUserPlaylists = false;
    state.userPlaylists.data[pagination.currentPage] = playlists.map(
      (playlist) => playlist.id,
    );
    state.userPlaylists.pagination = pagination;
  },
  [playlistActions.errorUserPlaylists.type]: (
    state,
    { payload }: PayloadAction<string>,
  ) => {
    state.isRequestingUserPlaylists = false;
    state.errorUserPlaylists = payload;
  },
  [playlistActions.resetPagination.type]: (state) => {
    state.userPlaylists.data = {};
  },

  [playlistActions.receiveAllMyPlaylists.type]: (
    state,
    { payload }: PayloadAction<PlaylistResponse[]>,
  ) => {
    state.allMyPlaylists = payload;
  },
});
