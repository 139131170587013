import axios from './axios';
import ResponseWrapper from './ResponseWrapper';

export interface AiExpertChatDataType {
  id: number;
  name: string;
  prompt: string;
  messages: AiExpertChatMessagesType[];
}
export interface AiExpertChatMessagesType {
  content: string;
  role: string;
  timestamp?: number;
}
export const askQuestionToMusicAiExpert = async (
  question: string,
  prompt: string,
  chat_id?: number,
) =>
  axios
    .post<ResponseWrapper<{ id: number; message: AiExpertChatMessagesType }>>(
      `question`,
      {
        question,
        prompt,
        chat_id,
      },
    )
    .then(({ data }) => data.data);

export const getAiExpertChatHistoryListData = async (prompt: string) =>
  axios
    .get<ResponseWrapper<AiExpertChatDataType[]>>('chat-history', {
      params: { prompt },
    })
    .then(({ data }) => data.data);

export const getAiExpertChatHistoryById = async (id: number) =>
  axios
    .get<ResponseWrapper<AiExpertChatDataType>>(`chat-history/${id}`)
    .then(({ data }) => data.data);

export const deleteAiExpertChatHistory = async (id: number) =>
  axios
    .delete<ResponseWrapper<AiExpertChatDataType>>(`chat-history/${id}`)
    .then(({ data }) => data.data);

export const editAiExpertChatHistoryName = async (id: number, name: string) =>
  axios
    .patch<ResponseWrapper<AiExpertChatDataType>>(`chat-history/${id}`, {
      name,
    })
    .then(({ data }) => data.data);
