import React from 'react';
import { CustomInput } from 'reactstrap';

export const VuexyCustomInput = ({
  children,
  ...props
}: VuexyCustomInputProps) => <CustomInput {...props}>{children}</CustomInput>;

interface VuexyCustomInputProps {
  className?: string;
  id: string | number;
  type: string;
  label?: string;
  inline?: boolean;
  valid?: boolean;
  invalid?: boolean;
  bsSize?: string;
  cssModule?: Record<string, unknown>;
  checked?: boolean | JSX.Element;
  onChange?: unknown | (() => void);
  onClick?: unknown | (() => void);
  children?: JSX.Element | Node | Array<string> | (() => void);
  innerRef?: string | Record<string, unknown> | (() => void);
  name?: string;
  value?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
}

export default VuexyCustomInput;
