import { connect } from 'react-redux';

const UnconnectedLogged = ({ userIsLoggedIn, loggedIn, loggedOut }) =>
  // eslint-disable-next-line no-nested-ternary
  userIsLoggedIn ? loggedIn : loggedOut;

export const Logged = connect(({ authentication }) => ({
  userIsLoggedIn: authentication.isLoggedIn,
  checkedLogin: authentication.hasCheckedInitialLogin,
}))(UnconnectedLogged);

const UnconnectedLoggedIn = ({
  userIsLoggedIn,
  loadingAnimation = null,
  children,
}) => (userIsLoggedIn && children) || loadingAnimation;

export const LoggedIn = connect(({ authentication }) => ({
  userIsLoggedIn: authentication.isLoggedIn,
  checkedLogin: authentication.hasCheckedInitialLogin,
}))(UnconnectedLoggedIn);

const UnconnectedLoggedOut = ({
  userIsLoggedIn,
  loadingAnimation = null,
  children,
}) => (!userIsLoggedIn && children) || loadingAnimation;

export const LoggedOut = connect(({ authentication }) => ({
  userIsLoggedIn: authentication.isLoggedIn,
  checkedLogin: authentication.hasCheckedInitialLogin,
}))(UnconnectedLoggedOut);
