/*
    This is a temporary enum.
    Refactor later.
*/

const usePlanRulesEnum = () => {
  const UPLOAD_LIMIT_DEMOS = 'all_for_free';
  const COMMUNITY_SUPPORT = 'join_exclusive_contests';
  const PRO_BADGE = 'request_feedback';
  const EXTENDED_STATISTICS = 'customizable_profile';
  const UNLIMITED_GLOBAL_DISTRIBUTION = 'unlimited_global_distribution';
  const SEE_LABEL_REACH = 'monetize_your_music';
  const MONTHLY_VCOINS = 'full_revenue';
  const JOIN_EXCLUSIVE_CONTESTS = 'full_royalties';
  const SALES_PERFORMANCE_DATA = 'promotion_of_your_music';
  const SELECTED_FOR_INDEPENDENT_RELEASE = 'one_day_response';
  const CUSTOM_LABEL_NAME = 'split_pay';
  const SCHEDULE_RELEASE_DATE = 'pre_save_link';
  const SPOTIFY_VERIFIED_CHECKMARK = 'spotify_verified_checkmark';
  const PRIORITY_CUSTOMER_CARE = 'licensing_deals';
  const MONTHLY_PAYOUT = 'custom_label_name';
  const ADD_MULTIPLE_ARTISTS = 'add_multiple_artists';
  const LIVE_STATISTICS = 'live_statistics_and_sales_report';
  const LIMITED_GLOBAL_DISTRIBUTION = 'limited_global_distribution';

  return {
    UPLOAD_LIMIT_DEMOS,
    COMMUNITY_SUPPORT,
    PRO_BADGE,
    EXTENDED_STATISTICS,
    UNLIMITED_GLOBAL_DISTRIBUTION,
    SEE_LABEL_REACH,
    MONTHLY_VCOINS,
    JOIN_EXCLUSIVE_CONTESTS,
    SALES_PERFORMANCE_DATA,
    SELECTED_FOR_INDEPENDENT_RELEASE,
    CUSTOM_LABEL_NAME,
    SCHEDULE_RELEASE_DATE,
    SPOTIFY_VERIFIED_CHECKMARK,
    PRIORITY_CUSTOMER_CARE,
    MONTHLY_PAYOUT,
    ADD_MULTIPLE_ARTISTS,
    LIVE_STATISTICS,
    LIMITED_GLOBAL_DISTRIBUTION,
  };
};

export default usePlanRulesEnum;
