import React from 'react';
import styled from 'styled-components';
import { LinkProps } from 'next/link';
import { Raffle, style } from '../../../api';
import { Link as ClassNameLink } from '../Miscellanea';

const Link = styled(ClassNameLink)`
  color: ${style.pink};
  text-decoration: none;
  font-weight: 700;
`;

export default Link;

export const InnerLink = styled(ClassNameLink)`
  color: ${style.dark};
  text-decoration: none;
`;

type Props = {
  children?: React.ReactChild;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
} & Omit<LinkProps, 'as' | 'href'>;

export const TrackLink = ({
  trackId,
  children,
  className,
  ...props
}: { trackId: number } & Props) => (
  <InnerLink
    {...props}
    href="/tracks/[trackId]"
    linkAs={`/tracks/${trackId}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const ProfileLink = ({
  profileId,
  children,
  className,
  ...props
}: { profileId: unknown } & Props) => (
  <InnerLink
    {...props}
    href="/profile/[profileId]"
    linkAs={`/profile/${profileId}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const EditTrackLink = ({
  trackId,
  children,
  className,
  ...props
}: { trackId: number } & Props) => (
  <InnerLink
    {...props}
    href="/edit-track/[trackId]"
    linkAs={`/edit-track/${trackId}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const ProfileLinkStyled = styled(ProfileLink)`
  color: ${style.black};
  text-decoration: none;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const ConversationLink = ({
  conversationId,
  children,
  className,
  ...props
}: { conversationId: number | 'new' } & Props) => {
  const href =
    conversationId === 'new' ? '/chat?new=true' : `/chat/${conversationId}`;

  return (
    <InnerLink {...props} href={href} className={className}>
      {children}
    </InnerLink>
  );
};

export const TrackReportLink = ({
  trackId,
  children,
  className,
  ...props
}: { trackId: number } & Props) => (
  <InnerLink
    {...props}
    href="/track-report/[trackId]"
    linkAs={`/track-report/${trackId}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const TrackEditLink = ({
  trackId,
  children,
  className,
  ...props
}: { trackId: number } & Props) => (
  <InnerLink
    {...props}
    href="/edit-track/[trackId]"
    linkAs={`/edit-track/${trackId}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const RaffleLink = ({
  raffle,
  children,
  className,
  ...props
}: { raffle?: Raffle } & Props) => {
  if (!raffle) {
    return null;
  }

  const contest = raffle.type !== 'raffle';

  return (
    <InnerLink
      {...props}
      href={`/${contest ? 'contests' : 'raffles'}/[raffle.id]`}
      linkAs={`/${contest ? 'contests' : 'raffles'}/${raffle.id}`}
      className={className}
    >
      {children}
    </InnerLink>
  );
};

export const ExploreLink = ({
  query,
  children,
  className,
  ...props
}: { query: string } & Props) => (
  <InnerLink
    {...props}
    href="/explore[query]"
    linkAs={`/explore${query}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const GenreLink = ({
  genreId,
  children,
  className,
  ...props
}: { genreId: number } & Props) => (
  <InnerLink
    {...props}
    href="/genre/[genreId]"
    linkAs={`/genre/${genreId}`}
    className={className}
  >
    {children}
  </InnerLink>
);

export const FlatButtonLink = styled(Link)`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  color: ${style.grey_dark_new};
  box-shadow: none;
  background-color: ${style.grey_light_2_new};
`;

export const TextButtonLink = styled(Link)`
  padding: 0px 12px 0 12px;
  color: ${style.grey_dark_new};
  font-weight: 400;
`;

export const GreyLink = styled(Link)`
  color: ${style.grey_dark_new};
  text-decoration: underline;
`;

export const MainLink = styled(Link)`
  text-decoration: underline;
`;
