import React, { useEffect } from 'react';

import styled from 'styled-components';
import { style, usePlayer, useLikeTrack, useAuthentication } from '../../api';
import Flex from '../common/Flex';
import TransparentButton from '../common/Buttons/TransparentButton';
import { Heart, HeartBorder, Replay, Next } from '../common/Icons';
import { TextRegular } from '../common/Text';
import PlayerCover from '../PlayerCover';
import Waveform from '../Waveform';
import Box from '../common/Box';
import { useSharedPlayerControls } from './PlayerContext';
import { TrackLink, ProfileLink, GenreLink } from '../common/Link';
import { useIsDesktop } from '../../style/constants';

import useFooterPlayerVisibility from '../../hooks/useFooterPlayerVisibility';

const FooterPlayer = () => {
  const { track, isPlaylist, isEditMode } = usePlayer();
  const isDesktop = useIsDesktop();

  const controls = useSharedPlayerControls();

  const { like, unlike } = useLikeTrack(track?.id);
  const { authenticated } = useAuthentication();

  // Pause player whenever theres no footer player
  useEffect(() => {
    const pause = controls?.pause;

    return pause;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTrackLike = () => {
    if (!track || !authenticated) return;

    if (track.logged_in_user_liked_track) {
      unlike();
    } else {
      like();
    }
  };

  const onSkipOrReplay = () => {
    if (!isPlaylist) {
      return controls?.replay();
    }

    /**
     * Ensure skip event is called if track is played from footer.
     * Consulted with Joey on this issue and he approves it
     */
    if (!track?.id) {
      return undefined;
    }

    return controls?.skip();
  };

  return (
    <>
      <Flex
        id="footer-player"
        width="100%"
        height="64px"
        position="fixed"
        bottom={0}
        background="white"
        zIndex={30}
        boxShadow={isDesktop ? '0px -8px 36px #00000015' : ''}
        alignItems="center"
      >
        <TransparentButton
          order={[4, 0]}
          alignItems="center"
          justifyContent="center"
          borderRight="1px solid"
          borderColor={style.grey_light_1_new}
          flexDirection="column"
          px={[3, 4]}
        >
          <Box size={20} onClick={toggleTrackLike}>
            {track ? (
              <>
                {track?.logged_in_user_liked_track ? (
                  <Heart size="100%" color={style.primary_pink_new} />
                ) : (
                  <HeartBorder size="100%" />
                )}
              </>
            ) : (
              '-'
            )}
          </Box>
          <TextRegular mt={1} fontSize={1} color={style.grey_dark_new}>
            {track?.total_likes}
          </TextRegular>
        </TransparentButton>
        <Flex px={3} alignItems="center" maxWidth="400px" overflow="hidden">
          {track && (
            <PlayerCover
              disableHover
              track={track}
              size="45px"
              playButtonSize="50%"
            />
          )}
          {track && (
            <Flex
              ml={2}
              display={['none', 'flex']}
              flexDirection="column"
              py={2}
              maxWidth="200px"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <span>
                <TrackLink trackId={track.id} prefetch={false}>
                  <TextRegular fontWeight="bold">{track?.title}</TextRegular>
                </TrackLink>
                {track.genre && (
                  <GenreLink genreId={track.genre.id} prefetch={false}>
                    {`, ${track.genre.name}`}
                  </GenreLink>
                )}
              </span>
              <TextRegular fontSize=".9em" mt={1} color={style.grey_dark_new}>
                <Flex alignItems="center">
                  {track.user && (
                    <ProfileLink profileId={track.user.id} prefetch={false}>
                      {track.user.display_name}
                    </ProfileLink>
                  )}
                </Flex>
              </TextRegular>
            </Flex>
          )}
        </Flex>
        <Flex ml={[0, 2]} py={2} width="100%" height="100%">
          {track && (
            <Waveform
              trackId={track?.id}
              full={isEditMode}
              rectanglePadding={0}
              colorActive="rgba(247, 20, 84, 1)"
              colorInactive={style.grey_dark_new}
            />
          )}
        </Flex>
        <PlayerNextButton
          display={['none', 'flex']}
          alignItems="center"
          justifyContent="center"
          borderRight="1px solid"
          borderColor={style.grey_light_1_new}
          flexDirection="column"
          px={4}
          onClick={onSkipOrReplay}
        >
          <Box size={20}>
            {track ? (
              <>{isPlaylist ? <Next size="100%" /> : <Replay size="100%" />}</>
            ) : (
              '-'
            )}
          </Box>
          <TextRegular mt={1} fontSize={1} color={style.grey_dark_new}>
            {isPlaylist ? 'next' : 'replay'}
          </TextRegular>
        </PlayerNextButton>
      </Flex>
    </>
  );
};

const WrappedFooterPlayer = () => {
  const visible = useFooterPlayerVisibility();

  if (!visible) {
    return null;
  }

  return <FooterPlayer />;
};

const PlayerNextButton = styled(TransparentButton)`
  height: 100%;
  transition: 0.2s;
  padding: 1rem 2rem;
  margin-left: 10px;

  :hover {
    background-color: whitesmoke;
  }
`;

export default WrappedFooterPlayer;
