import styled, { css } from 'styled-components';
import {
  compose,
  typography,
  color,
  space,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';
import { media } from 'styled-bootstrap-grid';
import { style } from '../../api';

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: ${style.black};
  font-weight: 700;
  font-size: 52px;
  text-transform: capitalize;
  margin-bottom: 35px;

  ${media.xs`
    font-size: 43px;
  `}
`;

const standardText = css`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.39px;
  display: inline;
`;

export type TextProps = TypographyProps & ColorProps & SpaceProps;
export const Text = styled.div<TextProps>`
  ${standardText}
  margin-bottom: 12px;
  ${compose(typography, color, space)}
`;

export const TextNoMargin = styled.div<TextProps>`
  ${standardText}
  ${compose(typography, color, space)}
`;

const bold = css`
  color: ${style.black};
  font-weight: 600;
  text-decoration: none;
`;

export const Bold = styled.span`
  ${bold}
`;

const red = css`
  color: ${style.pink};
  font-weight: 600;
  text-decoration: none;
  margin-left: 5px;
`;

export const Red = styled.span`
  ${red}
`;

export const NoData = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 24px;
  font-weight: 700;
`;

export const CenteredTitle = styled(Title)`
  text-align: center;
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;

export const Notice = styled.div`
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  padding: 50px 20px 50px 0;
  text-align: center;
`;

export const TextRegular = styled.div<TextProps>`
  font-weight: 400;
  display: inline-block;
  ${compose(typography, color, space)}
`;

export const TextRegular10 = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

export const TextRegular12 = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const TextRegular14 = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const TextRegular16 = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`;

export const TextRegular18 = styled.span`
  font-size: 18px;
  font-weight: 400;
`;

export const TextRegular28 = styled.span`
  font-size: 28px;
  font-weight: 400;
`;

export const TextBold10 = styled.span`
  font-size: 10px;
  font-weight: 500;
`;

export const TextBold12 = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

export const TextBold14 = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const TextBolder14 = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

export const TextBold18 = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

export const TextBold28 = styled.span`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextBold22 = styled.span`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -1px;
`;
export const TextBold21 = styled.span`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextBold24 = styled.span`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextBold36 = styled.span`
  font-size: 36px;
  font-weight: 700;
`;

export const Pink = styled.span`
  color: ${style.primary_pink_new};
`;

export const TextMuted = styled.span`
  color: #1f1f1f;
  opacity: 50%;
  display: inline-block;
  margin-top: 20%;
`;
