import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

const selectErrors = ({ alerts }: RootState) => alerts.errors;
const selectSuccessMessages = ({ alerts }: RootState) => alerts.successMessages;
const selectVcoinMessages = ({ alerts }: RootState) => alerts.vcoinMessages;
const selectCurrentLastError = ({ alerts }: RootState) =>
  alerts.currentLastError;

export const useAlerts = () => {
  const errors = useSelector(selectErrors);
  const successMessages = useSelector(selectSuccessMessages);
  const vcoinMessages = useSelector(selectVcoinMessages);
  const currentError = useSelector(selectCurrentLastError);
  return { errors, successMessages, vcoinMessages, currentError };
};
