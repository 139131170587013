import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { style } from '../api';
import Modal from './common/Modal';
import { ProOutlineButton } from '../VuexyDesign/atoms/_index';

interface Props {
  open: boolean;
  toggle: (state: boolean) => void;
}

const TrackUploadLimitModal = ({
  className,
  open = false,
  toggle,
}: Props & { className?: string }) => {
  const { t } = useTranslation('upload');
  const { push } = useRouter();

  return (
    <Wrapper className={className}>
      <LimitModal isOpened={open} toggle={toggle} width={720}>
        <ModalContent>
          <DemoHeardImage
            src="/static/images/demo-heard.svg"
            alt="Demo Heard"
          />
          <ModalTitle>{t('upload:needsToBeHeard') as string}</ModalTitle>
          <ModalSubTitle>{t('upload:demoLimit') as string}</ModalSubTitle>
          <ModalDescription>
            {t('upload:coulBeNextHit') as string}
          </ModalDescription>
          <div className="d-flex justify-content-center mt-2">
            <ProOutlineButton
              onClick={() => {
                push('/become-pro');
                toggle(false);
              }}
            >
              {t('upload:becomePro') as string}
            </ProOutlineButton>
          </div>
        </ModalContent>
      </LimitModal>
    </Wrapper>
  );
};

export default TrackUploadLimitModal;

const Wrapper = styled.div``;

const LimitModal = styled(Modal)``;

const ModalContent = styled.div`
  padding: 0 10px;
  text-align: center;
`;

const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: ${style.black_new};
  margin-bottom: 10px;
  margin-top: -30px;
`;

const ModalSubTitle = styled.div`
  font-size: 12px;
  color: ${style.grey_dark_new};
  max-width: 280px;
  margin: 0 auto;
`;

const ModalDescription = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${style.black_new};
  margin-top: 15px;
`;

const DemoHeardImage = styled.img`
  max-width: 394px;
  width: 100%;
`;
