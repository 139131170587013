import * as commentsAPI from '../../lib/api/comments';
import { ThunkResult } from './ThunkDispatch';
import commentsReducer from '../reducers/comments';
import { handleError } from './alerts';

const { actions: commentsActions } = commentsReducer;

export const fetchComments = (
  trackId: number,
  page = 1,
  perPage = 20,
): ThunkResult => async (dispatch) => {
  dispatch(commentsActions.requestTrackComments());

  try {
    const { comments, pagination } = await commentsAPI.getTrackComments(
      trackId,
      page,
      perPage,
    );
    dispatch(commentsActions.addCommentEntries(comments));
    dispatch(
      commentsActions.receiveTrackComments({ comments, trackId, pagination }),
    );
  } catch (e: any) {
    dispatch(commentsActions.errorTrackComments(e.message));
  }
};

export const fetchFeedback = (trackId: number): ThunkResult => async (
  dispatch,
) => {
  const feedbackComments = await commentsAPI.getTrackFeedback(trackId);

  dispatch(commentsActions.addCommentEntries(feedbackComments));
  dispatch(commentsActions.receiveFeedback({ feedbackComments, trackId }));
};

export const fetchById = (id: number): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    comments: { comments },
  } = getState();

  if (comments[id] !== undefined) {
    console.debug(
      `[Shared/comments] Comment with id ${id} already in store, not fetching`,
    );
    return;
  }

  dispatch(commentsActions.fetchCommentById());

  try {
    const comment = await commentsAPI.fetchById(id);
    dispatch(commentsActions.addCommentEntries([comment]));
  } catch (e: any) {
    dispatch(commentsActions.errorFetchCommentById(e.message));
  }
};

export const addComment = (
  trackId: number,
  text: string,
): ThunkResult => async (dispatch) => {
  dispatch(commentsActions.addTrackCommentRequest());

  try {
    const { data: comment, status } = await commentsAPI.commentTrack(
      trackId,
      text,
    );

    if (status === 'User must be logged in.') {
      throw new Error(status);
    }

    dispatch(commentsActions.addCommentEntries([comment]));
    dispatch(commentsActions.addTrackCommentSuccess({ trackId, comment }));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};

export const addReply = (
  trackId: number,
  commentId: number,
  text: string,
): ThunkResult => async (dispatch) => {
  dispatch(commentsActions.addCommentReplyRequest());

  try {
    const reply = await commentsAPI.replyToComment(trackId, commentId, text);
    dispatch(commentsActions.addCommentEntries([reply]));
  } catch (e: any) {
    dispatch(commentsActions.errorAddCommentReply(e.message));
  }
};

export const likeComment = (commentId: number): ThunkResult => async (
  dispatch,
) => {
  dispatch(commentsActions.requestCommentLike(commentId));

  try {
    await commentsAPI.likeComment(commentId);
    dispatch(commentsActions.successCommentLike(commentId));
  } catch (e: any) {
    dispatch(commentsActions.errorCommentLike(e.message));
  }
};

export const removeCommentLike = (commentId: number): ThunkResult => async (
  dispatch,
) => {
  dispatch(commentsActions.requestCommentRemoveLike(commentId));

  try {
    await commentsAPI.removeCommentLike(commentId);
    dispatch(commentsActions.successCommentRemoveLike(commentId));
  } catch (e: any) {
    dispatch(commentsActions.errorCommentRemoveLike(e.message));
  }
};

export const deleteComment = (
  commentId: number,
  parent: { parentCommentId?: number; trackId?: number },
): ThunkResult => async (dispatch) => {
  await commentsAPI.deleteComment(commentId);

  dispatch(commentsActions.successCommentDelete({ commentId, ...parent }));
};

export const reportComment = (commentId: number, reason: string) => () =>
  commentsAPI.mobileReportComment(commentId, reason);
