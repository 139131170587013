import AsyncStorage from '@callstack/async-storage';

// TODO probably can be deleted?
const tokenName = 'virpp-token';

export const setToken = async (token: string): Promise<void> =>
  AsyncStorage.setItem(tokenName, token);
export const getToken = async (): Promise<string | undefined> =>
  AsyncStorage.getItem(tokenName);
export const clearToken = async (): Promise<void> =>
  AsyncStorage.removeItem(tokenName);
