import React from 'react';
import DatePicker from 'react-datepicker';

interface PickerDefaultProps {
  handleDatePickerChange: (data: Date) => void;
  selectedDate?: Date;
  format?: string;
  openToDate?: Date;
  minDate?: Date;
  disabled?: boolean;
}
const PickerDefault = ({
  handleDatePickerChange,
  selectedDate,
  format = 'yyyy/MM/dd',
  openToDate,
  minDate,
  disabled,
}: PickerDefaultProps) => (
  <div>
    <DatePicker
      className="text-gray-dark"
      dateFormat={`${format}`}
      selected={selectedDate}
      onChange={(data: Date) => handleDatePickerChange(data)}
      fixedHeight
      openToDate={openToDate}
      minDate={minDate}
      disabled={disabled}
    />
  </div>
);

export default PickerDefault;
