import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import * as followActions from '../actions/followers';
import { Follower } from '../../lib/api/followers';
import { Pagination } from '../../lib/api/Pagination';
import PaginatedEntries from '../PaginatedEntries';
import deleteItemFromObj from '../../lib/utils';

export const FollowTypes = {
  follower: 'FOLLOWER',
  following: 'FOLLOWING',
};

export interface State {
  followers: PaginatedEntries;
  followersMutual: PaginatedEntries;
  following: PaginatedEntries;
  requestingIds: number[];
  followingIds?: number[];
  followersIds: number[];
  entries: Record<number, Follower>;
  isFetchingFollowing: boolean;
  isFetchingFollowers: boolean;
  errorFollowers: string;
  errorFollowing: string;
  isRequestingFollowing: boolean;
  errorFollowUser: string;
  isRequestingUnfollowing: boolean;
  errorUnfollowUser: string;
  requestingFollowingIds: boolean;
  errorFollowingIds?: string;
}

export const defaultState: State = {
  followers: {
    data: {},
  },
  followersMutual: {
    data: {},
  },
  following: {
    data: {},
  },
  requestingIds: [],
  followingIds: [],
  followersIds: [],
  entries: {},
  isFetchingFollowing: true,
  isFetchingFollowers: true,
  errorFollowers: '',
  errorFollowing: '',
  isRequestingFollowing: false,
  errorFollowUser: '',
  isRequestingUnfollowing: false,
  errorUnfollowUser: '',
  requestingFollowingIds: false,
  errorFollowingIds: undefined,
};

export default createReducer<State>(defaultState, {
  [followActions.addFollowEntries.type]: (
    state: State,
    action: PayloadAction<Follower[]>,
  ) => {
    action.payload.forEach((follow) => {
      state.entries[follow.id] = follow;
    });
  },
  [followActions.requestFollowers.type]: (state: State) => {
    state.isFetchingFollowers = true;
  },
  [followActions.receiveFollowers.type]: (
    state: State,
    action: PayloadAction<{ pagination: Pagination; followers: Follower[] }>,
  ) => {
    state.isFetchingFollowers = false;
    const { followers, pagination } = action.payload;
    state.followers.data[pagination.currentPage] = followers.map(
      (follower: Follower) => follower.id,
    );
    state.followers.pagination = pagination;
  },
  [followActions.receiveMutualFollowers.type]: (
    state: State,
    action: PayloadAction<{ pagination: Pagination; followers: Follower[] }>,
  ) => {
    const { followers, pagination } = action.payload;
    state.followersMutual.data[pagination.currentPage] = followers.map(
      (follower: Follower) => follower.id,
    );
    state.followersMutual.pagination = pagination;
  },

  [followActions.errorFollowers.type]: (
    state: State,
    action: PayloadAction<string>,
  ) => {
    state.isFetchingFollowers = false;
    state.errorFollowers = action.payload;
  },
  [followActions.requestFollowing.type]: (state: State) => {
    state.isFetchingFollowing = true;
  },
  [followActions.receiveFollowing.type]: (
    state: State,
    action: PayloadAction<{ pagination: Pagination; following: Follower[] }>,
  ) => {
    state.isFetchingFollowing = false;
    const { following, pagination } = action.payload;
    state.following.data[pagination.currentPage] = following.map(
      (follower: Follower) => follower.id,
    );
    state.following.pagination = pagination;
  },
  [followActions.errorFollowing.type]: (
    state: State,
    action: PayloadAction<string>,
  ) => {
    state.isFetchingFollowing = false;
    state.errorFollowing = action.payload;
  },
  [followActions.requestFollowUser.type]: (state: State) => {
    state.isRequestingFollowing = true;
  },
  [followActions.confirmFollowUser.type]: (
    state: State,
    action: PayloadAction<number>,
  ) => {
    state.isRequestingFollowing = false;

    if (state.followingIds) {
      const followedId = action.payload;
      state.followingIds.push(followedId);
    }
  },
  [followActions.errorFollowUser.type]: (
    state: State,
    action: PayloadAction<string>,
  ) => {
    state.isRequestingFollowing = false;
    state.errorFollowUser = action.payload;
  },
  [followActions.requestUnfollowUser.type]: (state: State) => {
    state.isRequestingUnfollowing = true;
  },
  [followActions.confirmUnfollowUser.type]: (
    state: State,
    action: PayloadAction<number>,
  ) => {
    state.isRequestingUnfollowing = false;

    if (state.followingIds) {
      const unfollowedId = action.payload;
      state.followingIds = state.followingIds.filter(
        (followingId) => followingId !== unfollowedId,
      );
    }
  },
  [followActions.errorUnfollowUser.type]: (
    state: State,
    action: PayloadAction<string>,
  ) => {
    state.isRequestingUnfollowing = false;
    state.errorUnfollowUser = action.payload;
  },
  [followActions.errorUnfollowUser.type]: (
    state: State,
    action: PayloadAction<string>,
  ) => {
    state.isRequestingUnfollowing = false;
    state.errorUnfollowUser = action.payload;
  },
  [followActions.changeFollowingByUserInFollowers.type]: (
    state,
    { payload: { id, flag } }: PayloadAction<{ id: number; flag: boolean }>,
  ) => {
    if (state.entries[id]) state.entries[id].following = flag;
  },
  [followActions.removeFromFollowing.type]: (
    { following },
    { payload }: PayloadAction<number>,
  ) => {
    following.data = deleteItemFromObj(following.data, payload);
  },
  [followActions.removeFromFollowers.type]: (
    { followers },
    { payload }: PayloadAction<number>,
  ) => {
    followers.data = deleteItemFromObj(followers.data, payload);
  },
  [followActions.addToFollowers.type]: (
    state,
    { payload }: PayloadAction<Follower>,
  ) => {
    state.entries[payload.id] = payload;
    if (state.followers.data[1] && state.followers.data[1][0] !== payload.id)
      state.followers.data[1].unshift(payload.id);
  },
  [followActions.resetPagination.type]: (
    state,
    action: PayloadAction<string>,
  ) => {
    switch (action.payload) {
      case FollowTypes.follower:
        state.followers.data = {};
        break;
      case FollowTypes.following:
        state.following.data = {};
        break;
      default:
        break;
    }
  },
  [followActions.addRequestingId.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.requestingIds.push(payload);
  },
  [followActions.removeRequestingId.type]: (
    state,
    { payload }: PayloadAction<number>,
  ) => {
    state.requestingIds = state.requestingIds.filter((id) => id !== payload);
  },

  [followActions.requestFollowingIds.type]: (state: State) => {
    state.requestingFollowingIds = true;
  },
  [followActions.confirmFollowingIds.type]: (
    state: State,
    { payload }: PayloadAction<number[]>,
  ) => {
    state.requestingFollowingIds = false;
    state.followingIds = payload;
  },
  [followActions.errorFollowingIds.type]: (
    state: State,
    { payload }: PayloadAction<string>,
  ) => {
    state.requestingFollowingIds = false;
    state.errorFollowingIds = payload;
  },

  [followActions.followersIdsReceived.type]: (
    state: State,
    { payload }: PayloadAction<number[]>,
  ) => {
    state.followersIds = payload;
  },
});
