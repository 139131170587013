import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { RootState, Dispatch, chatActions } from '../api';
import ChatMenu, {
  StateProps,
  DispatchProps,
  OwnProps,
} from '../components/Header/UserMenu/ChatMenu';

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  ({ chat, user: { id } }) => ({
    userId: id,
    conversations: sortBy(
      chat.conversations,
      (x) => x.last_reply_timestamp,
    ).reverse(),
    fetching: chat.fetchingConversation,
  }),
  (dispatch: Dispatch) => ({
    fetchConversations: () => dispatch(chatActions.fetchConversations()),
  }),
)(ChatMenu);
