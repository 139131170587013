import React from 'react';
import { Progress } from 'reactstrap';

function ProgressBar({
  striped,
  animated,
  value,
  labeled,
  color,
}: ProgressBarProps) {
  return (
    <>
      {labeled ? (
        <Progress
          striped={striped}
          animated={animated}
          value={value}
          color={color}
        >
          {value}%
        </Progress>
      ) : (
        <Progress
          striped={striped}
          animated={animated}
          value={value}
          color={color}
        />
      )}
    </>
  );
}

export default ProgressBar;

interface ProgressBarProps {
  value: number;
  striped?: boolean;
  animated?: boolean;
  labeled?: boolean;
  color?: string;
}
