import axios from './axios';
import { ResponseWrapperPaginated } from './ResponseWrapper';
import { ResponseWrapper } from '../../index';

interface SearchItem<K, T> {
  type: K;
  result: T;
}

export interface Collaborator {
  id: number;
  username: string;
}

type SearchTrack = SearchItem<
  'track',
  {
    id: number;
    title: string;
    image: string;
    subTitle: string;
    artists: Collaborator[];
  }
>;

type SearchUser = SearchItem<
  'user',
  {
    id: number;
    title: string;
    subTitle: string;
    image: string;
  }
>;

type SearchRaffle = SearchItem<
  'raffle',
  {
    id: number;
    image: string;
    title: string;
  }
>;

type SearchGenre = SearchItem<
  'genre',
  {
    id: number;
    image: string;
    title: string;
  }
>;

type SearchContest = SearchItem<
  'contest',
  {
    id: number;
    image: string;
    title: string;
  }
>;

export type SearchResult =
  | SearchTrack
  | SearchUser
  | SearchRaffle
  | SearchGenre
  | SearchContest;

// eslint-disable-next-line import/prefer-default-export
export const search = async (keyword?: string, page = 1) =>
  axios
    .get<ResponseWrapperPaginated<SearchResult[]>>('/search', {
      params: { keyword, page },
    })
    .then(({ data }) => data);

export interface SearchKeyword {
  keyword: string;
  total?: number;
  isRecent?: boolean;
}

export const getSearchesPopular = async () =>
  axios
    .get<ResponseWrapper<SearchKeyword[]>>('/searches')
    .then(({ data }) => data.data);

export const getSearchesRecent = async (userId: number) =>
  axios
    .get<ResponseWrapper<SearchKeyword[]>>(`/searches/${userId}`)
    .then(({ data }) => data.data);
