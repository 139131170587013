import axios from './axios';
import { ResponseWrapperPaginated } from './ResponseWrapper';
import { User } from './user';

export const fetch = async (page = 1, keyword?: string) =>
  axios
    .get<ResponseWrapperPaginated<User[]>>('/labels', {
      params: { page, keyword },
    })
    .then(({ data }) => ({
      labels: data.data,
      pagination: data.pagination,
    }));
