import React from 'react';

import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import loadNamespaces from 'next-translate/loadNamespaces';
import { style } from '../api';
import { Button } from '../VuexyDesign/atoms/_index';

import { InnerLink } from '../components/common/Link';
import { VirpPageContext } from './_app';

const NotFound = () => {
  const { t } = useTranslation('common');

  return (
    <StyledContainer>
      <Row>
        <Col>
          <Title>{t('common:error.error1') as string}</Title>
          <Msg>{t('common:error.error2') as string}</Msg>
          <InnerLink href="/">
            <Button color="primary" className="br-5">
              {t('chat:home') as string}
            </Button>
          </InnerLink>
        </Col>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  ${Col} {
    padding: 50px 0px 0px 0px;
    justify-content: center;
    text-align: center;
  }

  button {
    margin: auto;
  }
`;

const Title = styled.div`
  color: ${style.black};
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 25px;
  ${media.xs`
    font-size: 37px;
    margin-bottom: 14px;
  `}
`;
const Msg = styled.div`
  color: ${style.black};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
  ${media.xs`
      font-size: 14px;
    `}
`;

NotFound.getInitialProps = async (ctx: VirpPageContext) => {
  const getTranslations = await loadNamespaces({
    ...ctx,
    pathname: '/_error',
  });
  return { ...getTranslations };
};

export default NotFound;
