import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { ArrowRight } from 'react-feather';
import { useStore } from 'react-redux';
import {
  TextBolder24,
  TextRegular12,
  TextRegular14,
} from '../VuexyDesign/atoms/Text';

import { Button, Checkbox, Input } from '../VuexyDesign/atoms/_index';

import {
  API,
  authenticationActions,
  StoreType,
  useCurrentUser,
  useDispatch,
  useSetDataLayerAutoLoginFlagSessionStorage,
} from '../api';

import { useIsDesktop } from '../style/constants';
import { DividerWithText } from './common/Dividers';
import { usersIndex } from '../utils/search';
import { login } from '../utils/auth';
import { registerPusher } from '../utils/registerPusher';
import GoogleLoginHandler from './common/SocialLogin/GoogleLoginHandler';
import { TextBold14 } from './common/Text';
import ResendVerificationEmail from './ResendVerificationEmail';
import { getPlanNamesFromPlanTypes } from '../utils/getPlanNamesFromPlanTypes';
import { addToDataLayerGA } from '../utils/googleAnalytics';

interface AuthenticationPopupDialogBodyProps {
  closeDialog: () => void;
  setIsNewUser?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AuthenticationPopupDialogBody({
  closeDialog,
  setIsNewUser,
}: AuthenticationPopupDialogBodyProps) {
  const currentUser = useCurrentUser();
  const isDesktop = useIsDesktop();
  const store = useStore() as StoreType;
  const dispatch = useDispatch();
  const flagForAutoLoginDataLayerSent = useSetDataLayerAutoLoginFlagSessionStorage();
  const { t } = useTranslation('register');

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [registerError, setRegisterError] = useState();

  const [username, setUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(undefined);
  const [emailError, setEmailError] = useState<string>();
  const [showUnverifiedBtn, setShowUnverifiedBtn] = useState(false);
  const [lastSubmittedEmail, setLastSubmittedEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(true);

  const cb = (token: string) => {
    setRegisterError(undefined);
    dispatch(authenticationActions.verifyLoginOnStartup(token));
  };

  const registerValidate = async () => {
    let emailValid = false;

    if (email.length > 0) {
      await usersIndex
        .search(email, {
          attributesToRetrieve: ['email'],
          restrictSearchableAttributes: ['email'],
          filters: `email:"${email}"`,
        })
        .then((result) => {
          if (result.nbHits > 0) {
            setEmailError(t('register:errors.emailTaken') as string);
          } else {
            emailValid = true;
            setEmailError(undefined);
          }
        });
    }

    setIsValid(
      emailValid &&
        fullName.length > 0 &&
        password.length > 0 &&
        hasAcceptedTerms,
    );
  };

  const handleFormSwitch = (isRegister: boolean) => {
    setIsRegisterForm(isRegister);

    setFullName('');
    setEmail('');
    setPassword('');
    setHasAcceptedTerms(false);

    setUsername('');
    setLoginPassword('');
    setKeepLoggedIn(false);
  };

  const finalizeLogin = async (token: string, new_user?: boolean) => {
    await login({ token, cb });
    await currentUser.refetch().then((user) => {
      if (user.data) {
        addToDataLayerGA({
          event: 'login',
          userId: user.data.id,
          userType: getPlanNamesFromPlanTypes(user.data.abilities?.plan),
        });
        flagForAutoLoginDataLayerSent.setIsDataLayerSent();
      }
      if (new_user) {
        addToDataLayerGA({
          event: 'artist_sub_free',
          userId: user?.data?.id,
          userType: 'free',
        });
      }
    });
    setLoginError(undefined);
    registerPusher(store);
    closeDialog();
  };

  const onRegister = async () => {
    // eslint-disable-next-line
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      setEmailError('Enter a valid email address');
      return;
    }

    const formData = {
      username: fullName,
      email,
      terms: hasAcceptedTerms,
      password_confirmation: password,
      password,
    };

    API.authentication
      .register({
        ...formData,
      })
      .then(async ({ token }) => {
        if (!token) {
          return;
        }
        if (setIsNewUser) {
          setIsNewUser(true);
        }

        await login({ token, cb });
        /* ------------------------ on register we send a free signup data layer ------------------------ */
        await currentUser.refetch().then((user) => {
          addToDataLayerGA({
            event: 'artist_sub_free',
            userId: user?.data?.id,
            userType: 'free',
          });
        });
        closeDialog();
      })
      .catch((err) => setRegisterError(err.response.data.message));
  };

  const onLogin = async () => {
    setShowUnverifiedBtn(false);
    setLastSubmittedEmail('');
    API.authentication
      .login(username, loginPassword, keepLoggedIn)
      .then(({ headers: { authorization: token } }) => finalizeLogin(token))
      .catch((err) => {
        const errorsFromServer = err?.response?.data.data as
          | Record<string, string | undefined>
          | undefined;
        setLoginError(err.response.data.message);

        // check if user is verified
        if (
          errorsFromServer?.verified !== undefined &&
          !errorsFromServer.verified
        ) {
          setShowUnverifiedBtn(true);
          setLastSubmittedEmail(username);
        }
      });
  };

  const loginValidate = () => {
    setIsValid(username !== '' && loginPassword !== '');
  };

  useEffect(() => {
    registerValidate();
  }, [email, password, fullName, hasAcceptedTerms]);

  useEffect(() => {
    loginValidate();
  }, [username, loginPassword]);

  if (currentUser.id) {
    closeDialog();
  }
  return (
    <div className="w-100 px-5 pb-2 d-flex flex-column align-items-center">
      {isRegisterForm ? (
        <>
          <TextBolder24>
            {t('pricing:yourMusicCareerStartshere') as string} &#128640;
          </TextBolder24>
          <TextRegular14 className="mb-2">
            {t('pricing:nextLevel') as string}
          </TextRegular14>

          <div
            id="social-register"
            className={`d-flex flex-column ${isDesktop ? 'w-100' : ''} `}
          >
            <div id="google-login-social" className="w-100">
              <GoogleLoginHandler
                finalize={({ token, new_user }) => {
                  finalizeLogin(token, new_user);
                }}
                width={isDesktop ? '400px' : '315px'}
                authType="SIGN_UP"
              />
            </div>
          </div>
          <DividerWithText text={t('common:or') as string} className="my-2" />

          <div className="d-flex flex-column w-100">
            <TextRegular14>
              {t('register:placeholders.username') as string}
            </TextRegular14>
            <Input
              type="text"
              name="name"
              id="exampleName"
              placeholder="John Doe"
              className="text-dark"
              value={fullName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFullName(e.target.value);
              }}
            />
          </div>

          <div className="d-flex flex-column w-100 mt-1">
            <TextRegular14>
              {t('register:placeholders.emailAdress') as string}
            </TextRegular14>
            <Input
              type="email"
              name="userEmail"
              id="userEmail"
              placeholder="john.doe@gmail.com"
              className="text-dark"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
              }}
            />
            {emailError ? (
              <TextRegular12 className="text-danger">
                {emailError}
              </TextRegular12>
            ) : (
              <></>
            )}
          </div>

          <div className="d-flex flex-column w-100 mt-1">
            <TextRegular14>
              {t('register:placeholders.password') as string}
            </TextRegular14>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder={`${t('register:placeholders.password') as string}`}
              className="text-dark"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="d-flex align-items-center w-100 mt-1">
            <Checkbox
              id="payment-terms-and-conditions"
              onChange={() => setHasAcceptedTerms((prev) => !prev)}
              defaultChecked={hasAcceptedTerms}
              value={hasAcceptedTerms}
            />
            <TextRegular12>{t('register:agreeTo') as string} </TextRegular12>
            <a
              href="/hello/privacy-statement-virpp/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex align-items-center"
              style={{ marginLeft: '3px' }}
            >
              <TextRegular12>
                {t('register:privacyPolicyAndTerms') as string}
              </TextRegular12>
            </a>
          </div>

          {registerError ? (
            <div className="d-flex align-items-center w-100 mt-1">
              <TextRegular14 className="text-danger">
                {registerError}
              </TextRegular14>
            </div>
          ) : (
            <></>
          )}
          <Button
            color="primary"
            className="w-100 mt-2"
            disabled={!isValid}
            onClick={onRegister}
          >
            <>
              <TextBold14 className="mr-1 w-100">
                {t('register:signUp') as string}
              </TextBold14>
              <ArrowRight size={17} />
            </>
          </Button>

          <div
            className="mt-1 w-100 text-center"
            style={{ fontSize: `${isDesktop ? '16px' : '15px'}` }}
          >
            <span style={{ color: '#4B465C' }}>
              {t('register:alreadyAccount') as string}
            </span>{' '}
            <span
              style={{ color: '#F71454', cursor: 'pointer' }}
              onClick={() => handleFormSwitch(false)}
            >
              {t('register:signInInstead') as string}
            </span>
          </div>
        </>
      ) : (
        <>
          <TextBolder24>
            {t('pricing:welcomeBack') as string} &#129311;
          </TextBolder24>
          <TextRegular14 className="mb-2">
            {t('pricing:loginToGetStarted') as string}
          </TextRegular14>

          <div
            id="social-register"
            className={`d-flex flex-column ${isDesktop ? 'w-100' : ''} `}
          >
            <div id="google-login-social" className="w-100">
              <GoogleLoginHandler
                finalize={({ token }) => {
                  finalizeLogin(token);
                }}
                width={isDesktop ? '400px' : '300px'}
                authType="SIGN_IN"
              />
            </div>
          </div>
          <DividerWithText text={t('common:or') as string} className="my-2" />
          {showUnverifiedBtn && (
            <ResendVerificationEmail
              email={lastSubmittedEmail}
              close={() => setShowUnverifiedBtn(false)}
            />
          )}
          <div className="d-flex flex-column w-100">
            <TextRegular14>
              {t('register:placeholders.emailAdress') as string}
            </TextRegular14>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder={t('register:placeholders.emailAdress') as string}
              className="text-dark"
              value={username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUsername(e.target.value);
              }}
            />
          </div>

          <div className="d-flex flex-column w-100 mt-1">
            <TextRegular14>
              {t('register:placeholders.password') as string}
            </TextRegular14>
            <Input
              type="password"
              name="loginPassword"
              id="loginPassword"
              placeholder={t('register:placeholders.password') as string}
              className="text-dark"
              value={loginPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLoginPassword(e.target.value);
              }}
            />
          </div>
          {loginError ? (
            <div className="d-flex align-items-center w-100 mt-1">
              <TextRegular14 className="text-danger">
                {loginError}
              </TextRegular14>
            </div>
          ) : (
            <></>
          )}

          <div className="d-flex align-items-center w-100 mt-1">
            <Checkbox
              id="keep-me-logged-in"
              onChange={() => setKeepLoggedIn((prev) => !prev)}
              checked={keepLoggedIn}
            />
            <TextRegular12>
              {t('common:keepMeLoggedIn') as string}{' '}
            </TextRegular12>
          </div>
          <Button
            color="primary"
            className="w-100 mt-2"
            disabled={!isValid}
            onClick={onLogin}
          >
            <>
              <TextBold14 className="mr-1 w-100">
                {t('pricing:login') as string}
              </TextBold14>
              <ArrowRight size={17} />
            </>
          </Button>

          <Link href="/reset-password" passHref>
            <div className="w-100 mt-1">
              <Button
                color="secondary"
                outline
                text={t('register:forgotPassword') as string}
                className="w-100"
              />
            </div>
          </Link>

          <div
            className="mt-1 w-100 text-center"
            style={{ fontSize: `${isDesktop ? '16px' : '15px'}` }}
          >
            <span style={{ color: '#4B465C' }}>
              {t('register:noAccount') as string}
            </span>{' '}
            <span
              style={{ color: '#F71454', cursor: 'pointer' }}
              onClick={() => handleFormSwitch(true)}
            >
              {t('register:signupInstead') as string}
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default AuthenticationPopupDialogBody;
