import { useInfiniteQuery } from 'react-query';

import axios from '../../lib/api/axios';

import { Artist } from '../../lib/api/charts';
import { ResponseWrapperPaginated } from '../../lib/api/ResponseWrapper';
import { getEntries, getNextPageParam, getTotal } from '../../utils/pagination';

const getPulledArtists = (id?: number, page?: number) =>
  axios
    .get<ResponseWrapperPaginated<Artist[]>>(`pulls/${id}`, {
      params: { page },
    })
    .then(({ data }) => data);

export const useQueryPulledArtists = (id?: number) =>
  useInfiniteQuery<ResponseWrapperPaginated<Artist[]>, Error>(
    [`pulls/${id}`],
    ({ pageParam }) => getPulledArtists(id, pageParam),
    { enabled: !!id, getNextPageParam },
  );

export const usePulledArtists = (id?: number) => {
  const { data, fetchNextPage, isLoading, hasNextPage } = useQueryPulledArtists(
    id,
  );

  const records = getEntries(data);
  const total = getTotal(data);

  return {
    records,
    total,
    fetchMore: fetchNextPage,
    isLoading,
    canFetchMore: hasNextPage,
  };
};
