import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useCallback } from 'react';
import * as chatAPI from '../../lib/api/chat';
import { chatActions } from '../index';
import { usersIdentifier, useUser } from './users';
import useDispatch from './common/useDispatch';
import { getEntries, getNextPageParam } from '../../utils/pagination';
import {
  useMergeWithPreviousData,
  useUpdateEntriesInfiniteQuery,
} from './common/queryUtils';
import {
  defaultResponsePaginated,
  ResponseWrapperPaginated,
} from '../../lib/api/ResponseWrapper';
import { User } from '../../lib/api/user';
import { useAuthentication } from './authentication';

const identifier = 'chat';

export { identifier as chatQueryIdentifier };

export const useQueryAllConversations = (filter?: chatAPI.ChatFilterType) => {
  const updateChatQueries = useUpdateEntriesInfiniteQuery();
  const { data, ...hook } = useInfiniteQuery(
    [identifier, 'all', filter],
    ({ pageParam }) => chatAPI.getConversations(pageParam, filter),
    {
      getNextPageParam,
      onSuccess: (res) => updateChatQueries(res, (con) => [identifier, con.id]),
    },
  );

  return {
    ...hook,
    data: getEntries(data),
  };
};

export const useQueryConversation = (conversationId?: number) => {
  const queryClient = useQueryClient();
  const key = [identifier, conversationId];
  const merge = useMergeWithPreviousData(key);
  const { refetch: refetchUnreadMessagesCount } = useQueryUnreadMessagesCount();
  const hook = useQuery(
    key,
    () => (conversationId ? chatAPI.fetchSingle(conversationId) : undefined),
    { select: merge },
  );

  const deleteConversation = useMutation(async () => {
    if (conversationId) {
      chatAPI.deleteConversation(conversationId);
    }
  });

  const markAsRead = useMutation(
    async () =>
      conversationId
        ? chatAPI.markConversationRead(conversationId)
        : Promise.resolve(false),
    {
      onSuccess: (markedReadSuccessfully) => {
        if (markedReadSuccessfully && conversationId) {
          queryClient.setQueryData<chatAPI.Conversation | undefined>(
            key,
            (data) =>
              data && {
                ...data,
                total_unread_messages: 0,
              },
          );
          refetchUnreadMessagesCount();
        }
      },
    },
  );

  return {
    ...hook,
    deleteConversation,
    markAsRead,
  };
};

export const useQueryConversationReplies = (conversationId?: number) => {
  const hook = useInfiniteQuery(
    [identifier, conversationId, 'replies'],
    ({ pageParam }) =>
      conversationId
        ? chatAPI.getConversationReplies(conversationId, pageParam as number)
        : defaultResponsePaginated([]),
    {
      getNextPageParam,
    },
  );

  const reportMessage = useMutation(
    ({ messageId, reason }: { messageId: number; reason: string }) =>
      chatAPI.reportMessage(messageId, reason),
  );

  return {
    ...hook,
    data: getEntries(hook.data),
    reportMessage,
  };
};

export const useQuerySendReply = (recipientId?: number) => {
  const { data: recipient } = useUser(recipientId);

  const queryClient = useQueryClient();
  const addReply = useQueryAddReply();

  const sendReply = useCallback(
    async ({
      reply: replyArg,
      feedbackTrackId,
    }: {
      reply?: FormData | string;
      feedbackTrackId?: number;
    }) => {
      if (replyArg === undefined) {
        return;
      }

      let formData: FormData | undefined;

      if (typeof replyArg === 'string') {
        formData = new FormData();
        formData.append('body', replyArg);
      } else {
        formData = replyArg;
      }

      if (!recipient) {
        throw new Error('Cannot send message without recipient');
      }

      const { conversation_id } = recipient;

      if (conversation_id) {
        // If its a feedback reply add the track id
        // There will never be a feedback reply to a non existing conversation
        // because the feedback request will create the conversation
        if (feedbackTrackId) {
          formData.append('track_id', feedbackTrackId.toString());
          formData.append('is_feedback_answer', 'true');
        }

        const createdReply = await chatAPI.createConversationReply(
          formData,
          conversation_id,
        );
        addReply(conversation_id, createdReply);
      } else if (recipientId) {
        // Create new conversation
        formData.append('recipients', recipientId.toString());
        const createdConversation = await chatAPI.createConversation(formData);

        // Update react query
        queryClient.setQueryData(
          [identifier, createdConversation.id],
          createdConversation,
        );

        // Update the conversation id for the recipient
        queryClient.setQueryData<User | undefined>(
          [usersIdentifier, recipientId],
          (data) =>
            data
              ? {
                  ...data,
                  conversation_id: createdConversation.id,
                }
              : undefined,
        );
      }
    },
    [recipient?.id],
  );

  return useMutation(sendReply);
};

export const useSendReply = (recipientId?: number) => {
  const dispatch = useDispatch();
  const { data: recipient } = useUser(recipientId);

  return async (reply?: string | FormData) => {
    if (reply === undefined) {
      return;
    }

    if (!recipient) {
      throw new Error('Cannot send message without recipient');
    }

    const { conversation_id } = recipient;

    let formData: FormData | undefined;
    if (typeof reply === 'string') {
      formData = new FormData();
      formData.append('body', reply);
    } else {
      formData = reply;
    }

    if (conversation_id) {
      await dispatch(
        chatActions.createConversationReply(formData, conversation_id),
      );
    } else {
      await dispatch(chatActions.createConversation(formData, recipient.id));
    }
  };
};

export const useQueryUnreadMessagesCount = () => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [identifier, 'unread-messages'],
    chatAPI.getUnreadMessagesNumber,
    {
      enabled: isLoggedIn,
    },
  );
};

export const useQueryAddReply = () => {
  const queryClient = useQueryClient();

  return (conversationId: number, createdReply: chatAPI.Reply) => {
    queryClient.setQueryData<
      InfiniteData<ResponseWrapperPaginated<chatAPI.Reply[]>> | undefined
    >([identifier, conversationId, 'replies'], (replies) => {
      if (!replies) {
        return undefined;
      }

      // Dirty state mutation but much faster
      replies.pages[0]?.data?.unshift(createdReply);

      return replies;
    });

    queryClient.setQueryData<chatAPI.Conversation | undefined>(
      [identifier, conversationId],
      (conversation) =>
        conversation
          ? {
              ...conversation,
              total_unread_messages:
                (conversation.total_unread_messages || 0) + 1,
              last_reply: createdReply.body,
              last_reply_from_me: false,
              last_reply_message: createdReply.body,
              last_reply_timestamp:
                createdReply.timestamp || conversation.last_reply_timestamp,
            }
          : undefined,
    );
  };
};
