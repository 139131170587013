import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useCurrentUser, authenticationActions } from '../../api';

const AccountStatusGate = ({ children }: { children: React.ReactElement }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { status } = useCurrentUser();

  useEffect(() => {
    // do not allow other routes if user status is disabled
    if (
      status === 'disabled' &&
      !router.pathname.includes('/reactivate') &&
      !router.pathname.includes('/register')
    ) {
      router.push('/reactivate');
    }

    // logout user if they want to create a new account
    if (status === 'disabled' && router.pathname.includes('/register')) {
      dispatch(authenticationActions.logout());
    }
  });

  return children as React.ReactElement;
};

export default AccountStatusGate;
