import { createAction } from '@reduxjs/toolkit';
import { User } from '../../lib/api/user';
import { Track } from '../../lib/api/tracks';
import { Pagination } from '../../lib/api/Pagination';
import { SearchKeyword, SearchResult } from '../../lib/api/search';
import { ResponseWrapperPaginated } from '../../lib/api/ResponseWrapper';
import { Raffle } from '../../lib/api/raffles';
import { Genre } from '../../lib/api/genres';
import { SimpleChatUser } from '../../lib/api/chat';

export const requestUsersSearch = createAction<string>(
  'SEARCH_REQUEST_USERS_SEARCH',
);
export const requestTracksSearch = createAction<string>(
  'SEARCH_REQUEST_TRACKS_SEARCH',
);
export const requestRafflesSearch = createAction<string>(
  'SEARCH_REQUEST_RAFFLES_SEARCH',
);
export const requestContestsSearch = createAction<string>(
  'SEARCH_REQUEST_CONTESTS_SEARCH',
);
export const requestGenresSearch = createAction<string>(
  'SEARCH_REQUEST_GENRES_SEARCH',
);

export const receiveSearchResult_DEPRECATED = createAction<{
  tracks?: { data: Track[]; pagination: Pagination };
  users?: { data: User[]; pagination: Pagination };
  raffles?: { data: Raffle[]; pagination: Pagination };
  contests?: { data: Raffle[]; pagination: Pagination };
  genres?: Genre[];
  keyword: string;
}>('SEARCH_RECEIVE_RESULT');

export const errorSearchResult = createAction<string>('SEARCH_ERROR');

export const clearSearchResult = createAction('SEARCH_CLEAR');

export const receiveSearchResults = createAction<
  ResponseWrapperPaginated<SearchResult[]>
>('RECEIVE_SEARCH_RESULT');

export const receiveSearchesRecent = createAction<SearchKeyword[]>(
  'RECEIVE_RECENT_SEARCHES',
);
export const receiveSearchesPopular = createAction<SearchKeyword[]>(
  'RECEIVE_POPULAR_SEARCHES',
);

export const updateRecentSearches = createAction<string>(
  'UPDATE_RECENT_SEARCHES',
);

export const receiveSearchResultUserEntries = createAction<SimpleChatUser[]>(
  'SEARCH_RECEIVE_USER_ENTRIES',
);
