import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
import { useAffiliate } from '../utils/affiliate';
import {
  StoreType,
  authenticationActions,
  useCurrentUser,
  useDispatch,
  useOneTapGoogleLogin,
  useSetDataLayerAutoLoginFlagSessionStorage,
} from '../api';
import { login } from '../utils/auth';
import { registerPusher } from '../utils/registerPusher';
import { ToastError } from './Toast';
import { pagesGoogleSignupPopupNotRedirectAfterLogin } from '../constants/pagesWithHiddenGoogleSignupPopup';
import { addToDataLayerGA } from '../utils/googleAnalytics';
import { getPlanNamesFromPlanTypes } from '../utils/getPlanNamesFromPlanTypes';

const GoogleLoginPopupProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const router = useRouter();
  const { refetch: getCurrentUser } = useCurrentUser();
  const affiliate = useAffiliate();
  const dispatch = useDispatch();
  const {
    googleLoginCredentialData,
    errorMessageGoogleLogin,
  } = useOneTapGoogleLogin();
  const store = useStore() as StoreType;
  const flagForAutoLoginDataLayerSent = useSetDataLayerAutoLoginFlagSessionStorage();
  const cb = (token: string) => {
    dispatch(authenticationActions.verifyLoginOnStartup(token));
  };

  const isGoogleLoginRidirects = () => {
    if (pagesGoogleSignupPopupNotRedirectAfterLogin.includes(router.pathname)) {
      return false;
    }
    return true;
  };
  /* --------------------- login handler after login with google sign in popup -------------------- */
  const finalizeLogin = async (token: string) => {
    await login({ token, cb });
    registerPusher(store);
    affiliate.flushAffilateId();
    await getCurrentUser().then((res) => {
      if (res?.data) {
        const { abilities, id } = res.data;
        /* --------------------------- fire data layer event for google login --------------------------- */
        if (!googleLoginCredentialData?.new_user) {
          addToDataLayerGA({
            event: 'login',
            userId: id,
            userType: getPlanNamesFromPlanTypes(abilities?.plan),
          });
          flagForAutoLoginDataLayerSent.setIsDataLayerSent();
        }
        /* ------------------------------ redirection after login or signup ----------------------------- */
        if (abilities && isGoogleLoginRidirects()) {
          if (abilities?.plan === 'amateur') {
            router.push(
              `/become-pro${
                googleLoginCredentialData?.new_user
                  ? '?newuser=true&fire_ga=true'
                  : ''
              }`,
            );
          } else {
            router.push(`/profile/${id}`);
          }
        }
      }
    });
  };

  // google login handler
  useEffect(() => {
    if (googleLoginCredentialData) {
      finalizeLogin(googleLoginCredentialData.token);
    }
  }, [googleLoginCredentialData]);

  // error message hadeling for google login
  useEffect(() => {
    if (errorMessageGoogleLogin) {
      ToastError(errorMessageGoogleLogin);
    }
  }, [errorMessageGoogleLogin]);

  return children as React.ReactElement;
};

export default GoogleLoginPopupProvider;
