import axiosLib from 'axios';

const axios = axiosLib.create({
  headers: {
    Platform: '6e0db108-4879-438c-9279-d9a19a4317e0',
  },
});

export const setAxiosBaseUrl = (url?: string) => {
  if (url === undefined) {
    throw new Error('Cannot set undefined string as axios base url');
  }
  axios.defaults.baseURL = url;
};

export const setAxiosAuthHeader = (token: string) => {
  axios.defaults.headers.common.Authorization = token;
};

export const setAxiosPlatformHeader = (value: string) => {
  axios.defaults.headers.common.Platform = value;
};

export default axios;
