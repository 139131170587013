import React from 'react';
import styled from 'styled-components';

export { Favorite as Heart } from '@styled-icons/material/Favorite';
export { FavoriteBorder as HeartBorder } from '@styled-icons/material/FavoriteBorder';

export { PlayArrow as Play } from '@styled-icons/material/PlayArrow';
export { Pause } from '@styled-icons/material/Pause';
export { Replay } from '@styled-icons/material/Replay';
export { Reply as Back } from '@styled-icons/material/Reply';
export { SkipNext as Next } from '@styled-icons/material/SkipNext';

export { PlayCircleFilled as PlayCircle } from '@styled-icons/material/PlayCircleFilled';
export { PauseCircleFilled as PauseCircle } from '@styled-icons/material/PauseCircleFilled';

export { Play as PlayOutline } from '@styled-icons/feather/Play';
export { Pause as PauseOutline } from '@styled-icons/feather/Pause';

export { LocationOn as Location } from '@styled-icons/material/LocationOn';

export { DotsHorizontalRounded as DotsMenu } from '@styled-icons/boxicons-regular/DotsHorizontalRounded';

export { Layers } from '@styled-icons/material/Layers';

export { Timeline as Statistics } from '@styled-icons/material/Timeline';

export { Edit } from '@styled-icons/material/Edit';

export { AddCircle } from '@styled-icons/material/AddCircle';
export { AddCircleOutline as Add } from '@styled-icons/material/AddCircleOutline';
export { Add as AddSolid } from '@styled-icons/material/Add';

export { Delete } from '@styled-icons/material/Delete';

export { Upload } from '@styled-icons/zondicons/Upload';

export { LightBulb } from '@styled-icons/octicons/LightBulb';

export { Style } from '@styled-icons/material/Style';
export { ViewList } from '@styled-icons/material/ViewList';

export { ArrowDown } from '@styled-icons/typicons/ArrowDown';
export { ArrowUp } from '@styled-icons/typicons/ArrowUp';
export { FilterList } from '@styled-icons/material/FilterList';
export { Check } from '@styled-icons/material/Check';
export { CheckCircle } from '@styled-icons/material/CheckCircle';
export { People } from '@styled-icons/material/People';

export { ArrowBack } from '@styled-icons/material/ArrowBack';

export { Close } from '@styled-icons/material/Close';

export { Paperclip } from '@styled-icons/feather/Paperclip';
export { File } from '@styled-icons/boxicons-regular/File';
export { RemoveCircle } from '@styled-icons/material/RemoveCircle';

export { MusicNote } from '@styled-icons/material/MusicNote';
export { LeftArrowAlt } from '@styled-icons/boxicons-regular';
export { RightArrowAlt } from '@styled-icons/boxicons-regular';

export { Done as Checkmark } from '@styled-icons/material/Done';
export { StarRate as Star } from '@styled-icons/material/StarRate';

export { Error } from '@styled-icons/material/Error';

export { Camera } from '@styled-icons/boxicons-solid/Camera';

export { Warning } from '@styled-icons/material/Warning';

export { Info } from '@styled-icons/material/Info';

export { Security as Shield } from '@styled-icons/material/Security';

export { Lock } from '@styled-icons/material/Lock';

export const Dot = styled(
  ({
    size = 14,
    color,
    className,
  }: {
    size?: number;
    color: string;
    className?: string;
  }) => (
    <svg height={size} width={size} className={className}>
      <circle fill={color} r={size / 2} cx={size / 2} cy="50%" />
    </svg>
  ),
)``;

export { Spotify } from '@styled-icons/boxicons-logos/Spotify';
export { Applemusic } from '@styled-icons/simple-icons/Applemusic';
export { Amazon } from '@styled-icons/boxicons-logos/Amazon';
export { Tidal } from '@styled-icons/simple-icons/Tidal';
export { Youtubemusic } from '@styled-icons/simple-icons/Youtubemusic';
