import styled from 'styled-components';
import Box, { BoxProps } from './Box';

const Flex = styled(Box)``;
export type FlexProps = BoxProps;

Flex.defaultProps = {
  display: 'flex',
};

export default Flex;
