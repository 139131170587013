import { useRouter } from 'next/router';
import { pagesWithGreyBackground } from '../constants/pages-with-grey-background';

const useGreyBackground = () => {
  const { route } = useRouter();

  const greyBackground = pagesWithGreyBackground.includes(route);

  return greyBackground;
};

export default useGreyBackground;
