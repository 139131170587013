import React, { useEffect } from 'react';

import {
  useAuthentication,
  useCurrentUser,
  useSetDataLayerAutoLoginFlagSessionStorage,
} from '../api';
import { addToDataLayerGA } from '../utils/googleAnalytics';
import { getPlanNamesFromPlanTypes } from '../utils/getPlanNamesFromPlanTypes';

const AutoLoginSendDataLayerProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const user = useCurrentUser();
  const authData = useAuthentication();
  const flagForAutoLoginDataLayerSent = useSetDataLayerAutoLoginFlagSessionStorage();

  useEffect(() => {
    if (
      !flagForAutoLoginDataLayerSent.getIsDataLayerSentSessionStorage() &&
      authData.isLoggedIn &&
      authData.token
    ) {
      if (user.id) {
        addToDataLayerGA({
          event: 'auto_login',
          userId: user.id,
          userType: getPlanNamesFromPlanTypes(user.abilities?.plan),
        });
        flagForAutoLoginDataLayerSent.setIsDataLayerSent();
      }
    }
  }, [authData, user]);

  return children as React.ReactElement;
};

export default AutoLoginSendDataLayerProvider;
