import React from 'react';
import { Badge } from 'reactstrap';

const VuexyBadge = ({ icon, text, ...props }: VuexyBadgeProps) => (
  <Badge {...props}>
    <>
      {icon && icon}
      {text}
    </>
  </Badge>
);

interface VuexyBadgeProps {
  text: string;
  icon?: React.ReactNode;
  color?: string;
  outline?: boolean;
  className?: string;
  style?: React.CSSProperties;
  pill?: boolean;
}

export default VuexyBadge;
