import React from 'react';
import { CustomInput } from 'reactstrap';

const VuexyRadio = ({ ...props }: VuexyRadioProps) => (
  <CustomInput {...props} type="radio" />
);

interface VuexyRadioProps {
  name?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  check?: boolean;
}
export default VuexyRadio;
