import React from 'react';
import { CardText } from 'reactstrap';

export const VuexyCardText = ({
  text,
  children,
  ...props
}: VuexyCardTextProps) => (
  <CardText {...props}>
    {text} {children}
  </CardText>
);

interface VuexyCardTextProps {
  tag?: () => void | string;
  className?: string;
  text?: string;
  children?: JSX.Element | JSX.Element[] | string;
  style?: string | Record<string, unknown>;
}

export default VuexyCardText;
