import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';
import useTranslation from 'next-translate/useTranslation';
import { style, Track, SimpleChatUser, useSearchResults } from '../api';
import { ProfileLink, TrackLink } from './common/Link';
import UserImage from './common/UserImage';
import TrackImage from './common/TrackImage';
import {
  desktop,
  noDesktop,
  useIsDesktop,
  useIsTablet,
  useIsMobile,
} from '../style/constants';
import { useHeaderHeight } from '../hooks/style';

interface Props {
  searchQuery: string;
  onSubmit: () => void;
  focussed: boolean;
  onClose?: () => void;
}

const ResultsPopup = ({ searchQuery, onSubmit, focussed, onClose }: Props) => {
  const isTablet = useIsTablet();
  const maxItemsDisplayed = isTablet ? 6 : 8;
  const maxArtistsDisplayed = isTablet ? 3 : 4;
  const { t } = useTranslation('search');
  const { users, tracks, totalResults } = useSearchResults();
  const [clickedOutside, setClickedOutside] = useState(false);
  const isMobile = useIsMobile();

  const { headerHeight } = useHeaderHeight();

  const isDesktop = useIsDesktop();

  useEffect(() => setClickedOutside(false), [searchQuery]);

  const usersToDisplay = users.slice(0, maxArtistsDisplayed);
  const tracksToDisplay = tracks.slice(
    0,
    maxItemsDisplayed - usersToDisplay.length,
  );

  const showAllResultsRow = totalResults > maxItemsDisplayed;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ResultsPopup.handleClickOutside = () => {
    setClickedOutside(true);
    if (onClose) {
      onClose();
    }
  };

  const handleClose = () => {
    if (!isDesktop && onClose) {
      onClose();
    }
  };

  if (clickedOutside && !focussed) return null;

  return (
    <Container offset={headerHeight}>
      {isDesktop && (
        <PerformQueryRow onClick={onSubmit}>
          <ResultText>
            {totalResults >= 0 && (
              <div>
                <span className="font-weight-bolder">
                  {t('header:found') as string} {totalResults}{' '}
                  {t('header:relatingTo') as string}
                </span>{' '}
                {searchQuery}
              </div>
            )}
          </ResultText>
          <RightArrowAlt size={20} />
        </PerformQueryRow>
      )}
      <div
        className={`d-flex flex-column ${
          isMobile ? '' : 'justify-content-around'
        }`}
        style={{ height: '65vh' }}
      >
        <div className="d-flex justify-content-center w-100">
          <div
            className={`d-flex justify-content-around ${
              isMobile ? 'flex-column w-100' : 'flex-row w-50'
            }`}
          >
            {usersToDisplay.map((user) => (
              <UserResult
                key={user.id}
                user={user}
                onClose={handleClose}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center w-100">
          <div
            className={`d-flex  justify-content-around ${
              isMobile ? 'flex-column w-100' : 'flex-row w-50'
            }`}
          >
            {tracksToDisplay.map((track) => (
              <TrackResult
                key={track.id}
                track={track}
                onClose={handleClose}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {showAllResultsRow && !isDesktop && (
        <PerformQueryRow
          onClick={onSubmit}
          style={{ justifyContent: 'center' }}
        >
          <span>
            {t('search:show') as string} {totalResults}{' '}
            {t('search:results') as string}
          </span>
        </PerformQueryRow>
      )}
    </Container>
  );
};

export default onClickOutside(ResultsPopup, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  handleClickOutside: () => ResultsPopup.handleClickOutside,
});

const UserResult = ({
  user,
  onClose,
  onClick,
}: {
  user: SimpleChatUser;
  onClose: () => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  let cardWidth;
  if (isMobile) {
    cardWidth = 'w-100';
  } else if (isTablet) {
    cardWidth = 'mx-1';
  } else {
    cardWidth = 'w-25 mx-1';
  }
  return (
    <div className={cardWidth}>
      <ProfileLink profileId={user.id}>
        <ResultRow
          className={`d-flex h-100 ${
            isMobile ? 'flex-row' : 'flex-column  rounded'
          }`}
          style={{ width: isMobile ? '100% ' : '200px' }}
          onClick={(event) => {
            if (onClick) {
              onClick(event);
            }
            onClose();
          }}
        >
          <UserImage size={!isMobile ? 150 : 25} src={user.profile_photo} />
          <div
            className={`d-flex w-100 text-truncate ${
              isMobile ? 'flex-row' : 'flex-column'
            }`}
          >
            <ResultHighlighted result={user.display_name} />
            <ResultDescription className="text-truncate">
              - {user.role === 'label' ? 'Label' : 'Artist'}
            </ResultDescription>
          </div>
        </ResultRow>
      </ProfileLink>
    </div>
  );
};

const TrackResult = ({
  track,
  onClose,
  onClick,
}: {
  track: Track;
  onClose: () => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  let cardWidth;
  if (isMobile) {
    cardWidth = 'w-100';
  } else if (isTablet) {
    cardWidth = 'mx-1';
  } else {
    cardWidth = 'w-25 mx-1';
  }
  return (
    <div className={cardWidth}>
      <TrackLink trackId={track.id}>
        <ResultRow
          className={`d-flex h-100 ${
            isMobile ? 'flex-row' : 'flex-column  rounded'
          }`}
          style={{ width: isMobile ? '100% ' : '200px' }}
          onClick={(event) => {
            if (onClick) {
              onClick(event);
            }
            onClose();
          }}
        >
          <TrackImage size={!isMobile ? 150 : 25} src={track.track_img} />
          <div
            className={`d-flex w-100 text-truncate ${
              isMobile ? 'flex-row' : 'flex-column'
            }`}
          >
            {' '}
            <ResultHighlighted result={track.title || 'Untitled'} />
            <ResultDescription className="text-truncate">
              - {track.user.display_name}
            </ResultDescription>
          </div>
        </ResultRow>
      </TrackLink>
    </div>
  );
};

const ResultHighlighted = ({ result }: { result: string }) => {
  const isMobile = useIsMobile();
  return (
    <div className={`text-truncate ${isMobile ? 'pl-1' : 'pt-1'} `}>
      <ResultHighlightedNonMatch>{result}</ResultHighlightedNonMatch>
    </div>
  );
};

const ResultHighlightedNonMatch = styled.span`
  color: ${style.grey_dark_new};
`;

const ResultText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Container = styled.div<{ offset: number }>`
  background-color: #ffffffd9;
  backdrop-filter: blur(30px);
  position: absolute;

  @media ${desktop} {
    width: 100%;
    top: 71px;
    left: 0px;
    box-shadow: ${style.lightBoxShadow};
  }

  @media ${noDesktop} {
    position: fixed;
    width: 100vw;
    left: 0px;
    top: ${(p) => p.offset + 75}px;
    background-color: #f5f5f57a;
    backdrop-filter: blur(7px);
  }

  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 15px 25px;
  font-size: 14px;
  transition: background-color 0.9s;
  :hover {
    background-color: white;
  }
`;

const PerformQueryRow = styled(Row)`
  height: 60px;
  justify-content: space-between;
  border-bottom: 1px solid ${style.grey_light_2_new};
  cursor: pointer;
`;

const ResultRow = styled(Row)`
  height: 50px;
  justify-content: flex-start;
  :hover {
    box-shadow: 0 1rem 3rem rgb(34 41 47 / 18%) !important;
  }
`;

const ResultDescription = styled.span`
  color: ${style.grey_main_new};
  font-style: italic;
`;
