import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import tracks, {
  State as Tracks,
  defaultState as tracksDefaultState,
} from './tracks';
import player, { State as Player } from './player';
import authentication, {
  State as Authentication,
  defaultState as authenticationDefaultState,
} from './authentication';
import user, { State as User, defaultState as userDefaultState } from './user';
import playlists, {
  State as Playlists,
  defaultState as playlistsDefaultState,
} from './playlists';
import users, {
  State as Users,
  defaultState as usersDefaultState,
} from './users';
import chat, { State as Chat, defaultState as chatDefaultState } from './chat';
import followers, {
  State as Followers,
  defaultState as followersDefaultState,
} from './followers';
import comments, { State as Comments } from './comments';
import musicKnowledgeHub, {
  State as MusicKnowledgeHub,
} from './musicKnowledgeHub';
import search, { State as Search } from './search';
import notifications, {
  State as Notifications,
  defaultState as notificationsDefaultState,
} from './notifications';
import vcoins, {
  State as VCoins,
  defaultState as vcoinsDefaultState,
} from './vcoins';
import { clearToken } from '../../lib/tokenTools';
import swiper, { State as Swiper } from './swiper';

import alerts, { AlertsState } from './alerts';

import labelWidget, {
  State as LabelWidget,
  defaultState as labelWidgetInitialState,
} from './label-widget';
import aiMusicChat, {
  State as AiMusicChat,
  defaultState as aiMusicChatDefaultState,
} from './ai-music-chat';

export interface RootState {
  tracks: Tracks;
  player: Player;
  authentication: Authentication;
  user: User;
  playlists: Playlists;
  followers: Followers;
  users: Users;
  chat: Chat;
  comments: Comments;
  musicKnowledgeHub: MusicKnowledgeHub;
  search: Search;
  notifications: Notifications;
  vcoins: VCoins;
  swiper: Swiper;
  alerts: AlertsState;
  labelWidget: LabelWidget;
  aiMusicChat: AiMusicChat;
}

export default (state: RootState | undefined, action: AnyAction) => {
  // Reset all privacy-sensitive items in the redux state when logging out
  let newState: RootState | undefined;
  if (
    action.type === authentication.actions.logout.type &&
    state !== undefined &&
    state.authentication.isLoggedIn
  ) {
    clearToken();
    newState = {
      ...state,
      users: usersDefaultState,
      user: userDefaultState,
      chat: chatDefaultState,
      authentication: authenticationDefaultState,
      playlists: {
        ...state.playlists,
        userPlaylists: playlistsDefaultState.userPlaylists,
      },
      followers: followersDefaultState,
      notifications: notificationsDefaultState,
      vcoins: vcoinsDefaultState,
      labelWidget: labelWidgetInitialState,
      tracks: tracksDefaultState,
      aiMusicChat: aiMusicChatDefaultState,
    };
  }

  if (action.type === '__NEXT_REDUX_WRAPPER_HYDRATE__') {
    newState = {
      ...state,
      ...action.payload,
    };
  }

  return combineReducers({
    tracks: tracks.reducer,
    player: player.reducer,
    authentication: authentication.reducer,
    user: user.reducer,
    playlists,
    followers,
    users: users.reducer,
    chat,
    comments: comments.reducer,
    musicKnowledgeHub: musicKnowledgeHub.reducer,
    search,
    notifications,
    vcoins: vcoins.reducer,
    swiper: swiper.reducer,
    alerts,
    labelWidget: labelWidget.reducer,
    aiMusicChat: aiMusicChat.reducer,
  })(newState || state, action);
};
