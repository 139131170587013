import React from 'react';
import styled from 'styled-components';
import { style } from '../../api';

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${style.grey_light_1_new};
`;

export const DarkerDivider = styled.div`
  height: 1px;
  width: 85%;
  border-bottom: 1px solid ${style.grey_light_2_new};
`;

const DividerWithTextContainer = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    color: ${style.grey_dark_new};
    font-weight: bold;
  }
`;

const DividerWithTextDivider = styled.div`
  border-bottom: 1px solid ${style.grey_main_new};
  width: 100%;
`;

export const DividerWithText = styled(
  ({
    text,
    style: containerStyle,
    className,
  }: {
    text: string;
    style?: React.CSSProperties;
    className?: string;
  }) => (
    <DividerWithTextContainer className={className} style={containerStyle}>
      <DividerWithTextDivider />
      <span>{text}</span>
      <DividerWithTextDivider />
    </DividerWithTextContainer>
  ),
)``;
