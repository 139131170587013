/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Field } from 'formik';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { Times } from '@styled-icons/fa-solid/Times';
import { Check as Checkmark } from '@styled-icons/material/Check';
import { style } from '../../../api';
import LayoutBox from '../Box';

import { LoginLoader } from '../Loader';
import './select.module.css';
import { useIsMobile } from '../../../style/constants';

const Select = dynamic<{
  searchable?: boolean;
  className?: string;
  options: {
    label: string;
    value: number;
  }[];
  disabled?: boolean;
  value: number;
  placeholder: string;
  onChange: (value: number) => void;
  name?: string;
  onBlur?: any;
  classes: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
}>(() => import('react-styled-select'), {
  ssr: false,
  loading: () => <LoginLoader />,
});

const textInput = css`
  width: 100%;
  color: ${style.dark};
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding-left: 25px;

  &::placeholder {
    color: ${style.grey3};
  }
`;

export const TextInput = styled(Field)`
  ${textInput}

  ${media.xs`
    height: 51px;
  `}
`;

export const Input = styled.input`
  ${textInput}
  height: 40px;
`;

export const CheckBox = (props: {
  checked: boolean;
  name?: string;
  onClick: () => void;
  disabled?: boolean;
}) => (
  // eslint-disable-next-line
  <Box {...props}> {props.checked ? <Check size={18} /> : null}</Box>
);

const Box = styled.div<{ checked?: boolean }>`
  min-width: 18px;
  width: 18px;
  height: 19px;
  color: ${style.light};
  border: 2px solid ${style.pink};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.checked ? style.primary_pink_new : style.light)};
  cursor: pointer;
`;

export const Radio = styled.div<{ checked?: boolean }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  border-style: solid;
  border-width: ${(p) => (p.checked ? '4px' : '2px')};
  border-color: ${(p) =>
    p.checked ? style.primary_pink_new : style.grey_main_new};
`;

const InputBox = (
  props: React.ComponentProps<typeof LayoutBox> & React.ComponentProps<'input'>,
) => <LayoutBox as="input" {...props} />;

export const TransparentInput = styled(InputBox)`
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: initial;
  font-size: inherit;
  height: initial;
  line-height: inherit;
`;

const TextareaBox = (
  props: React.ComponentProps<typeof LayoutBox> &
    React.ComponentProps<'textarea'>,
) => <LayoutBox as="textarea" {...props} />;

export const TransparentTextarea = styled(TextareaBox)`
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: initial;
  font-size: inherit;
  height: initial;
  line-height: inherit;
`;

export const SelectField = ({
  className,
  options,
  field,
  form,
  placeholder,
  formCopy,
  setFormCopy,
  searchable = false,
}: {
  className?: string;
  options: {
    label: string;
    value: number;
  }[];
  field: any;
  form: any;
  placeholder: string;
  formCopy: any;
  setFormCopy?: (formCopyArg: any) => void;
  searchable?: boolean;
}) => {
  const isMobile = useIsMobile();

  const onChange = (option: number) => {
    form.setFieldValue(field.name, option);
    if (setFormCopy) {
      setFormCopy({ ...formCopy, [field.name]: option });
    }
  };

  if (!isMobile) {
    return (
      <Select
        className={className}
        options={options}
        name={field.name}
        value={field.value}
        onChange={(option) => onChange(option)}
        onBlur={field.onBlur}
        placeholder={placeholder}
        classes={{
          selectControl: 'select-control',
          selectInputField: 'select-input',
          selectMultiValueWrapper: 'select-multi-value-wrapper',
        }}
        searchable={searchable}
      />
    );
  }

  return (
    <StyledHtmlSelect onChange={(e) => onChange(Number(e.target.value))}>
      <option selected disabled hidden>
        {placeholder}
      </option>
      {options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </StyledHtmlSelect>
  );
};

const StyledHtmlSelect = styled.select`
  border: 2px solid ${style.grey_main_new};
  color: ${style.grey_main_new};
  height: 45px;
  appearance: none;
  padding-left: 20px;
  border-radius: 0px;
  font-weight: 500;
  font-size: 14px;

  background-image: url('/static/images/select-arrow.png');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto, 100%;

  option {
    color: ${style.grey_dark_new};
  }
`;

const OTPLabel = styled.label`
  position: absolute;
  padding: 5px 0 5px 20px;
  color: ${style.grey_dark_new};
  z-index: 2;
`;

const OTPStatusLabel = styled.label`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: ${style.grey_dark_new};
  display: flex;
  align-items: center;
`;

const OTPLayoutBox = styled(LayoutBox)`
  width: 100%;
  height: 64px;
  border: 1px solid ${style.grey_main_new};
  padding-top: 10px;
  color: ${style.black_new};
  letter-spacing: 0.5rem;
  background-color: ${style.grey_light_1_new};
  position: relative;
`;

export const OTPInput = (
  props: React.ComponentProps<typeof LayoutBox> &
    React.ComponentProps<'input'> & {
      label: string;
      width?: string;
      isValid: boolean;
      isDirty: boolean;
    },
) => {
  const { label, width = '100%', isValid, isDirty, ...rest } = props;

  return (
    <div style={{ width, position: 'relative' }}>
      <OTPLabel>{label}</OTPLabel>
      <OTPLayoutBox as="input" {...rest} />
      {isDirty && (
        <OTPStatusLabel>
          {isValid ? (
            <>
              <Checkmark color={style.success_new} size="14" />
              Correct
            </>
          ) : (
            <>
              <Times color={style.error_new} size="14" fontWeight="400" />
              Incorrect
            </>
          )}
        </OTPStatusLabel>
      )}
    </div>
  );
};
