import React, { ErrorInfo } from 'react';

/**
 * TODO - Replace @sentry/browser with @sentry/minimal
 * TODO - Possibility to catch issues in _error page instead like a champ?
 */
import { init, withScope, captureException } from '@sentry/browser';

import ReportErrorMessage from './ReportErrorMessage';
import PageTitle from './common/BrowserPageTitle';

init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.CIRCLE_SHA1,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
  eventId?: string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(
    error: Error,
    errorInfo: ErrorInfo & Record<string, string>,
  ) {
    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      const eventId = captureException(error);

      this.setState({ eventId });
    });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <PageTitle>Oops..</PageTitle>
          <ReportErrorMessage eventId={eventId} />
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
