import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { ListenerLevel, AwardWithTrack, Award } from './user';

export interface TrackPull {
  accepted: number;
  created_at: string;
  id: number;
  message: string;
  status?: string;
  track_id: number;
  updated_at: string;
  user_id: number;
}

export interface ListenerInvited {
  type: 'ListenerInvited';
  team_id: number;
  label_id: number;
  invitation_id: number;
}

export interface General {
  type: 'General';
  message: string;
}

export interface TrackApproved {
  type: 'TrackApproved';
  track_id: number;
}

export interface TrackPullRequestMade {
  type: 'TrackPullRequestMade';
  track_id: number;
  label_id: number;
  pull_id: number;
}

export interface TrackLiked {
  type: 'TrackLiked';
  user_id: number;
  track_id: number;
}

export interface ConversationMessageReceived {
  type: 'ConversationMessageReceived';
  conversation_id: number;
  recipient_id: number;
}

export interface LikedTrackGetPulled {
  type: 'LikedTrackGetPulled';
  track_id: number;
  vcoins: number;
  profile_status: ListenerLevel;
}

export interface ListenerInvitationAccepted {
  type: 'ListenerInvitationAccepted';
  listener_id: number;
}

export interface ListenerInvitationDenied {
  type: 'ListenerInvitationDenied';
  listener_id: number;
}

export interface TrackCommented {
  type: 'TrackCommented';
  commentator_id: number;
  track_id: number;
}

export interface TrackCommentReplied {
  type: 'TrackCommentReplied';
  comment_id: number;
  commentator_id: number;
  track_id: number;
}

export interface TrackInCharts {
  type: 'TrackInCharts';
  track_id: number;
  chart: string;
  position: number;
}

export interface TrackPlayedByLabel {
  type: 'TrackPlayedByLabel';
  label_id: number;
  track_id: number;
}

export interface TrackPlayedSecondTimeByLabel {
  type: 'TrackPlayedSecondTimeByLabel';
}

export interface TrackLikedByLabel {
  type: 'TrackLikedByLabel';
  user_id: number;
  track_id: number;
}

export interface TrackPullRequestAccepted {
  type: 'TrackPullRequestAccepted';
  producer_id: number;
  track_id: number;
}

export interface TrackPullRequestDenied {
  type: 'TrackPullRequestDenied';
  producer_id: number;
  track_id: number;
}

export interface UserFollowed {
  type: 'UserFollowed';
  follower_id: number;
}

export interface UserAwardEarned {
  type: 'UserAwardEarned';
  user_id: number;
  award: Award;
}

export interface AwardEarned {
  type: 'AwardEarned';
  award: AwardWithTrack;
}

export interface VcoinsTransaction {
  type: 'VcoinsTransaction';
  amount: number;
  message: string;
}

export interface TrackCollaborationRequest {
  type: 'TrackCollaborationRequest';
  user_id: number;
  track_id: number;
}

export interface TrackCollaborationAccepted {
  type: 'TrackCollaborationApproved';
  track_id: number;
  collaborator_id: number;
}

export interface TrackCollaborationDeclined {
  type: 'TrackCollaborationDeclined';
  track_id: number;
  collaborator_id: number;
}

export interface TrackFeedbackRequested {
  type: 'TrackFeedbackRequested';
  track_id: number;
  user_id: number;
  vcoins: number;
}

export interface WonRaffle {
  type: 'WonRaffle';
  raffle_id: number;
  conversation_id: number;
}

export interface RaffleEnded {
  type: 'RaffleEnded';
  raffle_id: number;
}

export interface PendingPullRequest {
  type: 'PendingPullRequest';
  pull_id: number;
}

export interface UserJoinedContest {
  type: 'UserJoinedContest';
  raffle_id: number;
}

export interface UserJoinedRemixContest {
  type: 'UserJoinedRemixContest';
  raffle_id: number;
}

export interface VcoinsOrder {
  type: 'VcoinsOrder';
  package: { amount: number };
}

export interface ReceivedTrackFeedback {
  type: 'ReceivedTrackFeedback';
  track_id: number;
  commentator_id: number;
}

export interface RemixContestEnded {
  type: 'RemixContestEnded';
  raffle_id: number;
}

export interface ContestEnded {
  type: 'ContestEnded';
  raffle_id: number;
  conversation_id: number;
}

export interface WonContest {
  type: 'WonContest';
  raffle_id: number;
  conversation_id: number;
}

export interface WonRemixContest {
  type: 'WonRemixContest';
  raffle_id: number;
  conversation_id: number;
}

export interface ContestEntryPublic {
  type: 'ContestEntryPublic';
  track_id: number;
}

export interface InvoicePaymentFailed {
  type: 'InvoicePaymentFailed';
}

export interface PaymentConfirmation {
  type: 'PaymentConfirmation';
  user_id: number;
  status: string;
}
export interface TrackSelectedForRelease {
  type: 'TrackSelectedForRelease';
  track_id: number;
}

export interface TrackReleaseReleased {
  type: 'TrackReleaseReleased';
  track_id: number;
}

export interface NewUserAddedToTrackRelease {
  type: 'NewUserAddedToTrackRelease';
  track_id: number;
}

export interface UserAddedToTrackRelease {
  type: 'UserAddedToTrackRelease';
  track_id: number;
}

export interface TrackReleaseRequestForChanges {
  type: 'TrackReleaseRequestForChanges';
  track_id: number;
  reason: string;
}

export type VcoinsTransactionNormalized = VcoinsTransaction;

export type NotificationData =
  | ConversationMessageReceived
  | LikedTrackGetPulled
  | ListenerInvitationAccepted
  | ListenerInvitationDenied
  | TrackApproved
  | TrackCommented
  | TrackCommentReplied
  | TrackInCharts
  | TrackPullRequestAccepted
  | TrackPullRequestDenied
  | UserFollowed
  | General
  | TrackLiked
  | UserAwardEarned
  | AwardEarned
  | VcoinsTransactionNormalized
  | TrackCollaborationRequest
  | TrackCollaborationAccepted
  | TrackCollaborationDeclined
  | TrackFeedbackRequested
  | RaffleEnded
  | WonRaffle
  | UserJoinedContest
  | UserJoinedRemixContest
  | VcoinsOrder
  | ReceivedTrackFeedback
  | RemixContestEnded
  | ContestEnded
  | WonContest
  | WonRemixContest
  | ContestEntryPublic
  | InvoicePaymentFailed
  | PaymentConfirmation
  | TrackSelectedForRelease
  | TrackReleaseReleased
  | NewUserAddedToTrackRelease
  | UserAddedToTrackRelease
  | TrackReleaseRequestForChanges;

export type LabelNotificationData =
  | TrackPlayedByLabel
  | TrackPlayedSecondTimeByLabel
  | TrackLikedByLabel
  | TrackPullRequestMade
  | ListenerInvited
  | PendingPullRequest;

export type NotificationDataCommon = (
  | NotificationData
  | LabelNotificationData
) & { notification_default_text?: string };

export type NotificationTypes = NotificationDataCommon['type'];

export interface UserNotification {
  id: string;
  data: NotificationData & { notification_default_text?: string };
  read_at?: string;
  created_at?: string | number;
  updated_at?: string;
  timestamp: number;
}

export interface LabelNotification {
  id: string;
  data: LabelNotificationData & { notification_default_text?: string };
  read_at?: string;
  created_at?: string | number;
  updated_at?: string;
  timestamp: number;
}

export type Notification = LabelNotification | UserNotification;

export interface TrackPullStatus {
  id: number;
  status: 'open' | 'accepted' | 'declined';
  created_at: number;
  label_id: number;
  track_id: number;
}

interface Unread {
  total: number;
}

export const fetch = async (
  userId: number,
  page = 1,
  size = 12,
  filter?: 'activity' | 'labels',
) =>
  axios
    .get<ResponseWrapperPaginated<UserNotification[]>>(
      `/users/${userId}/notifications`,
      { params: { page, size, filter } },
    )
    .then(({ data }) => ({
      notifications: data.data || [],
      pagination: data.pagination,
    }));

export const acceptTrackPull = async (id: number) =>
  axios.put(`pull/${id}/accept`).then(({ data }) => data);

export const declineTrackPull = async (id: number) =>
  axios.put(`pull/${id}/decline`).then(({ data }) => data);

export const getTrackPullStatus = async (id: number) =>
  axios
    .get<ResponseWrapper<TrackPullStatus>>(`pull/${id}`)
    .then(({ data }) => data.data);

interface Unread {
  total: number;
}

export const getUnreadNotificationsNumber = async () =>
  axios
    .get<ResponseWrapper<Unread>>('/notifications/unread')
    .then(({ data }) => data.data.total);

export const uploadPushDeviceToken = async (token: string, isIOS: boolean) =>
  axios.post('/push/user-device', {
    platform: isIOS ? 'ios' : 'android',
    device_token: token,
  });
