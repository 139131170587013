import styled, { css } from 'styled-components';
import {
  compose,
  typography,
  color,
  space,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';
import { media } from 'styled-bootstrap-grid';
import { style } from '../../api';

interface DynamicTitleProps {
  fontSize?: string;
  fontWeight?: number;
}

export const Title = styled.h1`
  margin: 0 !important;
  padding: 0;
  color: ${style.black};
  font-weight: 700;
  font-size: 52px;
  text-transform: capitalize;
  margin-bottom: 5px;

  ${media.xs`
    font-size: 43px;
  `}
`;

const standardText = css`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.39px;
  display: inline;
`;

export type TextProps = TypographyProps & ColorProps & SpaceProps;
export const Text = styled.div<TextProps>`
  ${standardText}
  margin-bottom: 12px;
  ${compose(typography, color, space)}
`;

export const TextNoMargin = styled.div<TextProps>`
  ${standardText}
  ${compose(typography, color, space)}
`;

const bold = css`
  color: ${style.black};
  font-weight: 600;
  text-decoration: none;
`;

export const Bold = styled.span`
  ${bold}
`;

const red = css`
  color: ${style.pink};
  font-weight: 600;
  text-decoration: none;
  margin-left: 5px;
`;

export const Red = styled.span`
  ${red}
`;

export const NoData = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 24px;
  font-weight: 700;
`;

export const CenteredTitle = styled(Title)`
  text-align: center;
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;

export const Notice = styled.div`
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  padding: 50px 20px 50px 0;
  text-align: center;
`;

export const TextRegular = styled.div<TextProps>`
  font-weight: 400;
  display: inline-block;
  ${compose(typography, color, space)}
`;

export const TextRegular10 = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

export const TextRegular12 = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const TextRegular14 = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const TextRegular16 = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`;

export const TextRegular18 = styled.span`
  font-size: 18px;
  font-weight: 400;
`;

export const TextRegular1dot2 = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;

export const TextRegular28 = styled.span`
  font-size: 28px;
  font-weight: 400;
`;

export const TextBold10 = styled.span`
  font-size: 10px;
  font-weight: 500;
`;

export const TextBold12 = styled.span`
  font-size: 12px;
  font-weight: 500;
`;
export const TextMedium12 = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const TextMedium28 = styled.span`
  font-size: 28px;
  font-weight: 500;
`;

export const TextBolder12 = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

export const TextBold14 = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const TextBolder14 = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

export const TextBold16 = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const TextBolder16 = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const TextBold18 = styled.span`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
`;
export const TextBold18NoSpacing = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

export const TextBolder18 = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

export const TextBolder22 = styled.span`
  font-size: 22px;
  font-weight: 700;
`;

export const TextBolder24 = styled.span`
  font-size: 24px;
  font-weight: 700;
`;

export const TextBold28 = styled.span`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextBold32 = styled.span`
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextBold22 = styled.span`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextRegular22 = styled.span`
  font-size: 22px;
  font-weight: 400;
`;
export const TextBold24 = styled.span`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextRegular24 = styled.span`
  font-size: 24px;
  font-weight: 500;
`;

export const TextRegular26 = styled.span`
  font-size: 26px;
  font-weight: 500;
`;

export const TextBold36 = styled.span`
  font-size: 36px;
  font-weight: 700;
`;

export const TextBold49 = styled.span`
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextBold64 = styled.span`
  font-size: 62px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const Pink = styled.span`
  color: ${style.primary_pink_new};
`;

export const TextMuted = styled.span`
  color: #1f1f1f;
  opacity: 50%;
  display: inline-block;
  margin-top: 20%;
`;

export const TitleBold21 = styled.h2`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBold28 = styled.h2`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBold46 = styled.h1`
  font-size: 46px;
  font-weight: 500;
  letter-spacing: -1px;
`;

export const TitleBolder40 = styled.h1`
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBolder18 = styled.h1`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBolder48 = styled.h1`
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBolder36 = styled.h1`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBolder56 = styled.h1`
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TitleBolder64 = styled.h1`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const TextWithGradient = styled.span`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const CustomTextWithGradient = styled.span<DynamicTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: -1;
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const CustomTitleH1 = styled.h1<DynamicTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: -1px;
`;
export const CustomTitleH2 = styled.h2<DynamicTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: -1px;
`;

export const CustomTitleH3 = styled.h3<DynamicTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: -1px;
`;
export const CustomTitleH4 = styled.h4<DynamicTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: -1px;
`;

export const CustomDescriptionP = styled.p<DynamicTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  line-height: 1.45;
`;
