export const pagesEverythingHiddenAllPlatforms = [
  '/register',
  '/register/email/initial',
  '/register/email/artist-name',
  '/register/email/personal',
  '/register/email/location',
  '/register/email/roles',
  '/register/success',
  '/reactivate',
  '/releases/[releaseId]',
  '/track-releases/[trackId]',
  '/track-release/[trackReleaseId]',
  '/distribution/completed',
  '/become-pro',
  '/distribution-landing/ad-landing',
  '/login',
  '/reset-password',
];

export const pagesWithHiddenHeader = [
  ...pagesEverythingHiddenAllPlatforms,
  '/login',
  '/pro-payment',
  '/pro-membership',
  '/my-pro',
];

export const pagesWithHiddenHeaderOnMobile = [
  ...pagesEverythingHiddenAllPlatforms,
  '/chat/[conversationId]',
  '/chat',
  '/chat/new',
];

export const pagesWithHiddenFooter = [
  ...pagesEverythingHiddenAllPlatforms,
  '/pro-membership',
  '/login',
  '/pro-payment',
  '/profile/edit',
  '/chat/[conversationId]',
  '/chat',
  '/chat/new',
  '/my-pro',
  '/my-tracks',
  '/distribution',
  '/edit-track-preview/[trackId]',
  '/thank-you-for-uploading',
  '/',
  '/password/reset/[tokenId]',
  '/upload-landing',
  '/ai-music-chat/[aiExpert]',
];

export const pagesWithHiddenFooterOnMobile = [
  ...pagesEverythingHiddenAllPlatforms,
  '/charts',
  '/explore',
  '/raffles',
  '/my-pro',
  '/my-tracks',
  '/chat/[conversationId]',
  '/chat',
  '/chat/new',
  '/distribution',
  '/edit-track/[trackId]',
  '/edit-track-preview/[trackId]',
  '/thank-you-for-uploading',
  '/',
  '/password/reset/[tokenId]',
  '/ai-music-chat/[aiExpert]',
];

export const pagesWithHiddenAudioPlayer = [
  ...pagesEverythingHiddenAllPlatforms,
  '/login',
  '/upload',
  '/edit-track/[trackId]',
  '/edit-track-preview/[trackId]',
  '/settings',
  '/settings/deactivate',
  '/settings/delete',
  '/distribution',
  '/thank-you-for-uploading',
  '/chat/[conversationId]',
  '/chat',
  '/chat/new',
  '/ai-music-chat/[aiExpert]',
];

export const pagesWithHiddenAudioPlayerOnMobile = [
  ...pagesEverythingHiddenAllPlatforms,
  '/login',
  '/upload',
  '/edit-track/[trackId]',
  '/chat/[conversationId]',
  '/chat',
  '/chat/new',
  '/edit-track-preview/[trackId]',
  '/settings',
  '/settings/deactivate',
  '/settings/delete',
  '/distribution',
  '/thank-you-for-uploading',
  '/ai-music-chat/[aiExpert]',
];
