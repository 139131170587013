import axios from './axios';
import { ResponseWrapperPaginated } from './ResponseWrapper';
import { Track } from './tracks';

export interface Like {
  user_id: number;
  track_id: number;
  created_at: string;
  updated_at: string;
  track: Track;
}

export const getLikes = async (id: number, page = 1) =>
  axios
    .get<ResponseWrapperPaginated<Like[]>>(`likes/${id}`, {
      params: { page },
    })
    .then(({ data }) => ({
      likes: data.data || [],
      pagination: data.pagination,
    }));
