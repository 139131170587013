import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '../../VuexyDesign/atoms/_index';
import { desktop, noDesktop } from '../../style/constants';
import MediaQuery from '../common/MediaQuery';
import isClientside from '../../utils/isClientside';
import { TextBold14 } from '../common/Text';

const LoginMenu = () => {
  const { t } = useTranslation('header');
  let hasLoggedInBefore = false;
  if (isClientside) {
    hasLoggedInBefore = Boolean(localStorage.getItem('hasLoggedInBefore'));
  }

  return (
    <Container>
      <MediaQuery query={desktop}>
        {hasLoggedInBefore ? (
          <>
            <Link href="/login" prefetch={false}>
              <Button
                color="primary"
                text={t('header:signIn') as string}
                className="mb-sm-0"
              />
            </Link>
          </>
        ) : (
          <>
            <Link href="/register" prefetch={false}>
              <Button
                color="primary"
                text={t('header:createAccount') as string}
                className="mb-sm-0"
              />
            </Link>
          </>
        )}
      </MediaQuery>
      <MediaQuery query={noDesktop}>
        <Link href="/login">
          <Button color="primary" className="ml-1">
            <TextBold14>{t('header:signIn')}</TextBold14>
          </Button>
        </Link>
      </MediaQuery>
    </Container>
  );
};

export default LoginMenu;

const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 15px;
  }
`;
