import axios from './axios';
import ResponseWrapper from './ResponseWrapper';
import { PaymentSystem } from './user';

export interface PaymentPlan {
  cost: number;
  description: string;
  discount_percentage: number | null;
  id: number;
  name: string;
  stripe_plan: string;
}

export interface PaymentPlanType {
  cost: number;
  name: string;
  monthly_cost?: string;
  yearly_cost: number;
  monthly_plan_id?: string;
  yearly_plan_id?: string;
  currency?: {
    currency?: string;
    currencySymbol?: string;
    price?: string;
    monthly_price?: string;
  };
}

export interface Subscription {
  id: number;
  user_id: number;
  name: string;
  stripe_id: string;
  stripe_status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid';
  stripe_plan: string;
  paypal_status:
    | 'approval_pending'
    | 'approved'
    | 'active'
    | 'suspended'
    | 'cancelled'
    | 'expired';
  quantity: number;
  trial_ends_at: number | null;
  ends_at: number | null;
  expires_at: number | null;
  created_at: number;
  updated_at: number;
  cancel_date?: number;
  payment_system: PaymentSystem;
  cancelled: boolean;
  plan_type: string;
  active: boolean;
  interval: 'YEAR' | 'HALF-YEARLY' | 'MONTH';
  amount?: number;
  currency?: string;
  processing?: boolean;
}

interface StripeSubscription {
  intent: {
    id: string;
    client_secret: string;
  };
}

interface CreateSessionArgs {
  plan_id: number;
  success_url: string;
  cancel_url: string;
  coupon?: string;
}

interface UpgradeSessionArgs {
  success_url: string;
  cancel_url: string;
  plan_type: string;
}

export interface VerifyReceiptBodyAndroid {
  productId: string;
  orderId: string;
  purchaseToken?: string;
  packageName: string;
}

export interface MobilePaymentVerificationResponse {
  membership_expires_at: number;
}

export interface PlanRules {
  plan: string;
  rules: string[];
  active: boolean;
}

export const getPaymentPlans = async (plan: string | undefined) =>
  axios
    .get<ResponseWrapper<PaymentPlan[]>>('/plans', { params: { plan } })
    .then(({ data }) => data.data);

export const getPaymentPlansTypes = async () =>
  axios
    .get<ResponseWrapper<PaymentPlanType[]>>('/plan-types')
    .then(({ data }) => data.data);

export interface UnsubscribeReason {
  id: number;
  name: string;
}

export const getUnsubscribeReasons = async () =>
  axios
    .get<ResponseWrapper<UnsubscribeReason[]>>('/cancel-reasons')
    .then(({ data }) => data.data);

export const selectPlan = async (planId: number) =>
  axios
    .get<ResponseWrapper<StripeSubscription>>(
      `/subscriptions/create?plan=${planId}`,
    )
    .then(({ data }) => data.data);

export const changePlan = async (plan_id: number, subsId: number) =>
  axios
    .patch<ResponseWrapper<{ subscription: Subscription }>>(
      `/subscriptions/${subsId}`,
      { plan_id },
    )
    .then(({ data }) => data);

export const getSubscription = async (
  plan_id: number,
  payment_method: string,
  code: string | null,
) =>
  axios
    .post<ResponseWrapper<{ subscription: Subscription }>>('/subscriptions', {
      plan_id,
      payment_method,
      code,
    })
    .then(({ data }) => data);

export const deleteSubscription = async (args: {
  reason?: string;
  cancel_reason_id?: number;
}) =>
  axios
    .delete<ResponseWrapper<boolean>>(`/subscriptions/cancel`, {
      data: args,
    })
    .then(({ data }) => data);

export const verifySubscriptionReceiptIOS = async (
  plan_name: string,
  receipt_data: string,
) =>
  axios
    .post<ResponseWrapper<MobilePaymentVerificationResponse>>(
      '/apple-pay/verify-receipt',
      { plan_name, receipt_data },
    )
    .then(({ data }) => data.data);

export const sendOriginalTransactionIdIOS = async (
  original_transaction_id?: string,
) =>
  axios
    .post('/apple-pay/send-transaction-id', { original_transaction_id })
    .then(({ data }) => data.data);

export const verifyVcoinsReceiptIOS = async (
  product_id: string,
  receipt_data: string,
) =>
  axios
    .post<ResponseWrapper<MobilePaymentVerificationResponse>>(
      '/apple-pay/verify-vcoins-receipt',
      { product_id, receipt_data },
    )
    .then(({ data }) => data.data);

export const verifySubscriptionReceiptAndroid = async (
  plan_id: string,
  verificationData: VerifyReceiptBodyAndroid,
) =>
  axios
    .post<ResponseWrapper<MobilePaymentVerificationResponse>>(
      '/google-pay/verify-receipt',
      { plan_id, ...verificationData },
    )
    .then(({ data }) => data.data);

export const verifyVcoinsReceiptAndroid = async (
  verificationData: VerifyReceiptBodyAndroid,
) =>
  axios
    .post<ResponseWrapper<MobilePaymentVerificationResponse>>(
      '/google-pay/verify-vcoins-receipt',
      verificationData,
    )
    .then(({ data }) => data.data);

export const createStripeSession = async (args: CreateSessionArgs) =>
  axios
    .post<ResponseWrapper<{ session_id: string }>>(
      `/subscriptions/create-session`,
      args,
    )
    .then((res) => res.data.data.session_id)
    .catch((err) => err);

export const createBillingPortalSession = async () =>
  axios
    .get<ResponseWrapper<{ url: string }>>(`/subscriptions/billing-portal`)
    .then((res) => res.data.data.url);

export const createSetupIntent = async (
  plan_id: number,
  coupon: string | undefined,
) =>
  axios
    .post<ResponseWrapper<{ client_secret: string }>>(
      '/subscriptions/create-setup-intent',
      {
        plan_id,
        coupon,
      },
    )
    .then((res) => res.data.data.client_secret);

export const addPaymentMethod = async (paymentMethodId: string) =>
  axios
    .post('/cards', { payment_method_id: paymentMethodId })
    .then((res) => res.data);

export const updatePaymentMethod = async ({
  idOfPaymentMethodToReplace,
  idOfNewPaymentMethod,
}: {
  idOfPaymentMethodToReplace: string;
  idOfNewPaymentMethod: string;
}) =>
  axios.patch(`/cards/${idOfPaymentMethodToReplace}`, {
    payment_method: idOfNewPaymentMethod,
  });

export const reactivate = async () => axios.put('/subscriptions/reactivate');

export const createPaypalUrl = async (args: CreateSessionArgs) =>
  axios
    .post<ResponseWrapper<{ paypal_url: string }>>(
      `/subscriptions/paypal/create`,
      args,
    )
    .then((res) => res.data.data.paypal_url);

export const upgradePaypalUrl = async (args: UpgradeSessionArgs) =>
  axios
    .post<ResponseWrapper<{ paypal_url: string }>>(
      `/subscriptions/paypal/upgrade`,
      args,
    )
    .then((res) => res.data.data.paypal_url);

export const downgradePaypalUrl = async (args: UpgradeSessionArgs) =>
  axios
    .post<ResponseWrapper<{ paypal_url: string }>>(
      `/subscriptions/paypal/downgrade`,
      args,
    )
    .then((res) => res.data.data.paypal_url);

export const getPlanDifference = async (planType: string) =>
  axios
    .get<ResponseWrapper<{ amount: number }>>(`/plans/difference`, {
      params: { plan_type: planType },
    })
    .then((res) => res.data.data.amount);

export const upgradeStripeUrl = async (planType: string) =>
  axios
    .patch<ResponseWrapper<unknown>>(`/subscriptions/upgrade`, {
      plan_type: planType,
    })
    .then((res) => res.data);

export const downgradeStripeUrl = async (planType: string) =>
  axios
    .patch<ResponseWrapper<unknown>>(`/subscriptions/downgrade`, {
      plan_type: planType,
    })
    .then((res) => res.data);

export const getPlanRules = async () =>
  axios
    .get<ResponseWrapper<PlanRules[]>>(`/plans/abilities`)
    .then((res) => res.data.data);

export const getShowPaymentModal = async () =>
  axios
    .get<ResponseWrapper<{ showModal: boolean }>>('/show-payment-modal')
    .then(({ data }) => data.data);
