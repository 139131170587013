import React from 'react';
import styled from 'styled-components';
import enhanceWithClickOutside from 'react-onclickoutside';
import Router, { withRouter, NextRouter } from 'next/router';
import Link from 'next/link';
import Trans from 'next-translate/Trans';
import { MessageSquare } from 'react-feather';
import { style, Conversation } from '../../../api';
import Notification from './NotificationIndicator';
import { ProfileLink } from '../../common/Link';
import UserImage from '../../common/UserImage';
import { CenteredLoader } from '../../common/Loader';

export interface StateProps {
  conversations: Record<number, Conversation>;
  fetching: boolean;
  userId?: number;
}

export interface DispatchProps {
  fetchConversations: () => void;
}

export interface OwnProps {
  newMessages?: number;
}

type Props = StateProps & DispatchProps & OwnProps & { router: NextRouter };

interface State {
  showChatDropdown: boolean;
}

class ChatMenu extends React.Component<Props, State> {
  state: State = {
    showChatDropdown: false,
  };

  componentDidMount() {
    const { fetchConversations } = this.props;
    fetchConversations();

    Router.events.on('routeChangeStart', this.handleRouteChangeStart);
  }

  componentWillReceiveProps({ userId: nextUserId }: Props) {
    const { fetchConversations, userId } = this.props;
    if (nextUserId !== userId) {
      fetchConversations();
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleRouteChangeStart);
  }

  handleRouteChangeStart = () => {
    this.setShowChatDropdown(false);
  };

  setShowChatDropdown = (show: boolean) =>
    this.setState({ showChatDropdown: show });

  handleClickOutside = () => this.setShowChatDropdown(false);

  toggleChatMenu = () =>
    this.setState((state) => ({
      showChatDropdown: !state.showChatDropdown,
    }));

  filterConversations = (conversations: Record<number, Conversation>) => {
    const { userId } = this.props;
    return Object.keys(conversations)
      ?.filter((key) => conversations[Number(key)].user?.id !== userId)
      ?.map((key) => conversations[Number(key)])
      ?.slice(0, 3);
  };

  render() {
    const { showChatDropdown } = this.state;
    const { conversations, fetching, newMessages } = this.props;

    return (
      <Container open={showChatDropdown}>
        <MessageSquare size={25} onClick={this.toggleChatMenu} />
        {newMessages !== 0 && (
          <>
            <Notification>
              <NotificationsCount>{newMessages}</NotificationsCount>
            </Notification>
          </>
        )}
        {showChatDropdown && (
          <DropdownContainer>
            <DropdownInnerContainer>
              {fetching ? (
                <CenteredLoader style={{ paddingTop: 10 }} />
              ) : (
                this.filterConversations(conversations).map((conversation) => (
                  <DropdownItem
                    key={conversation.id}
                    conversation={conversation}
                  />
                ))
              )}
            </DropdownInnerContainer>
            <Link href="/chat">
              <ViewAllRow>
                <Trans i18nKey="header:viewAll" />
              </ViewAllRow>
            </Link>
          </DropdownContainer>
        )}
      </Container>
    );
  }
}

const DropdownItem = ({ conversation }: { conversation: Conversation }) => (
  <DropdownItemWrapper>
    <ProfileLink
      profileId={conversation.user.id}
      className="user-image-with-link"
    >
      <UserImage size={35} src={conversation.user.profile_photo} />
    </ProfileLink>
    <Link href={`/chat/${conversation.id}`}>
      <ChatMessage>
        <span className="font-weight-bold">
          {conversation.user.display_name}
        </span>
        <span>{conversation?.last_reply_message}</span>
      </ChatMessage>
    </Link>
  </DropdownItemWrapper>
);

export default withRouter(enhanceWithClickOutside(ChatMenu));

const DropdownInnerContainer = styled.div`
  overflow-y: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: auto;
  background-color: ${style.white};

  padding: 5px 0px;
`;

const Container = styled.div<{ open: boolean }>`
  position: relative;
  cursor: pointer;

  padding: 8px;
`;

const NotificationsCount = styled.span`
  font-size: 10px !important;
  position: relative;
  bottom: 2px;
  color: #ffffff;
  z-index: 999;
`;

const ViewAllRow = styled.div`
  min-height: 50px;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${style.primary_pink_new};
  font-size: 0.9rem;
  font-weight: 500;
  background-color: ${style.white};
  transition: 0.2s;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  :hover {
    color: black;
    background: whitesmoke;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 55px;
  border-radius: 10px;
  right: -120px;
  width: 440px;
  display: flex;
  flex-direction: column;
  box-shadow: ${style.lightBoxShadow};

  max-height: 450px;
`;

const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: whitesmoke;
  }
`;

const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 10px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
