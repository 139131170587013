import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import styled from 'styled-components';
import NavLink from 'next/link';
import { useRouter } from 'next/router';
import { Menu } from '@styled-icons/boxicons-regular/Menu';
import { Close } from '@styled-icons/material/Close';
import useTranslation from 'next-translate/useTranslation';
import { Globe } from 'react-feather';
import { style } from '../../api';
// import HowItWorksDropdown from './HowItWorksDropdown';
// import DiscoverDropdown from '../../VuexyDesign/molecules/DiscoverDropdown';
import {
  noDesktop,
  desktop,
  linkActiveClassname,
  useIsMobile,
  MobileContainer,
  DesktopContainer,
  useIsDesktop,
} from '../../style/constants';
import SearchBox from '../SearchBox';
import { Logged } from '../../utils/Logged';
import UserMenu from './UserMenu';
// import MobileMenu from './MobileMenu';
import BlackLayer from '../common/BlackLayer';
import LoginMenu from './LoginMenu';
import useDisableBackgroundScrolling from '../../hooks/useDisableBackgroundScrolling';
import { LanguageSpikeModal } from '../../VuexyDesign/organisms/LanguageSpikeModal';
import Flex from '../common/Flex';
// import LanguageSwitch from '../../VuexyDesign/organisms/LanguageSwitch';
// import DistributionHeaderDropdown from '../../VuexyDesign/molecules/DistributionHeaderDropdown';

const HowItWorksDropdown = dynamic(() => import('./HowItWorksDropdown'), {
  ssr: false,
});
const DiscoverDropdown = dynamic(
  () => import('../../VuexyDesign/molecules/DiscoverDropdown'),
  { ssr: false },
);
const MobileMenu = dynamic(() => import('./MobileMenu'), { ssr: false });

const DistributionHeaderDropdown = dynamic(
  () => import('../../VuexyDesign/molecules/DistributionHeaderDropdown'),
  { ssr: false },
);

const Header = () => {
  const isDesktop = useIsDesktop();
  const [showLinks, setShowLinks] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const handleLanguageModalClick = () => {
    setIsLanguageModalOpen(true);
  };

  const handleLanguageModalClose = () => {
    setIsLanguageModalOpen(false);
  };

  const toggleLinks = (willShowLinks: boolean) => {
    setShowLinks(willShowLinks);
  };

  const openMobileMenu = () => setShowMobileMenu(true);
  const closeMobileMenu = () => setShowMobileMenu(false);

  const router = useRouter();

  if (router.events) {
    router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
    });
  }

  const isMobile = useIsMobile();
  useDisableBackgroundScrolling(isMobile && showMobileMenu);

  return (
    <>
      <Flex flexDirection="column" zIndex={30} as="header">
        <HeaderContainer>
          <Left>
            <MobileContainer>
              {showMobileMenu ? (
                <Close
                  size={27}
                  onClick={closeMobileMenu}
                  style={{ margin: 21, marginLeft: 0, marginRight: 14 }}
                />
              ) : (
                <Menu
                  size={27}
                  onClick={openMobileMenu}
                  style={{ margin: 21, marginLeft: 0, marginRight: 14 }}
                />
              )}
            </MobileContainer>
            <NavLink href="/">
              <LogoContainer>
                <Image
                  src="/static/images/logo@2x.png"
                  alt="VIRPP"
                  priority
                  fill
                  className="pointer-events-none object-fit-cover"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </LogoContainer>
            </NavLink>
            {showLinks && (
              <DesktopContainer>
                <Logged
                  loggedOut={<LoggedOutMenu />}
                  loggedIn={<LoggedInMenu />}
                />
              </DesktopContainer>
            )}
          </Left>

          <Center>
            {isDesktop ? (
              <>
                <SearchBox toggleLinks={toggleLinks} showLinks={showLinks} />
              </>
            ) : null}
          </Center>

          <Right className="text-nowrap">
            {isDesktop && <HowItWorksDropdown />}
            <>
              <Logged
                loggedOut={
                  <>
                    {' '}
                    {isDesktop ? (
                      <>
                        <Globe
                          size={18}
                          onClick={handleLanguageModalClick}
                          className="mr-1 cursor-pointer"
                        />
                        <LanguageSpikeModal
                          isOpen={isLanguageModalOpen}
                          close={handleLanguageModalClose}
                        />
                      </>
                    ) : null}
                    <LoginMenu />
                  </>
                }
                loggedIn={<UserMenu />}
              />
            </>
          </Right>
          <MobileContainer>
            {showMobileMenu && <BlackLayer onClick={openMobileMenu} />}
            {showMobileMenu && (
              <MobileMenu
                close={closeMobileMenu}
                handleClickOutside={() => null}
              />
            )}
          </MobileContainer>
        </HeaderContainer>
      </Flex>
    </>
  );
};

const LoggedInMenu = () => (
  <HeaderNav>
    <>
      <DiscoverDropdown />
      <DistributionHeaderDropdown />
    </>
  </HeaderNav>
);

const LoggedOutMenu = () => {
  const { t } = useTranslation('header');
  return (
    <HeaderNav>
      <>
        <DiscoverDropdown />
        <DistributionHeaderDropdown />
        <a
          href="https://business.virpp.com/login"
          style={{ color: '#0A5AF5 ' }}
          className="text-nowrap"
          target="_blank"
          rel="noreferrer"
        >
          {t('header:tabs.forProfessionals')}
        </a>
      </>
    </HeaderNav>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid whitesmoke;

  & a,
  button {
    font-size: 16px;
    border-radius: 2px;
  }

  & span {
    font-size: 16px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 71px;
`;

const Center = styled.div`
  justify-content: flex-end;
  width: 100%;
`;

const Right = styled.div`
  display: flex;
  align-items: center;

  @media ${desktop} {
  }

  @media ${noDesktop} {
    flex-basis: 0%;
  }

  justify-content: flex-end;
`;

const LogoContainer = styled.div`
  position: relative;
  height: 28px;
  width: 120px;
  margin-right: 28px;

  @media ${noDesktop} {
    width: 120px;
    text-align: center;
  }

  :hover {
    cursor: pointer;
  }
`;

const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-weight: 500;
  & a {
    color: ${style.blackThree};
    text-decoration: none;
    margin-right: 2rem;
  }

  a.${linkActiveClassname} {
    color: ${style.pink};
  }
`;

export default Header;
