import axios from './axios';
import ResponseWrapper from './ResponseWrapper';
import { Country } from './countries';
import { PlanRules, Subscription } from './subscriptions';
import { Genre } from './genres';
import { Track } from './tracks';
import { SocialItem } from './users';
import { Pagination } from './Pagination';

export interface UserSimple {
  key: string;
  id: number;
  username: string;
  display_name: string;
  profile_photo: string;
  role: Role | null;
}

export type Gender = 'male' | 'female';

export interface User extends UserSimple {
  abilities: PlanRules;
  age: number;
  first_name: string;
  last_name: string;
  intro_text: string;
  description: string;
  about: string;
  country: Country;
  city?: string;
  profile_cover: string;
  profile_cover_original?: string;
  profile_photo_original?: string;
  birthday: string;
  total_followers: number;
  followers: string;
  playlists: string;
  gender: Gender;
  email: string;
  tracks: string;
  website: string;
  bandcamp?: string;
  applemusic?: string;
  facebook: string;
  twitter: string;
  gplus: string;
  youtube: string;
  instagram?: string;
  soundcloud: string;
  spotify?: string;
  logged_in_user_follows_user?: boolean;
  created_at: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  settings?: Settings;
  member: boolean;
  views: number;
  label_views: number;
  subscription?: Subscription;
  following: string;
  instructed?: boolean;
  blocked: boolean;
  producer_level?: ProducerLevel;
  listener_level: ListenerLevel;
  star_rating: number;
  team?: string;
  card_brand?: string;
  card_last_four?: number;
  membership_expires_at?: number;
  payment_system?: PaymentSystem;
  share: {
    fb_share_1200x630: string;
    ig_share_1080x1920: string;
    ig_share_other_1080x1920: string;
    share_1598x1598: string;
  };
  total_vcoins: number;
  total_likes_given: number;
  total_played_time: number;
  total_track_plays: number;
  total_plays: number;
  total_likes: number;
  genres: Genre[];
  conversation_id?: number;
  plan_id?: number;
  plays_count: number;
  likes_count: number;
  tracks_archived?: boolean;
  total_tracks: number;
  total_labels_played_your_tracks: number;
  profile_completion: ProfileCompletion;
  status?: string;
  buffer_days: number;
  producer_roles?: ProducerRole[];
  track_release_pending: boolean;
  track_release_released: boolean;
  check_payment_status?: boolean;
  phone?: string;
  is_verified: boolean;
}

export interface ProfileCompletion {
  details: boolean;
  profile_pics: boolean;
  profile_img: boolean;
  header_img: boolean;
  track_upload: boolean;
  shared_profile: boolean;
  overall_percentage: number;
}

export type Role = 'label' | 'producer' | 'listener' | 'admin';
export type ProducerLevel = 'producer' | 'experienced' | 'master';
export type ListenerLevel = 'listener' | 'music_expert' | 'master_ar';
export type PaymentSystem =
  | 'apple'
  | 'stripe'
  | 'android'
  | 'free'
  | 'paypal'
  | 'ideal';

export interface UpdateUserFormData {
  username?: string;
  display_name?: string;
  password?: string;
  password_confirmation?: string;
  email?: string;
  about?: string;
  location?: number;
  birthday?: string;
  city?: string;
  gender?: Gender;
  first_name?: string;
  last_name?: string;
  role_id?: 2 | 3 | 4 | null;
  website?: string;
  facebook?: string;
  twitter?: string;
  gplus?: string;
  youtube?: string;
  instagram?: string;
  soundcloud?: string;
  spotify?: string;
  roles?: number[];
  phone?: string;
}
export interface UpdateSettingsFormData {
  comments_notification_on?: boolean;
  followers_notification_on?: boolean;
  label_plays_notification_on?: boolean;
  likes_notification_on?: boolean;
  pulls_notification_on?: boolean;
  share_my_data?: boolean;
  ux_improvement_program?: boolean;
}

export interface Settings1 {
  id?: number;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
}

export type Settings = Settings1 & UpdateSettingsFormData;

export interface UpdateSocialSettingsFormData {
  website?: string;
  facebook?: string;
  twitter?: string;
  gplus?: string;
  youtube?: string;
  instagram?: string;
  soundcloud?: string;
  spotify?: string;
}

export type SimpleTrack = Pick<Track, 'id' | 'title' | 'track_img'>;

export interface Card {
  brand: 'visa' | 'master_card';
  default: boolean;
  exp_month: number;
  exp_year: number;
  funding: string;
  id: string;
  name: string;
  last_four: string;
  failed: boolean;
  failure_message?: string;
}

export interface UsersList {
  id: number;
  last_seen_online?: string;
  username: string;
  display_name: string;
  profile_photo: string;
  role?: string | null;
  country: string;
  city: string;
  age: number;
  logged_in_user_follows_user: boolean;
}

export interface UsersListResponse {
  data: UsersList[];
  pagination: Pagination;
}

export const getCurrent = async (
  token?: string,
  checkPaymentStatus?: boolean,
) =>
  axios
    .get(
      '/user',
      token
        ? {
            headers: {
              Authorization: token,
            },
            params: {
              check_payment_status: checkPaymentStatus,
            },
          }
        : {
            params: {
              check_payment_status: checkPaymentStatus,
            },
          },
    )
    .then(({ data }: { data: ResponseWrapper<User> }) => data.data);

export const getCurrentWithoutAxios = async (token?: string) =>
  fetch(`${process.env.REACT_APP_API_URL}/user`, {
    method: 'GET', // or 'POST' or other HTTP methods
    headers: {
      Authorization: `${token}`,
      'Content-Type': 'application/json', // adjust the content type if needed
    },
    // other options like body, mode, etc.
  })
    .then((response) => response.json())
    .then((data) => data);

export type AwardType =
  | 'a_and_r'
  | 'most_played'
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'diamond'
  | 'helper'
  | 'mentor'
  | 'expert'
  | 'guru'
  | 'keen_listener'
  | 'scout'
  | 'digger'
  | 'master';

export interface AwardWithoutTrack {
  timestamp: number;
  type: AwardType;
  track_id: undefined;
  share: {
    ig_1080x1920?: string;
  };
}

export interface AwardWithTrack {
  timestamp: number;
  type: AwardType;
  track_id: number;
  track?: Track;
}

export type Award = AwardWithTrack | AwardWithoutTrack;

export const awardCategories = ['tracks', 'feedback', 'listener'] as const;

export type AwardCategory = typeof awardCategories[number];

export interface CloseToAward {
  type: AwardType;
  award?: string;
  required?: number;
  left?: number;
}

export interface CloseToOrAward {
  type: AwardType;
  award?: string;
  required?: number;
  left?: number;
  track_id?: number;
}

export type CloseToAwards = Record<AwardCategory, CloseToAward>;

export const updateUser = async (
  userId: number,
  formData: Partial<UpdateUserFormData>,
) =>
  axios
    .put<ResponseWrapper<User>>(`users/${userId}`, formData)
    .then(({ data }) => data.data);

export const getSettings = async (userId: number) =>
  axios
    .get<ResponseWrapper<Settings>>(`users/${userId}/settings`)
    .then(({ data }) => data.data);

export const updateSettings = async (
  userId: number,
  formData: UpdateSettingsFormData,
) =>
  axios
    .patch<ResponseWrapper<Settings>>(`users/${userId}/settings`, formData)
    .then(({ data }) => data.data);

export const updatePhoto = async (userId: number, profile: FormData) =>
  axios
    .post<ResponseWrapper<User>>(`users/${userId}/profile`, profile)
    .then(({ data }) => data.data);

export const updateCover = async (userId: number, cover: FormData) =>
  axios
    .post<ResponseWrapper<User>>(`users/${userId}/cover`, cover)
    .then(({ data }) => data.data);

export const removeCover = async (userId: number) =>
  axios
    .post<ResponseWrapper<User>>(`users/${userId}/cover`, null)
    .then(({ data }) => data.data);

export const updateSocial = async (
  userId: number,
  formData: UpdateSocialSettingsFormData,
) =>
  axios
    .patch<ResponseWrapper<User>>(`users/${userId}/social`, formData)
    .then(({ data }) => data.data);

export interface Report {
  body: string;
  email: string;
  phone_number?: string;
  information_check: boolean;
  terms_check: boolean;
}

export const reportUser = async (
  id: number,
  { body, email, phone_number, information_check, terms_check }: Report,
) =>
  axios
    .post(`/user/${id}/report`, {
      body,
      email,
      phone_number,
      information_check,
      terms_check,
    })
    .then(({ data }) => data);

export const getTracksAll = async (id: number, token?: string) =>
  axios
    .get<ResponseWrapper<SimpleTrack[]>>(
      `user/${id}/tracks/all`,
      token ? { headers: { authorization: token } } : undefined,
    )
    .then(({ data }) => data.data);

export const confirmInstructedStatus = async () =>
  axios.patch('/user/instructed').then(() => true);

export const loadAwards = async (userId: number) =>
  axios
    .get<ResponseWrapper<Award[]>>(`/user/${userId}/awards`)
    .then((response) => response.data.data);

export const loadCloseToAwards = async (userId: number) =>
  axios
    .get<ResponseWrapper<CloseToAwards>>(`/user/${userId}/close-to-awards`)
    .then((response) => response.data.data);

export const linkFacebook = async (accessToken: string) =>
  axios
    .post<ResponseWrapper<User>>('/users/saveFacebookUrl', { accessToken })
    .then(({ data }) => data.data);

export const linkSpotify = async (accessToken: string, refreshToken: string) =>
  axios
    .post<ResponseWrapper<{ new_user: boolean; user: User }>>('/spotifyLogin', {
      accessToken,
      refreshToken,
    })
    .then(({ data, headers }) => ({
      user: data.data.user,
      token: headers.authorization as string,
    }));

export const signInWithGoogle = async (id_token: string) =>
  axios
    .get<ResponseWrapper<{ new_user: boolean }>>('/googleLogin', {
      params: { id_token },
    })
    .then(({ data, headers }) => ({
      ...data,
      token: headers.authorization as string,
    }));

export const addSocialInfo = async (socialItem: Omit<SocialItem, 'id'>) =>
  axios.post('/socials', socialItem);

export const updateSocialInfo = async (socials: SocialItem[]) =>
  axios.patch('/socials', { socials }).then(() => true);

export const removeSocialInfo = async (socialItemId: number) =>
  axios.delete(`/socials/${socialItemId}`);

export interface NotificationSettings {
  comments_notification_on: boolean;
  followers_notification_on: boolean;
  label_plays_notification_on: boolean;
  likes_notification_on: boolean;
  pulls_notification_on: boolean;
  chat_notification_on: boolean;
  vcoin_notification_on: boolean;
  award_notifications_on: boolean;
  push_notifications_on: boolean;
  email_notifications_on: boolean;
  share_my_data: boolean;
  ux_improvement_program: boolean;
  new_milestone_on: boolean;
  new_feature_on: boolean;
  new_raffle_on: boolean;
  tips_and_offers_on: boolean;
  surveys_and_feedbacks_on: boolean;
  suggested_content_on: boolean;
}

export const getNotificationSettings = (userId: number) =>
  axios
    .get<ResponseWrapper<NotificationSettings>>(`/users/${userId}/settings`)
    .then((res) => res.data.data);

export const updateNotificationSettings = (
  userId: number,
  settings: Partial<NotificationSettings>,
) => axios.patch(`/users/${userId}/settings`, settings);

export const getCards = () =>
  axios.get<ResponseWrapper<Card[]>>('/cards').then((res) => res.data.data);

export const setCardAsPrimary = (id: string) => axios.put(`/cards/${id}`);

export const deleteCard = (id: string) => axios.delete(`/cards/${id}`);

export interface Invoice {
  date: number;
  hosted_invoice_url: string;
  invoice_pdf: string;
  payment: number;
  status: 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
}

export const getInvoices = () =>
  axios
    .get<ResponseWrapper<Invoice[]>>('/user/invoices')
    .then((res) => res.data.data);

export const tracksArchivedNotified = (userId: number) =>
  axios
    .put<ResponseWrapper<Invoice[]>>(`/users/${userId}/archived-tracks`)
    .then((res) => res.data.data);

export const sendOtp = async () => axios.post('/otp/send').then(() => true);

export const loginUserWithCode = async (body: { code: string }) =>
  axios.post('otp/login', body);

export const deactivateAccount = async (userId: number, otp: string) =>
  axios.put(`/users/${userId}/deactivate`, { code: otp }).then(() => true);

export const deleteAccount = async (userId: number, otp: string) =>
  axios
    .delete(`/users/${userId}/delete`, {
      data: {
        code: otp,
      },
    })
    .then(() => true);

export const reactivateAccount = async (userId: number, otp: string) =>
  axios.put(`/users/${userId}/reactivate`, { code: otp }).then(() => true);

export interface ProducerRole {
  id: number;
  name: string;
}

export const reportProfileShare = async () =>
  axios
    .patch<ResponseWrapper<{ profile_completion: ProfileCompletion }>>(
      '/user/shared-profile',
    )
    .then(({ data }) => data.data);

export const getProducerRoles = async () =>
  axios
    .get<ResponseWrapper<ProducerRole[]>>('/roles')
    .then(({ data }) => data.data);

export interface UserPaymentMethod {
  payment_type?: 'bank' | 'paypal';
  bank_name?: string;
  account_number?: string;
  iban_code?: string;
  country?: string;
  paypal_email?: string;
}

export const getUserPaymentMethod = async () =>
  axios
    .get<ResponseWrapper<UserPaymentMethod>>('user/payment-info')
    .then(({ data }) => data);

export interface ReferalLink {
  affiliate_link: string;
}

export const getUserAffiliateLink = async () =>
  axios
    .get<ResponseWrapper<ReferalLink>>('user/affiliate')
    .then(({ data }) => data.data);

export const getUsersList = async ({
  username,
  role,
  page = 1,
  location,
  genre,
  sort,
  perPage = 25,
}: {
  username?: string;
  role?: string;
  page: number;
  location?: string;
  genre?: string;
  sort?: string;
  perPage?: number;
}): Promise<UsersListResponse> =>
  axios({
    method: 'GET',
    url: `users`,
    params: {
      username,
      page,
      role,
      location,
      genre,
      sort,
      perPage,
    },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));
