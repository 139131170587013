import React, { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {
  LabelWidgetSettings,
  LabelWidgetTrack,
  LabelWidgetTheme,
} from '../../../api/lib/api/widget';

import {
  labelWidgetActions,
  selectors,
  style,
  useAuthentication,
  useDispatch,
  useSelect,
} from '../../../api';

import Flex from '../../common/Flex';
import Loader from '../../common/Loader';
import { TextRegular, TextNoMargin } from '../../common/Text';
import { Close, LeftArrowAlt } from '../../common/Icons';
import TransparentButton from '../../common/Buttons/TransparentButton';
import { useCheckCookiesAvailable } from '../../../hooks/useCheckCookiesAvailable';
import isClientside from '../../../utils/isClientside';
import { useLabelWidgetSettings } from '../../../hooks/widget';

const defaultTheme: LabelWidgetTheme = 'light';

const wrongRedirectUrl = '/widgets/label-upload/wrong-redirect';

interface LabelUploadContext {
  apikey?: string;
  labelSettings?: LabelWidgetSettings;
  track?: LabelWidgetTrack;
  theme: LabelWidgetTheme;
  getTrackHash: () => string | undefined;
  setTrackHash: (trackHash?: string) => void;
  flushTrackHash: () => void;
  getTextColor: () => string;
  getBackgroundColor: () => string;
  getRedirectUrl: () => string;
}

const LabelUploadContext = React.createContext<LabelUploadContext>({
  apikey: undefined,
  labelSettings: undefined,
  track: undefined,
  theme: defaultTheme,
  setTrackHash: () => undefined,
  getTrackHash: () => undefined,
  flushTrackHash: () => undefined,
  getTextColor: () => style.black_new,
  getBackgroundColor: () => style.label_widget_light_bg,
  getRedirectUrl: () => wrongRedirectUrl,
});

export const useLabelWidget = () => React.useContext(LabelUploadContext);

export const LabelUploadWidget = dynamic<{
  children: React.ReactNode;
  noFrame?: boolean;
}>(
  Promise.resolve((props: { children: React.ReactNode; noFrame?: boolean }) => {
    const cookiesAvailable = useCheckCookiesAvailable();
    const labelSettings = useSelect(
      (state) => state.labelWidget?.labelSettings,
    );
    const backgroundColor = getBackgroundColor(
      labelSettings?.layout?.theme || defaultTheme,
    );

    const textColor = getTextColor(
      labelSettings?.layout?.theme || defaultTheme,
    );

    if (!isClientside) {
      return null;
    }

    if (!cookiesAvailable) {
      return (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          backgroundColor={backgroundColor}
        >
          <TextNoMargin color={textColor} fontSize="20px" textAlign="center">
            <Trans i18nKey="widget:enableCookies" />
          </TextNoMargin>
        </Flex>
      );
    }

    return <LabelUploadWidgetInner {...props} />;
  }),
  { ssr: false },
);

/**
 * Provider / Context for handeling logic of LabelUploadWidget.
 */
export const LabelUploadWidgetInner = ({
  children,
  noFrame,
}: {
  children: React.ReactNode;
  noFrame?: boolean;
}) => {
  const { t } = useTranslation('widget');
  const router = useRouter();
  const dispatch = useDispatch();
  const [apikey, setApikey] = useState(
    Array.isArray(router.query?.apikey) ? undefined : router.query?.apikey,
  );
  const [prevRoute, setPrevRoute] = useState<unknown>(router.asPath);
  const {
    data: labelSettings,
    isFetching: isFetchingLabelSettings,
    error: errorLabelSettings,
  } = useLabelWidgetSettings(apikey);
  const theme: LabelWidgetTheme = labelSettings?.layout?.theme || 'dark';
  const { authenticated } = useAuthentication();

  const setTrackHash = useCallback((hash?: string) => {
    if (!hash) {
      flushTrackHash();
    } else {
      localStorage.setItem('track_hash', hash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTrackHash = useCallback(() => {
    if (process.browser) {
      const trackHash = localStorage.getItem('track_hash');
      return trackHash === null ? undefined : trackHash;
    }
    return undefined;
  }, []);

  const flushTrackHash = useCallback(() => {
    localStorage.removeItem('track_hash');
  }, []);

  const track = useSelect(selectors.selectTrackByWidgetHash(getTrackHash()));

  useEffect(() => {
    const apikeyStorage = localStorage.getItem('widget-apikey');

    if (apikeyStorage) {
      setApikey(apikeyStorage);
    }
  }, []);

  const closeWidget = () => {
    const shouldReset = router.pathname.endsWith('success');
    if (typeof window.parent !== 'undefined') {
      window.parent.postMessage(
        {
          type: 'IFRAME_CLOSE',
          reset: shouldReset,
        },
        '*',
      );
    }
    localStorage.removeItem('widget');
  };

  useEffect(() => {
    if (apikey) {
      localStorage.setItem('widget-apikey', apikey);
      setApikey(apikey);
    }
  }, [apikey]);

  /**
   * Fetch label widget settings if we have a new API key or token, fetch track if we have a widget_hash.
   */
  useEffect(() => {
    const widget_hash = getTrackHash();
    if (widget_hash) {
      dispatch(labelWidgetActions.fetchLabelWidgetTrack(widget_hash));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apikey, authenticated]);

  /**
   * Keep track of previous route so we know when to render back button.
   */
  useEffect(
    () => () => {
      if (router.asPath) {
        setPrevRoute(router.asPath);
      }
    },
    [router?.asPath],
  );

  const getRedirectUrl = () =>
    apikey
      ? `/widgets/label-upload/${apikey}/contest-or-demo`
      : wrongRedirectUrl;

  const showBackButton =
    prevRoute !== router.asPath &&
    router.asPath !== `/widgets/label-upload/${apikey}/contest-or-demo` &&
    router.asPath !== `/widgets/label-upload/${apikey}`;

  const renderContent = () => {
    if (errorLabelSettings) {
      return (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <TextRegular fontWeight="bold">
            {t('widget:apiInvalid')} {errorLabelSettings}
          </TextRegular>
        </Flex>
      );
    }

    if (!labelSettings || isFetchingLabelSettings) {
      return (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Loader color={labelSettings?.primary_color} />
        </Flex>
      );
    }

    if (labelSettings && labelSettings.enable === false) {
      return (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <TextRegular fontWeight="bold">
            {t('widget:widgetDisabled')}
          </TextRegular>
        </Flex>
      );
    }

    return children;
  };

  return (
    <LabelUploadContext.Provider
      value={{
        labelSettings,
        apikey,
        getRedirectUrl,
        setTrackHash,
        getTrackHash,
        getBackgroundColor: () => getBackgroundColor(theme),
        getTextColor: () => getTextColor(theme),
        flushTrackHash,
        track,
        theme,
      }}
    >
      {noFrame ? (
        children
      ) : (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          flexDirection="column"
          backgroundColor={getBackgroundColor(theme)}
          color={getTextColor(theme)}
        >
          {labelSettings?.type === 'widget' && (
            <Flex
              width="100%"
              alignItems="flex-end"
              justifyContent={showBackButton ? 'space-between' : 'flex-end'}
            >
              {showBackButton && (
                <TransparentButton ml={2} onClick={() => router.back()}>
                  <LeftArrowAlt color={getTextColor(theme)} size={24} />
                  <TextRegular fontWeight="bold" color={getTextColor(theme)}>
                    {t('widget:goBack')}
                  </TextRegular>
                </TransparentButton>
              )}
              <TransparentButton onClick={() => closeWidget()}>
                <Close color={getTextColor(theme)} size="2rem" />
              </TransparentButton>
            </Flex>
          )}
          <Flex
            paddingTop={labelSettings?.type === 'widget' ? 0 : 3}
            width="100%"
            height="100%"
            flex={1}
            overflow="auto"
          >
            {renderContent()}
          </Flex>
        </Flex>
      )}
    </LabelUploadContext.Provider>
  );
};

const getBackgroundColor = (theme: LabelWidgetTheme) => {
  if (theme === 'dark') {
    return style.label_widget_dark_bg;
  }
  return style.label_widget_light_bg;
};

const getTextColor = (theme: LabelWidgetTheme) => {
  if (theme === 'dark') {
    return style.secondary_white_new;
  }
  return style.black_new;
};
