import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import dayjs from 'dayjs';
import { useCurrentUser } from './user';
import { API } from '../../index';
import * as subscriptionAPI from '../../lib/api/subscriptions';
import { Card, getCards } from '../../lib/api/user';
import { useAuthentication } from './authentication';

const identifier = 'subscriptions';

export const useQueryPaymentPlans = (plan?: string) =>
  useQuery(
    [identifier, 'payment-plans'],
    () => subscriptionAPI.getPaymentPlans(plan),
    {
      staleTime: Infinity,
    },
  );

export const useUnsubscribeReasons = () =>
  useQuery(
    [identifier, 'cancel-reasons'],
    API.subscriptions.getUnsubscribeReasons,
    {
      staleTime: Infinity,
    },
  );

export const stripe_status_errors = [
  'past_due',
  'incomplete',
  'incomplete_expired',
  'unpaid',
];

export const useHasPaymentError = () => {
  const currentUser = useCurrentUser();
  if (!currentUser || !currentUser.subscription) {
    return false;
  }

  const {
    subscription: { stripe_status, payment_system },
  } = currentUser;

  /**
   * Don't bother checking for errors in free subscriptions.
   *
   * - "Dobby is free!"
   */
  if (payment_system === 'free') {
    return false;
  }

  /**
   * If it's stripe check for possible errors
   */
  return (
    payment_system === 'stripe' && stripe_status_errors.includes(stripe_status)
  );
};

export const useSubscriptionHasExpired = () => {
  const { subscription } = useCurrentUser();
  if (!subscription?.ends_at) {
    return false;
  }

  return dayjs.unix(subscription.ends_at).isBefore(dayjs());
};

export const useSubscriptionInfo = () => {
  const { subscription, payment_system } = useCurrentUser();
  const hasExpired = useSubscriptionHasExpired();
  const hasPaymentError = useHasPaymentError();

  const isIdealSubscription = payment_system === 'ideal';
  const isIosSubscription = payment_system === 'apple';
  const isAndroidSubscription = payment_system === 'android';
  const isPaypalSubscription = payment_system === 'paypal';
  const isActiveSubscription =
    subscription?.stripe_status === 'active' ||
    subscription?.stripe_status === 'trialing' ||
    subscription?.paypal_status === 'active';
  const isPrepaidSubscription = isIdealSubscription;
  const isCanceledSubscription =
    subscription?.stripe_status === 'canceled' ||
    subscription?.cancelled ||
    subscription?.paypal_status === 'suspended';
  const isTrialAvailable = payment_system === undefined;

  let creationDate;
  let expiryDate;
  let expiresInDays;

  if (subscription?.created_at) {
    creationDate = dayjs.unix(subscription?.created_at).format('DD MMM YYYY');
  }

  if (subscription?.ends_at) {
    expiryDate = dayjs.unix(subscription.ends_at).format('DD MMM YYYY');
    expiresInDays = Math.floor(
      dayjs.unix(subscription.ends_at).diff(dayjs(), 'day', true),
    );
  }

  const isExpiring =
    subscription?.cancelled && expiresInDays !== undefined && expiresInDays < 7;

  return {
    hasExpired,
    hasPaymentError,
    isIdealSubscription,
    isIosSubscription,
    isAndroidSubscription,
    isPaypalSubscription,
    isActiveSubscription,
    isPrepaidSubscription,
    isCanceledSubscription,
    isExpiring,
    expiresInDays,
    expiryDate,
    creationDate,
    isTrialAvailable,
    planName: subscription?.name,
  };
};

export const useCancelSubscription = () => {
  const { refetch } = useCurrentUser();

  return useMutation(
    ({
      reasonId,
      customReason,
    }: {
      reasonId?: number;
      customReason?: string;
    }) =>
      subscriptionAPI.deleteSubscription({
        cancel_reason_id: reasonId,
        reason: customReason,
      }),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
};

export const useQueryCards = (options?: UseQueryOptions<Card[]>) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery<Card[]>([identifier, 'cards'], getCards, {
    ...options,
    enabled: isLoggedIn,
  });
};

export const useAddOrUpdatePaymentMethod = () => {
  const { refetch: refetchCards } = useQueryCards();
  return useMutation(
    ({
      idOfNewPaymentMethod,
      idOfPaymentMethodToReplace,
    }: {
      idOfNewPaymentMethod: string;
      idOfPaymentMethodToReplace?: string;
    }) =>
      idOfPaymentMethodToReplace
        ? subscriptionAPI.updatePaymentMethod({
            idOfNewPaymentMethod,
            idOfPaymentMethodToReplace,
          })
        : subscriptionAPI.addPaymentMethod(idOfNewPaymentMethod),
    {
      onSettled: async () => {
        await refetchCards();
      },
    },
  );
};
