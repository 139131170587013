import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { Track } from './tracks';
import { User } from './user';

export interface ReleaseFilter {
  genre?: number;
  sort?: ReleaseSortType;
  sort_dir?: 'asc' | 'desc';
  page?: number;
}

export const releasesSortOptions = ['title', 'date', 'release_date'] as const;

export type ReleaseSortType = typeof releasesSortOptions[number];

export interface Release {
  id: number;
  release_date: string;
  track_title: string;
  label_name: string;
  track_cover_300x300: string;

  track: Track;
  label: User;

  amazon_music?: string;
  apple_music?: string;
  spotify?: string;
  tidal?: string;
  youtube_music?: string;
}

export const get = ({ page = 1, ...filter }: Partial<ReleaseFilter> = {}) =>
  axios
    .get<ResponseWrapperPaginated<Release[]>>('/releases', {
      params: { page, ...filter },
    })
    .then(({ data }) => data.data);

export const getById = (releaseId: number) =>
  axios
    .get<ResponseWrapper<Release>>(`/releases/${releaseId}`)
    .then(({ data }) => data.data);

export interface ReleaseSuccessStory {
  id: number;
  url: string;
  user: User;
  label_subtitle: string;
  track: Track;
  image: string;
  animation: string;
  label_name: string;
  track_title: string;
  track_cover: string;
}
export const getReleaseSuccessStories = () =>
  axios
    .get<ResponseWrapper<ReleaseSuccessStory[]>>('success-stories')
    .then(({ data }) => data.data);
