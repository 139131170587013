import algoliasearch from 'algoliasearch/lite';
import { Role } from '../api/lib/api/user';
import { roleToRoleID, toArray } from '../api';

const hitsPerPage = 100;

const client = algoliasearch(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  process.env.ALGOLIA_APP_ID!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  process.env.ALGOLIA_API_KEY!,
);

export const usersIndex = client.initIndex('users');

export interface SearchResultUser {
  username: string;
  display_name: string;
  email: string;
  id: number;
  role_id: 2 | 3 | 4;
  profile_photo: string;
}

const formatFilter = (filters: Record<string, string | undefined>) =>
  Object.keys(filters).reduce((acc, key, index) => {
    if (filters[key] === undefined || filters[key] === '') {
      return acc;
    }
    if (index === 0) {
      return `${key}${filters[key]}`;
    }
    return `${acc} AND ${key}${filters[key]}`;
  }, '');

export const searchUsers = async (
  username: string,
  {
    roleRestriction,
    roleExclude,
    idsToExclude: idsToExcludeNonArray = [],
  }: {
    page?: number;
    roleRestriction?: Role;
    roleExclude?: Role;
    idsToExclude?: number | number[];
  },
) => {
  const idsToExclude = toArray(idsToExcludeNonArray);
  const filtersWithoutExclude = formatFilter({
    role_id: roleRestriction
      ? `=${roleToRoleID(roleRestriction)}`
      : roleExclude && `!=${roleToRoleID(roleExclude)}`,
  });

  const filters = filtersWithoutExclude
    .concat(
      filtersWithoutExclude.length > 0 && idsToExclude.length > 0
        ? ' AND '
        : '',
    )
    .concat(idsToExclude.map((id) => `id != ${id}`).join(' AND '));

  const index = client.initIndex('users');

  return index
    .search<SearchResultUser>(username, {
      hitsPerPage,
      filters,
    })
    .then((res) => res.hits);
};
