import { Role } from '../lib/api/user';

const roleIDMap: Record<Role, 1 | 2 | 3 | 4> = {
  admin: 1,
  producer: 2,
  label: 3,
  listener: 4,
};

export const roleToRoleID = (role: Role | undefined) => role && roleIDMap[role];

export const roleIDToRole = (roleID: 1 | 2 | 3 | 4 | undefined) =>
  Object.keys(roleIDMap).find((key) => roleIDMap[key as Role] === roleID) as
    | Role
    | undefined;
