import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';
import * as style from '../../api/style';

dayjs.extend(relativeTime);

interface Props {
  children: number;
  className?: string;
}

const MessageTime = ({ children, className }: Props) => (
  <Time className={className}>{dayjs.unix(children).fromNow()}</Time>
);

export default MessageTime;

const Time = styled.div`
  color: ${style.grey_main_new};
  font-size: 10px;
`;
