import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { style } from '../api';
import { desktop, mobile, tablet } from './constants';

const positionStyle = `
  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .fixed {
    position: fixed;
  }

  .inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;

const aspectRation = `
  .ratio {
    position: relative;
    width: 100%;

    &::before {
      display: block;
      padding-top: var(--aspect-ratio);
      content: "";
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .ratio-1x1 {
    --aspect-ratio: 100%;  
  }

  .ratio-16x9 {
    --aspect-ratio: calc(9 / 16 * 100%);
  }

  .ratio-1088x170 {
    --aspect-ratio: calc(170 / 1088 * 100%);
  }
`;

const objectFit = `
  .object-contain {
    object-fit: center;
  }

  .object-cover {
    object-fit: cover;
  }

  .object-fill {
    object-fit: fill;
  }
`;

const objectPosition = `
  .object-center {
    object-position: center;
  }
`;

const GlobalStyle = createGlobalStyle<{ greyBackground: boolean }>`

  #virpp-root button {
    border-radius: unset !important;
  }

  ${normalize}
  ${positionStyle}
  ${objectFit}
  ${objectPosition}
  ${aspectRation}

  .z-1 {
    z-index: 1;
  }

  .focus-none {
    &:focus {
      outline: none;
    }
  }

  .pointer-events-none {
    pointer-events: none;
  }
     
  * {
    box-sizing: border-box;
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #000000ab ;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }
  }

  body, html {
    height: 100%;
     background-color: ${({ greyBackground }) =>
       greyBackground ? '#f6f6f6' : ''} !important;
    color: ${style.black};
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    /* background-color: #F9F9F9 !important; */
  }
  
  h1 {
    margin: 0.10em 0;
  }

  #__next {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
  }

  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }

  button {
    outline: none;
    box-shadow: ${style.box_shadow_buttons};
    /* height: 40px; */
    color: ${style.secondary_white_new};
    background-color: ${style.primary_pink_new};
    border: none;
    cursor: pointer;
    padding: 5px 20px;

    font-weight: 500;
    font-size: 14px;
    min-height: 40px;

    :disabled {
      opacity: 0.5;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    height: 45px;
    line-height: 45px;
    border: 2px solid ${style.grey_main_new};
    background: ${style.secondary_white_new};
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    -webkit-appearance: none;
  }

  .select__input{
    height: 20px;
  }

  textarea {
    resize: none;
    border: 2px solid ${style.grey_main_new};
    background: ${style.secondary_white_new};
    padding: 10px 20px;
    font-size: 14px;
  }

  ::placeholder {
    opacity: 1;
    color: ${style.grey_main_new};
  }

  a {
    color: black;
    text-decoration: none;
  }

  .fb_dialog {
    @media ${mobile} {
      display: none !important;
    }
    
    iframe {
      bottom: 75px !important;
    }
  }

  .fb-customerchat {
    @media ${mobile} {
      display: none !important;
    }
    
    iframe {
      bottom: 135px !important;
    }
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .outline-none {
    outline: none !important;  
  }

  .br-10 {
    border-radius: 10px;
  }

  .br-5 {
    border-radius: 5px;
  }

  .br-2 {
    border-radius: 2px;
  }
  
  br-t-5 {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .br-b-5 {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .br-t-2 {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  
  .br-b-2 {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .br-t-10{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .br-b-10 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .h-100 {
    height: 100% !important
  }

  .mh-100 {
    min-height: 100% !important
  }

  .text-black {
    color: #000000;
    &:hover {
      color: #000000;
    }
  }

  .hover-underline:hover {
    text-decoration: underline;
  }

  .text-underline {
    text-decoration: underline;
  }

  #__player-manager {
    background: none;
    border: 0;
    padding: 0;
    position: fixed;
    bottom: 110px;
    right: 0;
    z-index: 99999;
    display: inline-flex;
    font-size: 1.5rem;
    margin: 0.5rem;
    cursor: pointer;
    width: fit-content;
    left: 0;
    display: flex;
    flex-direction: column;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .main-container {

  }

  .stroke {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
    letter-spacing: -2px;
    font-weight: 700;
    font-size: 63px;
    font-size: 42px;
  }

  #pro-membership-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    z-index: 2;

    // Ony banner is visible, offset container by banner height
    + [data-name="container"] {
      margin-top: 45px;
      // min-height: calc(100vh - 45px);
    }

    // Banner is visible, offset header by banner height
    + header {
      top: 40px;
    }
  }

  header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 65px;
    z-index: 2;

    // Only header is visible, offset container by header height
    + div {
      margin-top: 65px;
      // min-height: calc(100vh - 65px);
    }
  }


  #pro-membership-banner + header {
    + div {
      margin-top: 105px;
      // min-height: calc(100vh - 105px);
    }

    #main-menu,
    #user-menu,
    #main-menu-backdrop {
      top: 105px;
    }
  }

  #footer-player {
    // Footer player is visible, offset footer player height
    + footer {
      padding-bottom: 80px;
    }
  }

  footer.ReactQueryDevtools > button {
    bottom: 65px !important; 
  }

  .paragraph-color{
     color:rgb(46, 46, 46);
  }

  #credential_picker_container {
    top: 69px !important ;
  }

  .pading-pricing-buttons{
    padding: 0.2rem 0.8rem !important
  }

  .div-gradient-background {
    background: linear-gradient(135deg, #fcf3f8 0%, #f5f8fe 100%),
    linear-gradient(to left, #743ad5, #d53a9d);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  }

  .gradient-text {
    background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } 

  .flex-with-mobile-tablet-responsive{
    display: flex;   
    flex-direction: row;
    @media ${mobile} {
      flex-direction: column;
    }
    @media ${tablet} {
      flex-direction: column;
    }
    @media ${desktop} {
      flex-direction: row;
    }
  }
  .flex-with-mobile-responsive{
    display: flex;   
    @media ${mobile} {
      flex-direction: column;
    }
  }

  .reactstrap-select-custom-style{
    .select__control {
      border-radius: 10px !important;
      border: 1.5px solid #d8d8d8 !important;
      /* font-size: 14px !important; */
      .select__indicator-separator {
        display: none;
      }
      .select__placeholder {
        color: #242424 !important;
      }
      .select__clear-indicator {
        padding: 0px;
      }
      .select__dropdown-indicator {
        padding: 5px;
      }
      .select__placeholder {
        margin-left: 10px !important;
      }
    }
  }

  .noDesktop-display-content {
    display: none !important;

    @media all and (max-width: 991px) {
      display: block !important;
      &.flexDesktop {
        display: flex !important;
      }
    }
  }

  .desktop-display-content {
    display: block !important;
    &.image-content{
  display: flex !important;
  @media all and (max-width: 991px) {
      display: none !important;
    
    }
}
    @media all and (max-width: 991px) {
      display: none !important;
      &.flexDesktop {
        display: flex !important;
      }
    }
  }
`;

export default GlobalStyle;
