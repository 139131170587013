import { createAction } from '@reduxjs/toolkit';
import { Notification } from '../../lib/api/notifications';

export const conversationCreated = createAction('PUSHER_CONVERSATION_CREATED');

export const pusherConversationReplyCreated = createAction(
  'PUSHER_CONVERSATION_REPLY_CREATED',
);

export const pusherNotificationReceived = createAction<Notification>(
  'PUSHER_NOTIFICATION_RECEIVED',
);

export const incrementNotificationsCounter = createAction(
  'INCREMENT_NOTIFICATIONS_COUNTER',
);
export const zeroifyNotificationsCounter = createAction(
  'ZEROIFY_NOTIFICATIONS_COUNTER',
);

export const incrementLabelNotificationsCounter = createAction(
  'INCREMENT_LABEL_NOTIFICATIONS_COUUNTER',
);
export const zeroifyLabelNotificationsCounter = createAction(
  'ZEROIFY_LABEL_NOTIFICATIONS_COUNTER',
);
