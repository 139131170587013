/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { style, useCurrentUser, useIsLoggedIn } from '../api';

import { Release } from '../api/lib/api/releases';
import isClientSide from '../utils/isClientside';
import { useCheckCookiesAvailable } from './useCheckCookiesAvailable';

import ManageTracksDialog from '../components/MyTracks/Modal/ManageVirpps';
import MembershipExpiredDialog from '../components/Modals/MembershipExpiredDialog';
import PaymentMethodFailedDialog from '../components/Modals/PaymentMethodFailedDialog';
import TrackWithoutInformationDialog from '../components/Modals/TrackWithoutInformationDialog';
import MembershipWillSoonExpireDialog from '../components/Modals/MembershipWillSoonExpireDialog';
import TrackUploadLimitModal from '../components/TrackUploadLimitModal';
import ReleasedTrackModal from '../components/Modals/ReleasedTrackDialog';

const localStorageItemNameFreeProTrialBanner = 'hideFreeProTrialBanner';
const headerMenuHeight = 70;
const proTrialBannerHeight = 0;

interface StyleProviderContext {
  showFreeProTrialBanner: boolean;
  proTrialBannerHeight: number;
  headerMenuHeight: number;
  headerHeight: number;
  removeProTrialBanner: () => void;
  showTrackUploadLimitModal: () => void;
  showReleasedTrackModal: (release: Release) => void;
}

const StyleContext = React.createContext<StyleProviderContext>({
  showFreeProTrialBanner: false,
  proTrialBannerHeight,
  headerMenuHeight,
  headerHeight: headerMenuHeight,
  removeProTrialBanner: () => undefined,
  showTrackUploadLimitModal: () => undefined,
  showReleasedTrackModal: () => undefined,
});

export const StyleProvider = ({ children }: { children: React.ReactNode }) => {
  const [showFreeProTrialBanner, setShowFreeProTrialBanner] = useState(false);
  const [showTrackUploadLimitModal, setShowTrackUploadLimitModal] = useState(
    false,
  );

  // ! TODO CLEAN UP ASAP
  const user = useCurrentUser();
  const isLoggedIn = useIsLoggedIn();

  const cookiesAvailable = useCheckCookiesAvailable();

  useEffect(() => {
    if (
      isClientSide &&
      !user.requesting &&
      (user.id === undefined || !user.member)
    ) {
      setShowFreeProTrialBanner(true);
    } else {
      setShowFreeProTrialBanner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientSide, user, isLoggedIn]);

  const removeProTrialBanner = () => {
    setShowFreeProTrialBanner(false);

    if (isClientSide && cookiesAvailable) {
      localStorage.setItem(
        localStorageItemNameFreeProTrialBanner,
        true.toString(),
      );
    }
  };

  const [
    releasedTrackModalTrackId,
    setReleasedTrackModalTrackId,
  ] = useState<Release>();

  return (
    <StyleContext.Provider
      value={{
        showFreeProTrialBanner,
        proTrialBannerHeight,
        headerMenuHeight,
        showTrackUploadLimitModal: () => setShowTrackUploadLimitModal(true),
        headerHeight: showFreeProTrialBanner
          ? headerMenuHeight + proTrialBannerHeight
          : headerMenuHeight,
        removeProTrialBanner,
        showReleasedTrackModal: (trackId) =>
          setReleasedTrackModalTrackId(trackId),
      }}
    >
      {children}
      <ManageTracksDialog />
      <MembershipExpiredDialog />
      <PaymentMethodFailedDialog />
      <TrackWithoutInformationDialog />
      <MembershipWillSoonExpireDialog />
      <ReleasedTrackModal
        release={releasedTrackModalTrackId}
        onClose={() => setReleasedTrackModalTrackId(undefined)}
      />
      <TrackUploadLimitModal
        open={showTrackUploadLimitModal}
        toggle={setShowTrackUploadLimitModal}
      />
    </StyleContext.Provider>
  );
};

export const useHeaderHeight = () => {
  const {
    showFreeProTrialBanner,
    headerHeight,
    removeProTrialBanner,
  } = useContext<StyleProviderContext>(StyleContext);

  return {
    showFreeProTrialBanner,
    proTrialBannerHeight,
    headerMenuHeight,
    headerHeight,
    removeProTrialBanner,
  };
};

export const useGlobalDialogs = () => {
  const {
    showTrackUploadLimitModal,
    showReleasedTrackModal,
  } = useContext<StyleProviderContext>(StyleContext);

  return {
    showTrackUploadLimitModal,
    showReleasedTrackModal,
  };
};

export const useMaterialStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    color: 'white',
    background: style.grey_dark_new,
    fontSize: 12,
  },
}));
