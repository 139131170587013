import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '../../lib/api/Pagination';
import { Notification } from '../../lib/api/notifications';

export const addNotificationsEntries = createAction<Notification[]>(
  'ADD_NOTIFICATIONS_ENTRIES',
);

export const requestNotifications = createAction('REQUEST_NOTIFICATIONS');
export const receiveNotifications = createAction<{
  notifications: Notification[];
  pagination: Pagination;
}>('RECEIVE_NOTIFICATIONS');
export const receiveLabelNotifications = createAction<{
  notifications: Notification[];
  pagination: Pagination;
}>('RECEIVE_NOTIFICATIONS_BY_LABEL');
export const receiveActivityNotifications = createAction<{
  notifications: Notification[];
  pagination: Pagination;
}>('RECEIVE_NOTIFICATIONS_BY_USERS');

export const requestUnreadNotificationsNumber = createAction(
  'REQUEST_UNREAD_NOTIFICATIONS_NUMBER',
);
export const recieveUnreadNotificationsNumber = createAction<number>(
  'RECEIVE_UNREAD_NOTIFICATIONS_NUMBER',
);

/**
 * Should only be used on mobile devices!
 */
export const setPushDeviceToken = createAction<{
  token: string;
  isIOS: boolean;
}>('SET_PUSH_DEVICE_TOKEN');

/**
 * Should only be used on mobile devices!
 */
export const uploadPushDeviceToken = createAction('UPLOAD_PUSH_DEVICE_TOKEN');

/**
 * Should only be used on mobile devices!
 */
export const uploadPushDeviceTokenError = createAction<string>(
  'UPLOAD_PUSH_DEVICE_TOKEN_ERROR',
);

export const setTrackForRelease = createAction<{ id: number }>(
  'SET_TRACK_FOR_RELEASE',
);
