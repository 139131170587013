import styled from 'styled-components';
import { style } from '../../../../api';

export { RequestFeedbackButton } from './RequestFeedback';
export { FollowButton } from './FollowUnfollow';

export const WhiteButton = styled.button`
  background-color: ${style.secondary_white_new};
  color: ${style.primary_pink_new};
`;

export const WhiteBorderButton = styled.button`
  background-color: ${style.secondary_white_new};
  color: ${style.black};
  font-weight: bold;
  box-shadow: none;
  border: 1px solid ${style.grey_light_2_new};
`;

export const GreyBorderButton = styled.button`
  color: ${style.grey_dark_new};
  background-color: ${style.grey_light_1_new};
  border: 1px solid ${style.grey_light_2_new};
  box-shadow: none;
  font-weight: bold;
`;

export const SuccessButton = styled.button`
  background-color: ${style.success_new};
  color: white;
  box-shadow: none;
`;

export const WarningButton = styled.button`
  background-color: ${style.grey_light_1_new};
  color: ${style.warning_new};
`;

export const ErrorButton = styled.button`
  background-color: ${style.grey_light_1_new};
  color: ${style.error_new};
  box-shadow: none;
`;

export const GreyedOutButton = styled.button`
  background-color: ${style.grey_main_new};
  color: white;
`;

export const GreyedOutLightButton = styled.button`
  background-color: ${style.grey_light_1_new};
  color: ${style.grey_dark_new};
  box-shadow: none;
`;

export const GreyedOutLight2Button = styled.button`
  background-color: ${style.grey_light_2_new};
  color: ${style.grey_dark_new};
  box-shadow: none;
`;

export const ButtonProGradient = styled.button`
  color: white;
  background: transparent linear-gradient(165deg, #fd004c 0%, #2b85ff 100%) 0%
    0% no-repeat padding-box;
`;
