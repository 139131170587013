import React from 'react';
import { ListGroupItem } from 'reactstrap';

export const VuexyListGroupItem = ({
  children,
  ...props
}: VuexyListGroupProps) => <ListGroupItem {...props}>{children}</ListGroupItem>;

interface VuexyListGroupProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

export default VuexyListGroupItem;
