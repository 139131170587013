import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { media } from 'styled-bootstrap-grid';
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown';
import { Error as IconError } from '@styled-icons/material/Error';
import MUIBadge from '@material-ui/core/Badge';
import MUIAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import useTranslation from 'next-translate/useTranslation';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import Notification from './NotificationIndicator';

import { Role } from '../../../api/lib/api/user';
import {
  userActions,
  notificationActions,
  RootState,
  Dispatch,
  style,
  useCurrentUser,
  useBalance,
  useUnreadNotifications,
  useQueryUnreadMessagesCount,
  useUserProfileCompleted,
  useSubscriptionHasExpired,
} from '../../../api';

import { desktop, useIsDesktop, useIsMobile } from '../../../style/constants';
import ProfileMenu from './ProfileMenu';
import BlackLayer from '../../common/BlackLayer';
import MediaQuery from '../../common/MediaQuery';
import NotificationsMenu from '../../../containers/NotificationsMenu';
import ChatMenu from '../../../containers/ChatMenu';
import useDisableBackgroundScrolling from '../../../hooks/useDisableBackgroundScrolling';
import { AnimatedVCoin } from '../AnimatedVCoin';
import { NoReleasesModal } from '../../Modals/NoReleasesModal';
import { Button, ProOutlineButton } from '../../../VuexyDesign/atoms/_index';
import { LanguageSpikeModal } from '../../../VuexyDesign/organisms/LanguageSpikeModal';

interface StateProps {
  displayUploadPromoButton: boolean;
  profilePhotoUrl?: string;
  role?: Role | null;
  isAmateur?: boolean;
}

interface DispatchProps {
  fetchUserInfo: () => void;
  fetchUnreadNotificationsNumber: () => void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

const Avatar = withStyles(() => ({
  root: {
    width: 35,
    height: 35,
  },
}))(MUIAvatar);

const Badge = withStyles(() => ({
  badge: {
    padding: 0,
  },
}))(MUIBadge);

const avatarStyles = createStyles({
  root: {
    width: 35,
    height: 35,
    border: (props: { completed: boolean }) =>
      props.completed ? 'none' : `2px solid ${style.error_new}`,
  },
});

export interface UserAvatarProps extends WithStyles<typeof avatarStyles> {
  completed: boolean;
  src: AvatarProps['src'];
}

const UserAvatar = withStyles(avatarStyles)((props: UserAvatarProps) => {
  const { completed, ...rest } = props;

  return (
    <Badge
      overlap="circle"
      invisible={completed}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      badgeContent={<IconError size={12} color={style.error_new} />}
    >
      <Avatar variant="circle" {...rest} />
    </Badge>
  );
});

const UserMenu = ({
  fetchUserInfo,
  fetchUnreadNotificationsNumber,
  displayUploadPromoButton,
  profilePhotoUrl,
  role,
  isAmateur,
}: Props) => {
  useEffect(() => {
    fetchUserInfo();
    fetchUnreadNotificationsNumber();
  }, [fetchUserInfo, fetchUnreadNotificationsNumber]);

  const { t } = useTranslation('header');
  const user = useCurrentUser();
  const { data: newMessages } = useQueryUnreadMessagesCount();
  const newNotifications = useUnreadNotifications();
  const { complete } = useUserProfileCompleted();

  const profileCompleted =
    (user?.profile_completion?.overall_percentage ?? 0) === 100;

  const { data: vcoins } = useBalance();
  const [showProfile, setShowProfile] = useState(false);
  const [language, setLanguage] = useState(false);
  const [allowOpenProfileMenu, setAllowOpenProfileMenu] = useState(true);
  const close = () => setShowProfile(false);
  const open = () => {
    if (allowOpenProfileMenu) {
      setShowProfile(true);
    }
  };

  const closeLanguageModal = () => {
    setLanguage(false);
  };

  const [centeredModal, setCenteredModal] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const isExpired = useSubscriptionHasExpired();

  const total = newNotifications + (newMessages ?? 0);

  useDisableBackgroundScrolling(!isDesktop && showProfile);

  return (
    <Container>
      <MediaQuery query={desktop}>
        {displayUploadPromoButton && (
          <Link href="/upload-landing">
            <div>
              <Button color="primary">{t('header:upload') as string}</Button>
            </div>
          </Link>
        )}
        <>
          {(isAmateur && role === 'producer') ||
          (isExpired && role === 'producer') ? (
            <Link href="/become-pro" passHref>
              <div className="ml-1">
                <ProOutlineButton>
                  {t('header:becomePro') as string}
                </ProOutlineButton>
              </div>
            </Link>
          ) : null}
        </>
        {role !== 'producer' && (
          <Link href="https://business.virpp.com/" passHref legacyBehavior>
            <a className="mr-0">
              <Button
                text={t('header:label') as string}
                color="info"
                className="ml-1"
              />
            </a>
          </Link>
        )}
        <ChatMenu newMessages={newMessages} />
        <NotificationsMenu />
      </MediaQuery>
      {!isDesktop ? (
        <>
          {role === 'producer' ? (
            <Button
              color="primary"
              href="/upload"
              text={t('header:uploadMobile') as string}
            />
          ) : (
            <Button
              size="sm"
              color="info"
              text={t('header:labelMobile') as string}
              href="https://business.virpp.com/"
              className="rounded mr-0"
              style={{ fontSize: '14px' }}
            />
          )}
        </>
      ) : null}

      <UserDropDownButton onClick={open}>
        <UserAvatar src={profilePhotoUrl} completed={profileCompleted} />
        {!isDesktop ? (
          <>
            <NotificationsInnerWrapper>
              <NotificationsIconWrapper>
                {total !== 0 && complete === true && (
                  <Notification>
                    <NotificationsCount>{total}</NotificationsCount>
                  </Notification>
                )}
              </NotificationsIconWrapper>
            </NotificationsInnerWrapper>
          </>
        ) : null}
        {isDesktop && <ArrowDropDown size={20} color={style.black} />}
        <AnimatedVCoin
          iconSize="2rem"
          position="absolute"
          bottom="-4rem"
          right={['-5px', '-5px', 'calc(1rem + 5px)']}
          amount={vcoins}
        />
      </UserDropDownButton>

      {showProfile && isMobile && <BlackLayer />}
      {showProfile && (
        <>
          <ProfileMenu
            role={role}
            setLanguage={setLanguage}
            close={close}
            profilePhotoUrl={profilePhotoUrl}
            newMessages={newMessages}
            handleClickOutside={() => {
              setAllowOpenProfileMenu(false);
              close();
              setTimeout(() => setAllowOpenProfileMenu(true), 200);
            }}
          />
        </>
      )}
      <LanguageSpikeModal isOpen={language} close={closeLanguageModal} />
      <NoReleasesModal
        isOpen={centeredModal}
        close={() => setCenteredModal(!centeredModal)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  & > a {
    margin-right: 10px;
    position: relative;
    &:last-of-type {
      margin-right: none;
    }
  }

  & > button {
    margin-right: 10px;
    padding: 0 20px;

    ${media.xl`
    margin-right: 15px;
    `}
  }
`;

const NotificationsInnerWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -6px;
  left: -10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-bottom: 10px;
  }
  font-size: 12px;
  color: ${style.black_new};
  width: 100px;
`;

const NotificationsIconWrapper = styled.div`
  position: relative;
  display: flex;
`;

const NotificationsCount = styled.span`
  position: relative;
  bottom: 2px;
  font-size: 9px !important;
  color: #ffffff;
  z-index: 999;
`;

const UserDropDownButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 200;
`;

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  ({ user }) => ({
    displayUploadPromoButton: user.role === 'producer',
    profilePhotoUrl: user.profile_photo,
    member: user.member,
    role: user.role,
    userId: user.id,
    userName: user.display_name,
    isAmateur: user.abilities?.plan === 'amateur',
  }),
  (dispatch: Dispatch) => ({
    fetchUserInfo: () => dispatch(userActions.getCurrent()),
    fetchUnreadNotificationsNumber: () =>
      dispatch(notificationActions.getUnreadNotificationsNumber()),
  }),
)(UserMenu);
