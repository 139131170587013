import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useSubscriptionInfo } from '../../api';

import Dialog from '../common/Dialog';
import { TextNoMargin } from '../common/Text';
import { ButtonProGradient } from '../common/Buttons/V2';
import Flex from '../common/Flex';
import Logo from '../common/Logo';
import ProLogo from '../common/ProLogo';
import { useLocalStorageFlagForCurrentUser } from '../../hooks/useLocalstorageFlag';

const dontShowOnPages = ['/my-pro', '/pro-payment'];
const flag = 'HAS_SHOWN_MEMBERSHIP_WILL_SOON_EXPIRE_DIALOG';
const flagTomorrow = 'HAS_SHOWN_MEMBERSHIP_WILL_EXPIRE_TOMORROW_DIALOG';

const MembershipWillSoonExpireDialog = () => {
  const [show, setShow] = useState(true);
  const [showTomorrow, setShowTomorrow] = useState(true);
  const { expiresInDays, isCanceledSubscription } = useSubscriptionInfo();
  const router = useRouter();
  const showOnPage = !dontShowOnPages.includes(router.pathname);
  const [hasShown, setHasShown] = useLocalStorageFlagForCurrentUser(flag);
  const [
    hasShownDayBefore,
    setHasShownDayBefore,
  ] = useLocalStorageFlagForCurrentUser(flagTomorrow);
  const { t } = useTranslation('common');

  const expiresThisWeek =
    expiresInDays !== undefined && expiresInDays <= 7 && expiresInDays > 1;

  const expiresTomorrow =
    expiresInDays !== undefined && (expiresInDays === 1 || expiresInDays === 0);

  useEffect(() => {
    if (
      isCanceledSubscription &&
      expiresThisWeek &&
      !expiresTomorrow &&
      !hasShown &&
      showOnPage
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCanceledSubscription, expiresThisWeek, hasShown, showOnPage]);

  useEffect(() => {
    if (
      isCanceledSubscription &&
      expiresTomorrow &&
      !hasShownDayBefore &&
      showOnPage
    ) {
      setShowTomorrow(true);
    } else {
      setShowTomorrow(false);
    }
  }, [isCanceledSubscription, expiresTomorrow, hasShownDayBefore, showOnPage]);

  if (expiresInDays === undefined) {
    return null;
  }

  const onClose = () => {
    setShow(false);
    setShowTomorrow(false);
    if (expiresThisWeek) {
      setHasShown(true);
    }
    if (expiresTomorrow) {
      setHasShownDayBefore(true);
    }
  };

  const periodText = expiresThisWeek
    ? t('common:modals.soonExpiringModal.periodText.thisWeek', {
        days: expiresInDays,
      })
    : t('common:modals.soonExpiringModal.periodText.tomorrow');

  return (
    <Dialog open={show || showTomorrow} onClose={onClose}>
      <Flex
        flexDirection="column"
        maxWidth="590px"
        alignItems="center"
        paddingX="60px"
      >
        <TextNoMargin fontSize="18px" fontWeight="bold">
          {t('common:modals.soonExpiringModal.title', { periodText })}
        </TextNoMargin>
        <TextNoMargin fontSize="14px" mt="22px" textAlign="center">
          {t('common:modals.soonExpiringModal.body', {
            periodText: periodText.toLowerCase(),
          })}
        </TextNoMargin>

        <ButtonProGradient
          style={{ marginTop: 38 }}
          onClick={() => {
            onClose();
            router.push('/become-pro');
          }}
        >
          {t('common:modals.soonExpiringModal.button')}
        </ButtonProGradient>
        <Flex pt="8px" pb="58px">
          <SmallLogo />
          <ProLogo />
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default MembershipWillSoonExpireDialog;

const SmallLogo = styled(Logo)`
  img {
    height: 16px;
    width: auto;
  }
`;
