import axios from './axios';
import { User } from './user';
import ResponseWrapper from './ResponseWrapper';

export type GenderType = 'male' | 'female';

export interface RegisterFormValues {
  username: string;
  first_name?: string;
  last_name?: string;
  email: string;
  password: string;
  password_confirmation: string;
  location?: number;
  birthday?: string;
  gender?: GenderType;
  role_id?: 2 | 3 | 4;
  terms: boolean;
  affiliate?: string;
  apiKey?: string;
}

export interface RegistrationData {
  token: string;
  user: User;
}

export interface FBLoginData {
  new_user: boolean;
}

export interface GoogleSignInResponse {
  id_token: boolean;
  new_user: boolean;
}

export interface AppleSignInResponse {
  new_user: boolean;
}

export const register = async (formValues: RegisterFormValues) =>
  axios
    .post<ResponseWrapper<RegisterFormValues>>('/register', formValues)
    .then((response) => ({
      ...response.data.data,
      token: response.headers.authorization as string | undefined,
    }));

export const login = async (
  email: string,
  password: string,
  rememberMe: boolean,
) => axios.post('/login', { email, password, remember_me: rememberMe });

export const resetPassword = async (email: string) =>
  axios.post('/password/email', {
    email,
  });

export const confirmResetPassword = async (
  email: string,
  token: string,
  password: string,
  password_confirmation: string,
) =>
  axios
    .post('/password/reset', { email, token, password, password_confirmation })
    .then(() => true);

export const verifyEmail = async (token: string) =>
  axios
    .get(`/user/verify/${token}`)
    .then(
      ({
        data,
      }: {
        data: ResponseWrapper<RegistrationData> & { message?: string };
      }) => data,
    );

export const resendVerificationEmail = async (email: string) =>
  axios.post('/resend-verification', { email }).then(() => true);

export const facebookSignIn = async (accessToken: string, affiliate?: string) =>
  axios
    .post<ResponseWrapper<FBLoginData>>('/facebookLogin', {
      accessToken,
      affiliate,
    })
    .then((response) => ({
      ...response.data.data,
      token: response.headers.authorization as string,
    }));

export const appleSignIn = async (identityToken: string) =>
  axios
    .post<ResponseWrapper<AppleSignInResponse>>('/appleLogin', {
      identityToken,
    })
    .then((response) => ({
      ...response.data.data,
      token: response.headers.authorization as string,
    }));

export const spotifySignInWeb = async (
  authorizationCode: string,
  redirectUri: string,
  affiliate?: string,
) =>
  axios
    .post<ResponseWrapper<{ new_user: boolean }>>('/spotifyWebLogin', {
      authorizationCode,
      redirectUri,
      affiliate,
    })
    .then((response) => ({
      ...response.data.data,
      token: response.headers.authorization as string,
    }));

export const googleSignInWeb = async (credential: string, affiliate?: string) =>
  axios
    .post<ResponseWrapper<GoogleSignInResponse>>('/googleWebLogin', {
      credential,
      affiliate,
    })
    .then((response) => ({
      ...response.data.data,
      token: response.headers.authorization as string,
    }));
