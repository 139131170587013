import React from 'react';
import Box, { BoxProps } from './Box';

const ImageBox = ({
  src,
  srcSet,
  alt,
  ...props
}: {
  src: string;
  srcSet?: string;
  alt: string;
  style?: React.CSSProperties;
  className?: string;
} & Omit<BoxProps, 'color'>) => (
  <Box {...props}>
    <img
      src={src}
      srcSet={srcSet}
      alt={alt}
      style={{
        width: 'inherit',
        height: 'inherit',
        borderRadius: 'inherit',
      }}
    />
  </Box>
);

export default ImageBox;
