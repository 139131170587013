import { ThunkResult } from './ThunkDispatch';
import * as searchActions from '../actions/search';
import tracksReducer from '../reducers/tracks';
import * as tracksAPI from '../../lib/api/tracks';
import * as rafflesAPI from '../../lib/api/raffles';
import * as genresAPI from '../../lib/api/genres';
import * as searchAPI from '../../lib/api/search';
import { search as usersSearch } from '../../lib/api/users';
import { Pagination } from '../../lib/api/Pagination';

const {
  actions: { addTrackEntries },
} = tracksReducer;

let _lastKeyword: string;

const usePagination = (pagination?: Pagination, loadMore?: boolean) => {
  let page = 0;
  let isLastPage = false;

  if (loadMore && pagination) {
    isLastPage = pagination.lastPage === pagination.currentPage;
    page = pagination.currentPage + 1;
  }

  return { page, isLastPage };
};

export const search = (
  keyword: string,
  loadMore?: boolean,
): ThunkResult => async (dispatch, getState) => {
  _lastKeyword = keyword;

  const { pagination } = getState().search;
  const { page, isLastPage } = usePagination(pagination, loadMore);

  if (isLastPage) return;

  if (keyword.length === 0) dispatch(searchActions.clearSearchResult());

  if (keyword.length < 2) return;

  const results = await searchAPI.search(keyword, page);

  // quick typing may dispatch old results into the store. prevent that
  if (keyword === _lastKeyword) {
    dispatch(searchActions.receiveSearchResults(results));
    dispatch(searchActions.updateRecentSearches(keyword));
  }
};

export const getSearchesRecentPopular = (): ThunkResult => async (
  dispatch,
  getState,
) => {
  const { user } = getState();

  if (user.id) {
    const recentSearches = await searchAPI.getSearchesRecent(user.id);
    dispatch(searchActions.receiveSearchesRecent(recentSearches));
  }

  const popularSearches = await searchAPI.getSearchesPopular();
  dispatch(searchActions.receiveSearchesPopular(popularSearches));
};

export const search_DEPRECATED = (
  keyword: string,
  tracksPage = 1,
  usersPage = 1,
  rafflesPage = 1,
  contestsPage = 1,
): ThunkResult => async (dispatch) => {
  await Promise.all([
    dispatch(searchUsers(keyword, usersPage)),
    dispatch(searchTracks(keyword, tracksPage)),
    dispatch(searchRaffles(keyword, rafflesPage)),
    dispatch(searchContests(keyword, contestsPage)),
    dispatch(searchGenres(keyword)),
  ]);
};

const searchTracks = (keyword: string, page = 1): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    search: { searchResultTracks, lastKeyword },
  } = getState();

  if (
    keyword === lastKeyword &&
    page === searchResultTracks.pagination?.currentPage
  ) {
    return;
  }

  dispatch(searchActions.requestTracksSearch(keyword));

  try {
    const { tracks, pagination } = await tracksAPI.getAll({ keyword, page });
    dispatch(addTrackEntries(tracks));
    dispatch(
      searchActions.receiveSearchResult_DEPRECATED({
        tracks: { data: tracks, pagination },
        keyword,
      }),
    );
  } catch (error: any) {
    dispatch(searchActions.errorSearchResult(error.message));
    dispatch(searchActions.clearSearchResult());
  }
};

const searchUsers = (keyword: string, page = 1): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    search: { searchResultUsers, lastKeyword },
  } = getState();

  if (
    keyword === lastKeyword &&
    page === searchResultUsers.pagination?.currentPage
  ) {
    return;
  }

  dispatch(searchActions.requestUsersSearch(keyword));

  try {
    const result = await usersSearch({ username: keyword, page });
    dispatch(searchActions.receiveSearchResultUserEntries(result.data));
    dispatch(
      searchActions.receiveSearchResult_DEPRECATED({ users: result, keyword }),
    );
  } catch (error: any) {
    dispatch(searchActions.clearSearchResult());
    dispatch(searchActions.errorSearchResult(error.message));
  }
};

const searchRaffles = (keyword: string, page = 1): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    search: { searchResultRaffles, lastKeyword },
  } = getState();

  if (
    keyword === lastKeyword &&
    page === searchResultRaffles.pagination?.currentPage
  ) {
    return;
  }

  dispatch(searchActions.requestRafflesSearch(keyword));

  try {
    const { data: raffles, pagination } = await rafflesAPI.getAll({
      keyword,
      page,
      filter: 'contest',
    });
    dispatch(
      searchActions.receiveSearchResult_DEPRECATED({
        raffles: { data: raffles, pagination },
        keyword,
      }),
    );
  } catch (error: any) {
    dispatch(searchActions.errorSearchResult(error.message));
    dispatch(searchActions.clearSearchResult());
  }
};

const searchContests = (keyword: string, page = 1): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    search: { searchResultContests, lastKeyword },
  } = getState();

  if (
    keyword === lastKeyword &&
    page === searchResultContests.pagination?.currentPage
  ) {
    return;
  }

  dispatch(searchActions.requestContestsSearch(keyword));

  try {
    const { data: raffles, pagination } = await rafflesAPI.getAll({
      keyword,
      page,
      filter: 'remix_contest',
    });
    dispatch(
      searchActions.receiveSearchResult_DEPRECATED({
        contests: { data: raffles, pagination },
        keyword,
      }),
    );
  } catch (error: any) {
    dispatch(searchActions.errorSearchResult(error.message));
    dispatch(searchActions.clearSearchResult());
  }
};

const searchGenres = (keyword: string): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    search: { lastKeyword },
  } = getState();

  if (keyword === lastKeyword) {
    return;
  }

  dispatch(searchActions.requestGenresSearch(keyword));

  try {
    const genres = await genresAPI.getAll({ keyword });
    dispatch(
      searchActions.receiveSearchResult_DEPRECATED({
        genres,
        keyword,
      }),
    );
  } catch (error: any) {
    dispatch(searchActions.errorSearchResult(error.message));
    dispatch(searchActions.clearSearchResult());
  }
};

export const clearSearch = (): ThunkResult => async (dispatch) => {
  dispatch(searchActions.clearSearchResult());
};
