import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import ImageBox from '../common/ImageBox';
import { TextRegular } from '../common/Text';
import { BoxProps } from '../common/Box';
import Flex from '../common/Flex';

const rollInBlurred = keyframes`
{
  0% {
    -webkit-transform: translateX(1000px) rotate(720deg);
            transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
`;

const rollOutBlurred = keyframes`
{
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
}
`;

const AnimatedFlex = styled(Flex)`
  opacity: 0;
  ${({ show }: { show?: boolean | undefined }) => {
    if (typeof show === 'undefined') {
      return ``;
    }
    return show
      ? css`
          animation: ${rollInBlurred} 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
        `
      : css`
          animation: ${rollOutBlurred} 0.65s
            cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
        `;
  }}
`;

/**
 * Using undefined value here in the show state to tell our animation to do nothing on initial render.
 * As soon as the amount changes, it will be set to true, and to false when the animation is fading out.
 */
export const AnimatedVCoin = ({
  amount = undefined,
  iconSize = '1.5rem',
  hideText,
  ...props
}: {
  amount?: number;
  iconSize?: BoxProps['size'];
  fontSize?: BoxProps['fontSize'];
  hideText?: boolean;
} & Omit<BoxProps, 'color'>) => {
  const [show, setShow] = useState<boolean>();
  const [prevAmount, setPrevAmount] = useState<number | undefined>(amount);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    // If it's the same do no animation (undefined)
    console.debug(
      `[AnimatedVCoin] Amount: ${amount} PrevAmount: ${prevAmount}`,
    );
    if (amount === prevAmount || typeof prevAmount === 'undefined') {
      setShow(undefined);
      return () => {};
    }

    setShow(true);
    return () => {
      if (amount !== prevAmount) {
        setPrevAmount(amount);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    // if an animation has started, start timer in 3 sec to start the fade out animation.
    if (show) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = (setTimeout(() => {
        setShow(false);
      }, 3000) as unknown) as number;
    }
  }, [show]);

  useEffect(
    () =>
      // Clear timeout when unmounting.
      () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      },
    [],
  );

  const getAmountString = () => {
    if (!amount || !prevAmount) {
      return amount || '0';
    }

    if (amount - prevAmount > 0) {
      return `+${amount - prevAmount}`;
    }
    return `${amount - prevAmount}`;
  };

  return (
    <AnimatedFlex
      key={`vcoins-animated-${amount}`}
      alignItems="center"
      show={show}
      opacity={0}
      flexDirection="column"
      justifyContent="center"
      {...props}
    >
      <ImageBox
        size={iconSize}
        display="flex"
        mr={1}
        alt="vcoin"
        src="/static/images/vcoin/vcoin.svg"
      />
      {Boolean(typeof amount !== 'undefined' && !hideText) && (
        <TextRegular
          mt={1}
          fontSize="inherit"
          color="inherit"
          fontWeight="bold"
        >
          {getAmountString()}
        </TextRegular>
      )}
    </AnimatedFlex>
  );
};
