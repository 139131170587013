import React from 'react';
import styled from 'styled-components';
import { StyledIcon } from '@styled-icons/styled-icon';

const IconWithBorder = ({
  color,
  size,
  icon: Icon,
  borderWidth = 3,
}: {
  color: string;
  size: number;
  icon: StyledIcon;
  borderWidth?: number;
}) => (
  <IconWrapper className="icon-wrapper" borderWidth={borderWidth}>
    <Icon className="inner" size={size} color={color} />
  </IconWrapper>
);

export default IconWithBorder;

const IconWrapper = styled.div<{ borderWidth: number }>`
  .inner {
    overflow: visible;
    path {
      stroke: white;
      stroke-width: ${(p) => p.borderWidth};
    }
  }
`;
