import { User, Role } from './user';
import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';
import { roleToRoleID } from '../../utils/role';
import { Track } from './tracks';

export interface ConversationInfo {
  recipient: User;
  last_reply: string;
  conversation_id: number;
  id: number;
}

export interface SimpleChatUser {
  id: number;
  last_seen_online?: string; // Unused.
  username: string;
  display_name: string;
  profile_photo: string;
  role?: string | null;
}

export interface ReplyAttachment {
  url: string;
  mime_type: string;
}

export interface Reply {
  id: number;
  parent_id?: number;
  conversation_id: number;
  body: string;
  user: SimpleChatUser;
  created_at?: string;
  deleted_at?: string;
  attachment?: ReplyAttachment;
  read_at?: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  timestamp?: number;
  track?: Track;
  is_feedback_answer?: boolean;
  feedback_request_status?: 'open' | 'closed' | null;
  track_id?: number;
}

export interface Conversation {
  id: number;
  created_at: string;
  last_reply: string;
  last_reply_message: string;
  last_reply_read_at?: string;
  last_reply_from_me: boolean;
  last_reply_timestamp: number;
  user: SimpleChatUser;
  timestamp: number;
  track_id?: number; // exists if this is a feedback request
  total_unread_messages?: number;
  contains_feedback_request: boolean;
}

export type ChatFilterType = 'unread' | 'search' | 'feedback' | undefined;

interface Unread {
  total: number;
}

export const getConversations = async (page: number, filter?: ChatFilterType) =>
  axios
    .get<ResponseWrapperPaginated<Conversation[]>>('conversations/overview', {
      params: { page, filter },
    })
    .then(({ data }) => data);

export const createConversation = async (formData: FormData) =>
  axios
    .post<ResponseWrapper<Conversation>>('conversations', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data.data);

export const showConversation = async (id: number) =>
  axios.get(`conversations/${id}`).then(({ data }) => data.data);

export const createConversationReply = async (body: FormData, id: number) =>
  axios
    .post<ResponseWrapper<Reply>>(`conversations/${id}/replies`, body)
    .then(({ data }) => data.data);

export const deleteConversationReply = async (id: number, reply: number) =>
  axios.delete(`conversations/${id}/replies/${reply}`).then(({ data }) => data);

export const searchConversation = async (username: string) =>
  axios
    .get('conversations/search', { params: { username } })
    .then(({ data }) => data.data.data);

export const deleteConversation = async (id: number) =>
  axios.delete(`conversations/${id}`).then(({ data }) => data);

export const getConversationReplies = async (id: number, page: number) =>
  axios
    .get<ResponseWrapperPaginated<Reply[]>>(
      `conversations/${id}/replies?page=${page}`,
    )
    .then(({ data }) => data);

export const findByName = async (username: string, role?: Role, page = 1) =>
  axios
    .get<ResponseWrapperPaginated<SimpleChatUser[]>>('/users', {
      params: {
        username,
        page,
        ...(role ? { role: roleToRoleID(role) } : {}),
      },
    })
    .then(({ data }) => ({
      users: data.data,
      pagination: data.pagination,
    }));

interface Unread {
  total: number;
}

export const getUnreadMessagesNumber = async () =>
  axios
    .get<ResponseWrapper<Unread>>('messages/unread')
    .then(({ data }) => data.data.total);

export const reportMessage = async (messageId: number, reason: string) =>
  axios
    .post(`/reply/${messageId}/report`, { body: reason })
    .then(({ data }) => data.data);

export const markConversationRead = async (conversationId: number) =>
  axios.patch(`conversations/${conversationId}/read`).then(() => true);

export const fetch = (ids: number[], token?: string) =>
  ids.length > 0
    ? axios
        .get<ResponseWrapper<Conversation[]>>(
          `conversations/ids/${ids.join(',')}`,
          {
            ...(token ? { headers: { authorization: token } } : {}),
          },
        )
        .then(({ data }) => data.data)
    : Promise.resolve([]);

export const fetchSingle = (id: number, token?: string) =>
  axios
    .get<ResponseWrapper<Conversation[]>>(`conversations/ids/${id}`, {
      ...(token ? { headers: { authorization: token } } : {}),
    })
    .then(({ data }) => data.data[0]);
