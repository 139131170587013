import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { useRouter } from 'next/router';
import { Share2 } from '@styled-icons/feather/Share2';
import { Heart } from '@styled-icons/fa-regular/Heart';
import { Heart as FullHeart } from '@styled-icons/fa-solid/Heart';
import { Close } from '@styled-icons/material/Close';
import { Edit } from '@styled-icons/feather/Edit';
import { ZoomIn } from '@styled-icons/boxicons-regular/ZoomIn';
import { ZoomOut } from '@styled-icons/boxicons-regular/ZoomOut';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import { style } from '../../../api';
import {
  bigTablet,
  tablet,
  noBigTablet,
  mobile,
} from '../../../style/constants';
import MediaQuery from '../MediaQuery';

export { Close };

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBase = ({
  children,
  ...props
}: {
  children: JSX.Element | string | null | (string | JSX.Element | null)[];
  as?: string;
  href?: string;
  target?: string;
} & ButtonProps) => (
  <button type="button" {...props}>
    {children}
  </button>
);

export const Button = styled(ButtonBase)<{ disabled?: boolean }>`
  border: none;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  padding: 5px 20px;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  transition: ${style.transitionTime};

  box-shadow: ${style.box_shadow_buttons};
`;

export const FbButton = styled(Button)`
  background-color: ${style.facebook_new};
  width: 100%;
  color: ${style.secondary_white_new};
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${style.box_shadow_buttons};

  :disabled {
    opacity: 0.5;
  }
`;

export const BorderButton = styled(Button)`
  border: 2px solid;
  background-color: white;
  border-color: ${style.primary_pink_new};
  color: ${style.primary_pink_new};
  box-shadow: none;
`;

export const BigBorderBtn = styled(BorderButton)`
  align-self: center;
  font-size: 17px;
  margin-top: 20px;
  letter-spacing: 0.77px;
`;

export const GradientButton = styled(Button)`
  border: none;
  color: ${style.secondary_white_new};
  background-color: ${style.primary_pink_new};
`;

export const GreyButton = styled(Button)`
  border: none;
  color: ${style.secondary_white_new};
  background-color: ${style.grey_main_new};
  border-radius: 5px;
`;

export const WhiteButton = styled(Button)`
  color: ${style.black};
  background: ${style.mainBg};
`;

export const LogRegBtn = styled(GradientButton)`
  font-size: 16px;

  @media ${mobile} {
    height: 43px;
  }
`;

export const FlatButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  color: ${style.grey_dark_new};
  box-shadow: none;
  background-color: ${style.grey_light_2_new};
`;

export const FlatButtonWhite = styled(FlatButton)`
  background-color: #fff;
  color: ${style.grey_dark_new};
  box-shadow: ${style.box_shadow_buttons};
`;

const LightBtnBase = styled.button<{ liked?: boolean }>`
  outline: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: 36px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  transition: ${style.transitionTime};

  color: ${style.black};

  & div {
    margin-left: 5px;
  }

  &:hover {
    background: ${style.black};
    color: ${style.light};
    border-color: transparent;
  }

  ${media.xs`
    padding: 0 10px;
  `}
`;

export const LightBtn = styled(LightBtnBase)`
  min-width: 100px;
`;

export const ShareLightBtn = ({
  size = 40,
  ...props
}: { size?: number } & ButtonProps) => (
  <LightBtnBase {...props}>
    <MediaQuery query={noBigTablet}>
      <Share2 size={0.4 * size} />
    </MediaQuery>
    <MediaQuery query={bigTablet}>
      <Share2 size={0.5 * size} />
      <div>share</div>
    </MediaQuery>
  </LightBtnBase>
);

export const LightEdit = (props: { size?: number } & ButtonProps) => (
  <LightBtnBase {...props}>
    <Edit size={20} />
  </LightBtnBase>
);

export const LightClose = (props: ButtonProps) => (
  <LightBtnBase {...props}>
    <Close size={20} />
  </LightBtnBase>
);

const LikeBtnBase = styled.button<{ liked?: boolean; swiper?: boolean }>`
  height: 40px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${style.secondary_white_new};
  background: ${style.primary_pink_new};
  text-transform: capitalize;
  padding: 0 20px;
  cursor: pointer;
  box-shadow: ${style.box_shadow_buttons};
  border: none;

  & div {
    margin: 0 5px;
  }

  ${(p) =>
    p.swiper
      ? media.xs`
    padding:  '0 20px'
  `
      : media.xs`
    padding: '0 10px'};
  `}
`;

const heartSizes = 18;

const LikeBtnContent = (
  props: {
    query?: string;
    liked?: boolean;
    swiper?: boolean;
    className?: string;
    forceShowText?: boolean;
  } & ButtonProps,
) => {
  const { query, liked, forceShowText } = props;
  return (
    <LikeBtnBase {...props}>
      {liked && <FullHeart size={heartSizes} />}
      {!liked && <Heart size={heartSizes} />}
      {forceShowText ? (
        <div>{liked ? 'liked' : 'like'}</div>
      ) : (
        <MediaQuery query={query || tablet}>
          <div>{liked ? 'liked' : 'like'}</div>
        </MediaQuery>
      )}
    </LikeBtnBase>
  );
};

export const LikeBtn = styled(LikeBtnContent)``;

export const BorderedButton = styled(ButtonBase)`
  background: none;
  border: 2px solid ${style.primary_pink_new};
  color: ${style.primary_pink_new};
`;

export const PlaylistLike = (props: { liked?: boolean } & ButtonProps) => (
  <button type="button" {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.liked ? 'liked' : 'like'}
  </button>
);

export const HeaderButtonLink = ({
  to,
  text,
  ...props
}: {
  to: string;
  text: string;
  style?: CSSProperties;
}) => {
  const router = useRouter();
  return (
    <GradientButton onClick={() => router.push(to)} {...props}>
      {text}
    </GradientButton>
  );
};

export const ZoomInBtn = (props: ButtonProps) => (
  <LightBtnBase {...props}>
    <ZoomIn size={20} />
  </LightBtnBase>
);

export const ZoomOutBtn = (props: ButtonProps) => (
  <LightBtnBase {...props}>
    <ZoomOut size={20} />
  </LightBtnBase>
);

export const ZoomResetBtn = (props: ButtonProps) => (
  <LightBtnBase {...props}>
    <Reset size={20} />
  </LightBtnBase>
);

export const ErrorButton = styled(Button)`
  background-color: ${style.error_new};
`;

export const CancelButton = styled(Button)`
  background-color: ${style.grey_light_2_new};
  color: ${style.black_new};
`;

export const InfoButton = styled(Button)`
  background-color: ${style.info_new};
  color: ${style.secondary_white_new};
  font-size: 14px;

  @media ${mobile} {
    font-size: 10px;
  }
`;
