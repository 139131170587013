import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import setLanguage from 'next-translate/setLanguage';
import { TextBold18, TextBold24 } from '../../components/common/Text';
import { style } from '../../api';

interface LanguageSpikeModalProps {
  isOpen: boolean;
  close: () => void;
}

export const languageNames: Record<LanguageCode, string> = {
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Française',
};

export type LanguageCode = 'en' | 'de' | 'es' | 'fr';

export const LanguageSpikeModal = ({
  isOpen,
  close,
}: LanguageSpikeModalProps) => {
  const router = useRouter();
  const { lang, t } = useTranslation();

  const changeLanguage = async (locale: string) => {
    await setLanguage(locale);

    router.push(router.asPath, undefined, { locale });
  };

  function isLanguageCode(value: string): value is LanguageCode {
    return ['en', 'de', 'es', 'fr'].includes(value);
  }

  return (
    <Modal isOpen={isOpen} toggle={close} centered className="p-0">
      <ModalBody className="p-4">
        <TextBold24>{t('language:chooseLanguage') as string}</TextBold24>
        <div className="mt-1">
          {router.locales &&
            router.locales.map((locale) => {
              if (isLanguageCode(locale)) {
                return (
                  <div
                    key={locale}
                    onClick={() => {
                      changeLanguage(locale);
                      close();
                    }}
                    className="rounded mb-1 p-1 d-flex align-items-center cursor-pointer"
                    style={{
                      border: `solid 2px ${
                        lang === locale ? style.pink : style.grey1
                      }`,
                    }}
                  >
                    <RadioButtons
                      type="radio"
                      id={`radio-${locale}`}
                      name="language-radio"
                      value={locale}
                      className="cursor-pointer"
                      onClick={() => changeLanguage(locale)}
                      style={{
                        border: `solid ${lang === locale ? '5px' : '2px'} ${
                          lang === locale ? style.pink : 'grey'
                        }`,
                      }}
                    />

                    <label
                      className="ml-2 mb-0"
                      style={{
                        color: `${lang === locale ? style.pink : 'black'}`,
                      }}
                      htmlFor={`radio-${locale}`}
                    >
                      <TextBold18 className="cursor-pointer">
                        {languageNames[locale] || locale.toUpperCase()}
                      </TextBold18>
                    </label>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </ModalBody>
    </Modal>
  );
};

const RadioButtons = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  &:checked {
    background-color: white;
  }
`;
