import { Dispatch } from 'redux';
import { ThunkResult } from './ThunkDispatch';
import { RootState } from '../reducers';

import { Track } from '../../lib/api/tracks';
import * as playlistAPI from '../../lib/api/playlists';
import * as playlistActions from '../actions/playlists';
import tracksReducer from '../reducers/tracks';
import { addTracksToPlaylistById } from './player';
import { PlaylistBase } from '../../index';
import { successAction } from '../reducers/alerts';
import { handleError } from './alerts';
import usersReducer from '../reducers/users';

const { actions: usersActions } = usersReducer;
const { actions: tracksActions } = tracksReducer;

export const get = (
  id: number,
  mobile = false,
  token?: string,
): ThunkResult => async (dispatch, getState) => {
  const {
    playlists: { entries: playlists },
  } = getState();

  if (playlists[id]) {
    return;
  }

  dispatch(playlistActions.requestGetPlaylist([id]));
  const playlist = await playlistAPI.get(id, token);
  const { tracks, user } = playlist;
  dispatch(tracksActions.addTrackEntries(tracks));
  dispatch(usersActions.addUsers(user));
  dispatch(playlistActions.receiveGetPlaylist(playlist));

  if (!mobile) {
    dispatch(
      addTracksToPlaylistById({
        ids: tracks.map((track) => track.id),
        resetList: true,
      }),
    );
  }
};

export const create = (name: string) => async (
  dispatch: Dispatch,
): Promise<PlaylistBase | undefined> => {
  dispatch(playlistActions.requestCreatePlaylist());

  try {
    const newPlaylist = await playlistAPI.create(name);

    if (newPlaylist) {
      dispatch(playlistActions.receiveCreatePlaylist(newPlaylist));
      return newPlaylist;
    }
  } catch (error: any) {
    if (error.response)
      dispatch(
        playlistActions.errorCreatePlaylist(
          error.response?.data.data.original.name[0],
        ),
      );
    else dispatch(playlistActions.errorCreatePlaylist('Failed, unknown error'));
  }

  return undefined;
};

export const remove = (id: number): ThunkResult => async (dispatch) => {
  dispatch(playlistActions.requestRemovePlaylist());
  try {
    await playlistAPI.remove(id);
    dispatch(playlistActions.receiveRemovePlaylist(id));
  } catch (error: any) {
    dispatch(playlistActions.errorRemovePlaylist(error.message));
  }
};

export const addTrack = (
  playlistId: number,
  trackId: number,
): ThunkResult<Promise<boolean>> => async (dispatch, getState) => {
  const playlistName = getState().playlists.entries[playlistId]?.name;

  dispatch(playlistActions.requestAddTrack());
  dispatch(playlistActions.addToQueue(playlistId));
  try {
    await playlistAPI.addTrack(playlistId, trackId);
    dispatch(playlistActions.receiveAddTrack());
    dispatch(successAction(`Track added to ${playlistName}`));
    return true;
  } catch (error: any) {
    dispatch(playlistActions.errorAddTrack(error.message));
    dispatch(handleError(error));
    return false;
  } finally {
    dispatch(playlistActions.removeFromQueue(playlistId));
  }
};

export const removeTrack = (
  playlistId: number,
  trackId: number,
): ThunkResult => async (dispatch) => {
  dispatch(playlistActions.requestRemoveTrack());
  try {
    await playlistAPI.removeTrack(playlistId, trackId);
    dispatch(playlistActions.receiveRemoveTrack({ playlistId, trackId }));
  } catch (error: any) {
    dispatch(playlistActions.errorRemoveTrack(error.message));
  }
};

export const likePlaylist = (playlistId: number): ThunkResult => async (
  dispatch,
) => {
  dispatch(playlistActions.requestLikePlaylist());
  dispatch(playlistActions.receiveLikePlaylist(playlistId));
  try {
    playlistAPI.like(playlistId);
  } catch (error: any) {
    dispatch(playlistActions.errorLikePlaylist(error.message));
    dispatch(playlistActions.receiveUnlikePlaylist(playlistId));
  }
};

export const unlikePlaylist = (playlistId: number): ThunkResult => async (
  dispatch,
) => {
  dispatch(playlistActions.requestUnlikePlaylist());
  dispatch(playlistActions.receiveUnlikePlaylist(playlistId));
  try {
    await playlistAPI.removeLike(playlistId);
    dispatch(playlistActions.receiveUnlikePlaylist(playlistId));
  } catch (error: any) {
    dispatch(playlistActions.errorUnlikePlaylist(error.message));
    dispatch(playlistActions.receiveLikePlaylist(playlistId));
  }
};

export const addPlaylistLinkId = (id: number) => (dispatch: Dispatch) => {
  dispatch(playlistActions.addPlaylistLinkId(id));
};

export const toggleEditMode = (flag: boolean) => (dispatch: Dispatch) => {
  dispatch(playlistActions.toggleEditMode(flag));
};

export const update = (id: number, name: string): ThunkResult => async (
  dispatch,
) => {
  dispatch(playlistActions.requestCreatePlaylist());
  try {
    const updated = await playlistAPI.update(id, name);
    dispatch(playlistActions.receiveGetPlaylist(updated));
    dispatch(playlistActions.receiveUpdatePlaylist());
  } catch (error: any) {
    if (error.response)
      dispatch(
        playlistActions.errorUpdatePlaylist(error.response?.data.message),
      );
    else dispatch(playlistActions.errorUpdatePlaylist('Failed, unknown error'));
  }
};

export { addToQueue, removeFromQueue } from '../actions/playlists';

export const getUserPlaylists = ({
  id,
  page = 1,
  trackId,
  keyword,
}: playlistAPI.PlaylistsRequest): ThunkResult => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  let userId;

  if (id) userId = id;
  else
    ({
      user: { id: userId },
    } = getState());

  if (!userId) return;

  dispatch(playlistActions.requestUserPlaylists());
  try {
    const { playlists, pagination } = await playlistAPI.getPlaylists({
      id: userId,
      page,
      trackId,
      keyword,
    });
    const allTracks: Track[] = [];
    playlists.forEach((playlist) => {
      allTracks.push(...playlist.tracks);
    });
    if (page === 1) dispatch(playlistActions.resetPagination());
    dispatch(tracksActions.addTrackEntries(allTracks));
    dispatch(playlistActions.receiveGetPlaylist(playlists));
    dispatch(playlistActions.recieveUserPlaylists({ playlists, pagination }));
  } catch (error: any) {
    dispatch(playlistActions.errorUserPlaylists(error.message));
  }
};

export const getAllMyPlaylists = (): ThunkResult => async (
  dispatch,
  getState,
) => {
  const { user } = getState();

  if (!user.id) return;

  try {
    const playlists = await playlistAPI.getAllPlaylists(user.id);
    dispatch(playlistActions.receiveAllMyPlaylists(playlists));
  } catch (error: any) {
    dispatch(handleError(error));
  }
};
