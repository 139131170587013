import React from 'react';
import { Container } from 'reactstrap';

export const VuexyContainer = (ContainerProps: VuexyContainerProps) => (
  <Container {...ContainerProps} />
);

interface VuexyContainerProps {
  className?: string;
  children?: JSX.Element | JSX.Element[] | string;
  fluid?: boolean | string;
  style?: string;
  // applies .container-fluid class if bool or .container-{breakpoint} if string
}

export default VuexyContainer;
