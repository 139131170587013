import { AnyAction } from 'redux';
import { ThunkDispatch as RThunkDispatch } from 'redux-thunk';

import * as selectors from './redux/selectors';
import { RootState } from './redux/reducers';
import * as redux from './redux';
import * as style from './style';

import * as track from './lib/api/tracks';
import { search as getSearchResult } from './lib/api/search';
import { markConversationRead, createConversation } from './lib/api/chat';

import * as authentication from './lib/api/authentication';
import * as user from './lib/api/user';
import * as users from './lib/api/users';
import * as subscriptions from './lib/api/subscriptions';
import * as comments from './lib/api/comments';
import * as vcoins from './lib/api/vcoins';
import * as charts from './lib/api/charts';
import * as raffles from './lib/api/raffles';
import * as widget from './lib/api/widget';
import * as genres from './lib/api/genres';
import * as countries from './lib/api/countries';
import * as labels from './lib/api/labels';
import * as statistics from './lib/api/statistics';
import type { SocialPlatform } from './lib/api/users';
import * as trackReleases from './lib/api/track-release';
import * as musicKnowledgeHub from './lib/api/musicKnowledgeHub';

import { request as requestFeedback } from './lib/api/feedback';
import PaginatedEntries from './redux/PaginatedEntries';
import ResponseWrapper from './lib/api/ResponseWrapper';
import notUndefinedOrNull from './utils/notUndefinedOrNull';
import { ThunkResult, ThunkDispatch } from './redux/dispatch/ThunkDispatch';
import getRaffleType from './utils/getRaffleType';
import * as pullAPI from './lib/api/pullRequests';

import {
  chatActions,
  trackActions,
  playerActions,
  swiperActions,
  authenticationActions,
  userActions,
  subscriptionsActions,
  playlistActions,
  usersActions,
  followActions,
  searchActions,
  commentsActions,
  musicKnowledgeHubActions,
  notificationActions,
  vcoinActions,
  labelWidgetActions,
} from './redux';

export { setupPusher, stopPusher } from './redux/pusher';

export {
  chatActions,
  trackActions,
  playerActions,
  swiperActions,
  authenticationActions,
  userActions,
  subscriptionsActions,
  playlistActions,
  usersActions,
  followActions,
  searchActions,
  commentsActions,
  musicKnowledgeHubActions,
  notificationActions,
  vcoinActions,
  labelWidgetActions,
};

export type {
  RegisterFormValues,
  RegistrationData,
} from './lib/api/authentication';
export { setAxiosPlatformHeader, setAxiosBaseUrl } from './lib/api/axios';

export type { RootState };
export * from './redux/selectors';
export * from './redux/hooks';

export type { AxiosError } from 'axios';
export { handleAxiosError } from './redux/dispatch/alerts';
export { PeriodFilterOptions } from './lib/api/tracks';
export type {
  Filter,
  SwiperFilter,
  Track,
  NewTrack,
  TrackUpdate,
  Report as TrackReport,
  PeriodFilterType,
  TrackStatus,
  Selection,
  SortType,
} from './lib/api/tracks';
export type {
  CountryOption,
  TrackArtist,
  TrackRelease,
  // ImageInfo,
  ArtistRole,
  ArtistDetails,
  SpotifyArtist,
  TrackDetails,
  MusicStores,
  PaymentInfo,
  ArtistPercentage,
  DocusignContract,
  Signatures,
  DocusignSignature,
  StoreLink,
  DSP,
  FugaLanguage,
  IncorrectDetails,
  IncorrectArtist,
  ReleaseStatistics,
  TrackReleaseDetails,
} from './lib/api/track-release';
export type {
  UserSimple,
  User,
  Settings,
  SimpleTrack,
  ProducerLevel,
  ListenerLevel,
  Award,
  AwardType,
  CloseToAward,
  CloseToAwards,
  AwardCategory,
  CloseToOrAward,
} from './lib/api/user';
export type { Genre } from './lib/api/genres';
export type { Country } from './lib/api/countries';
export type { State as UserState } from './redux/reducers/user';
export type { Follower } from './lib/api/followers';
export type { Comment } from './lib/api/comments';
export type { PlaylistBase } from './lib/api/playlists';
export type { StatePlaylist } from './redux/reducers/playlists';
export type {
  Conversation,
  Reply,
  SimpleChatUser,
  ChatFilterType,
} from './lib/api/chat';
export type { Pagination } from './lib/api/Pagination';
export type {
  UserNotification as NotificationNormalized,
  TrackPullStatus,
  NotificationData,
  NotificationDataCommon,
  Notification,
  NotificationTypes,
} from './lib/api/notifications';
export type { StatsPeriods, CountryStats } from './lib/api/statistics';
export { statsPeriodsOptions } from './lib/api/statistics';
export type {
  Subscription,
  PaymentPlan,
  PlanRules,
} from './lib/api/subscriptions';
export type { PaymentSystem } from './lib/api/user';
export { raffleSortOptions } from './lib/api/raffles';
export type {
  Raffle,
  RaffleSort,
  RaffleFilter,
  RaffleStatus,
} from './lib/api/raffles';
export type { Transaction } from './lib/api/vcoins';
export type { Artist } from './lib/api/charts';

export type { PaginatedEntries };

export type Dispatch = RThunkDispatch<RootState, undefined, AnyAction>;
export type { ThunkResult, ThunkDispatch };

const API = {
  trackReleases,
  track,
  countries,
  authentication,
  user,
  users,
  search: {
    getSearchResult,
  },
  subscriptions,
  comments,
  chat: {
    markConversationRead,
    createConversation,
  },
  raffles,
  feedback: {
    request: requestFeedback,
  },
  statistics,
  vcoins,
  charts,
  widget,
  genres,
  labels,
  musicKnowledgeHub,
};

export * from './redux';
export { compose } from 'redux';
export { redux, style, API, selectors };
export type { AnyAction, ResponseWrapper };
export { roleToRoleID } from './utils/role';
export { logError } from './utils/logging';
export { default as toArray } from './utils/toArray';
export { notUndefinedOrNull, getRaffleType };
export { useDebounce, useDebouncedCallback } from 'use-debounce';

export { default as axios } from './lib/api/axios';

export { hexToRGB } from './style';

export { getAwardTitles, awardPrefixes } from './utils/awardTitles';

export type { SocialPlatform };

export { getPullRequestsForLogginInUser } from './lib/api/tracks';
export type { TrackPulledRecieved } from './lib/api/tracks';

export { pullAPI };
