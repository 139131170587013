import { CloseToOrAward } from '../lib/api/user';

export const getAwardTitles = ({ type, award }: CloseToOrAward) => {
  switch (type) {
    case 'a_and_r':
      return {
        title: award || `awards:arDemo`,
      };
    case 'bronze':
      return {
        title: award || `awards:bronzeDemo`,
        subTitle: `awards:fiveHundredPlays`,
        prefix: 'awards:VirppWithMoreThan',
        unit: 'awards:plays',
      };
    case 'silver':
      return {
        title: award || `awards:silverDemo`,
        subTitle: `awards:thousandPlays`,
        prefix: 'awards:VirppWithMoreThan',
        unit: 'awards:plays',
      };
    case 'gold':
      return {
        title: award || `awards:goldDemos`,
        subTitle: `awards:twentyFiveHundredPlays`,
        prefix: 'awards:VirppWithMoreThan',
        unit: 'awards:plays',
        description: 'awards:attentionOfPremium',
      };
    case 'diamond':
      return {
        title: award || `Diamond Demo`,
        subTitle: `awards:tenKPlays`,
        prefix: 'awards:VirppWithMoreThan',
        unit: 'awards:plays',
        description: 'awards:everyScoutingRadar',
      };
    case 'most_played':
      return {
        title: award || `awards:mostPlayed`,
        subTitle: `awards:mostPlayedExclemation`,
        unit: 'awards:plays',
      };
    case 'helper':
      return {
        title: award || `awards:theHelper`,
        subTitle: 'awards:fiveFeedbacks',
        unit: 'awards:feedbacks',
      };
    case 'mentor':
      return {
        title: award || `awards:theMentor`,
        subTitle: 'awards:tenFeedbacks',
        unit: 'awards:minutes',
      };
    case 'expert':
      return {
        title: award || `awards:theExpert`,
        subTitle: 'awards:hundredFeedbacks',
        unit: 'awards:feedbacks',
        description: 'awards:becomeAAr',
      };
    case 'guru':
      return {
        title: award || `awards:theGuru`,
        subTitle: 'awards:twoFiftyFeedbacks',
        unit: 'awards:feedbacks',
        description: 'awards:helpInExploring',
      };
    case 'keen_listener':
      return {
        title: award || `awards:keenListener`,
        subTitle: 'awards:thirtyMinutes',
        unit: 'awards:minutes',
      };
    case 'scout':
      return {
        title: award || `awards:theScout`,
        subTitle: 'awards:twoHours',
        unit: 'awards:minutes',
      };
    case 'digger':
      return {
        title: award || `awards:theDigger`,
        subTitle: 'awards:sixHours',
        unit: 'awards:minutes',
        description: 'awards:extraVcoins',
      };
    case 'master':
      return {
        title: award || `awards:masterListener`,
        subTitle: 'awards:twentyFourHours',
        unit: 'awards:minutes',
        description: 'awards:insAndOutsOfVirpps',
      };
    default: {
      return {
        title: 'awards:mysticalAward',
        subTitle: 'awards:thisIsAMystical',
        unit: '',
        description: '',
      };
    }
  }
};

export const awardPrefixes = {
  tracks: 'awards:virppWithMoreThan',
  feedback: 'awards:give',
  listener: 'awards:listen',
};
