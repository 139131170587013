import React from 'react';
import styled from 'styled-components';

export default styled(({ className }: { className?: string }) => (
  <Wrapper className={className}>
    <ProLogoImg src="/static/images/pro-logo.svg" />
  </Wrapper>
))``;

const Wrapper = styled.div`
  width: 40px;
`;

const ProLogoImg = styled.img`
  width: 100%;
`;
