import React from 'react';
import styled from 'styled-components';
import { useHeaderHeight } from '../../hooks/style';

const BlackLayer = styled.div<{ offset: number }>`
  position: fixed;
  top: ${(p) => p.offset}px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.8;
`;

const BlackLayerComponent = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children?: React.ReactNode;
}) => {
  const { headerHeight } = useHeaderHeight();

  return (
    <BlackLayer onClick={onClick} offset={headerHeight} id="main-menu-backdrop">
      {children}
    </BlackLayer>
  );
};

export default BlackLayerComponent;
