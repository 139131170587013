import React from 'react';
import MoonLoader from 'react-spinners/ScaleLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import BarLoader from 'react-spinners/BarLoader';

import styled from 'styled-components';
import { style } from '../../api';

const MoonLoaderPink = ({
  color = style.pink,
  height,
}: {
  color: string;
  height?: number;
}) => <MoonLoader color={color} height={height} />;

const Loader = ({
  className,
  color = style.pink,
  height,
}: {
  className?: string;
  color?: string;
  height?: number;
}) => (
  <div className={className}>
    <MoonLoaderPink color={color} height={height} />
  </div>
);

const CenterItem = styled.div`
  > div {
    display: flex;
    align-items: center;
  }
`;

export const WaveformLoader = () => (
  <CenterItem>
    <PropagateLoader color={style.primary_pink_new} />
  </CenterItem>
);

export const LoginLoader = () => <BarLoader color={style.pink} />;

export default styled(Loader)`
  margin: 20px 0px;
  align-self: center;
`;

const CenteredContent = ({
  className,
  style: containerStyle,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div className={className} style={containerStyle}>
    <Loader />
  </div>
);

export const CenteredLoader = styled(CenteredContent)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
