import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCurrentUser } from '../../api';

import Dialog from '../common/Dialog';
import { TextNoMargin } from '../common/Text';
import { ButtonProGradient } from '../common/Buttons/V2';
import Flex from '../common/Flex';
import Logo from '../common/Logo';
import ProLogo from '../common/ProLogo';
import { useLocalStorageFlagForCurrentUser } from '../../hooks/useLocalstorageFlag';

const dontShowOnPages = ['/my-pro', '/pro-payment'];
const flag = 'HAS_SHOWN_MEMBERSHIP_EXPIRED_DIALOG';

const MembershipExpiredDialog = () => {
  const { t } = useTranslation('modals');
  const [show, setShow] = useState(false);
  const { subscription } = useCurrentUser();
  const router = useRouter();
  const dontShowOnPage = dontShowOnPages.includes(router.pathname);
  const ends_at = subscription?.ends_at;
  const [hasShown, setHasShown] = useLocalStorageFlagForCurrentUser(flag);

  useEffect(() => {
    if (!ends_at) {
      return;
    }

    if (
      !hasShown &&
      !dontShowOnPage &&
      dayjs.unix(ends_at).isBefore(dayjs()) &&
      subscription?.stripe_status !== 'past_due'
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ends_at, dontShowOnPage, hasShown]);

  return (
    <Dialog
      open={show}
      onClose={() => {
        setShow(false);
        setHasShown(true);
      }}
    >
      <Flex
        flexDirection="column"
        maxWidth="590px"
        alignItems="center"
        paddingX="60px"
      >
        <TextNoMargin fontSize="18px" fontWeight="bold">
          {t('modals:membershipEnded')}
        </TextNoMargin>
        <TextNoMargin fontSize="14px" mt="22px" textAlign="center">
          {t('modals:reactivateMembership')}
        </TextNoMargin>

        <ButtonProGradient
          style={{ marginTop: 38 }}
          onClick={() => {
            setShow(false);
            router.push('/become-pro');
          }}
        >
          {t('modals:renewMembership')}
        </ButtonProGradient>
        <Flex pt="8px" pb="58px">
          <SmallLogo />
          <ProLogo />
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default MembershipExpiredDialog;

const SmallLogo = styled(Logo)`
  img {
    height: 16px;
    width: auto;
  }
`;
