import React from 'react';
import { Row } from 'reactstrap';

export const VirppRow = ({ children, ...props }: VuexyRowProps) => (
  <Row {...props}>{children}</Row>
);

interface VuexyRowProps {
  noGutters?: boolean;
  className?: string;
  // see https://reactstrap.github.io/components/form Form Grid with Form Row
  form?: boolean;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
  children?: JSX.Element | JSX.Element[];
  style?: string | Record<string, unknown>;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
}

export default VirppRow;
