import React from 'react';
import { Card } from 'reactstrap';

export const VuexyCard = ({ children, ...props }: VuexyCardProps) => (
  <Card {...props}>{children}</Card>
);

interface VuexyCardProps {
  // Pass in a Component to override default element
  tag?: () => void | string;
  inverse?: boolean;
  color?: string;
  body?: boolean;
  className?: string | Record<string, unknown>;
  children?: unknown;
  style?: string | Record<string, unknown>;
}

export default VuexyCard;
