import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Loader from '../../components/common/Loader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

function LineChart({ options, data }: LineChartProps) {
  const [chartOptions] = useState<ChartOptions<'line'>>({
    ...defaultOptionsValues,
    ...options,
  });
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    labels: data.labels,
    datasets: data.datasets.map((dataset) => ({ ...dataStyle, ...dataset })),
  });

  useEffect(() => {
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset) => ({ ...dataStyle, ...dataset })),
    });
  }, [data]);

  if (!chartData || !chartOptions) {
    return <Loader />;
  }

  return <Line data={chartData} options={chartOptions} />;
}

export default LineChart;

interface LineChartProps {
  data: ChartData<'line'>;
  options?: ChartOptions<'line'>;
}

const defaultOptionsValues: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  backgroundColor: 'red',
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: true,
    },
  },
};

const dataStyle = {
  borderColor: '#0A5AF5',
  lineTension: 0.5,
  pointStyle: 'circle',
  backgroundColor: 'rgba(228, 240, 252, 0.5)',
  pointBackgroundColor: '#0A5AF5',
  fill: true,
  pointRadius: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 4,
  pointBorderColor: 'transparent',
  pointHoverBorderColor: '#0A5AF5',
  pointHoverBackgroundColor: '#0A5AF5',
  pointShadowOffsetX: 1,
  pointShadowOffsetY: 1,
  pointShadowBlur: 5,
  pointShadowColor: 'rgba(0, 0, 0, 0.25)',
};
