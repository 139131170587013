import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { style } from '../../../api';
import { noDesktop } from '../../../style/constants';

const Container = styled.div<{ mobile?: boolean }>`
  background: ${style.primary_pink_new};
  min-width: 19px;
  height: 19px;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  font-size: 14px;
  color: ${style.light};
  position: absolute;
  top: 3%;
  right: 10%;
  z-index: 200;
  padding: 0px 1px 2px 1px;
  border: 1.5px solid ${style.white};
  @media ${noDesktop} {
    min-width: 17px;
    height: 17px;
    top: -17%;
    right: 76%;
  }
`;

const NotificationIndicator = ({
  mobile,
  number,
  children,
}: {
  mobile?: boolean;
  number?: number;
  children?: ReactNode;
}) => (
  <Container mobile={mobile}>
    {mobile && number}
    {children}
  </Container>
);

export default NotificationIndicator;
