import styled from 'styled-components';
import { Field } from 'formik';
import { style } from '../../api';

import { Text } from './Text';
import { CheckBox } from './Inputs';

export const ErrorLabel = styled.div`
  color: red;
  padding: 5px 0 0;
  position: absolute;
  top: 100%;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 52px;
  font-weight: 700;
  color: ${style.black};
  margin: 30px 0;

  @media (max-width: 576px) {
    font-size: 43px;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  letter-spacing: 0px;
  line-height: 18px;
  color: ${style.black};
`;

export const TrackData = styled.div`
  display: flex;
  margin-top: 25px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #2d2c2c;
  }
`;

export const TrackImage = styled.img<{ size?: number }>`
  width: ${(p) => p.size || 35}px;
  height: ${(p) => p.size || 35}px;
  min-width: ${(p) => p.size || 35}px;
  min-height: ${(p) => p.size || 35}px;

  border-radius: 2px;
  margin-right: 10px;
`;

export const TrackTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const TrackOwner = styled.div`
  font-size: 12px;
`;

export const Description = styled(Field)`
  width: 100%;
  height: 130px;
  border-radius: 4px;
  border: 1px solid #cacaca;
  color: #474747;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 20px 25px;
  resize: none;

  &::placeholder {
    color: ${style.grey3};
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins';
  }
`;

export const CheckboxMark = styled(CheckBox)`
  margin-bottom: 0;
`;

export const CheckboxText = styled(Text)`
  padding-left: 10px;
  margin-bottom: 0;

  & + div {
    margin-left: 28px;
    padding-top: 0;
  }
`;
