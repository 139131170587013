import React from 'react';
import { StyledIcon } from '@styled-icons/styled-icon';
import styled from 'styled-components';
import { Track } from '../../api';

import TrackImage from '../common/TrackImage';
import IconWithBorder from '../common/IconWithBorder';

const TrackImageWithIcon = ({
  color,
  size = 15,
  icon,
  track,
  borderWidth,
}: {
  color: string;
  size?: number;
  icon: StyledIcon;
  track: Track;
  borderWidth?: number;
}) => (
  <TrackImageWithIconWrapper size={size}>
    <IconWithBorder
      borderWidth={borderWidth}
      color={color}
      size={size}
      icon={icon}
    />
    <TrackImage src={track?.images?.['210x210'] ?? track?.track_img} />
  </TrackImageWithIconWrapper>
);

export default TrackImageWithIcon;

const TrackImageWithIconWrapper = styled.div<{ size: number }>`
  position: relative;
  display: flex;
  align-items: center;

  margin-left: ${(p) => p.size / 2}px;

  .icon-wrapper {
    position: absolute;
    left: -${(p) => p.size / 2}px;
  }
`;
