import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as commentsAPI from '../../lib/api/comments';
import * as musicKnowledgeHubAPI from '../../lib/api/musicKnowledgeHub';
import { useMergeWithPreviousData } from './common/queryUtils';

const identifier = 'comments';

export const useTrackFeedback = (trackId: number | undefined) =>
  useQuery([identifier, 'feedback', trackId], () =>
    trackId ? commentsAPI.getTrackFeedback(trackId) : undefined,
  );

export const useComment = (commentId: number) => {
  const queryIdentifier = [identifier, commentId];
  const merge = useMergeWithPreviousData(queryIdentifier);
  const hook = useQuery(
    queryIdentifier,
    () => (commentId ? commentsAPI.fetchById(commentId) : undefined),
    {
      select: merge,
    },
  );

  const client = useQueryClient();

  const setAsLiked = () => {
    client.setQueryData<commentsAPI.Comment | undefined>(
      queryIdentifier,
      (prev) =>
        prev
          ? {
              ...prev,
              user_liked_comment: true,
              like_count: prev.like_count + 1,
            }
          : undefined,
    );
  };

  const setAsUnliked = () => {
    client.setQueryData<commentsAPI.Comment | undefined>(
      queryIdentifier,
      (prev) =>
        prev
          ? {
              ...prev,
              user_liked_comment: false,
              like_count: Math.max(prev.like_count - 1, 0),
            }
          : undefined,
    );
  };

  const { mutateAsync: like } = useMutation(
    () => commentsAPI.likeComment(commentId),
    {
      onMutate: setAsLiked,
      onError: setAsUnliked,
    },
  );

  const { mutateAsync: unlike } = useMutation(
    () => commentsAPI.removeCommentLike(commentId),
    {
      onMutate: setAsUnliked,
      onError: setAsLiked,
    },
  );

  const { mutateAsync: reply } = useMutation(
    ({ trackId, message }: { trackId: number; message: string }) =>
      commentsAPI.replyToComment(trackId, commentId, message),
    {
      onSuccess: (newReply) => {
        client.setQueryData(queryIdentifier, newReply);
      },
    },
  );

  return {
    ...hook,
    like,
    unlike,
    reply,
  };
};

export const useCommentMusicKnowledgeHub = (commentId: number) => {
  const queryIdentifier = ['musicKnowledgeHubComment', commentId];
  const merge = useMergeWithPreviousData(queryIdentifier);
  const hook = useQuery(
    queryIdentifier,
    () =>
      commentId ? musicKnowledgeHubAPI.fetchCommentById(commentId) : undefined,
    {
      select: merge,
    },
  );

  const client = useQueryClient();

  const setAsLiked = () => {
    client.setQueryData<commentsAPI.Comment | undefined>(
      queryIdentifier,
      (prev) =>
        prev
          ? {
              ...prev,
              logged_in_user_liked: true,
              like_count: prev.like_count + 1,
            }
          : undefined,
    );
  };

  const setAsUnliked = () => {
    client.setQueryData<commentsAPI.Comment | undefined>(
      queryIdentifier,
      (prev) =>
        prev
          ? {
              ...prev,
              logged_in_user_liked: false,
              like_count: Math.max(prev.like_count - 1, 0),
            }
          : undefined,
    );
  };

  const { mutateAsync: like } = useMutation(
    () => musicKnowledgeHubAPI.likeOnCommentData(commentId),
    {
      onMutate: setAsLiked,
      onError: setAsUnliked,
    },
  );

  const { mutateAsync: unlike } = useMutation(
    () => musicKnowledgeHubAPI.removeLikeOnCommentData(commentId),
    {
      onMutate: setAsUnliked,
      onError: setAsLiked,
    },
  );

  const { mutateAsync: deleteCommentData } = useMutation(
    ({ commentIdData }: { parentCommentId: number; commentIdData: number }) =>
      musicKnowledgeHubAPI.deleteCommentData(commentIdData),
    {
      onSuccess: (_, { parentCommentId }) => {
        client.setQueryData<commentsAPI.Comment | undefined>(
          ['musicKnowledgeHubComment', parentCommentId],
          (prev) =>
            prev
              ? {
                  ...prev,
                  replies: prev.replies.filter(
                    (reply) => reply.id !== commentId,
                  ),
                }
              : undefined,
        );
      },
    },
  );

  const { mutateAsync: reply } = useMutation(
    ({ postId, message }: { postId: number; message: string }) =>
      musicKnowledgeHubAPI.replyToCommentData(postId, commentId, message),
    {
      onSuccess: (newReply) => {
        client.setQueryData(queryIdentifier, newReply);
      },
    },
  );

  return {
    ...hook,
    like,
    unlike,
    reply,
    deleteCommentData,
  };
};
