import axios from './axios';
import ResponseWrapper from './ResponseWrapper';
import { Track } from './tracks';
import { User, UserSimple } from './user';
import { TrackPullStatus } from './notifications';
import { ReleaseStatistics } from './track-release';
import { Pagination } from './Pagination';

export interface WorldMapDataEntry {
  id: number;
  name: string;
  code: string;
  plays_count: number;
  likes_count: number;
  percentage: number;
  x: number;
  y: number;
}

export type TrackStats = Track & TrackStatsResponse;

export interface CountryStats {
  id: number;
  name: string;
  code: string;
  plays_count: number;
  likes_count: number;
}

export interface GenderStats {
  [key: string]: {
    percentage: number;
    amount: number;
  };
}

export interface TrackStatsResponse {
  id: number;
  plays: number;
  likes: number;
  skips: number;
  percentage: number;
  interactions: Interactions;
  worldmap_data: WorldMapDataEntry[];
}

export interface Statistics {
  plays: number;
  likes: number;
  skips: number;
  comments: number;
  plays_by_labels: number;
  worldmap_data: WorldMapDataEntry[];
  top_countries: CountryStats[];
  gender?: GenderStats;
  age?: {
    [key: string]: number;
  };
}

export type Interactions = {
  [timestamp: string]: InteractionsEntry;
};

export type StatisticsForAllTracks = Statistics & {
  interactions: Interactions;
  tracks: TrackStatsResponse[];
};

export interface InteractionsEntry {
  plays?: number;
  likes?: number;
  skips?: number;
  date?: string;
}

export interface StatisticsPopularTracks {
  most_played?: Track;
  most_liked?: Track;
  most_commented?: Track;
}

export const statsPeriodsOptions = [
  '24hrs',
  'last_week',
  'last_month',
  'all',
] as const;

export type StatsPeriods = typeof statsPeriodsOptions[number];

interface Filter {
  period?: StatsPeriods;
}

export interface LabelInteractionStats {
  plays: number;
  likes: number;
  pull_requests: Record<number, TrackPullStatus>;
  activity: {
    track_id: number;
    played: boolean;
    liked: boolean;
    downloaded: boolean;
  }[];
}

export interface UserStats {
  total_played_time: number;
  total_likes_given: number;
  total_plays_your_tracks: number | null;
  total_likes_your_tracks: number | null;
  total_following: number;
  total_mutual_followers: number | null;
}

export interface ReleaseTracksResponse {
  data: ReleaseStatistics[];
  pagination: Pagination;
}

export interface AffiliateListResponse {
  data: {
    user: {
      display_name: string;
      id: number;
      profile_photo: string;
    };
    plan: string;
    billing: string;
    earnings: string;
    sortable?: boolean;
  }[];
  pagination: Pagination;
}

export const fetchStats = (userId: number) =>
  axios
    .get<ResponseWrapper<UserStats>>(`statistics/user/${userId}`)
    .then(({ data }) => data.data);

export const getStatisticsMostPopular = async (id: number, filters?: Filter) =>
  axios
    .get(`statistics/users/${id}/tracks/most`, { params: filters })
    .then(
      ({ data }: { data: ResponseWrapper<StatisticsPopularTracks> }) =>
        data.data,
    );

export const getTrackStatistics = async (id: number, filters?: Filter) =>
  axios
    .get(`statistics/tracks/${id}`, { params: filters })
    .then(({ data }: { data: ResponseWrapper<TrackStats> }) => data.data);

export const getAllStats = async (userId: number, period: StatsPeriods) =>
  axios
    .get<ResponseWrapper<StatisticsForAllTracks>>(
      `statistics/users/${userId}/tracks`,
      { params: { period } },
    )
    .then((res) => res.data.data);

export const getStatsPopularTracks = async (id: number, filters?: Filter) =>
  axios
    .get(`statistics/users/${id}/tracks/most`, { params: filters })
    .then(
      ({ data }: { data: ResponseWrapper<StatisticsPopularTracks> }) =>
        data.data,
    );

export const getStatsForLabels = async () =>
  axios
    .get<ResponseWrapper<User[]>>('statistics/labels')
    .then((res) => res.data.data);

export const getStatsForLabel = async (labelId: number) =>
  axios
    .get<ResponseWrapper<LabelInteractionStats>>(`statistics/labels/${labelId}`)
    .then((res) => res.data.data);

export const getLineChartsStreams = async (
  selection_type: string,
  sale_type: string,
  period: string,
) =>
  axios
    .get('track-releases/statistics/line-chart-streams', {
      params: { selection_type, sale_type, period },
    })
    .then((res) => res.data.data);
export const getDonutChartStreams = async (
  selection_type: string,
  sale_type: string,
  period: string,
) =>
  axios
    .get('track-releases/statistics/donut-chart-streams', {
      params: { selection_type, sale_type, period },
    })
    .then((res) => res.data.data);

export const getDonutChartValues = async (
  selection_type: string,
  sale_type: string,
  period: string,
) =>
  axios
    .get('track-releases/statistics/total-streams', {
      params: { selection_type, sale_type, period },
    })
    .then((res) => res.data.data);

export const getLineChartsStreamsForTrack = async (
  trackId: number,
  selection_type: string,
  sale_type: string,
  period: string,
) =>
  axios
    .get(`track-releases/statistics/line-chart-streams/${trackId}`, {
      params: { selection_type, sale_type, period },
    })
    .then((res) => res.data.data);

export const getDonutChartStreamsForTrack = async (
  trackId: number,
  selection_type: string,
  sale_type: string,
  period: string,
) =>
  axios
    .get(`track-releases/statistics/donut-chart-streams/${trackId}`, {
      params: { selection_type, sale_type, period },
    })
    .then((res) => res.data.data);

export const getDonutChartValuesForTrack = async (
  trackId: number,
  selection_type: string,
  sale_type: string,
  period: string,
) =>
  axios
    .get(`track-releases/statistics/total-streams/${trackId}`, {
      params: { selection_type, sale_type, period },
    })
    .then((res) => res.data.data);

export const getLabelsForTrack = async (id: number) =>
  axios
    .get(`statistics/tracks/${id}/labels`)
    .then(({ data }: { data: ResponseWrapper<UserSimple[]> }) => data.data);

export interface LabelPlays {
  label: User;
  plays: number;
}

export const getLabelPlaysForUser = async () =>
  axios
    .get(`statistics/labels/tracks`)
    .then(({ data }: { data: ResponseWrapper<LabelPlays[]> }) => data.data);

export const getListTrackReleases = (
  _key: string,
  page: number,
  period: string,
): Promise<ReleaseTracksResponse> =>
  axios({
    method: 'GET',
    url: `track-releases/statistics`,
    params: { page, period },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));

export const getListAffiliate = (
  page: number,
  perPage: number,
  period: string,
  keyword: string,
): Promise<AffiliateListResponse> =>
  axios({
    method: 'GET',
    url: `/affiliates`,
    params: { page, perPage, period, keyword },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));

export const geSalesAnalyticsForAffiliate = async (period: string) =>
  axios
    .get(`affiliates/total-sales`, {
      params: { period },
    })
    .then((res) => res.data.data);
export const geRecurringAnalyticsForAffiliate = async (period: string) =>
  axios
    .get(`affiliates/total-recurring`, {
      params: { period },
    })
    .then((res) => res.data.data);

export const getWalletEarningsData = async (userId: number) =>
  axios.get(`users/${userId}/balance`).then((res) => res.data.data);

export const getWalletUserTransactions = async (userId: number) =>
  axios.get(`users/${userId}/transactions`).then((res) => res.data.data);

export const getWalletTransactionStatement = async (
  userId: number,
  transactionId: number,
  meta: string,
) =>
  axios
    .post(`users/${userId}/royalty-statement`, {
      User: userId,
      transaction_id: transactionId,
      meta,
    })
    .then(({ data }) => data.data);

export const requestWithdrawAmount = async (
  amount: string,
  bank_name: string,
  bank_account_no: string,
  bank_code: string,
  paypal_address: string,
  userId?: number,
  type?: string,
  currency?: string,
) =>
  axios
    .post(`users/${userId}/request-manual-withdraw`, {
      amount,
      type,
      bank_name,
      bank_account_no,
      bank_code,
      paypal_address,
      currency,
    })
    .then(() => true);
