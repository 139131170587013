import axios from './axios';
import ResponseWrapper, { ResponseWrapperPaginated } from './ResponseWrapper';

export interface Transaction {
  id: number;
  message: string;
  amount: number;
  created_at: number;
  current: number;
  data?: {
    type: string;
    track_id?: number;
    raffle_id?: number;
    plan_id?: number;
  };
}

export interface VcoinsPackage {
  id: number;
  name: string;
  description?: string;
  price: number;
  amount: number;
  live: boolean;
  package_id: string;
  type: 'vcoins' | 'ideal_subscription';
}

export const fetchBalance = () =>
  axios
    .get<ResponseWrapper<{ total: number }>>('/vcoins/total')
    .then(({ data }) => data.data);

export const fetchBalanceWithPeriod = (period: string) =>
  axios
    .get<ResponseWrapper<{ total: number }>>('/vcoins/total', {
      params: { period },
    })
    .then((res) => res.data.data);

export const fetchTransactions = (page: number) =>
  axios
    .get<ResponseWrapperPaginated<Transaction[]>>('/user/points', {
      params: { page },
    })
    .then(({ data }) => data);

export const fetchTransactionsWithPeriod = (page: number, period: string) =>
  axios
    .get<ResponseWrapperPaginated<Transaction[]>>('/user/points', {
      params: { page, period },
    })
    .then(({ data }) => data);

export const fetchWebVcoinPackages = async () =>
  axios
    .get('/packages')
    .then(({ data }: { data: ResponseWrapper<VcoinsPackage[]> }) => data.data);

export const fetchSubscriptions = async () =>
  axios
    .get('/subscriptions')
    .then(({ data }: { data: ResponseWrapper<VcoinsPackage[]> }) => data.data);

export const fetchMobilePackages = () =>
  axios
    .get('/mobile-plans')
    .then(({ data }: { data: ResponseWrapper<VcoinsPackage[]> }) => data.data);

export const createStripeSession = async (args: {
  package_id: number;
  success_url: string;
  cancel_url: string;
  coupon?: string;
}) =>
  axios
    .post<ResponseWrapper<{ session_id: string }>>(
      '/vcoins/create-session',
      args,
    )
    .then((res) => res.data.data.session_id);
