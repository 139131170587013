import { ReactElement } from 'react';

import useMedia from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import * as style from '../api/style';

export const largeWidth = 1200;
export const desktopWidth = 992;
export const bigTabletWidth = 768;
export const tabletWidth = 576;

// breakpoints
export const large = `(min-width: ${largeWidth}px)`;
export const desktop = `(min-width: ${desktopWidth}px)`;
export const noDesktop = `(max-width: ${desktopWidth - 1}px)`;
export const bigTablet = `(min-width: ${bigTabletWidth}px)`;

export const onlyBigTablet = `${bigTablet} and ${noDesktop}`;

export const noBigTablet = `(max-width: ${bigTabletWidth - 1}px)`;

export const tablet = `(min-width: ${tabletWidth + 1}px)`;

export const mobile = `(max-width: ${tabletWidth}px)`;

// hooks
export const useIsDesktop = () => useMedia(desktop);

export const useIsMobile = () => useMedia(noBigTablet);

export const useIsTablet = () => useMedia(onlyBigTablet);

// prettier-ignore
export const useIsSmallMobile = () => useMedia(`(max-width: ${tabletWidth - 1}px)`);

export const Desktop: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isDesktop = useIsDesktop();

  return isDesktop ? (children as ReactElement) : null;
};

export const NoDesktop: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isDesktop = useMedia(`(max-width: ${desktopWidth}px)`);

  return isDesktop ? (children as ReactElement) : null;
};

export const Tablet: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isTablet = useIsTablet();

  return isTablet ? (children as ReactElement) : null;
};

export const Mobile: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isMobile = useIsMobile();

  return isMobile ? (children as ReactElement) : null;
};

export const NoMobile: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isMobile = useMedia(bigTablet);

  return isMobile ? (children as ReactElement) : null;
};

export const Default: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isNotMobile = useMedia(bigTablet);

  return isNotMobile ? (children as ReactElement) : null;
};

// grid theme
export const globalGrid = {
  container: {},
};

// Styled System: Breakpoints Array
const primaryBreakpoints = [tabletWidth, desktopWidth, largeWidth];
export const breakpoints = primaryBreakpoints.map((item) => `${item}px`);

// Styled System: fontSizes
export const fontSizes = [10, 12, 14, 18, 16, 20, 22, 24, 28, 32, 48, 64, 72];

// Styled System: Spacing / Padding
export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

// transition time
export const transitionTime = '0.2s';

// classed
export const linkActiveClassname = 'active-header-link';

export const MobileContainer = styled.div`
  @media ${desktop} {
    display: none;
  }
`;

export const DesktopContainer = styled.div`
  @media ${noDesktop} {
    display: none;
  }
`;

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: style.primary_pink_new,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: tabletWidth,
      md: desktopWidth,
      lg: largeWidth,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'DM Sans', sans-serif",
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          border: `2px solid ${style.grey_main_new}`,
          borderRadius: 0,
        },
        '&:hover fieldset': {
          border: `2px solid ${style.grey_dark_new}`,
          borderRadius: 0,
        },
        '&.Mui-focused fieldset': {
          border: `2px solid ${style.black_new} !important`,
          borderRadius: '5px',
        },
      },
      input: {
        paddingLeft: '12px !important',
        padding: '3.5px 4px !important',
      },
    },
  },
});
