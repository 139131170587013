import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import { authenticationActions, useDispatch } from '../api';

const defaultArguments = {
  redirectOnLogout: true,
  redirectPath: '/',
};

const useLogout = ({
  redirectPath: defaultRedirectPath = defaultArguments.redirectPath,
  redirectOnLogout = defaultArguments.redirectOnLogout,
}: Partial<typeof defaultArguments> = defaultArguments) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const queryClient = useQueryClient();

  return (redirectPath = defaultRedirectPath) => {
    dispatch(authenticationActions.logout());
    queryClient.resetQueries();
    if (redirectOnLogout) {
      router.push(redirectPath);
    }
  };
};

export default useLogout;
