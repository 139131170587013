import React from 'react';
import { CustomInput } from 'reactstrap';

export const VuexyRadioButton = ({ ...props }: VuexyButtonProps) => (
  //   <Input type="radio" checked style={{ borderRadius: '2px' }} {...props}>
  //     {text} {children}
  //   </Input>

  <CustomInput type="radio" {...props} />
);

interface VuexyButtonProps {
  defaultChecked?: boolean;
  inline?: boolean;
  disabled?: boolean;
  className?: string;
  style?: string | Record<string, unknown>;
  id: string;
  name?: string;
  checked?: boolean;
}

export default VuexyRadioButton;
