import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { Done } from '@styled-icons/material/Done';
import { Heart } from '@styled-icons/boxicons-solid/Heart';
import { Message } from '@styled-icons/boxicons-solid/Message';
import { Bookmark } from '@styled-icons/boxicons-solid/Bookmark';
import { Flag } from '@styled-icons/material/Flag';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { Medal } from '@styled-icons/fa-solid/Medal';
import { BarChartAlt2 } from '@styled-icons/boxicons-solid/BarChartAlt2';
import {
  TextMedium12,
  TextRegular18,
  TextBold14,
} from '../../VuexyDesign/atoms/Text';
import { FEATURE_COLLABORATION_ENABLED } from '../../constants/config';
import {
  InnerLink,
  ProfileLink as ProfileLinkInternal,
  TrackLink as TrackLinkInternal,
  RaffleLink as RaffleLinkInternal,
  ConversationLink,
} from '../common/Link';

import {
  Notification,
  style,
  User,
  Track,
  followActions,
  logError,
  useSelect,
  useUser,
  useTrack,
  trackActions,
  useCurrentUser,
  NotificationTypes,
  useRaffle,
  useQueryPullRequest,
  pullAPI,
  TrackPulledRecieved,
  API,
  useDispatch,
} from '../../api';
import { TrackCollaborationRequest } from '../../api/lib/api/notifications';

import { Error as ErrorIcon } from '../common/Icons';
import { ToastError } from '../Toast';
import MessageTime from './MessageAndTime';
import TrackImageWithIcon from './TrackImageWithIcon';
import AcceptDeclineButtons from './AcceptDeclineButtons';
import CongratulationsIcon from './CongratulationsIcon';
import UserImage from '../common/UserImage';
import TrackImage from '../common/TrackImage';

import styles from './styles.module.css';
import Button from '../../VuexyDesign/atoms/Button';

interface Props {
  notification: Notification;
}

const Index = ({ notification }: Props) => {
  const { t } = useTranslation('notifications');
  const { users, tracks, conversations } = useSelect(
    ({
      tracks: { entries: tracksEntries },
      users: { entries: usersEntries },
      chat: { conversations: conversationEntries },
    }) => ({
      tracks: tracksEntries as Record<number, Track | undefined>,
      users: usersEntries as Record<number, User | undefined>,
      conversations: conversationEntries,
    }),
  );

  const { data } = notification;
  const created_at = notification.timestamp;

  const [pullRequests, setPullRequests] = useState<TrackPulledRecieved[]>([]);
  const [pullStatus, setPullStatus] = useState('open');
  const [notificationTrack, setNotificationTrack] = useState<Track>();

  const handlePullAction = (pullId: number, action: string) => {
    if (action === 'accept') {
      setPullStatus('accepted');
      pullAPI.accept(pullId);
    }
    if (action === 'decline') {
      setPullStatus('declined');
      pullAPI.decline(pullId);
    }
  };

  const setNotificationTrackById = async (trackId?: number) => {
    if (trackId) {
      API.track.fetch([trackId]).then((resp) => setNotificationTrack(resp[0]));
    }
  };

  useEffect(() => {
    const setPulls = async () => {
      if (data.type !== 'TrackPullRequestMade') {
        return;
      }

      const pulls: TrackPulledRecieved[] = await API.track
        .getPullByTrackId(data.track_id)
        .then((res) => res.data)
        .catch((err) => console.log(err));
      setPullRequests(pulls);
    };
    setPulls();

    if (
      data.type === 'TrackSelectedForRelease' ||
      data.type === 'TrackReleaseReleased'
    ) {
      setNotificationTrackById(data.track_id);
    }
  }, []);

  const getPullForTrack = ({
    trackId,
    userId,
  }: {
    trackId?: number;
    userId?: number;
  }) => {
    if (!trackId || !userId) {
      return null;
    }
    return pullRequests.filter(
      (pull) => pull.user_id === userId && pull.track_id === trackId,
    )[0];
  };

  const dispatch = useDispatch();

  if (!created_at) {
    return null;
  }

  switch (data.type) {
    case 'TrackCommented':
    case 'TrackCommentReplied': {
      const user = users[data.commentator_id];
      const track = tracks[data.track_id];

      if (!user || !track) {
        return null;
      }
      return (
        <TrackLinkInternal trackId={track.id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey={`notifications:entries.${data.type}`}
                  values={{
                    userName: user.display_name,
                    trackTitle: track.title,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImageWithIcon
                color={style.success_new}
                icon={Message}
                track={track}
              />
            </div>
          </Row>
        </TrackLinkInternal>
      );
    }

    case 'UserFollowed': {
      const user = users[data.follower_id];

      if (!user) {
        return null;
      }

      const followUser = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ) => {
        e.preventDefault();

        if (user.logged_in_user_follows_user) {
          return dispatch(followActions.unfollow(user.id));
        }

        return dispatch(followActions.follow(user.id));
      };

      return (
        <ProfileLinkInternal profileId={data.follower_id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="centerandright-adornment">
              <div className="center">
                <div>
                  <Trans
                    i18nKey="notifications:entries.UserFollowed.message"
                    values={{
                      userName: user.display_name,
                    }}
                    components={{
                      strong: <strong />,
                    }}
                  />
                </div>
                <div className="dateAndButtonWrapper">
                  <MessageTime>{created_at}</MessageTime>
                  <div className="right-adornment">
                    {user.logged_in_user_follows_user ? (
                      <Button
                        type="button"
                        onClick={followUser}
                        className="h-50"
                        color="primary"
                        outline
                        size="sm"
                      >
                        <TextMedium12>
                          {
                            t(
                              'notifications:entries.UserFollowed.button',
                            ) as string
                          }
                        </TextMedium12>
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        onClick={followUser}
                        className="h-50"
                        color="primary"
                        outline
                        size="sm"
                      >
                        {
                          t(
                            'notifications:entries.UserFollowed.button',
                          ) as string
                        }
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </ProfileLinkInternal>
      );
    }

    case 'TrackLikedByLabel':
    case 'TrackLiked': {
      const user = users[data.user_id];
      const track = tracks[data.track_id];

      if (!user || !track) {
        return null;
      }

      return (
        <TrackLinkInternal trackId={track.id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.TrackLiked"
                  values={{
                    userName: user.display_name,
                    trackTitle: track.title,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime>{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImageWithIcon
                icon={Heart}
                color={style.primary_pink_new}
                track={track}
              />
            </div>
          </Row>
        </TrackLinkInternal>
      );
    }

    case 'LikedTrackGetPulled': {
      const track = tracks[data.track_id];

      if (!track) {
        return null;
      }

      return (
        <InnerLink href="/my-wallet">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={track?.user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.LikedTrackGetPulled"
                  values={{
                    trackTitle: track.title,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImageWithIcon
                icon={Bookmark}
                track={track}
                color={style.success_new}
              />
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'TrackPlayedByLabel': {
      const track = tracks[data.track_id];
      const label = users[data.label_id];

      if (!track || !label) {
        return null;
      }

      return (
        <InnerLink href="/statistics">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={label?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.TrackPlayedByLabel"
                  values={{
                    trackTitle: track.title,
                    labelName: label.display_name,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={track.track_img} />
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'TrackCollaborationRequest': {
      if (!FEATURE_COLLABORATION_ENABLED) {
        return null;
      }

      return <CollaborationRequest {...data} created_at={created_at} />;
    }

    case 'TrackCollaborationApproved': {
      if (!FEATURE_COLLABORATION_ENABLED) {
        return null;
      }

      const track = tracks[data.track_id];
      const user = users[data.collaborator_id];

      if (!user || !track) {
        return null;
      }

      return (
        <Row data-type={data.type}>
          <div className="left-adornment">
            <UserImage src={user?.profile_photo} />
          </div>
          <div className="center">
            <div>
              <Trans
                i18nKey="notifications:entries.TrackCollaborationApproved"
                values={{
                  userName: user.display_name,
                  trackTitle: track.title,
                }}
                components={{
                  strong: <strong />,
                }}
              />
            </div>
            <MessageTime className="ml-1">{created_at}</MessageTime>
          </div>
        </Row>
      );
    }

    case 'TrackCollaborationDeclined': {
      if (!FEATURE_COLLABORATION_ENABLED) {
        return null;
      }

      const track = tracks[data.track_id];
      const user = users[data.collaborator_id];

      if (!user || !track) {
        return null;
      }

      return (
        <Row data-type={data.type}>
          <div className="left-adornment">
            <UserImage src={user?.profile_photo} />
          </div>
          <div className="center">
            <div>
              <Trans
                i18nKey="notifications:entries.TrackCollaborationDeclined"
                values={{
                  userName: user.display_name,
                  trackTitle: track.title,
                }}
                components={{
                  strong: <strong />,
                }}
              />
            </div>
            <MessageTime className="ml-1">{created_at}</MessageTime>
          </div>
        </Row>
      );
    }

    case 'WonRaffle': {
      return (
        <WonRaffle
          raffleId={data.raffle_id}
          type={data.type}
          createdAt={created_at}
        />
      );
    }

    case 'VcoinsTransaction': {
      return (
        <InnerLink href="/my-wallet">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <img
                src="/static/images/vcoin/vcoin.svg"
                alt="vcoins logo"
                height={25}
                width={25}
              />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey={`notifications:entries.VcoinsTransaction.${
                    data.amount > 0 ? 'earned' : 'spent'
                  }`}
                  values={{ amount: Math.abs(data.amount) }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime>{created_at}</MessageTime>
            </div>
          </Row>
        </InnerLink>
      );
    }

    // NOTE: Received by label
    case 'TrackPullRequestDenied':
    case 'TrackPullRequestAccepted': {
      const track = tracks[data.track_id];
      const user = users[data.producer_id];

      if (!user || !track) {
        return null;
      }

      return (
        <a
          href={process.env.REACT_APP_LABEL_DASHBOARD_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={styles['row-link']}
          data-type={data.type}
        >
          <Row>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey={`notifications:entries.${data.type}`}
                  values={{
                    userName: user.display_name,
                    trackTitle: track.title,
                  }}
                  components={{
                    strong: <strong />,
                    green: <div className="text-success" />,
                    danger: <div className="text-danger" />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
          </Row>
        </a>
      );
    }

    // NOTE: Received by producer
    case 'TrackPullRequestMade': {
      const track = tracks[data.track_id];
      const user = users[data.label_id];
      const pull = getPullForTrack({ trackId: track?.id, userId: user?.id });

      if (!user || !track) {
        return null;
      }

      return (
        <Row data-type={data.type}>
          <InnerLink href="/statistics">
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
          </InnerLink>
          <div className="center mt-1">
            {pull?.status === 'open' && pullStatus === 'open' && (
              <>
                <InnerLink href="/statistics">
                  <div>
                    <Trans
                      i18nKey="notifications:entries.TrackPullRequestMade"
                      values={{
                        userName: user.display_name,
                        trackTitle: track.title,
                      }}
                      components={{
                        strong: <strong />,
                        green: <div className="text-success" />,
                      }}
                    />
                  </div>
                  <MessageTime className="ml-1">{created_at}</MessageTime>
                </InnerLink>
                <div>
                  <div className="d-block">
                    <Button
                      className="mr-1 mt-1 d-inline-flex"
                      color="success"
                      onClick={() => {
                        const pullId = pull.id || -1;
                        if (pullId !== -1) {
                          handlePullAction(pullId, 'accept');
                        }
                      }}
                    >
                      {t('notifications:accept') as string}
                    </Button>
                    <Button
                      className="mt-1 d-inline-flex"
                      color="danger"
                      onClick={() => {
                        const pullId = pull.id || -1;
                        if (pullId !== -1) {
                          handlePullAction(pullId, 'decline');
                        }
                      }}
                    >
                      {t('notifications:decline') as string}
                    </Button>
                  </div>
                </div>
              </>
            )}

            {(pull?.status === 'accepted' || pullStatus === 'accepted') && (
              <>
                <InnerLink href="/statistics">
                  <div>
                    <Trans
                      i18nKey="notifications:entries.TrackPullRequestAccept"
                      values={{
                        userName: user.display_name,
                        trackTitle: track.title,
                      }}
                      components={{
                        strong: <strong />,
                        green: <div className="text-success" />,
                      }}
                    />
                  </div>
                </InnerLink>
                <div>
                  <div
                    className="w-75 font-weight-bold mt-1"
                    style={{ fontSize: '13px' }}
                  >
                    {t('notifications:startChatWith')} {user.display_name}{' '}
                    {t('notifications:nextSteps')}
                  </div>
                  <Button
                    className="d-inline-flex justify-content-center align-items-center"
                    outline
                    color="primary"
                    style={{
                      borderRadius: '5px',
                      width: 'fit-content',
                      padding: '3px 10px',
                    }}
                  >
                    <InnerLink
                      href={`/chat/${user.conversation_id}`}
                      style={{ color: '#f71454', fontSize: '12px' }}
                      className="d-inline-flex justify-content-center align-items-center"
                    >
                      {t('notifications:sendMessageTo')} {user.display_name}
                    </InnerLink>
                  </Button>
                  <MessageTime className="ml-1">{created_at}</MessageTime>
                </div>
              </>
            )}

            {(pull?.status === 'declined' || pullStatus === 'declined') && (
              <>
                <InnerLink href="/statistics">
                  <div>
                    <Trans
                      i18nKey="notifications:entries.TrackPullRequestDecline"
                      values={{
                        userName: user.display_name,
                        trackTitle: track.title,
                      }}
                      components={{
                        strong: <strong />,
                        danger: <div className="text-danger" />,
                      }}
                    />
                  </div>
                  <MessageTime className="ml-1">{created_at}</MessageTime>
                </InnerLink>
                <div>
                  <Button
                    className="mt-1 d-flex"
                    color="danger"
                    disabled
                    style={{ fontSize: '12px', cursor: 'default' }}
                  >
                    {t('notifications:decline') as string}
                  </Button>
                </div>
              </>
            )}
          </div>

          <div className="right-adornment">
            <InnerLink href="/statistics">
              <TrackImageWithIcon
                icon={Bookmark}
                track={track}
                color={style.success_new}
              />
            </InnerLink>
          </div>
        </Row>
      );
    }

    // NOTE: Received by labels
    case 'ListenerInvitationDenied':
    case 'ListenerInvitationAccepted': {
      const user = users[data.listener_id];

      if (!user) {
        return null;
      }

      return (
        <a
          href={process.env.REACT_APP_LABEL_DASHBOARD_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={styles['row-link']}
          data-type={data.type}
        >
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey={`notifications:entries.${data.type}`}
                  values={{
                    userName: user.display_name,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
          </Row>
        </a>
      );
    }

    // TODO - Received by producer
    case 'ListenerInvited': {
      const user = users[data.label_id];

      if (!user) {
        return null;
      }

      return (
        <InnerLink href="/statistics">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.ListenerInvited"
                  values={{
                    userName: user.display_name,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'TrackApproved': {
      const track = tracks[data.track_id];

      if (!track) {
        return null;
      }

      return (
        <TrackLinkInternal trackId={track.id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <Done color={style.success_new} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.TrackApproved"
                  values={{
                    trackTitle: track.title,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={track.track_img} />
            </div>
          </Row>
        </TrackLinkInternal>
      );
    }

    // TODO - Scroll to message
    case 'ReceivedTrackFeedback': {
      const track = tracks[data.track_id];
      const user = users[data.commentator_id];

      if (!user || !track) {
        return null;
      }

      return (
        <ConversationLink conversationId={user.conversation_id as number}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.ReceivedTrackFeedback"
                  values={{
                    userName: user.display_name,
                    trackTitle: track.title,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={track.track_img} />
            </div>
          </Row>
        </ConversationLink>
      );
    }

    // TODO - Scroll to message
    case 'TrackFeedbackRequested': {
      const track = tracks[data.track_id];
      const user = users[data.user_id];

      if (!user || !track) {
        return null;
      }

      return (
        <ConversationLink conversationId={user.conversation_id as number}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.TrackFeedbackRequested"
                  values={{
                    userName: user.display_name,
                    trackTitle: track.title,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime>{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={track.track_img} />
            </div>
          </Row>
        </ConversationLink>
      );
    }

    case 'UserJoinedContest':
    case 'UserJoinedRemixContest': {
      return (
        <UserJoinedContest
          raffleId={data.raffle_id}
          type={data.type}
          createdAt={created_at}
        />
      );
    }

    case 'RaffleEnded':
    case 'ContestEnded':
    case 'RemixContestEnded': {
      return (
        <ContestEnded
          raffleId={data.raffle_id}
          type={data.type}
          createdAt={created_at}
        />
      );
    }

    case 'WonContest':
    case 'WonRemixContest': {
      return (
        <WonContest
          raffleId={data.raffle_id}
          type={data.type}
          createdAt={created_at}
        />
      );
    }

    // TODO - Get the message inside notification payload!
    case 'ConversationMessageReceived': {
      const user = users[data.recipient_id];
      const conversation = conversations[data.conversation_id];

      if (conversation) {
        return null;
      }

      return (
        <ConversationLink conversationId={data.conversation_id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <UserImage src={user?.profile_photo} />
            </div>
            <div className="center">
              <span>{user?.display_name}</span>
            </div>
          </Row>
        </ConversationLink>
      );
    }

    case 'UserAwardEarned': {
      return (
        <InnerLink href="/awards">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <Medal />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.UserAwardEarned"
                  values={{ award: data.award }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime>{created_at}</MessageTime>
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'TrackInCharts': {
      const track = tracks[data.track_id];

      if (!track) {
        return null;
      }

      return (
        <InnerLink href="/awards">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <BarChartAlt2 />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.TrackInCharts"
                  values={{
                    trackTitle: track.title,
                    position: data.position,
                    chart: data.chart,
                  }}
                />
              </div>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'VcoinsOrder': {
      return (
        <InnerLink href="/my-wallet">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <img src="/static/images/vcoin/vcoin.svg" alt="vcoins logo" />
            </div>
            <div className="center">
              <div>
                <Trans
                  i18nKey="notifications:entries.VcoinsOrder"
                  values={{ package: data.package }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
              <MessageTime>{created_at}</MessageTime>
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'InvoicePaymentFailed': {
      return (
        <InnerLink href="/settings">
          <Row data-type={data.type}>
            <div className="left-adornment">
              <ErrorIcon color={style.error_new} size={35} />
            </div>
            <div className="center">
              <Trans i18nKey="notifications:entries.InvoicePaymentFailed" />
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
          </Row>
        </InnerLink>
      );
    }

    case 'ContestEntryPublic': {
      const track = tracks[data.track_id];

      if (!track) {
        return null;
      }

      return (
        <TrackLinkInternal trackId={data.track_id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <Flag color={style.error_new} size={35} />
            </div>
            <div className="center">
              <Trans i18nKey="notifications:entries.ContestEntryPublic" />
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={track.track_img} />
            </div>
          </Row>
        </TrackLinkInternal>
      );
    }

    case 'TrackSelectedForRelease': {
      if (!notificationTrack) {
        return null;
      }

      return (
        <TrackLinkInternal trackId={data.track_id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <TextRegular18>&#127881;</TextRegular18>
            </div>
            <div className="center">
              <TextBold14>
                {t('notifications:')} {notificationTrack.title}{' '}
                {t('notifications:hasBeenSelectedForRelease')}
              </TextBold14>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={notificationTrack.track_img} />
            </div>
          </Row>
        </TrackLinkInternal>
      );
    }

    case 'TrackReleaseReleased': {
      if (!notificationTrack) {
        return null;
      }

      return (
        <TrackLinkInternal trackId={data.track_id}>
          <Row data-type={data.type}>
            <div className="left-adornment">
              <TextRegular18>&#127881;</TextRegular18>
            </div>
            <div className="center">
              <TextBold14>
                {t('notifications:releaseSuccess') as string}{' '}
                <strong>{notificationTrack.title}</strong>{' '}
                {t('notifications:isNowReleased') as string}
              </TextBold14>
              <MessageTime className="ml-1">{created_at}</MessageTime>
            </div>
            <div className="right-adornment">
              <TrackImage src={notificationTrack.track_img} />
            </div>
          </Row>
        </TrackLinkInternal>
      );
    }

    default: {
      if (data.notification_default_text) {
        return (
          <Row data-type={data.type}>
            {data.notification_default_text}
            <MessageTime className="ml-1">{created_at}</MessageTime>
          </Row>
        );
      }

      const impossibleType = (data as unknown) as never;

      logError(
        new Error(
          `Impossible notification type ${
            (impossibleType as { type: string }).type
          }`,
        ),
      );

      return null;
    }
  }
};

export default Index;

export const Row: React.FC<{ extraHigh?: boolean }> = ({
  extraHigh,
  children,
  ...rest
}) => (
  <RowOuter {...rest}>
    <RowInner extraHigh={extraHigh}>{children}</RowInner>
  </RowOuter>
);

const RowOuter = styled.div`
  width: 100%;
  background-color: ${style.white};
  min-height: 45px;
  padding: 0px;
`;

const RowInner = styled.div<{ extraHigh?: boolean }>`
  width: 100%;

  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  transition: 0.2s;
  border-bottom: 1px solid ${style.grey_light_1_new};

  a {
    display: block;
    font-weight: 600;
    color: ${style.black_new};
  }

  strong {
    font-weight: bold;
    color: #1f1f1f;
  }

  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    gap: 4px;

    padding: 8px 10px;

    overflow: hidden;

    a {
      height: 100%;
    }
  }

  .left-adornment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 35px;

    > svg {
      color: ${style.primary_pink_new};
      width: 25px;
      height: 25px;
      // margin: 10px
    }

    ${TrackImage} {
      width: 35px;
      height: 35px;
    }

    ${UserImage} {
      width: 40px;
      height: 40px;
    }
  }

  .right-adornment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-bottom: 10px;

    button {
      padding: 0 10px 0 10px;
    }
  }
  .w-75.font-weight-bold.mt-1 {
    white-space: nowrap;
    width: 320px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .centerandright-adornment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }

  :hover {
    background-color: whitesmoke;
  }

  button {
    height: 25px;
    min-height: 25px;

    span {
      font-size: 12px !important;
    }
  }

  .dateAndButtonWrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5px;
  }

  button.d-inline-flex.justify-content-center.align-items-center.btn.btn-outline-primary {
    margin: 10px 0px 10px 0px;
  }
`;

const CollaborationRequest = ({
  track_id,
  user_id,
  created_at,
}: { created_at: number } & TrackCollaborationRequest) => {
  const { data: user } = useUser(user_id);
  const [track] = useTrack(track_id);
  const { id: currentUserId } = useCurrentUser();
  const dispatch = useDispatch();
  const { t } = useTranslation('notifications');

  if (!user || !track || !currentUserId) {
    return null;
  }

  const { pending_collaborators = [], collaborators } = track;
  const isOpen = pending_collaborators.map((c) => c.id).includes(currentUserId);

  const isAccepted = collaborators.map((c) => c.id).includes(currentUserId);
  const isDeclined = !isOpen && !isAccepted;

  const accept = async () => {
    try {
      await dispatch(trackActions.acceptCollaboration(track.id));
    } catch (err) {
      logError(err);
      ToastError(t('notifications:entries.TrackCollaborationRequest.error'));
    }
  };

  const decline = async () => {
    try {
      await dispatch(trackActions.declineCollaboration(track.id));
    } catch (err) {
      logError(err);
      ToastError(t('notifications:entries.TrackCollaborationRequest.error'));
    }
  };

  return (
    <Row extraHigh>
      <div className="left-adornment">
        <UserImage src={user?.profile_photo} />
      </div>
      <div className="center">
        {isOpen && (
          <div>
            <Trans
              i18nKey="notifications:entries.TrackCollaborationRequest.open"
              values={{ userName: user.display_name, trackTitle: track.title }}
              components={{
                strong: <strong />,
              }}
            />
          </div>
        )}
        {isAccepted && (
          <div>
            <Trans
              i18nKey="notifications:entries.TrackCollaborationRequest.accepted"
              values={{ userName: user.display_name, trackTitle: track.title }}
              components={{
                strong: <strong />,
              }}
            />
          </div>
        )}
        {isDeclined && (
          <div>
            <Trans
              i18nKey="notifications:entries.TrackCollaborationRequest.declined"
              values={{ userName: user.display_name, trackTitle: track.title }}
              components={{
                strong: <strong />,
              }}
            />
          </div>
        )}
        {isOpen && <AcceptDeclineButtons accept={accept} decline={decline} />}
        <MessageTime className="ml-1">{created_at}</MessageTime>
      </div>
    </Row>
  );
};

export const PullRequest = ({
  pullId,
  message,
}: {
  pullId: number;
  message?: string;
}) => {
  const {
    data: trackPullStatus,
    accept: { mutate: acceptPullRequest },
    decline: { mutate: declinePullRequest },
  } = useQueryPullRequest(pullId);
  const [track] = useTrack(trackPullStatus?.track_id);
  const { data: user } = useUser(trackPullStatus?.label_id);

  if (!user || !track || !trackPullStatus) {
    return null;
  }

  const { status, created_at } = trackPullStatus;

  let i18nKey = status as string;

  if (status === 'declined') {
    i18nKey = 'Decline';
  }

  if (status === 'open') {
    i18nKey = 'Made';
  }

  if (status === 'accepted') {
    i18nKey = 'Accept';
  }

  return (
    <Row>
      <div className="left-adornment">
        <TrackImageWithIcon
          color={style.grey_main_new}
          icon={Bookmark}
          track={track}
        />
      </div>
      <div className="center">
        <Link href={`/tracks/${track.id}`}>
          <div style={{ cursor: 'pointer' }}>
            <Trans
              i18nKey={`notifications:entries.TrackPullRequest${i18nKey}`}
              values={{ userName: user.display_name, trackTitle: track.title }}
              components={{
                strong: <strong />,
                green: <div className="text-success" />,
                danger: <div className="text-danger" />,
              }}
            />
          </div>
        </Link>
        {status === 'open' && (
          <>
            {message && <span style={{ fontSize: 12 }}>{message}</span>}
            <AcceptDeclineButtons
              accept={acceptPullRequest}
              decline={declinePullRequest}
            />
          </>
        )}
        <MessageTime className="ml-1">{created_at}</MessageTime>
      </div>
    </Row>
  );
};

const WonRaffle = ({
  raffleId,
  createdAt,
  type,
}: {
  raffleId: number;
  createdAt: number;
  type: NotificationTypes;
}) => {
  const { data: raffle } = useRaffle(raffleId);

  if (!raffle) {
    return null;
  }

  return (
    <RaffleLinkInternal raffle={raffle}>
      <Row data-type={type}>
        <div className="left-adornment">
          <CongratulationsIcon />
        </div>
        <div className="center">
          <div>
            <Trans
              i18nKey="notifications:entries.WonRaffle"
              values={{ raffleTitle: raffle.title }}
              components={{
                strong: <strong />,
              }}
            />
          </div>
          <MessageTime className="ml-1">{createdAt}</MessageTime>
        </div>
      </Row>
    </RaffleLinkInternal>
  );
};

const UserJoinedContest = ({
  raffleId,
  createdAt,
  type,
}: {
  raffleId: number;
  createdAt: number;
  type: NotificationTypes;
}) => {
  const { data: raffle } = useRaffle(raffleId);

  if (!raffle) {
    return null;
  }

  return (
    <RaffleLinkInternal raffle={raffle}>
      <Row data-type={type}>
        <div className="left-adornment">
          <CheckCircle color={style.primary_pink_new} />
        </div>
        <div className="center">
          <div>
            <Trans
              i18nKey={`notifications:entries.${type}`}
              values={{
                // for remove hidden ascii characters
                raffleTitle: raffle?.title
                  ? raffle?.title.replace(/[^\x00-\x7F]/g, '')
                  : '',
              }}
              components={{
                strong: <strong />,
                green: <div className="text-success" />,
                danger: <div className="text-danger" />,
              }}
            />
          </div>
          <MessageTime>{createdAt}</MessageTime>
        </div>
      </Row>
    </RaffleLinkInternal>
  );
};

const ContestEnded = ({
  raffleId,
  createdAt,
  type,
}: {
  raffleId: number;
  createdAt: number;
  type: NotificationTypes;
}) => {
  const { data: raffle } = useRaffle(raffleId);

  if (!raffle) {
    return null;
  }

  return (
    <RaffleLinkInternal raffle={raffle}>
      <Row data-type={type}>
        <div className="left-adornment">
          <Flag />
        </div>
        <div className="center">
          <div>
            <Trans
              i18nKey={`notifications:entries.${type}`}
              values={{
                raffleTitle: raffle.title,
                raffleUserName: raffle.user.display_name,
              }}
              components={{
                strong: <strong />,
                green: <div className="text-success" />,
                danger: <div className="text-danger" />,
              }}
            />
          </div>
          <MessageTime className="ml-1">{createdAt}</MessageTime>
        </div>
      </Row>
    </RaffleLinkInternal>
  );
};

const WonContest = ({
  raffleId,
  createdAt,
  type,
}: {
  raffleId: number;
  createdAt: number;
  type: NotificationTypes;
}) => {
  const { data: raffle } = useRaffle(raffleId);

  if (!raffle) {
    return null;
  }

  return (
    <RaffleLinkInternal raffle={raffle}>
      <Row data-type={type}>
        <div className="left-adornment">
          <CongratulationsIcon />
        </div>
        <div className="center">
          <div>
            <Trans
              i18nKey={`notifications:entries.${type}`}
              values={{ raffleTitle: raffle.title }}
              components={{
                strong: <strong />,
                green: <div className="text-success" />,
                danger: <div className="text-danger" />,
              }}
            />
          </div>
          <MessageTime className="ml-1">{createdAt}</MessageTime>
        </div>
      </Row>
    </RaffleLinkInternal>
  );
};
