import { createAction } from '@reduxjs/toolkit';
import {
  ConversationInfo,
  Conversation,
  Reply,
  SimpleChatUser,
} from '../../lib/api/chat';
import { Pagination } from '../../lib/api/Pagination';

export const addConversationsInfo = createAction<
  ConversationInfo | ConversationInfo[]
>('CHAT_ADD_CONVERSATIONS');

export const requestConversations = createAction(
  'REQUEST_ALL_MY_CONVERSATIONS',
);
export const receiveConversations = createAction<{
  conversations: Conversation[];
  pagination: Pagination;
  filter?: string;
}>('RECEIVE_ALL_MY_CONVERSATIONS');
export const errorConversations = createAction<any>(
  'ERROR_ALL_MY_CONVERSATIONS',
);

export const requestCreateConversations = createAction(
  'REQUEST_CREATE_CONVERSATIONS',
);
export const recieveCreateConversations = createAction<
  Conversation | ConversationInfo | ConversationInfo[]
>('RECEIVE_CREATE_CONVERSATIONS');
export const errorCreateConversations = createAction<string>(
  'ERROR_CREATE_CONVERSATIONS',
);

export const requestShowConversation = createAction(
  'REQUEST_SHOW_CONVERSATION',
);
export const recieveShowConversation = createAction<
  ConversationInfo | ConversationInfo[] | Conversation | Conversation[]
>('RECEIVE_SHOW_CONVERSATIONS');
export const errorShowConversation = createAction<string>(
  'ERROR_SHOW_CONVERSATION',
);
export const errorConversationDeleted = createAction<number>(
  'ERROR_CONVERSATION_DELETED',
);

export const requestCreateConversationsReply = createAction(
  'REQUEST_CREATE_CONVERSATIONS_REPLY',
);
export const recieveCreateConversationsReply = createAction<{
  reply: Reply | Reply[];
  mid?: number;
}>('RECEIVE_CREATE_CONVERSATIONS_REPLY');
export const errorCreateConversationsReply = createAction<string>(
  'ERROR_CREATE_CONVERSATIONS_REPLY',
);

export const requestDeleteConversationsReply = createAction(
  'REQUEST_REMOVE_CONVERSATIONS_REPLY',
);
export const recieveDeleteConversationsReply = createAction<{
  id: number;
  reply: number;
}>('RECEIVE_REMOVE_CONVERSATIONS_REPLY');
export const errorDeleteConversationsReply = createAction<string>(
  'ERROR_REMOVE_CONVERSATIONS_REPLY',
);

export const requestSearchConversations = createAction(
  'REQUEST_SEARCH_CONVERSATIONS',
);
export const recieveSearchConversations = createAction<
  ConversationInfo | ConversationInfo[]
>('RECEIVE_SEARCH_CONVERSATIONS');
export const errorSearchConversations = createAction<string>(
  'ERROR_SEARCH_CONVERSATIONS',
);

export const requestDeleteConversations = createAction(
  'REQUEST_DELETE_CONVERSATIONS',
);
export const recieveDeleteConversations = createAction<number>(
  'RECEIVE_DELETE_CONVERSATIONS',
);
export const errorDeleteConversations = createAction<string>(
  'ERROR_DELETE_CONVERSATIONS',
);

export const requestShowConversationReplies = createAction(
  'REQUEST_SHOW_CONVERSATION_REPLIES',
);

export const resetPagination = createAction('RESET_PAGINATION_FOR_REPLIES');

export const recieveShowConversationReplies = createAction<{
  id: number;
  replies: Reply[];
  pagination: Pagination;
}>('RECEIVE_SHOW_CONVERSATIONS_REPLIES');

export const errorShowConversationReplies = createAction<string>(
  'ERROR_SHOW_CONVERSATION_REPLIES',
);

export const addReplyEntries = createAction<Reply[]>('ADD_REPLY_ENTRIES');

export const clearSearchResult = createAction('CLEAR_SEARCH_RESULT');

export const requestFindUserByName = createAction('REQUEST_FIND_USER_BY_NAME');

export const errorFindUserByName = createAction<string>(
  'ERROR_FIND_USER_BY_NAME',
);

export const receiveUserSearchResult = createAction<{
  users: SimpleChatUser[];
  pagination: Pagination;
}>('RECEIVE_FIND_USER_BY_NAME');

export const clearUserSearch = createAction('CLEAR_USERS_SEARCH');

export const requestUnreadMessagesNumber = createAction(
  'REQUEST_UNREAD_MESSAGES_NUMBER',
);
export const recieveUnreadMessagesNumber = createAction<number>(
  'RECEIVE_UNREAD_MESSAGES_NUMBER',
);
export const errorUnreadMessagesNumber = createAction<string>(
  'ERROR_UNREAD_MESSAGES_NUMBER',
);

export const setAutoSelectedUserId = createAction<number>(
  'SET_AUTO_USER_SEARCH',
);

export const requestFetch = createAction<number[]>('CHATS_REQUEST_FETCH');
export const receiveFetch = createAction<number[]>('CHATS_RECEIVE_FETCH');
export const add = createAction<Conversation | Conversation[]>(
  'CHATS_ADD_ENTRIES',
);

export const incrementMessagesCounter = createAction<number | undefined>(
  'INCREMENT_MESSAGES_COUNTER',
);

export const conversationRead = createAction<number>('CONVERSATION_READ');

export const feedbackSent = createAction<{
  conversationId: number;
  trackId: number;
  reply: Reply;
}>('CHAT_FEEDBACK_SENT');

export const modalStateType = createAction<boolean>('CHAT_MODAL_STATE');
