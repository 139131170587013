import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import Loader from '../../components/common/Loader';
import { BorderButton } from '../../components/common/Buttons';

interface Props {
  onClick: VoidFunction;
  isFetching?: boolean;
}

function LoadMoreButton({ isFetching, onClick }: Props) {
  const { t } = useTranslation('common');
  if (isFetching) return <Loader />;

  return (
    <BorderButton onClick={onClick} style={{ marginBottom: '40px' }}>
      {t('common:loadMore')}
    </BorderButton>
  );
}

export default React.memo(LoadMoreButton);
