import React from 'react';
import NextLink, { LinkProps } from 'next/link';
import styled, { css } from 'styled-components';
import Loader from './Loader';

export const Link = ({
  style,
  className,
  children,
  linkAs,
  onClick,
  ...props
}: {
  style?: React.CSSProperties;
  className?: string;
  linkAs?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
} & LinkProps) => (
  <NextLink legacyBehavior {...props} as={linkAs}>
    <a onClick={onClick} className={className} style={style}>
      {children}
    </a>
  </NextLink>
);

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenterLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);

const flexCol = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomContainer = styled.div`
  ${flexCol}
  padding: 0 20px;
  margin: 0 auto;

  & > form {
    ${flexCol}
    width: 100%;

    & > input,
    > button {
      margin-bottom: 20px;
    }
  }
`;

export const ErrorLabel = styled.div`
  color: red;
`;
