import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LabelWidgetSettings } from '../../lib/api/widget';

export interface State {
  isFetchingLabelSettings?: boolean;
  isFetchingTrackLabel?: boolean;
  isUploadingTrackLabel?: boolean;
  labelSettings?: LabelWidgetSettings;
  errorLabelSettings?: string;
  errorTrackLabelWidget?: string;
  errorUploadingTrackLabel?: string;
}

export const defaultState: State = {
  isFetchingLabelSettings: false,
  isFetchingTrackLabel: false,
  labelSettings: undefined,
  errorLabelSettings: undefined,
};

export default createSlice({
  name: 'LABEL-WIDGET',
  initialState: defaultState,
  reducers: {
    // Widget Settings
    requestLabelWidgetSettings: (state) => {
      state.errorLabelSettings = undefined;
      state.isFetchingLabelSettings = true;
    },
    receiveLabelWidgetSettings: (
      state,
      action: PayloadAction<LabelWidgetSettings>,
    ) => {
      state.isFetchingLabelSettings = false;
      state.labelSettings = action.payload;
    },
    errorLabelWidgetSettings: (state, action: PayloadAction<string>) => {
      state.isFetchingLabelSettings = false;
      state.errorLabelSettings = action.payload;
    },
    // Track Fetching
    requestTrackLabelWidget: (state) => {
      state.isFetchingTrackLabel = true;
      state.errorTrackLabelWidget = undefined;
    },
    receiveTrackLabelWidget: (state) => {
      state.isFetchingTrackLabel = false;
    },
    errorTrackLabelWidget: (state, action: PayloadAction<string>) => {
      state.isFetchingTrackLabel = false;
      state.errorTrackLabelWidget = action.payload;
    },
    // Upload
    requestUploadTrackLabelWidget: (state) => {
      state.isUploadingTrackLabel = true;
      state.errorUploadingTrackLabel = undefined;
    },
    receiveUploadTrackLabelWidget: (state) => {
      state.isUploadingTrackLabel = false;
    },
    errorUploadTrackLabelWidget: (state, action: PayloadAction<string>) => {
      state.isUploadingTrackLabel = false;
      state.errorUploadingTrackLabel = action.payload;
    },
  },
});
