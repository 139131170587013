import React from 'react';
import { Button } from 'reactstrap';

export const VuexyButton = ({ text, children, ...props }: VuexyButtonProps) => (
  <Button style={{ borderRadius: '5px' }} {...props}>
    {text} {children}
  </Button>
);

interface VuexyButtonProps {
  href?: string;
  text?: string;
  size?: string;
  active?: boolean;
  'aria-label'?: string;
  block?: boolean;
  color?: string; // default: 'secondary'
  disabled?: boolean;
  outline?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  target?: string;
  type?: string;
}

export default VuexyButton;
