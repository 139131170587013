import axios from './axios';
import ResponseWrapper from './ResponseWrapper';

export interface Banner {
  name: string;
  url: string;
  banner: string;
  mobile_banner: string;
}

export const fetch = () =>
  axios.get<ResponseWrapper<Banner>>('/banners').then((res) => res.data.data);
