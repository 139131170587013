// @ts-nocheck
import { AxiosError } from 'axios';
import ResponseWrapper, { ErrorResponse } from '../../lib/api/ResponseWrapper';
import { errorAction, setCurrentLastError } from '../reducers/alerts';
import { ThunkResult } from '../../index';

export const handleError = (
  error: AxiosError<ResponseWrapper<ErrorResponse>>,
): ThunkResult => (dispatch) => {
  const isResponse = Boolean(error.response);
  let { message } = error;

  if (isResponse) {
    console.log('data:');
    console.warn(error.response?.data);
    message = handleAxiosError(error);
  }

  dispatch(errorAction(message));
  dispatch(setCurrentLastError(message));
};

export const handleAxiosError = (
  error: AxiosError<ResponseWrapper<ErrorResponse>>,
  showErrorKey = true,
): string => {
  const { response } = error;

  const message =
    response?.data.status ||
    response?.data.message ||
    response?.data ||
    error.message;

  if (!response) return message.toString();

  const isInvalidInput =
    response.data.message === 'User input invalid' ||
    response.data.message === 'Input invalid';

  if (isInvalidInput) {
    const errors = response.data?.data;

    if (errors) {
      let beMessage = '';

      // map all errors into one string, separated by new line
      Object.keys(errors).forEach((key) => {
        // @ts-ignore
        beMessage += `${showErrorKey ? `${key}: ` : ''}${errors[key][0]}\n`;
      });

      return beMessage;
    }
  }

  return message.toString();
};

export const getErrorResponseMessage = (error: AxiosError<ErrorResponse>) =>
  error.response?.data.message;
