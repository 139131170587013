import { useQuery } from 'react-query';
import * as releasesAPI from '../../lib/api/releases';

const identifier = 'releases';

export const useQueryReleases = (filter?: releasesAPI.ReleaseFilter) =>
  useQuery([identifier, filter], () => releasesAPI.get(filter));

export const useQueryReleasesStories = () =>
  useQuery(
    [identifier, 'success-stories'],
    releasesAPI.getReleaseSuccessStories,
  );

export const useQueryRelease = (releaseId?: number) =>
  useQuery([identifier, releaseId], () =>
    releaseId ? releasesAPI.getById(releaseId) : undefined,
  );
