import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useDispatch } from 'react-redux';

import * as userAPI from '../../lib/api/user';
import * as tracksAPI from '../../lib/api/tracks';
import { API } from '../../index';
import { userActions } from '../reducers/user';
import { getEntries, getNextPageParam } from '../../utils/pagination';
import { useSocialInfo } from './users';
import { useAuthentication } from './authentication';
import usePlanRulesEnum from '../../../hooks/usePlanRulesEnum';

const identifier = 'current-user';

export const useCurrentUser = (checkPaymentStatus?: boolean) => {
  const { isLoggedIn } = useAuthentication();
  const key = [identifier, 'fetch'];
  const {
    data = {} as Partial<userAPI.User>,
    isLoading: requesting,
    refetch,
  } = useQuery(
    [key, checkPaymentStatus],
    () => userAPI.getCurrent(undefined, checkPaymentStatus),
    {
      enabled: isLoggedIn,
      staleTime: 2000,
    },
  );

  const { id } = data;

  const queryClient = useQueryClient();
  const update = useMutation(
    (userData: Partial<userAPI.UpdateUserFormData>) =>
      id ? userAPI.updateUser(id, userData) : Promise.resolve(undefined),
    {
      onSuccess: (d) => {
        const currentData =
          queryClient.getQueryData<userAPI.User | undefined>(key) || {};
        queryClient.setQueryData(key, { ...currentData, ...d });
      },
    },
  );

  return { ...data, requesting, refetch, update };
};

export const useIsCurrentUser = (userId?: number) => {
  const currentUser = useCurrentUser();
  return currentUser?.id === userId;
};

export const useCurrentUserTracks = (
  filter: Omit<tracksAPI.Filter, 'page'> = {},
) => {
  const { isLoggedIn } = useAuthentication();
  const { data, ...res } = useInfiniteQuery(
    [identifier, 'my-tracks', filter],
    ({ pageParam: page }) => tracksAPI.getMyTracks({ ...filter, page }),
    { getNextPageParam, enabled: isLoggedIn },
  );
  return {
    data: getEntries(data),
    total: data?.pages[0].pagination.total,
    ...res,
  };
};

export const useIsLoggedIn = () => {
  const { id } = useCurrentUser();
  return id !== undefined;
};

export const useInvoices = () =>
  useQuery([identifier, 'invoices'], userAPI.getInvoices);

export const useIsProMember = () => {
  const { member } = useCurrentUser();
  return !!member;
};

export const useIsGrowthMember = () => {
  const { member } = useCurrentUser();
  return !!member;
};

export const useReportProfileShare = () => {
  const dispatch = useDispatch();

  const { mutate } = useMutation(API.user.reportProfileShare, {
    onSuccess: (res) => {
      dispatch(userActions.profileShared(res.profile_completion));
    },
  });

  return { mutate };
};

export const useUserCanUploadTrack = () => {
  const { UPLOAD_LIMIT_DEMOS } = usePlanRulesEnum();
  const user = useCurrentUser();

  return user.abilities?.rules.includes(UPLOAD_LIMIT_DEMOS);
};

export const useIsProfileIncomplete = () => {
  const user = useCurrentUser();

  let incomplete = false;

  if (user.profile_completion?.overall_percentage) {
    incomplete = user.profile_completion.overall_percentage < 100;
  }

  return incomplete;
};

export const useCurrentUserWaitingBufferPeriod = () => {
  const { buffer_days } = useCurrentUser();
  const isPro = useIsProMember();
  const isGrowth = useIsGrowthMember();

  return !!(!isPro && isGrowth && buffer_days && buffer_days > 0);
};

export const useUserProfileCompleted = () => {
  const { profile_completion } = useCurrentUser();

  const complete =
    !!profile_completion && profile_completion.overall_percentage === 100;

  return {
    ...profile_completion,
    complete,
  };
};

export const useProducerRoles = () =>
  useQuery([identifier, 'roles'], API.user.getProducerRoles);

export const useQueryAddSocialInfo = () => {
  const { id } = useCurrentUser();
  const { refetch: refetchSocialInfo } = useSocialInfo(id);
  return useMutation(userAPI.addSocialInfo, {
    onSuccess: () => {
      refetchSocialInfo();
    },
  });
};

/* ------------------------ hook for Check , is user is Pro (growth or ultra) member or Not ----------------------- */
export const useCheckUserProPlanMember = () => {
  const { abilities } = useCurrentUser();
  return abilities?.plan !== 'amateur';
};

export const useIsUserEligibleForViewStatistics = () => {
  const { abilities } = useCurrentUser();
  return abilities?.plan === 'ultra' || abilities?.plan === 'premium';
};

export const useCheckIsQueryInRoute = (
  queryName: string,
): { isRouteQuery: boolean; queryData: string | undefined } => {
  const { query: routerQuery } = useRouter();
  const [queryState, setQuertState] = useState({
    isRouteQuery: false,
    queryData: '',
  });

  useEffect(() => {
    if (routerQuery[queryName]) {
      setQuertState((prev) => ({
        ...prev,
        isRouteQuery: true,
        queryData: routerQuery[queryName]?.toString() ?? '',
      }));
    } else {
      setQuertState((prev) => ({
        ...prev,
        isRouteQuery: false,
        queryData: '',
      }));
    }
  }, [routerQuery]);

  return queryState;
};
