import * as labelWidgetAPI from '../../lib/api/widget';
import { ThunkResult } from './ThunkDispatch';

import tracksReducer from '../reducers/tracks';

import labelWidgetReducer from '../reducers/label-widget';
import { Track } from '../../lib/api/tracks';
import { handleAxiosError, handleError } from './alerts';
import { UploadLabelWidgetTrack } from '../../lib/api/widget';

const labelWidgetActions = labelWidgetReducer.actions;
const trackActions = tracksReducer.actions;

export const fetchLabelWidgetTrack = (
  widget_hash: string,
): ThunkResult => async (dispatch) => {
  dispatch(labelWidgetActions.requestTrackLabelWidget());
  try {
    const track = await labelWidgetAPI.getTrackLabelWidget(widget_hash);
    await dispatch(tracksReducer.actions.addTrackEntries(track as Track));
    await dispatch(labelWidgetActions.receiveTrackLabelWidget());
  } catch (error: any) {
    dispatch(handleError(error));
    dispatch(labelWidgetActions.errorTrackLabelWidget(handleAxiosError(error)));
  }
};

export const updateLabelWidgetTrack = (
  widget_hash: string,
  data: Partial<labelWidgetAPI.UpdateLabelWidgetTrack>,
): ThunkResult<Promise<labelWidgetAPI.LabelWidgetTrack | undefined>> => async (
  dispatch,
) => {
  dispatch(labelWidgetActions.requestUploadTrackLabelWidget());
  try {
    const track = await labelWidgetAPI.updateTrackLabelWidget(
      widget_hash,
      data,
    );
    dispatch(tracksReducer.actions.addTrackEntries(track as Track));
    dispatch(labelWidgetActions.receiveTrackLabelWidget());
    return track;
  } catch (error: any) {
    dispatch(handleError(error));
    dispatch(labelWidgetActions.errorTrackLabelWidget(handleAxiosError(error)));
    return undefined;
  }
};

export const uploadLabelWidgetTrack = ({
  api_key,
  track,
  data,
  track_url,
  onUploadProgress,
  contests,
}: UploadLabelWidgetTrack): ThunkResult<
  Promise<labelWidgetAPI.LabelWidgetTrack | undefined>
> => async (dispatch) => {
  dispatch(labelWidgetActions.requestUploadTrackLabelWidget());
  try {
    const uploadedTrack = await labelWidgetAPI.uploadTrackLabelWidget({
      api_key,
      track,
      track_url,
      data,
      onUploadProgress,
      contests,
    });

    await dispatch(trackActions.addTrackEntries(uploadedTrack as Track));
    await dispatch(labelWidgetActions.receiveUploadTrackLabelWidget());
    return uploadedTrack;
  } catch (error: any) {
    dispatch(handleError(error));
    dispatch(
      labelWidgetActions.errorUploadTrackLabelWidget(
        handleAxiosError(error, false),
      ),
    );
  }
  return undefined;
};
