/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Fragment, useMemo } from 'react';
import Pusher from 'pusher-js';
import Script from 'next/script';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { NextPageContext } from 'next/types';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/scss/react/libs/tables/react-dataTable-component.scss';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import appWithI18n from 'next-translate/appWithI18n';
// import withFBQ from 'next-fbq';
import { useStore } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DehydratedState, Hydrate } from 'react-query/hydration';
import { usePusher } from '../api/redux/pusher';
import {
  subscribeToTokenEvents,
  style as colors,
  logError,
  RootState,
} from '../api';
import i18nConfig from '../i18n';
// ! This is imported from the build folder for a reason!
// import { setAxiosBaseUrl as setAxiosBaseUrlBuild } from '../api/build/lib/api/axios';
import { setAxiosBaseUrl } from '../api/lib/api/axios';
// ! It wont get set properly otherwise

import {
  fontSizes,
  space,
  breakpoints,
  globalGrid,
  muiTheme,
} from '../style/constants';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

// necessary due to https://github.com/zeit/next-plugins/issues/282
import '../utils/empty.css';

import './connect/react-tabs/style/react-tabs.css';

import {
  getCookieToken,
  updateCookieToken,
  removeCookieToken,
  useCheckInitialLogin,
} from '../utils/auth';
import { wrapper, AppStore } from '../redux-tools';
import GlobalStyle from '../style/GlobalStyle';
import '../style/scss/core.scss';
import Layout from '../Layout';
import { initGA, GARouteTrackerRoute } from '../utils/googleAnalytics';

import FooterPlayer from '../components/Player/FooterPlayer';
import {
  SharedPlayerProvider,
  PlayerManagerProvider,
} from '../components/Player/PlayerContext';
import ToastContainer from '../components/Toast';
import { AffiliateProvider } from '../utils/affiliate';
import { StyleProvider } from '../hooks/style';

import ErrorBoundary from '../components/ErrorBoundary';
import AccountStatusGate from '../components/settings/AccountStatusGate';
import isClientside from '../utils/isClientside';
import LabelUploadWidget from '../components/Widgets/LabelUploadWidget';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../utils/react-datepicker.css';
import isProduction from '../utils/isProduction';
import GoogleLoginPopupProvider from '../components/GoogleLoginPopupProvider';
import useGreyBackground from '../hooks/useGreyBackground';
import AutoLoginSendDataLayerProvider from '../components/AutoLoginSendDataLayerProvider';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.CIRCLE_SHA1,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  ignoreErrors: ["t is not a function. (In 't()', 't' is undefined)"],
});

// ! This is nasty, we should look into fixing it
// ! Seems like widget requires the library version to be set, while the app requires the build version to be set
// ! Most likely, somehow the widget resolves to the non compiled instance of axios
setAxiosBaseUrl(process.env.REACT_APP_API_URL);
// setAxiosBaseUrlBuild(process.env.REACT_APP_API_URL);
// ! End of nastyness

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      // refetchOnMount: false,
      onError: (error: any) => {
        logError(error);
      },
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
});

// Since you'll be passing more stuff to Page
// declare module 'next/dist/server/lib/utils' {
export interface VirpPageContext extends NextPageContext {
  // @ts-ignore
  store: AppStore;
  token?: string;
}
// }

/**
 *
 * Extracting all styles providers
 */
export const ThemeContainer: React.FC<{
  layout?: 'widget' | 'none';
  children?: any;
}> = ({ layout, children }) => {
  /**
   * TODO: Resolve this in a more cleaner manner
   *
   * Quick fix to not include various modals rendered within
   * StyleProvider which should not be present on `widget`
   */
  const Wrapper = layout === 'widget' ? Fragment : StyleProvider;
  const greyBackground = useGreyBackground();

  return (
    <Wrapper>
      <GlobalStyle greyBackground={greyBackground} />
      <BaseCSS />
      <GridThemeProvider gridTheme={globalGrid}>
        <ThemeProvider
          theme={{
            breakpoints,
            fontSizes,
            space,
            colors: {
              ...colors,
              genreIdColorTable: null,
            },
          }}
        >
          <MUIThemeProvider theme={muiTheme}>{children}</MUIThemeProvider>
        </ThemeProvider>
      </GridThemeProvider>
    </Wrapper>
  );
};

const PusherProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  usePusher(
    isClientside,
    (config) => new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, config),
  );
  return <>{children}</>;
};

const CommonProviders: React.FC<{
  layout: LayoutProviderProps['layout'];
  children: React.ReactNode;
}> = ({ layout, children }) => {
  const AffiliateOrNot =
    layout !== 'widget' ? AffiliateProvider : React.Fragment;
  return (
    <PlayerManagerProvider>
      <AffiliateOrNot>
        <GoogleLoginPopupProvider>
          <AutoLoginSendDataLayerProvider>
            <AccountStatusGate>
              <PusherProvider>
                <>{children}</>
              </PusherProvider>
            </AccountStatusGate>
          </AutoLoginSendDataLayerProvider>
        </GoogleLoginPopupProvider>
      </AffiliateOrNot>
    </PlayerManagerProvider>
  );
};

type LayoutProviderProps = {
  layout?: 'widget' | 'none';
  children: React.ReactNode;
};

export const LayoutProvider: React.FC<LayoutProviderProps> = ({
  children,
  layout,
}) => {
  const store = useStore<RootState>();
  const router = useRouter();
  /**
   * Simulates componentWillMount lifecycle
   */
  useMemo(() => {
    if (typeof window) {
      subscribeToTokenEvents(
        store,
        getCookieToken,
        updateCookieToken,
        removeCookieToken,
        layout === 'widget'
          ? () => {
              router.push('/widgets/login');
            }
          : undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (layout === 'none') {
    return <>{children}</>;
  }

  if (layout === 'widget') {
    return <LabelUploadWidget>{children}</LabelUploadWidget>;
  }

  return (
    <SharedPlayerProvider>
      <Layout>
        <ErrorBoundary>
          {initGA() && <GARouteTrackerRoute />}
          {children}
          <FooterPlayer />
        </ErrorBoundary>
      </Layout>
    </SharedPlayerProvider>
  );
};

type PageProps = {
  layout?: 'widget' | 'none';
  dehydratedState?: DehydratedState;
  initialProps?: {
    dehydratedState?: DehydratedState;
    layout?: 'widget' | 'none';
  };
};

const App = (props: AppProps) => {
  const { Component, pageProps } = props;

  const {
    layout: pagePropsLayout,
    initialProps = {},

    dehydratedState,
    ...rest
  } = pageProps as PageProps;

  const {
    layout = pagePropsLayout,
    dehydratedState: dehydratedStateInitial,
    ...initialPropsRest
  } = initialProps;

  useCheckInitialLogin();

  const nextDehydratedState = dehydratedState || dehydratedStateInitial;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0,user-scalable=yes"
        />
        <Script src="https://polyfill.io/v3/polyfill.min.js?features=ResizeObserver" />
        <Script
          dangerouslySetInnerHTML={{
            __html: `(function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5BT2MW3')`,
          }}
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <noscript>
          <iframe
            title="Google Analytics noscript"
            src="https://www.googletagmanager.com/ns.html?id=GTM-5BT2MW3"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        <ReactQueryDevtools />
        <PayPalScriptProvider
          deferLoading
          options={{
            // @ts-ignore
            'client-id': isProduction
              ? process.env.PAYPAL_CLIENT_ID_PROD
              : process.env.PAYPAL_CLIENT_ID,
            currency: 'EUR',
          }}
        >
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
          >
            <Hydrate state={nextDehydratedState}>
              <CommonProviders layout={layout}>
                <ThemeContainer layout={layout}>
                  <LayoutProvider layout={layout}>
                    <Component {...rest} {...(initialPropsRest ?? {})} />
                  </LayoutProvider>
                </ThemeContainer>
              </CommonProviders>
            </Hydrate>
          </GoogleOAuthProvider>
        </PayPalScriptProvider>
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
};

const AppWithRedux = appWithI18n(App as any, {
  ...i18nConfig,
  skipInitialProps: true,
});

export default wrapper.withRedux(AppWithRedux);
