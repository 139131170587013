import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Comment } from '../../lib/api/comments';
import { Pagination } from '../../lib/api/Pagination';
import PaginatedEntries from '../PaginatedEntries';
import deleteItemFromObj from '../../lib/utils';

export interface State {
  comments: Record<number, Comment>;
  tracksComments: Record<number, PaginatedEntries>;
  feedbackComments: Record<number, Comment[]>;
  commentsRepliesIds: Record<number, number[]>;
  isFetchingComments: boolean;
  isFetchingById: boolean;
  isAddingComment: boolean;
  loadingCommentLikeId?: number;
  loadingCommentRemoveLikeId?: number;
  isAddingCommentReply: boolean;
  errorFetchComments?: string;
  errorAddComment?: string;
  errorAddCommentReply?: string;
  errorLikeComment?: string;
  errorRemoveLikeComment?: string;
  errorFetchById?: string;
}

export const initialState: State = {
  comments: {},
  tracksComments: {},
  feedbackComments: {},
  commentsRepliesIds: {},
  isFetchingComments: false,
  isAddingComment: false,
  isAddingCommentReply: false,
  isFetchingById: false,
};

export default createSlice({
  name: 'COMMENTS',
  initialState,
  reducers: {
    addCommentEntries: (state, action: PayloadAction<Comment[]>) => {
      const comments = action.payload;

      // put all comments and all their replies into state.comments
      comments.forEach((comment) => {
        state.comments[comment.id] = comment;

        // add all replies to state.comments
        comment.replies.forEach((reply) => {
          state.comments[reply.id] = reply;
        });

        // add all replies ids to state.commentsRepliesIds
        state.commentsRepliesIds[comment.id] = comment.replies.map(
          (reply) => reply.id,
        );
      });
    },

    requestTrackComments: (state) => {
      state.errorFetchComments = undefined;
      state.isFetchingComments = true;
    },
    // TODO: RECHECK
    receiveTrackComments: (
      state,
      action: PayloadAction<{
        comments: Comment[];
        trackId: number;
        pagination: Pagination;
      }>,
    ) => {
      const { trackId, comments, pagination } = action.payload;

      if (state.tracksComments[trackId] === undefined) {
        state.tracksComments[trackId] = { data: {} };
      }

      state.tracksComments[trackId].data[pagination.currentPage] = comments.map(
        (comment) => comment.id,
      );
      state.tracksComments[trackId].pagination = pagination;
      state.isFetchingComments = false;
    },

    receiveFeedback: (
      state,
      action: PayloadAction<{
        feedbackComments: Comment[];
        trackId: number;
      }>,
    ) => {
      const { trackId, feedbackComments } = action.payload;

      state.feedbackComments[trackId] = feedbackComments;
    },

    errorTrackComments: (state, action: PayloadAction<string>) => {
      state.errorFetchComments = action.payload;
      state.isFetchingComments = false;
    },

    addTrackCommentRequest: (state) => {
      state.errorAddComment = undefined;
      state.isAddingComment = true;
    },
    // TODO: RECHECK
    addTrackCommentSuccess: (
      state,
      action: PayloadAction<{ comment: Comment; trackId: number }>,
    ) => {
      const { trackId, comment } = action.payload;

      if (!state.tracksComments[trackId]) {
        state.tracksComments[trackId] = {
          data: {
            1: [comment.id],
          },
        };
      } else {
        state.tracksComments[trackId].data[1].unshift(comment.id);
      }

      state.isAddingComment = false;
    },
    errorAddTrackComment: (state, action: PayloadAction<string>) => {
      state.errorAddComment = action.payload;
      state.isAddingComment = false;
    },

    addCommentReplyRequest: (state) => {
      state.errorAddCommentReply = undefined;
      state.isAddingCommentReply = true;
    },
    errorAddCommentReply: (state, action: PayloadAction<string>) => {
      state.errorAddCommentReply = action.payload;
      state.isAddingCommentReply = false;
    },

    requestCommentLike: (state, action: PayloadAction<number>) => {
      state.loadingCommentLikeId = action.payload;
      state.errorLikeComment = undefined;
    },
    successCommentLike: (state, action: PayloadAction<number>) => {
      const commentId = action.payload;
      state.comments[commentId].user_liked_comment = true;
      state.comments[commentId].like_count += 1;

      state.loadingCommentLikeId = undefined;
    },
    errorCommentLike: (state, action: PayloadAction<string>) => {
      state.errorLikeComment = action.payload;
      state.loadingCommentLikeId = undefined;
    },

    requestCommentRemoveLike: (state, action: PayloadAction<number>) => {
      state.loadingCommentRemoveLikeId = action.payload;
      state.errorRemoveLikeComment = undefined;
    },
    successCommentRemoveLike: (state, action: PayloadAction<number>) => {
      const commentId = action.payload;
      state.comments[commentId].user_liked_comment = false;
      state.comments[commentId].like_count -= 1;

      state.loadingCommentRemoveLikeId = undefined;
    },
    errorCommentRemoveLike: (state, action: PayloadAction<string>) => {
      state.errorRemoveLikeComment = action.payload;
      state.loadingCommentRemoveLikeId = undefined;
    },

    // TODO: RECHECK
    successCommentDelete: (
      state,
      action: PayloadAction<{
        commentId: number;
        parentCommentId?: number;
        trackId?: number;
      }>,
    ) => {
      const { commentId, parentCommentId, trackId } = action.payload;
      let comments: number[] = [];

      // we're dealing with reply
      if (parentCommentId) {
        comments = state.commentsRepliesIds[parentCommentId];
        const index = comments.indexOf(commentId);
        comments.splice(index, 1);
        // we're dealing with a comment
      } else if (trackId) {
        state.tracksComments[trackId].data = deleteItemFromObj(
          state.tracksComments[trackId].data,
          commentId,
        );
      }
    },
    fetchCommentById: (state) => {
      state.isFetchingById = true;
      state.errorFetchById = undefined;
    },
    errorFetchCommentById: (
      state,
      { payload: message }: PayloadAction<string>,
    ) => {
      state.isFetchingById = false;
      state.errorFetchById = message;
    },
  },
});
