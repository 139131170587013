/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Track, Filter, NewTrack, Selection } from '../../lib/api/tracks';
import { Pagination } from '../../lib/api/Pagination';
import deleteItemFromObj from '../../lib/utils';
import PaginatedEntries from '../PaginatedEntries';
import { User } from '../../lib/api/user';
import { GenreIdentifier } from '../../lib/api/genres';
import commentsReducer from './comments';
import { getAllPaginatedItems } from '../index';

const { actions: commentsActions } = commentsReducer;

// extend to reflect all paginated types
export const TrackTypes = {
  defaultPaginated: 'DEFAULT_PAGINATED',
  newest: 'NEWEST',
  filteredRandom: 'FILTERED_RANDOM',
  likedTracks: 'LIKED_TRACKS',
  userTracks: 'USER_TRACKS',
  chartTracks: 'CHART_TRACKS',
  myTracks: 'MY_TRACKS',
};

export interface State {
  fetching: Record<number, boolean>;

  isFetching: boolean;
  isFetchingTopTen: boolean;
  isFetchingNewest: boolean;
  isFetchingFilteredRandom: boolean;
  isFetchingChartsTracks: Record<GenreIdentifier, boolean>;
  isFetchingGenreSets: Record<GenreIdentifier, boolean>;
  isFetchingTopArtistsGenreSets: Record<number, boolean>;
  isFetchingUserTracks: boolean;
  isFetchingLikedTracks: boolean;
  isFetchingTrackById: boolean;
  isFetchingRelatedTracks: boolean;
  isFetchingMyTracks: boolean;
  isFetchingDeleteTrack: boolean;
  isFetchingSelection: Partial<Record<Selection, boolean>>;
  isFetchingPopularGenres: boolean;

  isLikingTrack: boolean;
  isRemovingLike: boolean;
  isUploadingCover: boolean;

  success?: string | boolean;
  error?: string;
  entries: Record<number, Track>;
  filters: Filter;
  topTenIds: number[];
  defaultPaginated: PaginatedEntries;
  filteredRandom: PaginatedEntries;
  chartTracks: Record<GenreIdentifier, PaginatedEntries>;
  genreSets: Record<GenreIdentifier, PaginatedEntries>;
  topArtistsGenreSets: Record<number, PaginatedEntries>;
  newestTracks: PaginatedEntries;
  userTracks: Record<number, PaginatedEntries | undefined>;
  likedTracks: PaginatedEntries;
  relatedTracksIds: Record<number, number[]>;
  singleTrackId: number;
  selections: Record<Selection, PaginatedEntries>;
  popularGenres: number[];

  errorTopTen: string;
  errorNewest: string;
  errorFilteredRandom: string;
  errorChartsTracks: string;
  errorUserTracks: string;
  errorLikedTracks: string;
  errorTrackById: string;
  errorRelatedTracks: string;
  errorMyTracks: string;
  errorSearchUserTracks?: string;
  errorDeleteTrack?: string;
  errorLikeTrack?: string;
  errorRemoveLike?: string;
  errorUpdateCover: string;
  errorPopularGenres: string;
}

export const defaultState: State = {
  fetching: {},
  isFetching: false,
  isFetchingTopTen: false,
  isFetchingNewest: false,
  isFetchingFilteredRandom: false,
  isFetchingChartsTracks: {
    all: false,
    top10VirppPicks: false,
  },
  isFetchingGenreSets: {
    all: false,
    top10VirppPicks: false,
  },
  isFetchingTopArtistsGenreSets: {},
  isFetchingUserTracks: false,
  isFetchingLikedTracks: false,
  isFetchingTrackById: false,
  isFetchingRelatedTracks: false,
  isFetchingMyTracks: false,
  isFetchingDeleteTrack: false,
  isFetchingPopularGenres: false,
  isLikingTrack: false,
  isRemovingLike: false,
  isUploadingCover: false,
  isFetchingSelection: {},
  success: undefined,
  error: undefined,
  entries: {},
  filters: {},
  topTenIds: [],
  defaultPaginated: {
    data: {},
  },
  filteredRandom: {
    data: {},
  },
  chartTracks: {
    all: {
      data: {},
    },
    top10VirppPicks: {
      data: {},
    },
  },
  genreSets: {
    all: {
      data: {},
    },
    top10VirppPicks: {
      data: {},
    },
  },
  topArtistsGenreSets: {},
  newestTracks: {
    data: {},
  },
  userTracks: {},
  likedTracks: {
    data: {},
  },
  relatedTracksIds: {},
  singleTrackId: 0,
  selections: {
    editors_pick: {
      data: [],
    },
    new_selection: {
      data: [],
    },
    weekly_selections: {
      data: [],
    },
  },
  popularGenres: [],
  errorTopTen: '',
  errorNewest: '',
  errorFilteredRandom: '',
  errorChartsTracks: '',
  errorUserTracks: '',
  errorLikedTracks: '',
  errorTrackById: '',
  errorRelatedTracks: '',
  errorMyTracks: '',
  errorSearchUserTracks: '',
  errorDeleteTrack: '',
  errorLikeTrack: '',
  errorRemoveLike: '',
  errorUpdateCover: '',
  errorPopularGenres: '',
};

export default createSlice({
  name: 'TRACKS',
  initialState: defaultState,
  reducers: {
    addTrackEntries: (
      state,
      { payload: tracks }: PayloadAction<Track | Track[]>,
    ) => {
      const tracksToAdd = Array.isArray(tracks) ? tracks : [tracks];
      tracksToAdd.forEach((track) => {
        const currentTrack = state.entries[track.id] || {};
        state.entries[track.id] = {
          ...currentTrack,
          ...track,
        };
      });
    },
    requestTracks: (state) => {
      state.isFetching = true;
    },
    receiveTracks: (
      state,
      action: PayloadAction<
        { pagination: Pagination; tracks: Track[] } | undefined
      >,
    ) => {
      state.isFetching = false;
      state.success = true;
      if (action.payload) {
        const { tracks, pagination } = action.payload;
        state.defaultPaginated.data[pagination.currentPage] = tracks.map(
          (track) => track.id,
        );
        state.defaultPaginated.pagination = pagination;
      }
    },

    // archive all active tracks of current user
    archiveTracks: (state, action: PayloadAction<number>) => {
      const myTracksData = state.userTracks[action.payload];
      const myTracks = getAllPaginatedItems(myTracksData, state.entries);

      myTracks.forEach((track) => {
        if (track.status === 'active') {
          state.entries[track.id].status = 'archived';
        }
      });
    },

    activateTrack: (state, action: PayloadAction<number>) => {
      state.entries[action.payload].status = 'active';
    },

    errorTracks: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      state.success = false;
      state.error = action.payload;
    },
    requestTopTen: (state) => {
      state.isFetchingTopTen = true;
    },
    receiveTopTen: (state, action: PayloadAction<Track[]>) => {
      const { payload } = action;
      state.isFetchingTopTen = false;
      state.topTenIds = payload.map((track: Track) => track.id);
    },
    errorTracksTopTen: (state, action: PayloadAction<string>) => {
      state.isFetchingTopTen = false;
      state.errorTopTen = action.payload;
    },
    requestNewest: (state) => {
      state.isFetchingNewest = true;
    },
    receiveNewest: (
      state,
      action: PayloadAction<{ tracks: Track[]; pagination: Pagination }>,
    ) => {
      state.isFetchingNewest = false;
      const {
        payload: { tracks, pagination },
      } = action;
      state.newestTracks.data[pagination.currentPage] = tracks.map(
        (track) => track.id,
      );
      state.newestTracks.pagination = pagination;
    },
    errorTracksNewest: (state, action: PayloadAction<string>) => {
      state.isFetchingNewest = false;
      state.errorNewest = action.payload;
    },
    requestFilteredRandom: (state) => {
      state.isFetchingFilteredRandom = true;
    },
    receiveFilteredRandom: (
      state,
      action: PayloadAction<{ tracks: Track[]; pagination: Pagination }>,
    ) => {
      state.isFetchingFilteredRandom = false;

      const {
        payload: { tracks, pagination },
      } = action;

      state.filteredRandom.data[pagination.currentPage] = tracks.map(
        (track) => track.id,
      );

      state.filteredRandom.pagination = pagination;
    },
    errorTracksFilteredRandom: (state, action: PayloadAction<string>) => {
      state.isFetchingFilteredRandom = false;
      state.errorFilteredRandom = action.payload;
    },
    addTracksFilter: (state, action: PayloadAction<Filter>) => {
      state.filters = action.payload;
    },
    requestChartsTracks: (
      state,
      { payload: genre }: PayloadAction<GenreIdentifier>,
    ) => {
      state.isFetchingChartsTracks[genre] = true;
    },
    receiveChartsTracks: (
      state,
      {
        payload: { tracks, pagination, genre },
      }: PayloadAction<{
        tracks: Track[];
        pagination: Pagination;
        genre: GenreIdentifier;
      }>,
    ) => {
      state.isFetchingChartsTracks[genre] = false;
      if (state.chartTracks[genre] === undefined) {
        state.chartTracks[genre] = {
          data: {},
        };
      }
      state.chartTracks[genre].data[pagination.currentPage] = tracks.map(
        (track) => track.id,
      );
      state.chartTracks[genre].pagination = pagination;
    },
    errorChartsTracks: (
      state,
      {
        payload: { error, genre },
      }: PayloadAction<{ error: string; genre: GenreIdentifier }>,
    ) => {
      state.isFetchingChartsTracks[genre] = false;
      state.errorChartsTracks = error;
    },
    requestGenreSet: (
      state,
      { payload: genreId }: PayloadAction<GenreIdentifier>,
    ) => {
      state.isFetchingGenreSets[genreId] = true;
    },
    receiveGenreSet: (
      state,
      {
        payload: { genreId, pagination, tracks },
      }: PayloadAction<{
        genreId: number;
        pagination: Pagination;
        tracks: Track[];
      }>,
    ) => {
      state.isFetchingGenreSets[genreId] = false;
      if (state.genreSets[genreId] === undefined) {
        state.genreSets[genreId] = {
          data: {},
        };
      }
      state.genreSets[genreId].data[pagination.currentPage] = tracks.map(
        (track) => track.id,
      );
      state.genreSets[genreId].pagination = pagination;
    },
    requestTopArtistsGenreSet: (
      state,
      { payload: genreId }: PayloadAction<number>,
    ) => {
      state.isFetchingTopArtistsGenreSets[genreId] = true;
    },
    receiveTopArtistsGenreSet: (
      state,
      {
        payload: { genreId, pagination, tracks },
      }: PayloadAction<{
        genreId: number;
        pagination: Pagination;
        tracks: Track[];
      }>,
    ) => {
      state.isFetchingTopArtistsGenreSets[genreId] = false;
      if (state.topArtistsGenreSets[genreId] === undefined) {
        state.topArtistsGenreSets[genreId] = {
          data: {},
        };
      }
      state.topArtistsGenreSets[genreId].data[
        pagination.currentPage
      ] = tracks.map((track) => track.id);
      state.topArtistsGenreSets[genreId].pagination = pagination;
    },
    requestUserTracks: (state) => {
      state.isFetchingUserTracks = true;
    },
    receiveUserTracks: (
      state,
      action: PayloadAction<{
        userId: number;
        tracks: Track[];
        pagination: Pagination;
      }>,
    ) => {
      const {
        payload: { userId, tracks, pagination },
      } = action;
      state.isFetchingUserTracks = false;

      if (!state.userTracks[userId]) {
        state.userTracks[userId] = {
          data: { [pagination.currentPage]: tracks.map((track) => track.id) },
          pagination,
        };
      } else {
        state.userTracks[userId]!.data[pagination.currentPage] = tracks.map(
          (track) => track.id,
        );
        state.userTracks[userId]!.pagination = pagination;
      }
    },
    errorUserTracks: (state, action: PayloadAction<string>) => {
      state.isFetchingUserTracks = false;
      state.errorUserTracks = action.payload;
    },
    requestLikedTracks: (state) => {
      state.isFetchingLikedTracks = true;
    },
    receiveLikedTracks: (
      state,
      action: PayloadAction<{ tracks: Track[]; pagination: Pagination }>,
    ) => {
      const {
        payload: { tracks, pagination },
      } = action;
      state.isFetchingLikedTracks = false;
      state.likedTracks.data[pagination.currentPage] = Array.from(
        new Set(tracks.map((track: Track) => track.id)),
      );
      state.likedTracks.pagination = pagination;
    },
    errorLikedTracks: (state, action: PayloadAction<string>) => {
      state.isFetchingLikedTracks = false;
      state.errorLikedTracks = action.payload;
    },
    requestTrackById: (state) => {
      state.isFetchingTrackById = true;
    },
    receiveTrackById: (state, action: PayloadAction<Track>) => {
      state.isFetchingTrackById = false;
      const { payload } = action;
      state.entries[payload.id] = payload;
      state.singleTrackId = payload.id;
    },
    errorTrackById: (state, action: PayloadAction<string>) => {
      state.isFetchingTrackById = false;
      state.errorTrackById = action.payload;
    },
    requestRelatedTracks: (state) => {
      state.isFetchingRelatedTracks = true;
    },
    receiveRelatedTracks: (
      state,
      {
        payload: { tracks, trackId },
      }: PayloadAction<{ tracks: Track[]; trackId: number }>,
    ) => {
      state.isFetchingRelatedTracks = false;
      state.relatedTracksIds[trackId] = tracks.map((track: Track) => track.id);
    },
    errorRelatedTracks: (state, action: PayloadAction<string>) => {
      state.isFetchingRelatedTracks = false;
      state.errorRelatedTracks = action.payload;
    },
    requestSearchUserTracks: (state) => {
      state.isFetchingUserTracks = true;
    },
    errorSearchUserTracks: (state, { payload }: PayloadAction<string>) => {
      state.errorSearchUserTracks = payload;
    },
    requestDeleteTrack: (state) => {
      state.isFetchingDeleteTrack = true;
    },
    receiveDeleteTrack: (
      state,
      { payload }: PayloadAction<{ trackId: number; userId: number }>,
    ) => {
      if (!state.userTracks[payload.userId]) {
        throw new Error('Cant delete track from non-existing userTracks');
      }
      state.userTracks[payload.userId]!.data = deleteItemFromObj(
        state.userTracks[payload.userId]!.data,
        payload.trackId,
      );
    },
    errorDeleteTrack: (state, { payload }: PayloadAction<string>) => {
      state.errorDeleteTrack = payload;
    },
    requestLikeTrack: (state) => {
      state.isLikingTrack = true;
    },
    confirmLikeTrack: (state, { payload }: PayloadAction<number>) => {
      state.entries[payload].logged_in_user_liked_track = true;
      state.isLikingTrack = false;
    },
    errorLikeTrack: (state, { payload }: PayloadAction<string>) => {
      state.errorLikeTrack = payload;
      state.isLikingTrack = false;
    },
    requestRemoveLike: (state) => {
      state.isRemovingLike = true;
    },
    confirmRemoveLike: (state, { payload }: PayloadAction<number>) => {
      state.entries[payload].logged_in_user_liked_track = false;
      state.likedTracks.data = deleteItemFromObj(
        state.likedTracks.data,
        payload,
      );
      state.isRemovingLike = false;
    },
    errorRemoveLike: (state, { payload }: PayloadAction<string>) => {
      state.errorRemoveLike = payload;
      state.isRemovingLike = false;
    },
    requestMyTracks: (state) => {
      state.isFetchingMyTracks = true;
    },
    receiveMyTracks: (
      state,
      {
        payload,
      }: PayloadAction<{
        tracks: (Track | NewTrack)[];
        pagination: Pagination;
        myUserId: number;
      }>,
    ) => {
      const { tracks, pagination, myUserId } = payload;
      if (!state.userTracks[myUserId]) {
        state.userTracks[myUserId] = {
          data: {
            [pagination.currentPage]: tracks.map((track) => track.id),
          },
          pagination,
        };
      } else {
        state.userTracks[myUserId]!.data[pagination.currentPage] = tracks.map(
          (track: Track | NewTrack) => track.id,
        );
        state.userTracks[myUserId]!.pagination = pagination;
      }
      state.isFetchingMyTracks = false;
    },
    errorMyTracks: (state, action: PayloadAction<string>) => {
      state.errorMyTracks = action.payload;
      state.isFetchingMyTracks = false;
    },
    addTrackLinkId: (state, { payload }: PayloadAction<number>) => {
      state.singleTrackId = payload;
    },
    requestUpdateCover: (state) => {
      state.errorUpdateCover = '';
      state.isUploadingCover = true;
    },
    receiveUpdateCover: (state) => {
      state.isUploadingCover = false;
    },
    errorUpdateCover: (state, { payload }: PayloadAction<string>) => {
      state.errorUpdateCover = payload;
      state.isUploadingCover = false;
    },
    resetPaginatedTracks: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case TrackTypes.newest:
          state.newestTracks.data = {};
          break;
        case TrackTypes.filteredRandom:
          state.filteredRandom.data = {};
          // state.filters = {};
          break;
        case TrackTypes.likedTracks:
          state.likedTracks.data = {};
          break;
        case TrackTypes.userTracks:
          // TODO: what is going on here?
          // state.userTracks.data = {};
          break;
        // case TrackTypes.chartTracks:
        //   state.chartTracks.data = {};
        //   break;
        case TrackTypes.defaultPaginated:
          state.defaultPaginated.data = {};
          break;
        default:
          break;
      }
    },
    requestFetch: (state, { payload: ids }: PayloadAction<number[]>) => {
      ids.forEach((id) => {
        state.fetching[id] = true;
      });
    },
    receiveFetch: (state, { payload: ids }: PayloadAction<number[]>) => {
      ids.forEach((id) => {
        delete state.fetching[id];
      });
    },
    acceptCollaborationSuccess: (
      state,
      {
        payload: { trackId, user },
      }: PayloadAction<{ trackId: number; user: User }>,
    ) => {
      // Remove current user from pending collaborators and add it to collaborators
      const track = state.entries[trackId];

      const trackUpdated: Track = {
        ...track,
        pending_collaborators: track.pending_collaborators?.filter(
          (collaborator) => collaborator.id !== user.id,
        ),
        collaborators: [...track.collaborators, user],
      };

      state.entries[trackId] = trackUpdated;
    },
    declineCollaborationSuccess: (
      state,
      {
        payload: { trackId, userId },
      }: PayloadAction<{ trackId: number; userId: number }>,
    ) => {
      state.entries[trackId].pending_collaborators = state.entries[
        trackId
      ]?.pending_collaborators?.filter(
        (collaborator) => collaborator.id !== userId,
      );
    },
    feedbackSent: (state, { payload: trackId }: PayloadAction<number>) => {
      state.entries[trackId].requested_my_feedback = false;
    },
    feedbackRequested: (state, { payload: trackId }: PayloadAction<number>) => {
      state.entries[trackId].requested_my_feedback = true;
    },
    fetchSelection: (
      state,
      { payload: selection }: PayloadAction<Selection>,
    ) => {
      state.isFetchingSelection[selection] = true;
    },
    receiveSelection: (
      state,
      {
        payload: { tracks, pagination, selection },
      }: PayloadAction<{
        tracks: number[];
        pagination: Pagination;
        selection: Selection;
      }>,
    ) => {
      state.isFetchingSelection[selection] = false;
      state.selections[selection].data[pagination.currentPage] = tracks;
      state.selections[selection].pagination = pagination;
    },
    fetchPopularGenres: (state) => {
      state.errorPopularGenres = '';
      state.isFetchingPopularGenres = true;
    },
    receivePopularGenres: (
      state,
      { payload }: PayloadAction<{ tracks: Track[] }>,
    ) => {
      state.isFetchingPopularGenres = false;
      state.popularGenres = payload.tracks.map(({ id }) => id);
    },
    errorPopularGenres: (
      state,
      { payload }: PayloadAction<{ error: Error }>,
    ) => {
      state.isFetchingPopularGenres = false;
      state.errorPopularGenres = payload.error.message;
    },
  },
  extraReducers: {
    [commentsActions.addTrackCommentSuccess.type]: (
      state,
      { payload: { trackId } },
    ) => {
      state.entries[trackId].total_comments += 1;
    },
  },
});
