import React from 'react';
import { CustomInput } from 'reactstrap';

const VuexyCheckbox = ({ ...props }: VuexyCheckboxProps) => (
  <CustomInput {...props} type="checkbox" />
);

interface VuexyCheckboxProps {
  name?: string;
  id?: string;
  placeholder?: string;
  value?: boolean | string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  defaultChecked?: boolean;
}
export default VuexyCheckbox;
